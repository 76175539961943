/*
<svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.1251 17.999H20.0001C18.8066 17.999 17.6621 17.5249 16.8181 16.681C15.9742 15.8371 15.5001 14.6925 15.5001 13.499V0.374023C15.5001 0.274567 15.4606 0.179185 15.3903 0.108858C15.32 0.0385323 15.2246 -0.000976563 15.1251 -0.000976562H6.50012C4.90882 -0.000976562 3.3827 0.631165 2.25748 1.75638C1.13226 2.8816 0.500122 4.40772 0.500122 5.99902V35.999C0.500122 37.5903 1.13226 39.1164 2.25748 40.2417C3.3827 41.3669 4.90882 41.999 6.50012 41.999H27.5001C29.0914 41.999 30.6175 41.3669 31.7428 40.2417C32.868 39.1164 33.5001 37.5903 33.5001 35.999V18.374C33.5001 18.2746 33.4606 18.1792 33.3903 18.1089C33.32 18.0385 33.2246 17.999 33.1251 17.999ZM24.5001 32.999H9.50012C9.1023 32.999 8.72077 32.841 8.43946 32.5597C8.15816 32.2784 8.00012 31.8969 8.00012 31.499C8.00012 31.1012 8.15816 30.7197 8.43946 30.4384C8.72077 30.1571 9.1023 29.999 9.50012 29.999H24.5001C24.8979 29.999 25.2795 30.1571 25.5608 30.4384C25.8421 30.7197 26.0001 31.1012 26.0001 31.499C26.0001 31.8969 25.8421 32.2784 25.5608 32.5597C25.2795 32.841 24.8979 32.999 24.5001 32.999ZM24.5001 25.499H9.50012C9.1023 25.499 8.72077 25.341 8.43946 25.0597C8.15816 24.7784 8.00012 24.3969 8.00012 23.999C8.00012 23.6012 8.15816 23.2197 8.43946 22.9384C8.72077 22.6571 9.1023 22.499 9.50012 22.499H24.5001C24.8979 22.499 25.2795 22.6571 25.5608 22.9384C25.8421 23.2197 26.0001 23.6012 26.0001 23.999C26.0001 24.3969 25.8421 24.7784 25.5608 25.0597C25.2795 25.341 24.8979 25.499 24.5001 25.499Z" fill="url(#paint0_linear_1392_23231)"/>
<defs>
<linearGradient id="paint0_linear_1392_23231" x1="17.0001" y1="-0.000976563" x2="17.0001" y2="41.999" gradientUnits="userSpaceOnUse">
<stop stop-color="#269DD6"/>
<stop offset="1" stop-color="#1F618A"/>
</linearGradient>
</defs>
</svg>

*/

import type { SVG } from './types';

export const Document = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '48'} height={height || '48'} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.1251 20.999H27.0001C25.8066 20.999 24.6621 20.5249 23.8181 19.681C22.9742 18.8371 22.5001 17.6925 22.5001 16.499V3.37402C22.5001 3.27457 22.4606 3.17918 22.3903 3.10886C22.32 3.03853 22.2246 2.99902 22.1251 2.99902H13.5001C11.9088 2.99902 10.3827 3.63116 9.25748 4.75638C8.13226 5.8816 7.50012 7.40772 7.50012 8.99902V38.999C7.50012 40.5903 8.13226 42.1164 9.25748 43.2417C10.3827 44.3669 11.9088 44.999 13.5001 44.999H34.5001C36.0914 44.999 37.6175 44.3669 38.7428 43.2417C39.868 42.1164 40.5001 40.5903 40.5001 38.999V21.374C40.5001 21.2746 40.4606 21.1792 40.3903 21.1089C40.32 21.0385 40.2246 20.999 40.1251 20.999ZM31.5001 35.999H16.5001C16.1023 35.999 15.7208 35.841 15.4395 35.5597C15.1582 35.2784 15.0001 34.8969 15.0001 34.499C15.0001 34.1012 15.1582 33.7197 15.4395 33.4384C15.7208 33.1571 16.1023 32.999 16.5001 32.999H31.5001C31.8979 32.999 32.2795 33.1571 32.5608 33.4384C32.8421 33.7197 33.0001 34.1012 33.0001 34.499C33.0001 34.8969 32.8421 35.2784 32.5608 35.5597C32.2795 35.841 31.8979 35.999 31.5001 35.999ZM31.5001 28.499H16.5001C16.1023 28.499 15.7208 28.341 15.4395 28.0597C15.1582 27.7784 15.0001 27.3969 15.0001 26.999C15.0001 26.6012 15.1582 26.2197 15.4395 25.9384C15.7208 25.6571 16.1023 25.499 16.5001 25.499H31.5001C31.8979 25.499 32.2795 25.6571 32.5608 25.9384C32.8421 26.2197 33.0001 26.6012 33.0001 26.999C33.0001 27.3969 32.8421 27.7784 32.5608 28.0597C32.2795 28.341 31.8979 28.499 31.5001 28.499Z" fill="url(#paint0_linear_1392_23230)" />
      <path d="M39.3068 17.6773L25.8246 4.19515C25.7984 4.16909 25.765 4.15135 25.7287 4.14419C25.6925 4.13702 25.6549 4.14073 25.6207 4.15485C25.5866 4.16898 25.5573 4.19289 25.5367 4.22358C25.5161 4.25427 25.505 4.29037 25.5049 4.32734V16.497C25.5049 16.8949 25.6629 17.2764 25.9442 17.5577C26.2255 17.839 26.6071 17.997 27.0049 17.997H39.1746C39.2115 17.9969 39.2476 17.9858 39.2783 17.9652C39.309 17.9446 39.3329 17.9153 39.3471 17.8812C39.3612 17.847 39.3649 17.8094 39.3577 17.7732C39.3506 17.7369 39.3328 17.7036 39.3068 17.6773Z" fill="url(#paint1_linear_1392_23230)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23230" x1="24.0001" y1="2.99902" x2="24.0001" y2="44.999" gradientUnits="userSpaceOnUse">
          <stop stop-color="#269DD6" />
          <stop offset="1" stop-color="#1F618A" />
        </linearGradient>
        <linearGradient id="paint1_linear_1392_23230" x1="32.4331" y1="4.14062" x2="32.4331" y2="17.997" gradientUnits="userSpaceOnUse">
          <stop stop-color="#269DD6" />
          <stop offset="1" stop-color="#1F618A" />
        </linearGradient>
      </defs>
    </svg>

  );
};
