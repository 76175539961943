
import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuLayout from '../../../components/UI/SideMenu/SideMenuLayout';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import UserSettings from '../../User/UserSettings';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuV2Layout title={t('Settings')}>
      <UserSettings />
    </SideMenuV2Layout>
  );
};

export default SettingsPage;
