import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { cloudUpload } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { networking } from '../../../../api/networking';
import RelatedSelect from '../../../../components/Search/RelatedSelect/RelatedSelect';
import type { Result } from '../../../../components/Search/RelatedSelect/RelatedSelectInterface';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import DragAndDrop from '../../../../components/UI/DragAnddrop/DragAndDrop';
import { useAppSelector } from '../../../../hooks';
import useCameraUpload from '../../../../hooks/useCameraUpload';
import useFileUpload from '../../../../hooks/useFileUpload';
import useFocusNextInput from '../../../../hooks/useFocusNextInput';
import FileList from '../../../../pages/Tools/SiteAccessRequests/Settings/FileList.tsx';
import styles from '../ControlOfExecution.module.scss';

interface ButtonInterface {
  toggleBack: () => void
  onDelete: () => void;
  fetchOnSubmit: (recordId?: string) => void;
  record: Partial<E2U.V1.Models.ControlOfExecution>;
}

const EditModal: React.FC<ButtonInterface> = ({ fetchOnSubmit, record }) => {
  const [filesToRemove] = useState<E2U.V1.Models.File[]>([]);
  const { formRef, handleNextInput } = useFocusNextInput();
  const { t } = useTranslation();
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const project = useAppSelector(state => state.project.selectedProject);
  const user = useAppSelector(state => state.authentication.user);
  const form = useForm<E2U.V1.Models.ControlOfExecution>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      activity_code_id: undefined,
      assigned_user_id: user?.id,
      assigned_user: user,
      name: '',
      description: '',
      project_id: project?.id,
      confirmed: 0,
      assignees: [],
      requires_supervisor: false || undefined,
      files: [],
    },
  });

  const { control, formState, watch } = form;
  const { project_id } = watch();
  const files = fileProps.getUploadedFiles() ?? [];
  const photos = cameraProps.getUploadedPhotos() ?? [];

  const handlePost: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const isUpdating = Boolean(record && record.id);
    const request = isUpdating
      ? networking.put(`/api/v1/control_of_executions/${record.id}`, data)
      : networking.post(`/api/v1/control_of_executions`, data);

    toasters.promise(new Promise<any>((resolve, reject) => {
      request.then((saveRequest: E2U.V1.Response.Success<E2U.V1.Models.ControlOfExecution>) => {
        const finishedPromises: Promise<any>[] = [
          fileProps.uploadSelectedFiles(`/api/v1/control_of_executions`, saveRequest.data.data.id ?? ''),
          cameraProps.uploadSelectedPhotos(`/api/v1/control_of_executions`, saveRequest.data.data.id ?? '')
        ];

        if (isUpdating && record.assignees && record.assignees.length) {
          const existingAssigneeIds = data.assignees.map((r: E2U.V1.Models.User) => r.id);
          const assigneesToRemove: E2U.V1.Models.User[] = record.assignees.filter(
            (assignee: E2U.V1.Models.User) => !existingAssigneeIds.includes(assignee.id)
          );
          finishedPromises.push(
            Promise.all(
              assigneesToRemove.map((user: E2U.V1.Models.User) => networking.delete(
                `/api/v1/control_of_executions/${saveRequest.data.data.id}/assignees/${user.id}`
              )
                .catch((error) => {
                  Sentry.captureException(error);
                  toast(t(`Failed to remove assignee ${user.name} from control of execution`), {
                    type: 'error'
                  });
                })
              )
            )
          );
        }
        if (data.assignees.length) {
          finishedPromises.push(networking.post(
            `/api/v1/control_of_executions/${saveRequest.data.data.id}/assignees/${user?.id}`
          )
            .catch((error) => {
              Sentry.captureException(error);
              toast(t(`Failed to add assignee ${user?.name} to control of execution`), {
                type: 'error'
              });
            }));
        }
        if (filesToRemove.length) {
          finishedPromises.push(
            Promise.all(
              filesToRemove.map(
                (file: E2U.V1.Models.File) => networking.delete(
                  `/api/v1/control_of_executions/${saveRequest.data.data.id}/files/${file.id}`
                )
                  .catch((error) => {
                    Sentry.captureException(error);
                    toast(t(`Failed to remove file ${file.name} from control of execution`), {
                      type: 'error'
                    });
                  })
              )
            )
          );
        }
        Promise.all(finishedPromises)
          .finally(() => {
            fetchOnSubmit(isUpdating ? undefined : saveRequest.data.data.id, true);
            resolve(undefined);
          });
      })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    }), {
      pending: t('Saving Control of Execution'),
      success: t('Saved Control of Execution'),
      error: t('Error while saving Control of Execution'),
    });
  };

  const deleteFile = (id: E2U.V1.Models.File['id']) => {
    networking.delete(`/api/v1/files/${id}`)
      .then(() => {
        fetchOnSubmit(record.id);
        toasters.success(t('File deleted successfully'));
      });
  };

  useEffect(() => {
    form.setValue('requires_supervisor', record?.requires_supervisor || false);
  }, [record]);

  useEffect(() => {
    if (record) {
      if (record.files) {
        form.setValue('files', record.files, { shouldDirty: true });
      }
      if (record.assignees) {
        form.setValue('assignees', record.assignees, { shouldDirty: true });
      }
      if (record.activity_code_id) {
        form.setValue('activity_code_id', record.activity_code_id, { shouldDirty: true });
      }
      if (record.name) {
        form.setValue('name', record.name, { shouldDirty: true });
      }
      if (record.description) {
        form.setValue('description', record.description, { shouldDirty: true });
      }
      if (record.project_id) {
        form.setValue('project_id', record.project_id, { shouldDirty: true });
      }
    }
  }, [record]);

  return (
    <FormProvider {...form}>
      <IonGrid className='ion-padding-horizontal'>
        <form ref={formRef} onSubmit={form.handleSubmit(handlePost)} className={styles['record-view-edit-form']}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '50%',
              flexDirection: 'column',
              marginBottom: '16px'
            }}>
            <BigUp.Label.Thick
              label={`${t('Activity Code')} *`}
            />
            <Controller
              control={form.control}
              name='activity_code_id'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <RelatedSelect
                  buttonV2={true}
                  infiniteScroll={true}
                  value={value}
                  model="activity_codes"
                  onSelect={(data: Result) => onChange(data.id)}
                  title={t('Select activity code')}
                  displayFields={['code', 'name']}
                  fetchFields={['activityCategory', 'code_suffix']}
                />
              )}
            />
          </div>

          <input value={project_id} type='text' hidden />
          <BigUp.OutlinedInput
            id='title'
            customLabel={t('Title')}
            onKeyUp={handleNextInput}
            name='title'
            inputMode='text'
            autoCapitalize='sentences'
            placeholder={t('Title of the completed task')}
            register={'name'}
            onIonInput={(e: any) => form.setValue('name', e.detail.value)}
            validation={{
              required: { value: true, message: t('Title is required') },
              min: { value: 3, message: t('Title must be at least 3 characters long') },
              max: { value: 42, message: t('Title must be at most 42 characters long') }
            }}
          />

          <div className='ion-margin-vertical'>
            <BigUp.TextareaOutlined
              id='description'
              customLabel={t('Description')}
              name='description'
              inputMode='text'
              enterkeyhint='done'
              autoCapitalize='sentences'
              placeholder={t('Enter a description')}
              register={'description'}
              validation={{
                required: t('Description is required'),
                min: { value: 3, message: t('Description must be at least 3 characters long') },
                max: { value: 255, message: t('Description must be at most 255 characters long') }
              }}
            />
          </div>
          <IonGrid className={'ion-no-padding ion-margin-bottom'}>
            <IonRow className='ion-justify-content-between ion-no-padding ion-align-items-center'>
              <IonCol size='auto' className={'ion-no-padding'}>
                <BigUp.Label.Regular label={t('Images and files')} className='ion-no-margin' color='medium' />
              </IonCol>
              <IonCol size='auto' className={'ion-no-padding'}>
                <BigUp.ActionSheets.MediaUpload
                  cameraProps={cameraProps}
                  fileProps={fileProps}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          {isDesktop
            ? (
              <DragAndDrop
                onFilesSelect={(files) => {
                  fileProps.handleFileSelection(files);
                  form.trigger();
                }}
              >
                <IonIcon icon={cloudUpload} style={{ fontSize: 40 }} color='medium' />
                <BigUp.Label.Regular className='ion-no-margin ion-margin-start'
                  label={t('Drag and drop files here or click to upload')} color={'medium'} />
              </DragAndDrop>

            )
            : <></>
          }
          <IonGrid className={'ion-no-padding ion-margin-vertical'}>
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className={'ion-no-padding'}>
                <FileList
                  files={files}
                  photos={photos}
                  removeFile={fileProps.removeFile}
                  removePhoto={cameraProps.removePhoto}
                  cardSize={'full'}
                />
                <FileList
                  files={record?.files || []}
                  photos={[]}
                  handleDeleteRequest={(file: E2U.V1.Models.File) => deleteFile(file.id)}
                  cardSize={'full'}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <Controller
            control={control}
            name='requires_supervisor'
            render={({ field: { onChange, value } }) => (
              <BigUp.Toggles.Toggle
                title={t('Does this require a supervisor?')}
                onIonChange={({ detail: { checked } }) => onChange(checked)}
                checked={value === true}
              />
            )}
          />
          <IonRow className='ion-justify-content-center'>
            <IonCol size='auto'>
              <BigUp.Buttons.Primary title={t('Save')} type='submit'
                disabled={formState.isSubmitting || !formState.isValid} />
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </FormProvider>
  );
};

export default EditModal;
