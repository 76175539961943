import { IonContent, IonList, IonSplitPane } from '@ionic/react';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import styles from './SideMenuV2.module.scss';
import SideMenuV2Options from './SideMenuV2Options';
import { SideMenuV2Submenus } from './Submenus';
import SubmenuRoot from './Submenus/SubmenuRoot';
import { useAppSelector } from '../../../../hooks';
import AppLayout from '../../../../layout/AppLayout';
import storage from '../../../../storage';
import SkeletonTextThreeLines from '../../../SkeletonComponents/SkeletonTextThreeLines';
import ReturnChevronText from '../../Return/ReturnChevronText';
import type { ColourVariables } from '../../variables';

export type BaseUrlOptions = 'financials' | 'tools' | 'notice-board';

interface DynamicContentProps {
  children: React.ReactNode;
  canReturn?: boolean;
  title?: string;
  contentColour?: ColourVariables;
  paddedPage?: boolean;
  showDocumentActionsButton?: boolean;
}

const SideMenuV2Layout: React.FC<DynamicContentProps> = ({
  canReturn = false,
  children,
  contentColour = 'var(--ion-color-light)',
  paddedPage = true,
  showDocumentActionsButton = false,
  title
}) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [activeTab, setActiveTab] = useState<string | undefined>('home');
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);

  const handleActiveTabChange = (tab: string) => {
    storage.set('activeTab', tab).then(() => {
      setActiveTab(tab);
    });
  };

  useEffect(() => {
    storage.get('activeTab').then((tab: string) => {
      if (tab) {
        setActiveTab(tab);
      }
    });
  }, []);

  if (!isDesktop) {
    return (
      <AppLayout showDocumentActionsButton={showDocumentActionsButton} paddedPage={paddedPage} headerTitle={title} contentColour={contentColour}>
        {children}
      </AppLayout>
    );
  }

  return (
    <AppLayout scroll showDocumentActionsButton={showDocumentActionsButton}>
      <div className={styles['side-menu--base--holder']}>
        <IonSplitPane contentId="main" className={styles['side-menu--base']}>
          <SideMenuV2Options
            activeTab={activeTab}
            setActiveTab={handleActiveTabChange}
          />
          <SubmenuRoot
            submenuVisible={submenuVisible}
            setSubmenuVisible={setSubmenuVisible}
            hidden={!activeTab || !['projects', 'tools'].includes(activeTab)}
          >
            {activeTab === 'tools' &&
              <SideMenuV2Submenus.Tools
                submenuVisible={submenuVisible}
              />
            }
            {
              activeTab === 'projects' &&
              <SideMenuV2Submenus.Projects
                submenuVisible={submenuVisible}
              />
            }
          </SubmenuRoot>
          <div id="main">
            <IonContent color={'light'}>
              <div>
                {canReturn && (<IonList className={'ion-margin-bottom ion-padding-bottom'} style={{ height: '100%' }}>
                  <ReturnChevronText text={t('Back')} color={'none'} fill={'none'} />
                </IonList>)}
                {
                  isLoadingUser
                    ? <div className={'ion-padding-top'}>
                      <SkeletonTextThreeLines />
                    </div>
                    : children
                }
              </div>
            </IonContent>
          </div >
        </IonSplitPane>
      </div>
    </AppLayout>
  );
};

export default SideMenuV2Layout;
