import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import AccessManagementPage from './AccessManagementPage';
import MassAssignmentPage from './MassAssignmentPage';
import RequestSuccessfulPage from './RequestSuccessful';
import CreateAccountForm from '../../components/ClientAccount/CreateAccountForm';
import { matchUuid } from '../../constants/matchUuid';
import { AbilitiesWatchedRoute } from '../../routes/AbilitiesWatchedRoute';

const ClientAccountPage: React.FC = () => {
  return (
    <Switch>
      <AbilitiesWatchedRoute path={'/client-account/create'} exact={true}>
        <CreateAccountForm />
      </AbilitiesWatchedRoute>
      <AbilitiesWatchedRoute path={'/client-account/success'} exact={true}>
        <RequestSuccessfulPage />
      </AbilitiesWatchedRoute>
      <AbilitiesWatchedRoute path={`/client-account/:client_id(${matchUuid})/access/`} exact={true}>
        <AccessManagementPage type={'clients'} />
      </AbilitiesWatchedRoute>
      <Route path={`/client-account/:client_id(${matchUuid})/access/add/:tool?`}>
        <MassAssignmentPage type={'clients'} />
      </Route>
      <AbilitiesWatchedRoute path={'*'}>
        <Redirect to={'/'} />
      </AbilitiesWatchedRoute>
    </Switch>
  );
};

export default ClientAccountPage;
