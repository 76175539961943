import ActivityCode from './ActivityCode';
import Assignee from './Assignee';
import Attested from './AttestedTile';
import Description from './Description';

export const Tile = {
  Attested,
  ActivityCode,
  Assignee,
  Description
};
