import { IonAvatar, IonLabel } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';

import tileStyles from './Tiles.module.scss';
import { networking } from '../../../../api/networking';
import { useTableStyles } from '../../../../components/UI/Table';
import { getGravatarPicture } from '../../../../hooks/useProfilePicture';

const Assignee: React.FC<{ attributes: E2U.V1.Models.ControlOfExecution }> = (props) => {
  const styles = useTableStyles();
  const [assignee, setAssignee] = useState<E2U.V1.Models.ControlOfExecution['assigned_user']>();

  const findUser = () => {
    if (!props.attributes.assigned_user_id) {
      return;
    }
    networking.get(`/api/v1/users/${props.attributes.assigned_user_id}`)
      .then((response) => setAssignee(response.data.data));
  };

  useEffect(() => {
    findUser();
  }, []);

  return (
    <div className={styles.activity_code_column}>
      <div className={tileStyles['avatar-tile-wrapper']}>
        <IonAvatar slot="start" className='ion-no-margin'>
          {assignee && (
            <img src={getGravatarPicture('email' in assignee ? (assignee?.email ?? '') : '') ?? ''} alt={assignee?.first_name} />
          )}
        </IonAvatar>
        <IonLabel className='ion-no-margin'>{`${assignee?.first_name} ${assignee?.last_name}`}</IonLabel>
      </div>
    </div>
  );
};

export default Assignee;
