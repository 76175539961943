import {
  IonCol,
  IonGrid,
  IonRow,
  useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import {
  add,
} from 'ionicons/icons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AclCurtain from '../../../components/AclCurtain';
import DocumentTypeFormModal from '../../../components/DocumentTypeFormModal';
import BigUp from '../../../components/UI';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import { formatToNiceDate } from '../../../tools/formatDates';

const DocumentsListTable: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const projectId = useMemo(
    () => selectedProject && selectedProject.id,
    [selectedProject]
  );
  const router = useIonRouter();
  const [lastTypesFetch, setLastTypesFetch] = useState<number>(Date.now());
  const [createTypeModalOpen, setCreateTypeModalOpen] = useState<boolean>(false);

  const handleTypeSaved = () => {
    setLastTypesFetch(Date.now());
    setCreateTypeModalOpen(false);
  };

  return (
    <SideMenuV2Layout title={t('Documents')} showDocumentActionsButton={true}>
      <IonGrid>
        <IonRow className={'ion-wrap-reverse'}>
          <IonCol>
            <IonGrid>
              {isDesktop && (
                <IonRow>
                  <IonCol>
                    <BigUp.Title label={t('Documents')}/>
                  </IonCol>
                </IonRow>
              )}
              <IonRow className={'ion-align-items-center'}>
                <IonCol>
                  <BigUp.Label.V2Thick label={t('Categories')}/>
                </IonCol>
                <AclCurtain requiredAbilities={['can_store_document_types']}>
                  <IonCol size={'auto'}>
                    <BigUp.Buttons.Secondary
                      size={'small'}
                      title={t('Create')}
                      icon={{
                        icon: add,
                        slot: 'start',
                      }}
                      onClick={() => setCreateTypeModalOpen(true)}
                    />
                  </IonCol>
                </AclCurtain>
              </IonRow>
            </IonGrid>
            {projectId && <BigUp.Table
              sourceUrl={`/api/v1/projects/${projectId}/document_types`}
              columns={
                [{
                  key: 'name',
                  label: t('Name'),
                  alignment: 'left',
                  sizes: {
                    xs: '7',
                    sm: '7',
                    md: '8',
                    lg: '9',
                    xl: '9'
                  },
                  sortable: true
                }, {
                  key: 'last_document_change_ts',
                  label: t('Last updated'),
                  alignment: 'right',
                  sizes: {
                    xs: '5',
                    sm: '5',
                    md: '4',
                    lg: '3',
                    xl: '3'
                  }
                }]
              }
              reducers={{
                last_document_change_ts: (value: string) => formatToNiceDate(value),
              }}
              callbacks={{
                onRenderComplete: (rows: E2U.V1.Models.Type[], setRows) => {
                  setRows([{
                    id: 'uncategorized',
                    name: t('Uncategorized'),
                  },
                  ...rows]);
                },
                onRowClick: (row: E2U.V1.Models.Type) => router.push(`/tools/${projectId}/documents/category/${row.id}`),
              }}
              timestamp={lastTypesFetch}
            />}

            <DocumentTypeFormModal
              open={createTypeModalOpen}
              onClose={() => setCreateTypeModalOpen(false)}
              onSaved={() => handleTypeSaved()}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </SideMenuV2Layout>
  );
};

export default DocumentsListTable;
