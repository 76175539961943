import React, { useMemo } from 'react';

import { useAppSelector } from '../hooks';
import useAcl from '../hooks/useAcl';

const AclCurtain: React.FC<React.PropsWithChildren<any> & { requiredAbilities: string[]; }> = (props) => {
  const { children, requiredAbilities } = props;
  const { scopedAbilities } = useAcl();

  return requiredAbilities.every((ability: string) => scopedAbilities.includes(ability))
    ? children
    : (<></>);
};

export default AclCurtain;
