import { useIonPopover } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { downloadOutline, shareOutline, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FileList.module.scss';
import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import type { ActionSheetItem } from '../../../../components/UI/ActionSheets';
import type { PopoverItem } from '../../../../components/UI/Popovers';
import useModal from '../../../../hooks/useModal';
import storage from '../../../../storage';

interface FileListProps {
  files: E2U.V1.Models.File[];
  photos: E2U.V1.Models.File[];
  removeFile?: (file: E2U.V1.Models.File) => void;
  removePhoto?: (file: E2U.V1.Models.File) => void;
  handleDeleteRequest?: (file: E2U.V1.Models.File) => void;
  subLabel?: string;
  cardSize?: 'full' | 'default';
}

const FileList: React.FC<FileListProps> = (props) => {
  const { files, handleDeleteRequest, photos, removeFile, removePhoto } = props;
  const [selectedFile, setSelectedFile] = useState<E2U.V1.Models.File | null | any>(null);
  const [photoContent, setPhotoContent] = useState<{
    [fileHash: string]: string
  }>({});
  const { t } = useTranslation();
  const filePreviewModal = useModal();

  const downloadFile = (file: E2U.V1.Models.File) => {
    if (file.id === undefined) {
      if (file.file_hash && photoContent[`pending-${file.file_hash}`]) {
        const downloadLink = document.createElement('a');
        downloadLink.href = photoContent[`pending-${file.file_hash}`];
        downloadLink.download = file.name;
        downloadLink.click();
      }
      return;
    }
    networking.get(`/api/v1/files/${file.id}/share`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  };

  const deleteFile = (e: React.MouseEvent<HTMLIonButtonElement>, file: E2U.V1.Models.File) => {
    e.stopPropagation();

    if (file.type === 'image') {
      removePhoto && removePhoto(file);
    } else {
      removeFile && removeFile(file);
    }
  };

  const updatePhotoPreviews = () => {
    photos.forEach((photo: E2U.V1.Models.File) => {
      storage.get(`pending-${photo.file_hash}`).then((data) => {
        if (data) {
          setPhotoContent((prev) => ({
            ...prev,
            [`pending-${photo.file_hash}`]: data
          }));
        }
      });
    });
  };

  const getFileOptions = (file: E2U.V1.Models.File): (ActionSheetItem | PopoverItem)[] => [
    ...(file.type === 'image' || file.type === 'pdf'
      ? [
        {
          value: 'preview',
          label: t('Preview'),
          icon: {
            icon: shareOutline
          },
          onClick: () => {
            handlePreviewToggle('open', file);
            dismiss();
          }
        }
      ]
      : []),
    {
      value: 'download',
      label: t('Download'),
      icon: {
        icon: downloadOutline
      },
      onClick: () => {
        downloadFile(file);
        dismissPopover();
      }
    },
    {
      value: 'delete',
      label: t('Delete'),
      icon: {
        icon: trashOutline
      },
      onClick: (e: Event | undefined | React.MouseEvent<HTMLIonButtonElement>) => {
        handleDeleteRequest
          ? handleDeleteRequest(file)
          : deleteFile(e as React.MouseEvent<HTMLIonButtonElement>, file);
        dismissPopover();
      }
    }
  ];

  const [presentFileOptions, dismiss] = useIonPopover(BigUp.Popovers.Default, {
    items: selectedFile ? getFileOptions(selectedFile) : [],
    mode: 'md',
    event: undefined,
    onDidDismiss: () => setSelectedFile(null),
  });

  const dismissPopover = () => {
    setSelectedFile(null);
    dismiss();
  };

  const handlePreviewToggle = (action: 'open' | 'close', file?: File | any) => {
    action === 'open' ? setSelectedFile(file) : setSelectedFile(null);
    action === 'open' ? filePreviewModal.openModal() : filePreviewModal.closeModal();
  };

  useEffect(() => {
    updatePhotoPreviews();
  }, [photos]);

  return (
    <div className={styles['file-list']}>
      {files.concat(photos).map((file: E2U.V1.Models.File, i) => {
        return (
          <BigUp.Cards.FileCard
            key={i}
            fileType={file.type}
            name={file.name}
            subLabel={props.subLabel}
            onEllipsisClick={(e) => {
              setSelectedFile(file);
              presentFileOptions({ event: e?.nativeEvent });
            }}
            size={props.cardSize ?? 'default'}
          />
        );
      })}
      {selectedFile && (
        <BigUp.Modal.FilePreviewModal
          fileContent={selectedFile.file || photoContent[`pending-${selectedFile.file_hash}`]}
          file={selectedFile}
          isModalOpen={filePreviewModal.isModalOpen}
          closeModal={() => {
            handlePreviewToggle('close');
            setSelectedFile(null);
          }}
        />
      )}
    </div>
  );
};

export default FileList;
