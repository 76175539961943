import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';
import type { Notice } from '@techlove/easy2use-typings/src/V1/Models/Notices';

export interface EmergenciesReducer {
  selectedEmergency: any;
  emergencies: Notice[] | undefined;
}

export const emergencySlice = createSlice<
  EmergenciesReducer,
  SliceCaseReducers<EmergenciesReducer>,
  string
>({
  name: 'emergencies',
  initialState: {
    selectedEmergency: undefined,
    emergencies: undefined
  },

  reducers: {
    setEmergencies: (
      state: Draft<EmergenciesReducer>,
      action: PayloadAction<E2U.V1.Models.Notice[] | undefined>
    ) => {
      state.emergencies = action.payload;
    },
    setSelectedEmergency: (
      state: Draft<EmergenciesReducer>,
      action: PayloadAction<E2U.V1.Models.Notice | undefined>
    ) => {
      state.selectedEmergency = action.payload;
    },
  }
});

export const {
  setEmergencies,
  setSelectedEmergency,
} = emergencySlice.actions;

export default emergencySlice.reducer;
