import { IonItem } from '@ionic/react';
import classNames from 'classnames';

import type { ItemShadowInterface } from '../Items';
import itemStyles from '../Items.module.scss';

const ItemShadow: React.FC<ItemShadowInterface> = ({
  borderLeftColour, children, clickHandler, hasBorderLeft, ionicItem, noMarginTop, ...rest
}) => {
  const hasBorder: string | undefined = hasBorderLeft ? borderLeftColour : undefined;

  return (
    <IonItem
      {...ionicItem}
      lines='none'
      className={ionicItem?.className || classNames(itemStyles['item-shadow-container'], !noMarginTop && 'ion-margin-top')}
      style={{ borderLeft: hasBorder, maxWidth: '100%', borderRadius: '8px' }}
      onClick={rest.onClick || clickHandler}
    >
      {children}
    </IonItem>
  );
};
export default ItemShadow;
