import {
  IonContent
} from '@ionic/react';
import React from 'react';

import SideMenuLayout from '../../../components/UI/SideMenu/SideMenuLayout';
import DocumentForm from '../DocumentForm';

const UploadDocumentPage: React.FC = () => {
  return (
    <SideMenuLayout baseURL={'tools'}>
      <IonContent fullscreen>
        <DocumentForm />
      </IonContent>
    </SideMenuLayout>
  );
};

export default UploadDocumentPage;
