import { IonButton, IonIcon } from '@ionic/react';
import type { TFunction } from 'i18next';
import { addSharp, fileTray } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import type { IonicThemeColours } from '../../../../components/UI/variables';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';

interface FileSelection {
  custom?: boolean;
  onFilesSelect: (files: File[]) => void;
  disabled?: boolean;
  responsiveButton?: boolean;
  responsiveButtonColour?: IonicThemeColours;
  responsiveButtonFill?: 'clear' | 'outline' | 'solid' | 'default';
  label?: TFunction | string;
  multiple?: boolean;
  expand?: 'block' | 'full' | undefined;
}
const FileSelectionButton: React.FC<FileSelection> = ({
  custom,
  disabled = false,
  expand,
  label,
  multiple = true,
  onFilesSelect,
  responsiveButton = true,
  responsiveButtonColour = 'secondary',
  responsiveButtonFill = 'solid',

}) => {
  const { t } = useTranslation();
  const { acceptedFiles, getInputProps, getRootProps, inputRef } = useDropzone({
    multiple,
    maxSize: 1073741824
  });
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onFilesSelect(acceptedFiles);
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [acceptedFiles]);

  return (
    <React.Fragment>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {(responsiveButton && !custom) && (
          <BigUp.Buttons.Responsive
            expand={expand || undefined}
            disabled={disabled}
            title={i18n.t('Select files')}
            isDesktop={isDesktop}
            color={isDesktop ? 'primary' : 'light'}
            icon={{
              icon: fileTray,
              size: 'large',
              color: 'primary'
            }}
            onClick={() => { }}

          />
        )}
        {custom && (
          <BigUp.Buttons.Icon
            icon={{
              icon: addSharp,
              size: 'large'
            }}
            size='small'
            title={i18n.t('Select files')}
            color={'primary'}

          />
        )}
        {(!responsiveButton && !custom) && (
          <IonButton expand={expand} color={responsiveButtonColour} fill={responsiveButtonFill}>
            <IonIcon icon={addSharp}></IonIcon>{label || t('Select files')}
          </IonButton>
        )}
      </div>

    </React.Fragment>
  );
};
export default FileSelectionButton;
