import { IonIcon, IonToolbar } from '@ionic/react';
import React from 'react';

import type { ToolbarButtonProps } from './Tabs';
import styles from './TabToolbar.module.scss';

const TabToolbar: React.FC<ToolbarButtonProps> = (props) => {
  return (
    <IonToolbar className={styles['tab-toolbar']} color={'light'}>
      <div className={styles['tab-toolbar__buttons-container']}>
        {props.buttons.map((button, index) => (
          <button
            key={index}
            className={
              `${styles['tab-toolbar__button']} ${button.isActive
                ? styles['tab-toolbar__button--active']
                : ''
              }`
            }
            style={
              button.isActive && button.activeColor
                ? {
                  backgroundColor: button.activeColor.background || 'var(--ion-color-secondary)',
                  color: button.activeColor.textColour || 'var(--ion-color-dark)',
                }
                : {
                  backgroundColor: 'var(--ion-color-light)',
                }
            }
            onClick={button.isActive ? undefined : button.onClick}
            disabled={!!button.isActive}
          >
            <IonIcon {...button.icon} size='large' className={styles['tab-toolbar__icon']} />
            <div className={styles['tab-toolbar__label']}>{button.label}</div>
          </button>
        ))}
      </div>
    </IonToolbar>
  );
};

export default TabToolbar;
