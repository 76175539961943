import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';
import {
  createSlice
} from '@reduxjs/toolkit';
import type { E2U } from '@techlove/easy2use-typings';

import type { AclAbility } from '../routes/ProtectedRoute';

export interface AuthenticationReducer {
  user: E2U.V1.Models.User | undefined;
  passwordReset: E2U.V1.Models.PasswordReset | undefined;
  shouldRedirect: boolean;
  unread_count: number | undefined;
  should_update_count: boolean;
  count_poller_interval: NodeJS.Timeout | undefined;
  is_updating_count: boolean;
  is_super_admin?: boolean;
  profile_picture: string;
  abilities: {
    client: {
      [id: string]: AclAbility[]
    },
    project: {
      [id: string]: AclAbility[]
    }
  };
  cached_users: {
    [id: string]: E2U.V1.Models.User
  };
}

export const authenticationSlice = createSlice<AuthenticationReducer,
  SliceCaseReducers<AuthenticationReducer>,
  string>({
    name: 'authentication',
    initialState: {
      user: undefined,
      passwordReset: undefined,
      shouldRedirect: false,
      count_poller_interval: undefined,
      unread_count: undefined,
      should_update_count: false,
      is_updating_count: false,
      is_super_admin: false,
      profile_picture: '',
      abilities: {
        client: {},
        project: {}
      },
      cached_users: {}
    },
    reducers: {
      setUser: (
        state: Draft<AuthenticationReducer>,
        action: PayloadAction<E2U.V1.Models.User>
      ) => {
        state.user = action.payload;
      },
      setPasswordReset: (
        state: Draft<AuthenticationReducer>,
        action: PayloadAction<E2U.V1.Models.PasswordReset>
      ) => {
        state.passwordReset = action.payload;
      },
      setShouldRedirect: (
        state: Draft<AuthenticationReducer>,
        action: PayloadAction<boolean>
      ) => {
        state.shouldRedirect = action.payload;
      },
      setUnreadCount: (state, action: PayloadAction<number | undefined>) => {
        state.unread_count = action.payload;
      },
      setShouldUpdateCount: (state, action: PayloadAction<boolean>) => {
        state.should_update_count = action.payload;
      },
      setCountPollerInterval: (state, action: PayloadAction<NodeJS.Timeout | undefined>) => {
        state.count_poller_interval = action.payload;
      },
      setIsUpdatingCount: (state, action: PayloadAction<boolean>) => {
        state.is_updating_count = action.payload;
      },
      setProfilePicture: (state, action: PayloadAction<string>) => {
        state.profile_picture = action.payload;
      },
      setAbilities: (state, action: PayloadAction<{
        type: 'client' | 'project',
        id: string,
        abilities: AclAbility[]
      }>) => {
        state.abilities[action.payload.type][action.payload.id] = action.payload.abilities;
      },
      setCachedUser: (state, action: PayloadAction<E2U.V1.Models.User>) => {
        state.cached_users[action.payload.id] = action.payload;
      }
    }
  });

export const {
  setAbilities,
  setCachedUser,
  setCountPollerInterval,
  setIsUpdatingCount,
  setPasswordReset,
  setProfilePicture, setShouldRedirect,
  setShouldUpdateCount,
  setUnreadCount,
  setUser
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
