import { IonContent } from '@ionic/react';
import { useLocation } from 'react-router';

import styles from './SiteAccessRequests.module.scss';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';
import TabBar from '../../layout/TabBar';

const SiteAccessRequestContentWrapper: React.FC = ({ children }) => {
  const location = useLocation();
  const project = useAppSelector(state => state.onboarding.selectedProject);
  const stepLabels = new Map([
    ['details', i18n.t('Details')],
    ['certificates', i18n.t('Certificates')],
    ['project-documents', i18n.t('Documents')],
    ['summary', i18n.t('Summary')],
  ]);

  const getStepLabel = (step: string) => {
    return stepLabels.get(step) || i18n.t(step.charAt(0).toUpperCase() + step.slice(1));
  };

  const checkRouteMatch = (route: string) => location.pathname.includes(route);

  return (
    <>
      <IonContent className={styles['onboarding-page-content']} scrollY={true}>
        <div className={styles['onboarding-form-container']}>
          <div className={styles['onboarding-form-container-inner']}>
            <h2>{project?.name}</h2>
            <div className={styles['onboarding-form-steps']}>
              {['details', 'certificates', 'project-documents', 'summary'].map((step, index) => (
                <div key={index} className={styles['onboarding-form-step']}>
                  <p className={`${styles['step-text']} ${checkRouteMatch(step) ? styles.active : ''}`}>
                    {getStepLabel(step)}
                  </p>
                </div>
              ))}
            </div>
            {children}
          </div>
        </div>
      </IonContent>

      <TabBar />
    </>
  );
};

export default SiteAccessRequestContentWrapper;
