import { IonButton, IonCol, IonGrid, IonIcon, IonRow, isPlatform } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { ellipsisHorizontal, refreshOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ChangesColumn from './Columns/ChangesColumn';
import DeviationColumn from './Columns/DeviationColumn';
import NotPostedRowsColumn from './Columns/NotPostedRowsColumn';
import EconomyMonitoringRadialChart from './Graphs/EconomyMonitoringRadialChart';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SwiperController from '../../../../components/Swiper/SwiperController';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import { setProjectLiquidity } from '../../../../reducers/cashflow';
import { setEconomyMonitoring } from '../../../../reducers/economyMonitoring';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import shareUrl from '../../../../tools/shareUrl';
import ActivityCodeColumn from '../Precalculations/Columns/ActivityCodeColumn';

const EconomyMonitoring: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { t } = useTranslation();
  const [shareEconomyMonitoring, setShareEconomyMonitoring] = useState<any | undefined>();
  const [swiperController, setSwiperController] = useState<SwiperController>(new SwiperController());
  const [lastReload, setLastReload] = useState(new Date());
  const economyMonitoring: E2U.V1.Models.NotPosted | undefined = useAppSelector(state => state.economyMonitoring.economyMonitoring);
  const selectedProject: E2U.V1.Models.Project | undefined = useAppSelector(state => state.project.selectedProject);
  const projectLiquidity: E2U.V1.Objects.ProjectLiquidity | undefined = useAppSelector(state => state.cashFlow.projectLiquidity);

  const fetchEconomyMonitoring = () => {
    store.dispatch(setIsLoading({ name: 'economyMonitoring', value: true }));
    toasters
      .promise(
        networking.get(`/api/v1/projects/${uuid}/not_posted`),
        { error: t('Error while loading economy monitoring.') }
      )
      .then((response: any) => store.dispatch(setEconomyMonitoring(response.data.data.records[0])))
      .catch((error: E2U.V1.Response.Error) => Sentry.captureException(error))
      .finally(() => store.dispatch(setIsLoading({ name: 'economyMonitoring', value: false })));
  };

  const fetchProjectLiquidity = (projectId: string) => {
    networking.get(`/api/v1/projects/${projectId}/liquidity`).then(
      (response: E2U.V1.Response.Success<E2U.V1.Objects.ProjectLiquidity>) => {
        store.dispatch(setProjectLiquidity(response.data.data));
      });
  };

  const shareEconomyMonitorings = () => {
    networking.get(`/api/v1/not_posteds/${economyMonitoring?.id}/share`)
      .then((response) => {
        setShareEconomyMonitoring(response.data.data.url);
        shareUrl(
          `${t('Economy monitoring for project')} ${selectedProject?.name}.`,
          `${t('Here is the link to the economy monitoring for project ')}${selectedProject?.name}: ${isPlatform('ios') ? shareEconomyMonitoring : ''}`,
          `${shareEconomyMonitoring}`,
          t(`Let me know if there is something you are wondering about.`,),
          'share'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const requestGenerate = () => {
    toasters.promise(
      networking.post(`/api/v1/not_posteds/${economyMonitoring?.id}/report`),
      {
        pending: t('Generating economy monitoring.'),
        success: t('Generated successfully.'),
        error: t('Error while generating economy monitoring.'),
      }
    );
  };

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      fetchProjectLiquidity(selectedProject.id);
    }
    setLastReload(new Date() as any);
  }, []);

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      fetchProjectLiquidity(selectedProject.id);
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchEconomyMonitoring();
    setSwiperController(swiperController);
  }, [uuid]);

  useEffect(() => {
    swiperController.updateSliders();
  }, [swiperController, economyMonitoring]);

  const liquidityPieData = useMemo(() => {
    if (projectLiquidity) {
      return [
        {
          name: t('Costs'),
          value: projectLiquidity.outgoing,
          color: '#dc3545'
        },
        {
          name: t('Income'),
          value: projectLiquidity.incoming,
          color: undefined
        },
      ];
    } else {
      return undefined;
    }
  }, [projectLiquidity]);

  return (
    <DesktopWrapper>
      <div id='not-posted-costs-rows-top'>
        <IonGrid>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <IonCol size='auto'>
              <BigUp.Title label={t('Prognosis')} />
            </IonCol>
            <IonCol className='ion-text-left' size={'auto'}>
              <IonButton fill='clear' className='icon-only' onClick={shareEconomyMonitorings}>
                <IonIcon icon={ellipsisHorizontal} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-between ion-align-items-center ion-margin-vertical'>
            {(liquidityPieData && liquidityPieData.length > 0 &&
              <IonCol className={'ion-text-left'} sizeSm={'12'} sizeMd={'7'}>
                <EconomyMonitoringRadialChart
                  data={liquidityPieData}
                  percentageIndex={1}
                  size={'100%'}
                  total={liquidityPieData[0].value + liquidityPieData[1].value}
                  outgoing={liquidityPieData[0].value}
                  incoming={liquidityPieData[1].value}
                />
              </IonCol>
            )}
          </IonRow>
          <IonGrid className='ion-margin-top'>
            <IonRow className='ion-justify-content-end'>
              <IonCol size='auto'>
                <BigUp.Buttons.Secondary
                  size='small'
                  icon={{
                    icon: refreshOutline,
                  }}
                  onClick={requestGenerate}
                  title={t('Generate')}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <BigUp.Table
            columns={[
              {
                key: 'name',
                label: t('Name'),
                sortable: true,
                sizes: {
                  xl: '2',
                  xs: '6'
                },
                alignment: 'left',
                body: <ActivityCodeColumn
                  value=''
                  attributes={{} as E2U.V1.Models.EconomyMonitoringRow} />,
              },
              {
                key: 'production_budget_total',
                label: t('Prod budget'),
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'changes_total',
                label: t('Changes'),
                body: <ChangesColumn
                  value={0}
                  editable={true}
                  attributes={{} as E2U.V1.Models.EconomyMonitoringRow} />,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'deviations_total',
                label: t('Deviations'),
                body: <DeviationColumn value={0} editable={true}
                  attributes={{} as E2U.V1.Models.EconomyMonitoringRow} />,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'final_forecast_total',
                label: t('Fin. forecast'),
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'accrude_cost_total',
                label: t('Accrued cost'),
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'posted_cost_total',
                label: t('Posted cost'),
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'not_posted_cost_total',
                label: t('Not posted'),
                sizes: {
                  xl: '2',
                },
                body: <NotPostedRowsColumn editable={true}
                  attributes={{} as E2U.V1.Models.NotPostedRow}
                  callbacks={{
                    onUpdate: () => setLastReload(new Date())
                  }}
                />
              },
              {
                key: 'previous_not_posted_cost_total',
                label: t('Previous Not Posted'),
                sizes: {
                  xl: '2',
                },
              },
              {
                key: 'workup_rate',
                label: t('Workup rate'),
                sizes: {
                  xl: '2',
                },
              }
            ]}
            reducers={{
              workup_rate: (value: any) => value ? `${value * 100}%` : '0%',
              posted_cost_total: (value: any) => value ? formatNumber(value) : 0,
              production_budget_total: (value: any) => value ? formatNumber(value) : 0,
            }}
            sourceUrl={{
              url: `/api/v1/not_posteds/${economyMonitoring?.id}/rows`,
              args: {
                'with[]': 'costItems'
              },
            }}
            timestamp={lastReload}
          />

        </IonGrid>
      </div>
    </DesktopWrapper>
  );
};

export default EconomyMonitoring;
