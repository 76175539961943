import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useHistory } from 'react-router';

import type { ButtonProps, HeaderProps } from './Header.d';

const Header: React.FC<HeaderProps> = ({ buttons, ionHeader, ionTitle, ionToolbar }) => {
  const router = useHistory();

  const renderButton = (button: ButtonProps | undefined, slot: 'start' | 'end') => {
    if (!button?.show) return null;

    const handleClick = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      if (button.onClick) {
        button?.onClick(event);
      } else if (slot === 'start') {
        router.goBack();
      }
    };

    if (button?.isTextOnly && !button.icon) {
      return (
        <IonButtons slot={slot}>
          <span onClick={handleClick}
            style={{ cursor: 'pointer', padding: '10px', color: 'var(--ion-color-primary)', fontWeight: 'bold' }}>
            {button.text || (slot === 'start' ? 'Back' : '')}
          </span>
        </IonButtons>
      );
    }
    return (
      <IonButtons slot={slot}>
        <IonButton onClick={handleClick}>
          {button.icon && (
            <IonIcon
              icon={button.icon?.icon || (slot === 'start' ? chevronBack : undefined)}
              size={button.icon?.size || 'large'}
              {...button.icon}
            />
          )}
          {button?.text && (
            <span>{button?.text || (slot === 'start' ? 'Back' : '')}</span>
          )}
        </IonButton>
      </IonButtons>
    );
  };

  return (
    <IonHeader className='ion-no-border' {...ionHeader}>
      <IonToolbar className='ion-margin-top' {...ionToolbar}>
        {renderButton(buttons?.start, 'start')}
        <IonTitle size='small' style={{ fontWeight: 600, paddingBottom: '8px' }} {...ionTitle}>
          {ionTitle?.label}
        </IonTitle>
        {renderButton(buttons?.end, 'end')}
      </IonToolbar>
    </IonHeader >
  );
};
export default Header;
