import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DocumentActionsButton.module.scss';
import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import useFileUpload from '../../hooks/useFileUpload';
import { setFiles } from '../../reducers/file';
import store from '../../store';
import DocumentFormModal from '../DocumentFormModal';
import BigUp from '../UI';
import FabButton from '../UI/Buttons/Fabs/FabButton';
import toasters from '../UI/Toasts';

const fileUploadModalId = 'file-upload-modal';

const DocumentActionsButton: React.FC = () => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [documentFormOpen, setDocumentFormOpen] = useState(false);
  const { t } = useTranslation();
  const {
    getUploadedFiles,
    handleFileSelection,
    onlyUploadFiles,
    setUploadedFiles,
    uploadSelectedFiles
  } = useFileUpload();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const files = getUploadedFiles() ?? [];

  const uploadFilesOnSelection = () => {
    if (files.length === 0) {
      return;
    }

    const handleToasters = (successful: boolean) => {
      if (successful) {
        setUploadedFiles([]);
      }
    };

    const uploadFiles = () => {
      const uploadPromises = onlyUploadFiles().map((filePromise) =>
        filePromise.then((fileResponse) =>
          networking.post(`/api/v1/documents`, {
            name: fileResponse.data.data.name,
            description: '',
            project_id: selectedProject?.id,
          })
            .then((response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
              const documentId = response.data.data.id;
              const fileId = fileResponse.data.data.id;
              return networking.post(`/api/v1/documents/${documentId}/files/${fileId}`);
            })
        )
      );

      const allUploads = Promise.allSettled(uploadPromises);

      toasters.promiseToast(
        allUploads,
        {
          pending: {
            message: t('Uploading files'),
            background: 'var(--ion-color-light)',
          },
          success: {
            message: t('All files uploaded successfully'),
            background: 'var(--ion-color-light)',
            textColour: 'var(--ion-color-dark)',
          },
          error: {
            message: t('Some files could not be uploaded'),
            background: 'var(--ion-color-light)',
            textColour: 'var(--ion-color-dark)',
          },
        }
      )
        .then((results: PromiseSettledResult<E2U.V1.Models.Document>[]) => {
          const successful = results.every((result) => result.status === 'fulfilled');
          handleToasters(successful);
        })
        .catch((error) => {
          handleToasters(false);
          Sentry.captureException(error);
        });
    };
    uploadFiles();
    store.dispatch(setFiles([]));
  };

  useEffect(() => {
    if (files.length > 0) {
      uploadFilesOnSelection();
    }
  }, [files]);

  const importData = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = _ => {
      const files: any = Array.from(input.files as any);
      handleFileSelection(files);
    };
    input.click();
  };

  return (
    <>
      <FabButton
        icon={{
          icon: add,
        }}
        onClick={() => setOptionsVisible(!optionsVisible)}
        vertical={'bottom'}
        horizontal={'end'}
        className={`${styles['documents-action-button']} ${isDesktop && styles['DocumentActionsButton--desktop']}`}
        list={{
          buttons: [
            {
              icon: {
                icon: BigUp.Icons.Svg.Document,
              },
              label: t('Create document'),
              onClick: () => setDocumentFormOpen(true)
            },
            {
              icon: {
                icon: BigUp.Icons.Svg.Attachments,
              },
              label: t('Upload files'),
              onClick: () => importData(),
              /*
              id: fileUploadModalId,
                Remove the onclick above and uncomment the code below when FileUpload is ready to deploy
               */
            }
          ],
          side: 'top'
        }}
      />
      <DocumentFormModal
        open={documentFormOpen}
        onClose={() => setDocumentFormOpen(false)}
        onSaved={() => setDocumentFormOpen(false)}
      />

      {/* TODO:
        <BigUp.Modal.FileUpload trigger={fileUploadModalId} />
        Use when FileUpload is ready to deploy
      */}
    </>
  );
};

export default DocumentActionsButton;
