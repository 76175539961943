import type { SVG } from './types';

export const Teams = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (

    <svg width={width || '48'} height={height || '48'} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.249 23.998C17.9769 23.998 20.999 20.976 20.999 17.248C20.999 13.5201 17.9769 10.498 14.249 10.498C10.5211 10.498 7.49902 13.5201 7.49902 17.248C7.49902 20.976 10.5211 23.998 14.249 23.998Z" fill="url(#paint0_linear_1392_23280)" />
      <path d="M21.9384 27.7476C19.2984 26.4069 16.3846 25.8726 14.2509 25.8726C10.0715 25.8726 1.50085 28.4357 1.50085 33.5601V37.4976H15.5634V35.991C15.5634 34.2097 16.3134 32.4238 17.6259 30.9351C18.673 29.7463 20.1393 28.6429 21.9384 27.7476Z" fill="url(#paint1_linear_1392_23280)" />
      <path d="M31.8766 26.999C26.995 26.999 17.2516 30.014 17.2516 35.999V40.499H46.5016V35.999C46.5016 30.014 36.7582 26.999 31.8766 26.999Z" fill="url(#paint2_linear_1392_23280)" />
      <path d="M31.8749 24C36.4312 24 40.1249 20.3064 40.1249 15.75C40.1249 11.1937 36.4312 7.5 31.8749 7.5C27.3185 7.5 23.6249 11.1937 23.6249 15.75C23.6249 20.3064 27.3185 24 31.8749 24Z" fill="url(#paint3_linear_1392_23280)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23280" x1="14.249" y1="10.498" x2="14.249" y2="23.998" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9ABBF1" />
          <stop offset="1" stop-color="#0666FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_1392_23280" x1="11.7196" y1="25.8726" x2="11.7196" y2="37.4976" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9ABBF1" />
          <stop offset="1" stop-color="#0666FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_1392_23280" x1="31.8766" y1="26.999" x2="31.8766" y2="40.499" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9ABBF1" />
          <stop offset="1" stop-color="#0666FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_1392_23280" x1="31.8749" y1="7.5" x2="31.8749" y2="24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9ABBF1" />
          <stop offset="1" stop-color="#0666FF" />
        </linearGradient>
      </defs>
    </svg>

  );
};
