import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { addSharp, attachOutline, cameraOutline, close } from 'ionicons/icons';
import { useRef } from 'react';

import BigUp from '..';
import { useAppSelector } from '../../../hooks';
import type { CameraUploadResult } from '../../../hooks/useCameraUpload';
import type { FileUploadResult } from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import type { ActionSheetItem } from '../ActionSheets';
import type { PopoverItem } from '../Popovers';

interface MediaUpload {
  title?: string;
  disabled?: boolean;
  cameraProps: CameraUploadResult;
  fileProps: FileUploadResult;
}

const MediaUpload: React.FC<MediaUpload> = ({ cameraProps, disabled = false, fileProps, title }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      fileProps.handleFileSelection(Array.from(files));
      event.target.value = '';
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const takePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });
      cameraProps.handleTakenPhoto([photo]);
    } catch (error) {
      return (
        console.log(error)
      );
    }
  };

  const actionButtonList: ActionSheetItem[] | PopoverItem[] = [
    {
      value: 'photo',
      label: i18n.t('Take a photo'),
      onClick: takePhoto,
      icon: { icon: cameraOutline }
    },
    {
      value: 'file',
      label: i18n.t('Select a file'),
      onClick: openFileSelector,
      icon: { icon: attachOutline }
    },
    {
      value: 'cancel',
      label: i18n.t('Cancel'),
      onClick: () => undefined,
      icon: { icon: close }
    },
  ];

  return (
    <>
      <BigUp.Buttons.Secondary
        size='small'
        disabled={disabled}
        id={'open-media-select'}
        title={title || i18n.t('Upload file')}
        style={{ '--padding-top': '10px', '--padding-bottom': '10px' }}
        icon={{
          icon: addSharp,
          slot: 'start',
        }}
      />
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileChange}
        multiple
      />
      {isDesktop
        ? <BigUp.Popovers.Default usesID={true} items={actionButtonList} actionId='open-media-select' />
        : <BigUp.ActionSheets.ActionSheet items={actionButtonList} actionId="open-media-select" />}

    </>
  );
};

export default MediaUpload;
