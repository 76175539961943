import {
  IonCol,
  IonIcon,
  IonRippleEffect,
  IonRow,
  IonText, useIonPopover, useIonRouter
} from '@ionic/react';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';

import { useAppSelector } from '../../../../../hooks';
import i18n from '../../../../../i18n';
import BigUp from '../../../index';
import type { SubmenuItem } from '../MenuTypings';
import styles from '../SideMenuV2.module.scss';

interface SideMenuV2SubmenuProps {
  submenuVisible?: boolean
}

export const toolsSubmenuItems: SubmenuItem[] = [{
  icon: BigUp.Icons.Svg.WorkPreparation,
  title: i18n.t('Work Preparation'),
  route: '/tools/work-preparation/:uuid?',
  project_optional: true,
  permissions_needed: 'can_view_protocols_in_projects',
}, {
  icon: BigUp.Icons.Svg.ControlOfExecution,
  title: i18n.t('Control of Execution'),
  route: '/tools/:uuid?/control-of-execution',
  permissions_needed: 'can_view_control_of_executions_in_projects',
}, {
  icon: BigUp.Icons.Svg.WorkingEnvironment,
  title: i18n.t('Working Environment'),
  route: '/tools/:uuid?/working-environment',
  permissions_needed: 'can_view_working_environments_in_projects',
}, {
  icon: BigUp.Icons.Svg.SiteAccessRequest,
  title: i18n.t('Site Access Request'),
  route: '/tools/:uuid?/site-access-requests',
  permissions_needed: 'can_view_access_requests_in_projects',
}, {
  icon: BigUp.Icons.Svg.Map,
  title: i18n.t('Map'),
  route: '/tools/:uuid?/map',
  permissions_needed: 'can_view_work_sites_in_projects',
}, {
  icon: BigUp.Icons.Svg.Teams,
  title: i18n.t('Teams'),
  route: '/tools/:uuid?/settings/teams',
  permissions_needed: 'can_view_teams_in_projects',
}, {
  icon: BigUp.Icons.Svg.Document,
  title: i18n.t('Documents'),
  route: '/tools/:uuid?/documents',
  permissions_needed: 'can_view_documents_in_projects',
}, {
  icon: BigUp.Icons.Svg.ProjectInformation,
  title: i18n.t('Project Information'),
  route: '/financials/:uuid?/project-information',
  permissions_needed: 'can_view_project_information_in_projects',
}, {
  icon: BigUp.Icons.Svg.Calculation,
  title: i18n.t('Precalculation'),
  route: '/financials/:uuid?/precalculations',
  project_optional: true,
  permissions_needed: 'can_view_precalculations_in_projects',
}, {
  icon: BigUp.Icons.Svg.ProductionBudget,
  title: i18n.t('Production Budget'),
  route: '/financials/:uuid?/production-budget',
  permissions_needed: 'can_view_production_budget_in_projects',
}, {
  icon: BigUp.Icons.Svg.Cashflow,
  title: i18n.t('Cash Flow'),
  route: '/financials/:uuid?/cash-flow',
  permissions_needed: 'can_view_manual_cash_flow_in_projects',
}, {
  icon: BigUp.Icons.Svg.PaymentSchedule,
  title: i18n.t('Payment Schedule'),
  route: '/financials/:uuid?/payment-schedule',
  permissions_needed: 'can_view_payment_schedule_in_projects',
}, {
  icon: BigUp.Icons.Svg.Prognosis,
  title: i18n.t('Prognosis'),
  route: '/financials/:uuid?/economy-monitoring',
  permissions_needed: 'can_view_protocols_in_projects',
}, {
  icon: BigUp.Icons.Svg.Reports,
  title: i18n.t('Reports'),
  route: '/financials/:uuid?/economy-reports',
  permissions_needed: 'can_view_reports_in_projects',
}, {
  icon: BigUp.Icons.Svg.Deviations,
  title: i18n.t('Deviations'),
  route: '/financials/:uuid?/deviations',
  permissions_needed: 'can_view_deviations_in_projects',
}, {
  icon: BigUp.Icons.Svg.Changes,
  title: i18n.t('Changes'),
  route: '/financials/:uuid?/changes',
  permissions_needed: 'can_view_changes_in_projects',
}, {
  icon: BigUp.Icons.Svg.EmergencyContact,
  title: i18n.t('Emergency Contacts'),
  route: '/tools/:uuid?/emergency-contacts',
}, {
  icon: BigUp.Icons.Svg.Emergency,
  title: i18n.t('Emergency'),
  route: '/tools/:uuid?/emergency',
}, {
  icon: BigUp.Icons.Svg.Settings,
  title: i18n.t('Project Settings'),
  route: '/tools/:uuid?/settings',
}];

const ToolsSubmenu: React.FC<SideMenuV2SubmenuProps> = ({ submenuVisible }) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const menuIsPinned = useAppSelector((state) => state.navigation.submenuLocked);
  const [nextPath, setNextPath] = useState<string | undefined>(undefined);
  const onNavigate = (url: string) => router.push(url);
  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath,
    onNavigate
  });

  const handleOpenProjectSelection = (e: React.MouseEvent, item: SubmenuItem) => {
    setNextPath(item.base_route);
    present({
      event: e as any,
      dismissOnSelect: true,
      onDidDismiss: () => setNextPath(undefined)
    });
  };

  const itemsWithUuid: SubmenuItem[] = useMemo(() => toolsSubmenuItems.map(
    item => ({
      ...item,
      base_route: item.route,
      route: item.route.replace('/:uuid?', selectedProjectId ? `/${selectedProjectId}` : ''),
      active: matchPath(router.routeInfo.pathname, item.route),
      onClick: (e: React.MouseEvent, selected: SubmenuItem) => (selectedProjectId || selected.project_optional)
        ? router.push(selected.route)
        : handleOpenProjectSelection(e, selected),
      project_optional: item.project_optional ?? false
    })
  ), [toolsSubmenuItems, selectedProjectId, router.routeInfo.pathname]);

  const activeItem = useMemo(() => itemsWithUuid.find(i => i.active), [itemsWithUuid]);

  return (
    <>
      {selectedProject && (
        <IonRow
          className={classNames('ion-no-margin', styles['side-menu--sub--row'])}
        >
          <IonCol
            className={
              classNames(
                'ion-activatable',
                styles['side-menu--sub--item'],
              )
            }
            role={'button'}
          >
            <IonText
              className={
                classNames(
                  styles['side-menu--sub--selected-project'],
                  (submenuVisible || menuIsPinned) ? styles['side-menu--sub--selected-project--active'] : ''
                )
              }
              title={selectedProject?.name}
              onClick={(e: React.MouseEvent) => activeItem && handleOpenProjectSelection(e, activeItem)}
            >
              <p className={'ion-no-margin'}>
                {
                  (submenuVisible || menuIsPinned)
                    ? (
                      selectedProject?.name ?? t('Choose project')
                    )
                    : (
                      selectedProject?.name[0] ?? ''
                    )
                }
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      )}
      {itemsWithUuid.map((item: SubmenuItem, i) =>
        <IonRow
          key={i}
          className={classNames('ion-no-margin', styles['side-menu--sub--row'])}
          onClick={(e: React.MouseEvent) => item.onClick && item.onClick(e, item)}
        >
          <IonCol
            className={
              classNames(
                'ion-activatable ripple-parent',
                styles['side-menu--sub--item'],
                item.active
                  ? styles['side-menu--sub--item--active']
                  : ''
              )
            }
            role={'button'}
          >
            <IonRow className={'ion-align-items-center'}>
              <IonCol className={classNames(styles['side-menu--sub--item--icon-holder'])} size={'auto'}>
                <IonIcon icon={item.icon} />
              </IonCol>
              <IonCol
                className={classNames(
                  'ion-no-padding',
                )}
              >
                <IonText className={styles['side-menu--sub--item--title']}>
                  <p className={'ion-no-margin'}>{item.title}</p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect />
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default ToolsSubmenu;
