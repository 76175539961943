import { IonPage, IonRouterOutlet } from '@ionic/react';
import React from 'react';

import DocumentsListPage from '../../pages/Document/DocumentsListPage';
import UploadDocumentPage from '../../pages/Document/UploadDocumentPage';
import { AbilitiesWatchedRoute } from '../AbilitiesWatchedRoute';

const DocumentsRouteGroup: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute path={'/documents'} component={DocumentsListPage} exact />
        <AbilitiesWatchedRoute path={'/documents/upload'} component={UploadDocumentPage} exact />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default DocumentsRouteGroup;
