import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import { handleSource } from '../../../../components/ListsComponents/rowList/handlers';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import useAcl from '../../../../hooks/useAcl';
import ActionColumn from '../Columns/ActionColumn';

const Deviations: React.FC = () => {
  const { t } = useTranslation();
  const { project } = useAcl();
  const router = useIonRouter();
  const [updatedAt, setUpdatedAt] = useState<number>(Date.now());

  const deleteDeviation = (id: string | undefined) => {
    toasters.promise(networking.delete(`/api/v1/economy_deviations/${id}`), {
      success: t('Deviation deleted'),
      error: t('Could not delete deviation'),
    });
  };

  return (
    <DesktopWrapper>
      <IonGrid>
        <IonRow className={'ion-align-items-center'}>
          <IonCol>
            <BigUp.Title label={t('Deviations')}/>
          </IonCol>
        </IonRow>
      </IonGrid>

      {project && <BigUp.Table columns={[
        {
          key: 'name',
          label: t('Reference'),
          alignment: 'left',
          sizes: {
            xs: '6',
            xl: '10'
          }
        },
        {
          key: 'actions',
          label: t('Actions'),
          sortable: false,
          body: <ActionColumn type="deviations"
            attributes={{} as E2U.V1.Models.EconomyDeviation}
            callbacks={{
              onDelete: (id: string) => {
                deleteDeviation(id);
                setUpdatedAt(Date.now());
              }
            }}/>,
          sizes: {
            xs: '6',
            xl: '2'
          },
        }
      ]}
      callbacks={{
        onRowClick: (row: E2U.V1.Models.EconomyDeviation) => {
          const target = handleSource('deviations', project?.id);

          if (typeof target === 'object') {
            router.push(`${target.routes.base}/${row.id}`);
          }
        }
      }}
      sourceUrl={`/api/v1/projects/${project.id}/deviations`}
      timestamp={updatedAt}/>}

    </DesktopWrapper>
  );
};

export default Deviations;
