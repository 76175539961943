
import type { Photo } from '@capacitor/camera';
import { IonCol, IonGrid, IonLoading, IonProgressBar, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { CanceledError } from 'axios';
import { fileTray } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import UploadFileCard from './FileCard';
import { networking } from '../../../../../../../api/networking';
import toasters from '../../../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../../../components/UI';
import type { Certificate } from '../../../../../../../components/UI/List/UserCertificateList';
import { useAppSelector } from '../../../../../../../hooks';
import { fileContentHasher } from '../../../../../../../services/fileContentHasher';
import TakePhotoButton from '../../../../../../Tools/ControlOfExecution/AddFilesModal/TakePhotoButton';
import styles from '../../../styles/FileUpload.module.scss';
import DatePickContainer from '../DatePickContainer';
import type { Onboarding } from '../StageTwoTypings';

interface FileUploadProps {
  handleUploadedFile?: (file: E2U.V1.Models.File) => void;
  existingFiles: Onboarding.StageTwo.PendingFile[];
  setValue: (files: Onboarding.StageTwo.PendingFile[]) => void;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const { t } = useTranslation();

  const [uploadProgress, setUploadProgress] = useState<{ [fileName: string]: number }>({});

  const isLoadingFile: boolean = useAppSelector(
    (state) => state.loading.isLoading.file);

  const isUploadingFile: boolean = useAppSelector(
    (state) => state.loading.isLoading.uploadingFile);

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    multiple: true,
    maxSize: 1073741824
  });

  const prepareFileUpload = (file: File, controller: AbortController, fileHash: string) => {
    const formData = new FormData();
    formData.set('file', file);
    return networking.post('/api/v1/files', formData, {
      signal: controller.signal,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const newPercentage = (Math.round(((progressEvent.loaded / progressEvent.total) * 100)) / 100);

        if (!uploadProgress[fileHash] || (uploadProgress[fileHash] !== newPercentage)) {
          setUploadProgress({
            ...uploadProgress,
            [fileHash]: newPercentage
          });
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  const handleUploads = (uploads: Promise<any>[], files: Onboarding.StageTwo.PendingFile[]) => {
    Promise.allSettled<E2U.V1.Response.Success<E2U.V1.Models.File> & { filename: string }>(uploads)
      .then((results) => {
        results.forEach((result, i) => {
          if (result.status === 'fulfilled') {
            const existingFileInd = files.findIndex(
              (eF) => eF.filename === result.value.data.data.name
            );
            files[existingFileInd].uploaded = true;
            files[existingFileInd].id = result.value.data.data.id;
            delete files[existingFileInd].controller;
          } else {
            const reason: E2U.V1.Response.Error | CanceledError<any> = result.reason;
            const existingFileInd = files.findIndex(
              (eF) => eF.filename === acceptedFiles[i].name
            );
            if (existingFileInd !== -1) {
              if (reason.code === 'ERR_CANCELED') {
                delete files[existingFileInd];
              } else {
                files[existingFileInd].error = true;
                delete files[existingFileInd].controller;
              }
            }
          }
        });
      })
      .finally(() => {
        props.setValue(files);
      });
  };

  const handleFileSelect = () => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const newFiles = [
        ...props.existingFiles ?? []
      ];
      const uploads: Promise<any>[] = [];

      Promise.all(
        acceptedFiles.map(file => new Promise((resolve) => {
          fileContentHasher(file).then((hash) => {
            if (props.existingFiles && props.existingFiles.findIndex(eF => {
              return eF.file_hash === hash;
            }) === -1) {
              const controller = new AbortController();

              newFiles.push({
                filename: file.name,
                uploaded: false,
                expiration_date: (new Date()).toISOString().split('T')[0],
                error: false,
                controller,
                file_hash: hash
              });

              uploads.push(prepareFileUpload(file, controller, hash));
            }
            resolve('');
          });
        }))
      ).finally(() => {
        props.setValue(newFiles);
        handleUploads(uploads, newFiles);
      });
    }
  };

  const handleCameraUpload = (photos: Photo[]) => {
    const newFiles = [
      ...props.existingFiles ?? []
    ];
    const uploads: Promise<any>[] = [];

    Promise.all(
      photos.map((file: Photo) => new Promise((resolve) => {
        const fileName = new Date().getTime() + '.' + file.format;
        const controller = new AbortController();

        return fetch(file.dataUrl ? file.dataUrl : fileName)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], fileName, { type: 'image/jpeg' });
            fileContentHasher(file).then((hash) => {
              newFiles.push({
                filename: fileName,
                uploaded: false,
                expiration_date: (new Date()).toISOString().split('T')[0],
                error: false,
                controller,
                file_hash: hash
              });
              uploads.push(prepareFileUpload(file, controller, hash));
              resolve('');
            });
          });
      }))
    ).finally(() => {
      props.setValue(newFiles);
      handleUploads(uploads, newFiles);
    });
  };

  const performDelete = (id: string | undefined): Promise<string | undefined> => {
    return new Promise(
      (resolve, reject) => {
        networking.delete(`/api/v1/files/${id}`)
          .then(() => {
            toasters.success(t('Successfully deleted certificate'));
            resolve(id);
          })
          .catch((err) => {
            toasters.error(t('Failed deleting file'));
            Sentry.captureException(err);
            reject(id);
          });
      }
    );
  };

  const deleteDoc = (index: number) => {
    if (props.existingFiles[index].controller) {
      props.existingFiles[index].controller?.abort('Deleting file');
    }

    if (props.existingFiles[index].uploaded && props.existingFiles[index].id) {
      performDelete(props.existingFiles[index].id as string).then(
        () => removeIndexFromFiles(index)
      );
    } else {
      removeIndexFromFiles(index);
    }
  };

  const removeIndexFromFiles = (index: number | number[], save = true) => {
    let currentFiles = props.existingFiles.map((file) => { return { ...file }; });
    if (Array.isArray(index)) {
      index.forEach((i) => {
        currentFiles = removeIndexFromFiles(i, false) as Onboarding.StageTwo.PendingFile[];
      });
    } else {
      delete currentFiles[index];

      currentFiles = currentFiles.filter((doc) => doc && doc.filename);
    }

    if (save) {
      props.setValue(currentFiles);
    } else {
      return currentFiles;
    }
  };

  const updateExpiry = (index: number, value: string) => {
    const newFiles = [
      ...props.existingFiles ?? []
    ];

    newFiles[index].expiration_date = value;
    props.setValue(newFiles);
  };
  const user = useAppSelector((state) => state.authentication.user);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const fetchCertificates = () => {
    networking.get(`/api/v1/users/${user?.id}/certificates?with=files`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<Certificate>>) => {
        setCertificates(response.data.data.records);
        console.log(response.data.data.records);
      });
  };

  useEffect(() => {
    fetchCertificates();
  }, []);

  useEffect(() => {
    handleFileSelect();
  }, [acceptedFiles]);

  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  return (
    <React.Fragment>
      {(isLoadingFile || isUploadingFile)
        ? <IonLoading isOpen={true} />
        : <React.Fragment>
          <IonGrid>
            <IonRow className='ion-align-items-center ion-margin-bottom ion-justify-content-between'>
              <IonCol size='7' sizeLg='6' className='ion-text-left '>
                <p className={styles['upload-btn-text']}>
                  {t('Upload certificate with validity period')}
                </p>
              </IonCol>
              <IonCol size='2' sizeLg='3' className='ion-text-right'>
                <div {...getRootProps()}>
                  <BigUp.Buttons.Responsive
                    title={t('Upload')}
                    isDesktop={isDesktop}
                    color={isDesktop ? 'primary' : 'light'}
                    icon={{
                      icon: fileTray,
                      color: 'primary',
                      size: 'large'
                    }}
                  />
                  <input {...getInputProps()} />
                </div>
              </IonCol>
              <IonCol size='2' sizeLg='3'>
                <TakePhotoButton onTakePhotoClick={handleCameraUpload} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            {(props.existingFiles &&
              props.existingFiles.length > 0) &&
              props.existingFiles.map((doc, index) => {
                return (
                  <IonRow key={index} style={{
                    border: '1px solid rgb(234 234 234)',
                    padding: '8px 0px',
                    borderRadius: '4px',
                    marginBottom: '1rem',
                    backgroundColor: 'rgb(243 243 243 / 50%)'
                  }}>
                    <IonCol>
                      <UploadFileCard
                        deleteFile={() => { deleteDoc(index); }}
                        file={doc as any}
                        document_name={doc.file?.document_name}
                      />
                      {doc.uploaded
                        ? null
                        : (doc.error
                          ? t('An error occurred while uploading the certificate')
                          : (uploadProgress && uploadProgress[doc.filename]) &&
                          <IonProgressBar
                            value={uploadProgress[doc.filename]}
                          />
                        )
                      }
                      {doc.error
                        ? null
                        : <DatePickContainer
                          onChange={(value: string) => updateExpiry(index, value)}
                        />
                      }
                    </IonCol>
                  </IonRow>
                );
              })}
          </IonGrid>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default FileUpload;
