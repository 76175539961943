import { IonAvatar, IonLabel, IonSkeletonText } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import tileStyles from './EmergencyContacts.module.scss';
import { networking } from '../../api/networking';
import { useTableStyles } from '../../components/UI/Table';
import { useAppSelector } from '../../hooks';
import { getGravatarPicture } from '../../hooks/useProfilePicture';
import { setCachedUser } from '../../reducers/authentication';
import store from '../../store';

const Contact: React.FC<{ attributes: object & { user_id: string } }> = (props) => {
  const styles = useTableStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
  const cachedUsers = useAppSelector(state => state.authentication.cached_users);
  const contact = useMemo(() => cachedUsers[props.attributes.user_id], [cachedUsers, props.attributes.user_id]);

  const findUser = () => {
    if (!props.attributes.user_id) {
      return;
    }
    if (cachedUsers[props.attributes.user_id]) {
      setIsLoading(false);
      return;
    }
    if (cancelToken) {
      cancelToken.cancel();
    }
    const newCancelToken = axios.CancelToken.source();
    setCancelToken(newCancelToken);
    setIsLoading(true);
    networking.get(
      `/api/v1/users/${props.attributes.user_id}`,
      {
        cancelToken: newCancelToken.token
      }
    )
      .then((response) => store.dispatch(setCachedUser(response.data.data)))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    findUser();
  }, []);

  useEffect(() => {
    findUser();
  }, [props.attributes.user_id]);

  return (
    <div className={styles.activity_code_column}>
      <div className={tileStyles['avatar-tile-wrapper']}>
        {
          (isLoading)
            ? <IonSkeletonText
              animated
              className={tileStyles['avatar-skeleton']}
            />
            : <>
              <IonAvatar slot="start" className='ion-no-margin'>
                {contact && (
                  <img src={getGravatarPicture('email' in contact ? (contact?.email ?? '') : '')}
                    alt={contact?.first_name ?? ''} />
                )}
              </IonAvatar>
              <IonLabel className='ion-no-margin'>{
                contact
                  ? `${contact?.first_name} ${contact?.last_name}`
                  : t('Unavailable user')
              }</IonLabel>
            </>
        }
      </div>
    </div>
  );
};

export default Contact;
