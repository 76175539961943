import { IonCol, IonGrid, IonItem, IonRow, IonSpinner, IonTextarea, IonToggle } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { AmendmentActions } from './AmendmentActions';
import amendmentStyles from './CreateAmendment.module.scss';
import { FileList } from './FileList';
import styles from './styles.module.scss';
import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import { setActivityCodes as setActivityCodesReducer } from '../../../reducers/ActivityCodes';
import { setEconomyMonitoring } from '../../../reducers/economyMonitoring';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import formatNumber from '../../../tools/formatNumber';
import BigUp from '../../UI';
import EmptyList from '../../UI/EmptyList';
import { ValidationBadge } from '../../UI/Inputs/ValidationBadge';

type ModelType = E2U.V1.Models.EconomyChange | E2U.V1.Models.EconomyDeviation;
type RowType = E2U.V1.Models.EconomyChangeRow | E2U.V1.Models.EconomyDeviationRow;

interface CreateAmendmentProps {
    type: 'deviations' | 'changes';
    amendment?: E2U.V1.Models.EconomyChange | E2U.V1.Models.EconomyDeviation;
}

const AmendmentForm: React.FC<CreateAmendmentProps> = ({ amendment, type }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getUploadedPhotos, handleTakenPhoto, setUploadedPhotos, uploadSelectedPhotos } = useCameraUpload();
  const { getUploadedFiles, handleFileSelection, setUploadedFiles, uploadSelectedFiles } = useFileUpload();

  const { uuid } = useParams<{ uuid: string }>();
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const [activityCodes, setActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const [accepted, setAccepted] = useState<boolean>(amendment?.accepted ?? false);
  const [previewImage, setPreviewImage] = useState<number[]>([]);
  const [selectedEconomyMonitorings, setSelectedEconomyMonitorings] = useState<E2U.V1.Models.NotPostedRow[]>([]);
  const [update, setUpdate] = useState(false);

  const economyMonitoring: E2U.V1.Models.EconomyMonitoring | undefined = useAppSelector((state) => state.economyMonitoring.economyMonitoring);
  const preSelectedActivityCodes = useAppSelector((state) => state.activityCodes);
  const isLoading: boolean = useAppSelector((state) => state.loading.isLoading.economyMonitoring);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const fetchEconomyMonitoring = () => networking.get(`/api/v1/projects/${uuid}/not_posted`)
    .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.NotPosted[]>>) => {
      store.dispatch(setEconomyMonitoring(response.data.data.records[0]));
    });

  const uploadedFiles = getUploadedFiles();
  const uploadedPhotos = getUploadedPhotos();
  const allFiles: E2U.V1.Models.File[] = [
    ...(uploadedFiles || []),
    ...(uploadedPhotos || [])
  ];

  const methods = useForm<ModelType>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: amendment ?? {
      project_id: uuid,
      accepted: false,
      comment: '', // TODO: Remove validation from backend on changes, currently:  Must be a string, required from server (should not be required on a change).
      rows: []
    }
  });

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = methods;

  const toggleState = (setter: React.Dispatch<React.SetStateAction<number[]>>, i: number) => {
    setter((prev) => {
      if (prev.includes(i)) {
        return prev.filter((index) => index !== i);
      } else {
        return [...prev, i];
      }
    });
  };

  const fetchActivityCodes = () => {
    if (!economyMonitoring?.id) {
      return fetchEconomyMonitoring();
    }
    store.dispatch(setIsLoading({ name: 'fetchActivityCodes', value: false }));

    networking.get(`/api/v1/activity_codes?per_page=9999`)
      .then((response) => {
        setActivityCodes(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'fetchActivityCodes', value: false }));
        setUpdate(!update);
      });
  };

  const handleAcceptedToggle = () => {
    setAccepted(!accepted);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const postData = { ...data, accepted };

    const request = amendment
      ? networking.put(`/api/v1/${type}/${amendment.id}`, postData)
      : networking.post(`/api/v1/${type}`, postData);

    toasters.promise(request, {
      pending: i18n.t(`Saving ${type}`),
      success: i18n.t(`Successfully saved ${type}`),
      error: i18n.t(`Error saving ${type}`)
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.EconomyDeviation | E2U.V1.Models.EconomyChange>) => {
        Promise.allSettled([
          uploadSelectedFiles(
            `/api/v1/economy_${type}`,
            response.data.data.id ?? ''
          ),
          uploadSelectedPhotos(
            `/api/v1/economy_${type}`,
            response.data.data.id ?? ''
          )
        ])
          .then(() => history.goBack());
      });
  };

  useEffect(() => {
    fetchActivityCodes();
  }, [economyMonitoring]);

  useEffect(() => {
    setValue('rows', [
      ...getValues('rows'),
      ...selectedEconomyMonitorings.filter(
        (row: E2U.V1.Models.NotPostedRow) => {
          const rows = getValues('rows') ?? [];
          return rows.findIndex((existRow: RowType): boolean => {
            return existRow.activity_code_id === row.activity_code_id;
          }) === -1;
        }
      ).map((row) => {
        return {
          activity_code: row.activity_code,
          name: row.name,
          not_posted_row: row,
          quantity: 0,
          cost_per_unit: 0,
          description: '',
          unit: row.activity_code?.unit || 'sqm',
          activity_code_id: row.activity_code_id,
          not_posted_row_id: row.id
        };
      })
    ]);
    setUpdate(!update);
  }, [selectedEconomyMonitorings]);

  useEffect(() => {
    if (!selectedActivityCodes) {
      return;
    }
    setValue('rows', [
      ...getValues('rows'),
      ...selectedActivityCodes.filter(
        (row: E2U.V1.Models.ActivityCode) => {
          const rows = getValues('rows') ?? [];

          return rows.findIndex((existRow: RowType): boolean => {
            return existRow.activity_code_id === row.id;
          }) === -1;
        }
      ).map((row) => {
        return {
          activity_code: row as E2U.V1.Models.ActivityCode,
          quantity: '' || 0,
          cost_per_unit: '' || 0,
          unit: row.unit ?? 'sqm',
          activity_code_id: row.id ?? '',
          name: row.name || '',
          description: '',
        };
      })
    ]);
    setUpdate(!update);
  }, [selectedActivityCodes]);

  useEffect(() => {
    fetchEconomyMonitoring();
    checkExistingFiles();
  }, []);

  /**
     * Pre-populate rows with ActivityCode associated
     * with the row clicked on Monitoring page.
     */

  useEffect(() => {
    const preSelectedCodes = preSelectedActivityCodes?.activityCodes;

    if (!preSelectedCodes) {
      return;
    }

    setValue('rows', [
      ...getValues('rows'),
      ...preSelectedCodes.map((row) => {
        return {
          activity_code: row as E2U.V1.Models.ActivityCode,
          quantity: '' || 0,
          cost_per_unit: '' || 0,
          unit: row.unit ?? 'sqm',
          activity_code_id: row.id ?? '',
          name: row.name || '',
          description: '',
        };
      })
    ]);

    store.dispatch(setActivityCodesReducer([]));
  }, []);

  useEffect(() => {
    if (!getValues('rows').length && amendment) {
      setValue('rows', amendment.rows);
    }
  }, [update]);

  const checkExistingFiles = () => {
    if (amendment && amendment.files) {
      const photos = amendment.files.filter((file) => file.type === 'image');
      const files = amendment.files.filter((file) => file.type !== 'image');
      setUploadedPhotos(photos);
      setUploadedFiles(files);
    }
  };

  useEffect(() => {
    checkExistingFiles();
  }, [amendment]);

  return (
    <React.Fragment>
      {isLoading
        ? <IonSpinner/>
        : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonGrid className={styles.createChangeTopSection}>
                <IonRow
                  className='ion-padding-start ion-justify-content-between ion-align-items-start ion-margin-top'>
                  <IonCol size='12' className={classNames(['ion-padding', styles.headerWrapper])}>
                    <h2 className='ion-no-margin'>
                      {amendment
                        ? t('Edit {modification_type}: {name}', 'Edit {modification_type}: {name}', {
                          name: amendment.name,
                          modification_type: type,
                        })
                        : i18n.t('Create new {type}', 'Create new {type}', { type })
                      }
                    </h2>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid className={'ion-align-items-center ion-no-padding ion-padding-horizontal'}>
                <AmendmentActions
                  activityCodes={activityCodes}
                  setSelectedActivityCodes={setSelectedActivityCodes}
                  uploadFromCamera={handleTakenPhoto}
                  handleFileSelection={handleFileSelection}
                />
                <FileList
                  allFiles={allFiles}
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  toggleState={toggleState}
                  typeLabel={i18n.t('{type} File', '{type} File', { type: type === 'changes' ? t('Change') : i18n.t('Deviation') })}
                />
              </IonGrid>
              <div {...isDesktop && {
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }
              }}>
                <IonGrid
                  className={classNames(['ion-no-padding ion-padding-horizontal', amendmentStyles.rowContainer])}>
                  {getValues('rows').length === 0 && (
                    <EmptyList
                      title={i18n.t('No selected activity codes')}
                      message={i18n.t('Select activity codes to create your deviation.')}
                    />
                  )}
                  {getValues('rows').map((row, index) => (
                    <React.Fragment key={index}>
                      <IonRow>
                        <IonCol size='12'>
                          <IonItem className='ion-no-padding' lines='none'>
                            <BigUp.Label.Thick
                              label={`${row?.activity_code?.code} | ${row.name}`}/>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow
                        className={'ion-justify-content-start ion-align-items-center'}>
                        <IonCol size='6'>
                          <BigUp.Input
                            label={i18n.t('Quantity')}
                            labelPlacement='stacked'
                            onIonInput={(e) => {
                              const currentTarget = e.currentTarget as HTMLInputElement;
                              if (currentTarget && currentTarget.value) {
                                setValue(`rows.${index}.quantity` as any, formatNumber(parseFloat(currentTarget.value.replace(/[ ]/g, ''))));
                              }
                            }}
                            validation={{
                              required: {
                                value: true,
                                message: i18n.t('This field is required')
                              },
                              minLength: {
                                value: 1,
                                message: i18n.t('Minimum length is 1')
                              },
                              setValueAs: (value) => {
                                return value.toString().replace(/[ ]/g, '');
                              },
                            }}
                            register={`rows.${index}.quantity`}
                            clearOnEdit
                            type='text'
                            inputmode='numeric'
                            className='ion-no-padding' placeholder='Enter a value'
                          />
                        </IonCol>
                        <IonCol size='6'>
                          <BigUp.Input
                            label={i18n.t('Cost / unit')}
                            labelPlacement='stacked'
                            onIonInput={(e) => {
                              const currentTarget = e.currentTarget as HTMLInputElement;
                              if (currentTarget && currentTarget.value) {
                                methods.setValue(`rows.${index}.cost_per_unit` as any, formatNumber(parseFloat(currentTarget.value.replace(/[ ]/g, ''))));
                              }
                            }}
                            register={`rows.${index}.cost_per_unit`}
                            validation={{
                              required: {
                                value: true,
                                message: i18n.t('This field is required')
                              },
                              minLength: {
                                value: 1,
                                message: i18n.t('Minimum length is 1')
                              },
                              setValueAs: (value) => {
                                return value.toString().replace(/[ ]/g, '');
                              },
                            }}
                            clearOnEdit
                            type='text'
                            inputmode='numeric'
                            className='ion-no-padding' placeholder='Enter a value'
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        {errors.rows && errors.rows[index] && errors.rows[index]?.name &&
                                                  <ValidationBadge>
                                                    {errors.rows[index]?.name?.message}
                                                  </ValidationBadge>
                        }
                      </IonRow>

                      <IonRow>
                        <IonCol className='ion-no-margin'>
                          <BigUp.Textarea
                            autoCapitalize='sentences'
                            itemProps={{ className: 'ion-no-padding' }}
                            label={i18n.t('Description')}
                            placeholder={i18n.t('Add description')}
                            inputMode='text'
                            autoGrow
                            validation={{
                              required: { value: false, message: '' },
                              minLength: {
                                value: 3,
                                message: i18n.t('Minimum length is 3')
                              }
                            }}
                            register={`rows.${index}.description`}
                          />
                          {errors.rows && errors.rows[index] && errors.rows[index]?.description &&
                                                      <ValidationBadge>
                                                        {errors.rows[index]?.description?.message}
                                                      </ValidationBadge>
                          }
                        </IonCol>
                      </IonRow>
                    </React.Fragment>
                  ))}
                </IonGrid>

                <IonGrid className={classNames(['ion-no-padding ion-padding-start ion-padding-end'])}>
                  {type === 'deviations' && (
                    <IonRow>
                      <IonCol className='ion-no-margin'>
                        <IonTextarea
                          disabled={getValues('rows').length === 0}
                          className="ion-padding-left"
                          style={{
                            fontSize: '14px',
                            '--background': '#f2f2f2',
                            paddingLeft: '8px',
                            minHeight: '150px'
                          }}
                          autoGrow={true}
                          {...register('comment', {
                            required: {
                              value: true,
                              message: i18n.t('This field is required')
                            },
                            minLength: { value: 3, message: i18n.t('Minimum length is 3') }
                          })}
                          placeholder={
                            getValues('rows').length === 0
                              ? (t('Select activity code before adding comments'))
                              : i18n.t('Add comment for the {type}', 'Add comment for the {type}', {
                                type: amendment ? type.replace(/s$/, '') : type
                              })
                          }
                        />
                        {errors.comment &&
                                                  <ValidationBadge>
                                                    {errors.comment?.message}
                                                  </ValidationBadge>
                        }
                      </IonCol>
                    </IonRow>
                  )}
                  <IonGrid>
                    <IonRow className={'ion-align-items-center'}>
                      <IonCol size='12'>
                        <IonItem className={'ion-no-padding'}>
                          <IonToggle
                            mode='md'
                            labelPlacement='start'
                            color={'primary'}
                            name='accepted'
                            onClick={handleAcceptedToggle}
                            checked={accepted}

                          > {t('Accepted {type}', 'Accepted {type}', { type: type.replace(/s$/, '') })}</IonToggle>
                        </IonItem>

                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid>
                    <IonRow className='ion-justify-content-evenly ion-align-items-center'>
                      <IonCol size='4' className='ion-text-center ion-margin-vertical'>
                        <BigUp.Buttons.Regular
                          expand='block'
                          onClick={() => history.goBack()}
                          title={i18n.t('Cancel')}
                          color='light'
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-text-center'>
                        <BigUp.Buttons.Regular
                          expand='block'
                          title={i18n.t('Save')}
                          color='secondary'
                          type='submit'
                          disabled={!getValues('rows').length || methods.formState.isSubmitting}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonGrid>
              </div>
            </form>
          </FormProvider>
        )
      }
    </React.Fragment>
  );
};

export default AmendmentForm;
