import i18n from '../../../../../i18n';

export const columns = [
  {
    key: 'quantity',
    label: i18n.t('Quantity'),
    sizes: {
      xl: '2',
      xs: '4'
    },
    sortable: true,
  },
  {
    key: 'cost_per_unit',
    label: i18n.t('Cost/unit'),
    sizes: {
      xl: '2',
      xs: '4'
    },
    sortable: true,
  },
  {
    key: 'unit',
    label: i18n.t('Unit'),
    sizes: {
      xl: '2',
      xs: '4'
    },
    sortable: true,
  },
  {
    key: 'total',
    label: i18n.t('Total'),
    sizes: {
      xl: '2',
      xs: '4'
    },
    sortable: true,
  },
  {
    key: 'updated_at',
    label: i18n.t('Updated at'),
    alignment: 'right',
    sizes: {
      xl: '2',
      xs: '4'
    },
    sortable: true,
  },
];
