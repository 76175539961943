import { IonTextarea } from '@ionic/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import type { TextareaProps } from '../../../../components/UI/Textareas/interface';
import i18n from '../../../../i18n';

const inputProps: TextareaProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    labelPlacement: 'start',
    validation: {
      required: false
    },
    register: 'description',
  },
];

const Description: React.FC = () => {
  const methods = useFormContext();

  const showErrorOutline = (item: TextareaProps<any>): boolean => {
    const isTouched = methods.formState.touchedFields[item.register] ?? false;
    const required = item.validation?.required;
    const isSet = !methods.watch(item.register);

    return isTouched && required && isSet;
  };

  const { t } = useTranslation();
  const items = inputProps.map((input, index) => ({
    id: (index + 1).toString(),
    errorOutline: showErrorOutline(input),
    content: (
      <Controller
        name={input.name ?? 'description'}
        render={({ field }) => (
          <IonTextarea
            {...field}
            label={t('Comments')}
            label-placement="floating"
            rows={5}
            onIonChange={(e) => field.onChange(e.detail.value)}
          />
        )}
      />
    )
  }));
  return <BigUp.ItemList header={{ title: t('Work activities and risk assessment') }} items={items} />;
};

export default Description;
