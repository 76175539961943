import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

import AddNewTag from './Tags/AddNewTag';
import TagCategoryEdit from './Tags/TagCategoryEdit';
import TagList from './Tags/TagList';
import Toast from './Toast';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import EmptyList from '../../components/UI/EmptyList';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import { useDeleteConfirmation } from '../../hooks/useDeleteConfirmation';
import i18n from '../../i18n';

const TagPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ category: E2U.V1.Models.TagCategory }>();
  const { tag_category_id } = useParams<{ tag_category_id: string }>();
  const { handleDeleteConfirmation } = useDeleteConfirmation({});
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const [category, setCategory] = useState<E2U.V1.Models.TagCategory>(location.state?.category || {});
  const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);

  const methods = useForm<E2U.V1.Models.TagCategory>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      tags: []
    }
  });

  const getTags = () => {
    networking.get(`/api/v1/tag_categories/${tag_category_id}?with=tags`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.TagCategory>) => {
        const data = response.data.data;
        if (data) {
          setCategory(data);
          methods.setValue('name', data.name);
          methods.setValue('id', data.id);
          if (data.tags) {
            methods.setValue('tags', data.tags);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const findTagName = (id: string | undefined) => {
    return category.tags?.find((tag) => tag.id === id)?.name || '';
  };

  const handleSaveAndDelete = (
    type: 'tag' | 'tag_category',
    action: 'delete' | 'update',
    id: string | undefined,
    data?: FieldValues,
    callback?: () => void
  ) => {
    const url = type === 'tag' ? `/api/v1/tags/${id}` : `/api/v1/tag_categories/${id}`;
    const response = action === 'delete' ? networking.delete(url) : networking.put(url, data);

    response
      .then(() => {
        if (callback) callback();
        const successMessage: string = type === 'tag'
          ? t('Updated tag: {name}', 'Updated tag: {name}', { name: data?.name })
          : i18n.t('Updated tag category: {name}', 'Updated tag category: {name}', { name: data?.name });

        if (action === 'update') {
          Toast(successMessage, 'success');
        } else {
          getTags();
          type === 'tag_category' && history.replace(`/tools/${selectedProject?.id}/tags`);
          toasters.createToast({
            message: type === 'tag'
              ? t('Deleted tag: {name}', 'Deleted tag: {name}', { name: findTagName(id) })
              : i18n.t('Deleted tag category: {name}', 'Deleted tag category: {name}', { name: category.name }),
            background: 'var(--ion-color-light)'
          }, 'success');
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.response?.status === 409) {
          toasters.createToast({
            message: type === 'tag' ? t('Tag already exists') : i18n.t('Tag category already exists'),
            background: 'var(--ion-color-light)'
          }, 'error');
          return;
        }
        toasters.createToast({
          message: i18n.t('Something went wrong!'),
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  const deleteConfirmation = (
    type: 'tag' | 'tag_category',
    id: string | undefined,
    name: string
  ) => {
    handleDeleteConfirmation(
      () => handleSaveAndDelete(type, 'delete', id, undefined),
      t('Confirm delete'),
      t('Are you sure you want to delete {type} {name}?', 'Are you sure you want to delete {type} {name}?', {
        type: type === 'tag' ? 'tag' : 'category',
        name
      })
    );
  };

  const handleTagDelete = (id: string | undefined, name: string) => {
    deleteConfirmation('tag', id, name);
  };

  const handleTagCategoryDelete = () => {
    deleteConfirmation('tag_category', category.id, category.name);
  };

  useEffect(() => {
    getTags();
  }, [tag_category_id, category.name]);

  return (
    <DesktopWrapper>
      <TagCategoryEdit
        category={category}
        onSave={(data) => handleSaveAndDelete('tag_category', 'update', category.id, data, getTags)}
        onDelete={() => handleTagCategoryDelete()}
        onCancel={() => setIsEditingCategory(false)}
        isEditing={isEditingCategory}
        setIsEditing={setIsEditingCategory}
      />
      <AddNewTag getTags={getTags} />
      <TagList
        tags={category.tags || []}
        onTagSave={(id, data) => handleSaveAndDelete('tag', 'update', id, data, getTags)}
        onTagDelete={(id) => handleTagDelete(id, findTagName(id))}
      />
      {category.tags?.length === 0 && (
        <EmptyList
          title={i18n.t('No tags found')}
          message={i18n.t('You can add tags to your project to categorize your documents.')}
        />
      )}
    </DesktopWrapper>
  );
};

export default TagPage;
