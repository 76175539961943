import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import type { SelectOption } from './interface';
import { networking } from '../../../api/networking';
import i18n from '../../../i18n';
import type { CountryList } from '../../../tools/countryList';
import fixedCountryList from '../../../tools/countryList';

interface CountrySelectProps {
  default: CountryList['name'] | undefined;
  register?: string;
  label?: string;
  validation?: Record<string, string>;
  placeholder?: string;
  fetchCountries?: boolean;
}
const getCountryIdFromName = (country: string) => {
  return fetchCountriesRequest().then((countries) => {
    return countries.find((c) => c.value === country)?.id ?? '';
  });
};

const fetchCountriesRequest = () => {
  return networking.get('/api/v1/countries')
    .then((res: E2U.V1.Response.Success<E2U.V1.Models.Country[]>) => {
      return res.data.data.map((country) => {
        return {
          value: country.name,
          id: country.id ?? ''
        };
      });
    });
};

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const value = methods.watch(props.register ?? 'country');
  const [countries, setCountries] = useState<{
    value: string;
    id: string;
  }[]>([]);

  const getCountries = () => {
    return fixedCountryList.map((country) => {
      return {
        value: country.name,
        id: country.code
      };
    });
  };

  const fetchCountries = () => {
    fetchCountriesRequest().then((countries) => {
      setCountries(countries);
    });
  };

  const handleCountrySelection = (value: SelectOption | string) => {
    methods.setValue(props.register ?? 'country', value);
  };

  const countrySelect = {
    data: countries,
    selected: value,
    placeholder: props.placeholder ?? t('Select country'),
    handleSelection: handleCountrySelection,
    label: props.label ?? i18n.t('Country'),
    register: props.register ?? 'country',
    validation: props.validation ?? { required: i18n.t('Country is required') },
    itemProps: { className: 'ion-no-padding ion-margin-bottom' },
  };

  useEffect(() => {
    if (props.fetchCountries) {
      fetchCountries();
    } else {
      setCountries(getCountries());
    }
  }, []);

  useEffect(() => {
    if (props.fetchCountries) {
      fetchCountries();
    } else {
      setCountries(getCountries());
    }
  }, [props.fetchCountries]);

  return <BigUp.Select {...countrySelect} />;
};

const countries = {
  fromName: getCountryIdFromName,
};

export { countries };
export default CountrySelect;
