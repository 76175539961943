import * as net from 'net';

import {
  IonButton,
  IonButtons, IonCol,
  IonContent, IonGrid, IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList, IonLoading, IonModal, IonNote, IonRow, IonTitle, IonToolbar
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { AclFoundEntity } from '@techlove/easy2use-typings/src/V1/Objects/AclFoundEntity';
import { pencil, trash, warning } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import QuickAccessForm from '../QuickAccessForm';
import AccessRowLabel from './AccessRowLabel';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import type { FoundEntity } from '../../../pages/ClientAccount/AccessManagementPage';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import AccessModuleList from '../AccessModuleList';

export interface AclPolicy {
    id: string;
    name: string;
}

interface AccessListProps {
    rows: FoundEntity[];
    entity: 'clients' | 'projects';
    id: string;
    handleAccessGranted: (result: E2U.V1.Objects.AclGrantAccessResult) => void;
    handleAccessRevoked: () => void;
}

const AccessList: React.FC<AccessListProps> = ({
  entity,
  handleAccessGranted,
  handleAccessRevoked,
  id,
  rows
}) => {
  const { t } = useTranslation();
  const [row, setRow] = React.useState<AclFoundEntity | null>(null);
  const isRevokingAccess = useAppSelector(state => state.loading.isLoading.isRevokingAccess);

  const handleSelectEntity = (row: FoundEntity) => {
    setRow(row as AclFoundEntity);
  };

  const handleModalAccessGranted = (result: E2U.V1.Objects.AclGrantAccessResult) => {
    setRow(null);
    handleAccessGranted(result);
  };

  const deleteRow = (row: FoundEntity) => {
    store.dispatch(setIsLoading({ name: 'isRevokingAccess', value: true }));
    const idColumn = row.type === 'user' ? 'user_id' : 'team_id';
    networking.delete(`/api/v1/${entity}/${id}/acl`, {
      data: {
        [idColumn]: row.id,
      }
    })
      .then(() => {
        handleAccessRevoked();
        toasters.success(t('Access revoked successfully'));
      })
      .catch(() => {
        toasters.error(t('Failed to revoke access'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'isRevokingAccess', value: false }));
      });
  };

  return (
    <>
      {isRevokingAccess && <IonLoading isOpen={true}/>}
      <IonList>
        {rows.map((row, index) => (
          <IonItemSliding key={index}>
            <IonItem>
              <AccessRowLabel row={row}/>
              {!('email' in row && row.email !== null && row.email !== '')
                ? <>
                  <IonNote slot="end">{t('{amount} pcs', {
                    amount: row.count
                  })}</IonNote>
                </>
                : <>
                  <IonNote slot="end">{t('{amount} pcs', {
                    amount: row.count
                  })}</IonNote>
                </>}
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption
                onClick={() => handleSelectEntity(row)}
              >
                <IonIcon slot="icon-only" icon={pencil}/>
              </IonItemOption>
              <IonItemOption
                color="danger"
                onClick={() => deleteRow(row)}
              >
                <IonIcon slot="icon-only" icon={trash}/>
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
        <QuickAccessForm
          onAccessGranted={handleAccessGranted}
          id={id}
          entity={entity}
        />
      </IonList>
      <IonModal isOpen={!!row}>
        {row && <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setRow(null)}>{t('Close')}</IonButton>
              </IonButtons>
              <IonTitle>{t('Assign modules for {name}', { name: row.name })}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className={'ion-padding'}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <p className={'ion-no-margin ion-margin-bottom'}>{t('Select the desired modules for {name}.', {
                      name: row.name
                    })}</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <AccessModuleList
                      rows={{
                        found: [row],
                        missing: [],
                        unprocessable: []
                      }}
                      entity={entity}
                      id={id}
                      onSuccessfulGrant={handleModalAccessGranted}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
        </>}
      </IonModal>
    </>
  );
};

export default AccessList;
