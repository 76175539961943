import i18n from '../i18n';

export interface CountryList {
  name: string;
  code: string;
}

const fixedCountryList: CountryList[] = [
  {
    name: i18n.t('Sweden'),
    code: 'SE'
  },
  {
    name: i18n.t('Denmark'),
    code: 'DK'
  },
  {
    name: i18n.t('Finland'),
    code: 'FI'
  },
  {
    name: i18n.t('Norway'),
    code: 'NO'
  },
  {
    name: i18n.t('Estonia'),
    code: 'EE'
  },
  {
    name: i18n.t('Latvia'),
    code: 'LV'
  },
  {
    name: i18n.t('Ukraine'),
    code: 'UA'
  },
  {
    name: i18n.t('Poland'),
    code: 'PL'
  },
  {
    name: i18n.t('Romania'),
    code: 'RO'
  },
  {
    name: i18n.t('Bulgaria'),
    code: 'BG'
  },
  {
    name: i18n.t('Russian Federation'),
    code: 'RU'
  },
  {
    name: i18n.t('Afghanistan'),
    code: 'AF'
  },
  {
    name: i18n.t('Albania'),
    code: 'AL'
  },
  {
    name: i18n.t('Algeria'),
    code: 'DZ'
  },
  {
    name: i18n.t('American Samoa'),
    code: 'AS'
  },
  {
    name: i18n.t('Andorra'),
    code: 'AD'
  },
  {
    name: i18n.t('Angola'),
    code: 'AO'
  },
  {
    name: i18n.t('Anguilla'),
    code: 'AI'
  },
  {
    name: i18n.t('Antarctica'),
    code: 'AQ'
  },
  {
    name: i18n.t('Antigua and Barbuda'),
    code: 'AG'
  },
  {
    name: i18n.t('Argentina'),
    code: 'AR'
  },
  {
    name: i18n.t('Armenia'),
    code: 'AM'
  },
  {
    name: i18n.t('Aruba'),
    code: 'AW'
  },
  {
    name: i18n.t('Asia/Pacific Region'),
    code: 'AP'
  },
  {
    name: i18n.t('Australia'),
    code: 'AU'
  },
  {
    name: i18n.t('Austria'),
    code: 'AT'
  },
  {
    name: i18n.t('Azerbaijan'),
    code: 'AZ'
  },
  {
    name: i18n.t('Bahamas'),
    code: 'BS'
  },
  {
    name: i18n.t('Bahrain'),
    code: 'BH'
  },
  {
    name: i18n.t('Bangladesh'),
    code: 'BD'
  },
  {
    name: i18n.t('Barbados'),
    code: 'BB'
  },
  {
    name: i18n.t('Belarus'),
    code: 'BY'
  },
  {
    name: i18n.t('Belgium'),
    code: 'BE'
  },
  {
    name: i18n.t('Belize'),
    code: 'BZ'
  },
  {
    name: i18n.t('Benin'),
    code: 'BJ'
  },
  {
    name: i18n.t('Bermuda'),
    code: 'BM'
  },
  {
    name: i18n.t('Bhutan'),
    code: 'BT'
  },
  {
    name: i18n.t('Bolivia'),
    code: 'BO'
  },
  {
    name: i18n.t('Bonaire, Sint Eustatius and Saba'),
    code: 'BQ'
  },
  {
    name: i18n.t('Bosnia and Herzegovina'),
    code: 'BA'
  },
  {
    name: i18n.t('Botswana'),
    code: 'BW'
  },
  {
    name: i18n.t('Bouvet Island'),
    code: 'BV'
  },
  {
    name: i18n.t('Brazil'),
    code: 'BR'
  },
  {
    name: i18n.t('British Indian Ocean Territory'),
    code: 'IO'
  },
  {
    name: i18n.t('Brunei Darussalam'),
    code: 'BN'
  },
  {
    name: i18n.t('Burkina Faso'),
    code: 'BF'
  },
  {
    name: i18n.t('Burundi'),
    code: 'BI'
  },
  {
    name: i18n.t('Cambodia'),
    code: 'KH'
  },
  {
    name: i18n.t('Cameroon'),
    code: 'CM'
  },
  {
    name: i18n.t('Canada'),
    code: 'CA'
  },
  {
    name: i18n.t('Cape Verde'),
    code: 'CV'
  },
  {
    name: i18n.t('Cayman Islands'),
    code: 'KY'
  },
  {
    name: i18n.t('Central African Republic'),
    code: 'CF'
  },
  {
    name: i18n.t('Chad'),
    code: 'TD'
  },
  {
    name: i18n.t('Chile'),
    code: 'CL'
  },
  {
    name: i18n.t('China'),
    code: 'CN'
  },
  {
    name: i18n.t('Christmas Island'),
    code: 'CX'
  },
  {
    name: i18n.t('Cocos (Keeling) Islands'),
    code: 'CC'
  },
  {
    name: i18n.t('Colombia'),
    code: 'CO'
  },
  {
    name: i18n.t('Comoros'),
    code: 'KM'
  },
  {
    name: i18n.t('Congo'),
    code: 'CG'
  },
  {
    name: i18n.t('Congo, The Democratic Republic of the'),
    code: 'CD'
  },
  {
    name: i18n.t('Cook Islands'),
    code: 'CK'
  },
  {
    name: i18n.t('Costa Rica'),
    code: 'CR'
  },
  {
    name: i18n.t('Croatia'),
    code: 'HR'
  },
  {
    name: i18n.t('Cuba'),
    code: 'CU'
  },
  {
    name: i18n.t('Curaçao'),
    code: 'CW'
  },
  {
    name: i18n.t('Cyprus'),
    code: 'CY'
  },
  {
    name: i18n.t('Czech Republic'),
    code: 'CZ'
  },
  {
    name: i18n.t(`Côte d'Ivoire`),
    code: 'CI'
  },
  {
    name: i18n.t('Djibouti'),
    code: 'DJ'
  },
  {
    name: i18n.t('Dominica'),
    code: 'DM'
  },
  {
    name: i18n.t('Dominican Republic'),
    code: 'DO'
  },
  {
    name: i18n.t('Ecuador'),
    code: 'EC'
  },
  {
    name: i18n.t('Egypt'),
    code: 'EG'
  },
  {
    name: i18n.t('El Salvador'),
    code: 'SV'
  },
  {
    name: i18n.t('Equatorial Guinea'),
    code: 'GQ'
  },
  {
    name: i18n.t('Eritrea'),
    code: 'ER'
  },
  {
    name: i18n.t('Ethiopia'),
    code: 'ET'
  },
  {
    name: i18n.t('Falkland Islands (Malvinas)'),
    code: 'FK'
  },
  {
    name: i18n.t('Faroe Islands'),
    code: 'FO'
  },
  {
    name: i18n.t('Fiji'),
    code: 'FJ'
  },
  {
    name: i18n.t('France'),
    code: 'FR'
  },
  {
    name: i18n.t('French Guiana'),
    code: 'GF'
  },
  {
    name: i18n.t('French Polynesia'),
    code: 'PF'
  },
  {
    name: i18n.t('French Southern Territories'),
    code: 'TF'
  },
  {
    name: i18n.t('Gabon'),
    code: 'GA'
  },
  {
    name: i18n.t('Gambia'),
    code: 'GM'
  },
  {
    name: i18n.t('Georgia'),
    code: 'GE'
  },
  {
    name: i18n.t('Germany'),
    code: 'DE'
  },
  {
    name: i18n.t('Ghana'),
    code: 'GH'
  },
  {
    name: i18n.t('Gibraltar'),
    code: 'GI'
  },
  {
    name: i18n.t('Greece'),
    code: 'GR'
  },
  {
    name: i18n.t('Greenland'),
    code: 'GL'
  },
  {
    name: i18n.t('Grenada'),
    code: 'GD'
  },
  {
    name: i18n.t('Guadeloupe'),
    code: 'GP'
  },
  {
    name: i18n.t('Guam'),
    code: 'GU'
  },
  {
    name: i18n.t('Guatemala'),
    code: 'GT'
  },
  {
    name: i18n.t('Guernsey'),
    code: 'GG'
  },
  {
    name: i18n.t('Guinea'),
    code: 'GN'
  },
  {
    name: i18n.t('Guinea-Bissau'),
    code: 'GW'
  },
  {
    name: i18n.t('Guyana'),
    code: 'GY'
  },
  {
    name: i18n.t('Haiti'),
    code: 'HT'
  },
  {
    name: i18n.t('Heard Island and Mcdonald Islands'),
    code: 'HM'
  },
  {
    name: i18n.t('Holy See (Vatican City State)'),
    code: 'VA'
  },
  {
    name: i18n.t('Honduras'),
    code: 'HN'
  },
  {
    name: i18n.t('Hong Kong'),
    code: 'HK'
  },
  {
    name: i18n.t('Hungary'),
    code: 'HU'
  },
  {
    name: i18n.t('Iceland'),
    code: 'IS'
  },
  {
    name: i18n.t('India'),
    code: 'IN'
  },
  {
    name: i18n.t('Indonesia'),
    code: 'ID'
  },
  {
    name: i18n.t('Iran, Islamic Republic Of'),
    code: 'IR'
  },
  {
    name: i18n.t('Iraq'),
    code: 'IQ'
  },
  {
    name: i18n.t('Ireland'),
    code: 'IE'
  },
  {
    name: i18n.t('Isle of Man'),
    code: 'IM'
  },
  {
    name: i18n.t('Israel'),
    code: 'IL'
  },
  {
    name: i18n.t('Italy'),
    code: 'IT'
  },
  {
    name: i18n.t('Jamaica'),
    code: 'JM'
  },
  {
    name: i18n.t('Japan'),
    code: 'JP'
  },
  {
    name: i18n.t('Jersey'),
    code: 'JE'
  },
  {
    name: i18n.t('Jordan'),
    code: 'JO'
  },
  {
    name: i18n.t('Kazakhstan'),
    code: 'KZ'
  },
  {
    name: i18n.t('Kenya'),
    code: 'KE'
  },
  {
    name: i18n.t('Kiribati'),
    code: 'KI'
  },
  {
    name: i18n.t('Korea, Republic of'),
    code: 'KR'
  },
  {
    name: i18n.t('Kuwait'),
    code: 'KW'
  },
  {
    name: i18n.t('Kyrgyzstan'),
    code: 'KG'
  },
  {
    name: i18n.t('Laos'),
    code: 'LA'
  },
  {
    name: i18n.t('Lebanon'),
    code: 'LB'
  },
  {
    name: i18n.t('Lesotho'),
    code: 'LS'
  },
  {
    name: i18n.t('Liberia'),
    code: 'LR'
  },
  {
    name: i18n.t('Libyan Arab Jamahiriya'),
    code: 'LY'
  },
  {
    name: i18n.t('Liechtenstein'),
    code: 'LI'
  },
  {
    name: i18n.t('Lithuania'),
    code: 'LT'
  },
  {
    name: i18n.t('Luxembourg'),
    code: 'LU'
  },
  {
    name: i18n.t('Macao'),
    code: 'MO'
  },
  {
    name: i18n.t('Madagascar'),
    code: 'MG'
  },
  {
    name: i18n.t('Malawi'),
    code: 'MW'
  },
  {
    name: i18n.t('Malaysia'),
    code: 'MY'
  },
  {
    name: i18n.t('Maldives'),
    code: 'MV'
  },
  {
    name: i18n.t('Mali'),
    code: 'ML'
  },
  {
    name: i18n.t('Malta'),
    code: 'MT'
  },
  {
    name: i18n.t('Marshall Islands'),
    code: 'MH'
  },
  {
    name: i18n.t('Martinique'),
    code: 'MQ'
  },
  {
    name: i18n.t('Mauritania'),
    code: 'MR'
  },
  {
    name: i18n.t('Mauritius'),
    code: 'MU'
  },
  {
    name: i18n.t('Mayotte'),
    code: 'YT'
  },
  {
    name: i18n.t('Mexico'),
    code: 'MX'
  },
  {
    name: i18n.t('Micronesia, Federated States of'),
    code: 'FM'
  },
  {
    name: i18n.t('Moldova, Republic of'),
    code: 'MD'
  },
  {
    name: i18n.t('Monaco'),
    code: 'MC'
  },
  {
    name: i18n.t('Mongolia'),
    code: 'MN'
  },
  {
    name: i18n.t('Montenegro'),
    code: 'ME'
  },
  {
    name: i18n.t('Montserrat'),
    code: 'MS'
  },
  {
    name: i18n.t('Morocco'),
    code: 'MA'
  },
  {
    name: i18n.t('Mozambique'),
    code: 'MZ'
  },
  {
    name: i18n.t('Myanmar'),
    code: 'MM'
  },
  {
    name: i18n.t('Namibia'),
    code: 'NA'
  },
  {
    name: i18n.t('Nauru'),
    code: 'NR'
  },
  {
    name: i18n.t('Nepal'),
    code: 'NP'
  },
  {
    name: i18n.t('Netherlands'),
    code: 'NL'
  },
  {
    name: i18n.t('Netherlands Antilles'),
    code: 'AN'
  },
  {
    name: i18n.t('New Caledonia'),
    code: 'NC'
  },
  {
    name: i18n.t('New Zealand'),
    code: 'NZ'
  },
  {
    name: i18n.t('Nicaragua'),
    code: 'NI'
  },
  {
    name: i18n.t('Niger'),
    code: 'NE'
  },
  {
    name: i18n.t('Nigeria'),
    code: 'NG'
  },
  {
    name: i18n.t('Niue'),
    code: 'NU'
  },
  {
    name: i18n.t('Norfolk Island'),
    code: 'NF'
  },
  {
    name: i18n.t('North Korea'),
    code: 'KP'
  },
  {
    name: i18n.t('North Macedonia'),
    code: 'MK'
  },
  {
    name: i18n.t('Northern Mariana Islands'),
    code: 'MP'
  },
  {
    name: i18n.t('Oman'),
    code: 'OM'
  },
  {
    name: i18n.t('Pakistan'),
    code: 'PK'
  },
  {
    name: i18n.t('Palau'),
    code: 'PW'
  },
  {
    name: i18n.t('Palestinian Territory, Occupied'),
    code: 'PS'
  },
  {
    name: i18n.t('Panama'),
    code: 'PA'
  },
  {
    name: i18n.t('Papua New Guinea'),
    code: 'PG'
  },
  {
    name: i18n.t('Paraguay'),
    code: 'PY'
  },
  {
    name: i18n.t('Peru'),
    code: 'PE'
  },
  {
    name: i18n.t('Philippines'),
    code: 'PH'
  },
  {
    name: i18n.t('Pitcairn Islands'),
    code: 'PN'
  },
  {
    name: i18n.t('Portugal'),
    code: 'PT'
  },
  {
    name: i18n.t('Puerto Rico'),
    code: 'PR'
  },
  {
    name: i18n.t('Qatar'),
    code: 'QA'
  },
  {
    name: i18n.t('Reunion'),
    code: 'RE'
  },
  {
    name: i18n.t('Rwanda'),
    code: 'RW'
  },
  {
    name: i18n.t('Saint Barthélemy'),
    code: 'BL'
  },
  {
    name: i18n.t('Saint Helena'),
    code: 'SH'
  },
  {
    name: i18n.t('Saint Kitts and Nevis'),
    code: 'KN'
  },
  {
    name: i18n.t('Saint Lucia'),
    code: 'LC'
  },
  {
    name: i18n.t('Saint Martin'),
    code: 'MF'
  },
  {
    name: i18n.t('Saint Martin'),
    code: 'MF'
  },
  {
    name: i18n.t('Saint Pierre and Miquelon'),
    code: 'PM'
  },
  {
    name: i18n.t('Saint Vincent and the Grenadines'),
    code: 'VC'
  },
  {
    name: i18n.t('Samoa'),
    code: 'WS'
  },
  {
    name: i18n.t('San Marino'),
    code: 'SM'
  },
  {
    name: i18n.t('Sao Tome and Principe'),
    code: 'ST'
  },
  {
    name: i18n.t('Saudi Arabia'),
    code: 'SA'
  },
  {
    name: i18n.t('Senegal'),
    code: 'SN'
  },
  {
    name: i18n.t('Serbia'),
    code: 'RS'
  },
  {
    name: i18n.t('Serbia and Montenegro'),
    code: 'CS'
  },
  {
    name: i18n.t('Seychelles'),
    code: 'SC'
  },
  {
    name: i18n.t('Sierra Leone'),
    code: 'SL'
  },
  {
    name: i18n.t('Singapore'),
    code: 'SG'
  },
  {
    name: i18n.t('Sint Maarten'),
    code: 'SX'
  },
  {
    name: i18n.t('Slovakia'),
    code: 'SK'
  },
  {
    name: i18n.t('Slovenia'),
    code: 'SI'
  },
  {
    name: i18n.t('Solomon Islands'),
    code: 'SB'
  },
  {
    name: i18n.t('Somalia'),
    code: 'SO'
  },
  {
    name: i18n.t('South Africa'),
    code: 'ZA'
  },
  {
    name: i18n.t('South Georgia and the South Sandwich Islands'),
    code: 'GS'
  },
  {
    name: i18n.t('South Sudan'),
    code: 'SS'
  },
  {
    name: i18n.t('Spain'),
    code: 'ES'
  },
  {
    name: i18n.t('Sri Lanka'),
    code: 'LK'
  },
  {
    name: i18n.t('Sudan'),
    code: 'SD'
  },
  {
    name: i18n.t('Suriname'),
    code: 'SR'
  },
  {
    name: i18n.t('Svalbard and Jan Mayen'),
    code: 'SJ'
  },
  {
    name: i18n.t('Swaziland'),
    code: 'SZ'
  },
  {
    name: i18n.t('Switzerland'),
    code: 'CH'
  },
  {
    name: i18n.t('Syrian Arab Republic'),
    code: 'SY'
  },
  {
    name: i18n.t('Taiwan'),
    code: 'TW'
  },
  {
    name: i18n.t('Tajikistan'),
    code: 'TJ'
  },
  {
    name: i18n.t('Tanzania, United Republic of'),
    code: 'TZ'
  },
  {
    name: i18n.t('Thailand'),
    code: 'TH'
  },
  {
    name: i18n.t('Timor-Leste'),
    code: 'TL'
  },
  {
    name: i18n.t('Togo'),
    code: 'TG'
  },
  {
    name: i18n.t('Tokelau'),
    code: 'TK'
  },
  {
    name: i18n.t('Tonga'),
    code: 'TO'
  },
  {
    name: i18n.t('Trinidad and Tobago'),
    code: 'TT'
  },
  {
    name: i18n.t('Tunisia'),
    code: 'TN'
  },
  {
    name: i18n.t('Turkey'),
    code: 'TR'
  },
  {
    name: i18n.t('Turkmenistan'),
    code: 'TM'
  },
  {
    name: i18n.t('Turks and Caicos Islands'),
    code: 'TC'
  },
  {
    name: i18n.t('Tuvalu'),
    code: 'TV'
  },
  {
    name: i18n.t('Uganda'),
    code: 'UG'
  },
  {
    name: i18n.t('United Arab Emirates'),
    code: 'AE'
  },
  {
    name: i18n.t('United Kingdom'),
    code: 'GB'
  },
  {
    name: i18n.t('United States'),
    code: 'US'
  },
  {
    name: i18n.t('United States Minor Outlying Islands'),
    code: 'UM'
  },
  {
    name: i18n.t('Uruguay'),
    code: 'UY'
  },
  {
    name: i18n.t('Uzbekistan'),
    code: 'UZ'
  },
  {
    name: i18n.t('Vanuatu'),
    code: 'VU'
  },
  {
    name: i18n.t('Venezuela'),
    code: 'VE'
  },
  {
    name: i18n.t('Vietnam'),
    code: 'VN'
  },
  {
    name: i18n.t('Virgin Islands, British'),
    code: 'VG'
  },
  {
    name: i18n.t('Virgin Islands, U.S.'),
    code: 'VI'
  },
  {
    name: i18n.t('Wallis and Futuna'),
    code: 'WF'
  },
  {
    name: i18n.t('Western Sahara'),
    code: 'EH'
  },
  {
    name: i18n.t('Yemen'),
    code: 'YE'
  },
  {
    name: i18n.t('Zambia'),
    code: 'ZM'
  },
  {
    name: i18n.t('Zimbabwe'),
    code: 'ZW'
  },
  {
    name: i18n.t('Åland Islands'),
    code: 'AX'
  }
];

export default fixedCountryList;
