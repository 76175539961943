import { IonContent } from '@ionic/react';

import styles from './styles/Latest.module.scss';

const Latest = (props: any) => {
  const { nav } = props as { nav: HTMLIonNavElement };
  return (
    <IonContent className='ion-padding' style={{ '--background': 'var(--ion-color-light-shade)' }}>
      Latest
    </IonContent>
  );
};

export default Latest;
