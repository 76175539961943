import { useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

interface CreateButtonProps {
  onCategoryCreated: (category: any) => void;
  type: 'projects' | 'clients';
  id: string;
}
const CreateButton: React.FC<CreateButtonProps> = ({ id, onCategoryCreated, type }) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const { section_id } = useParams<{ section_id: string }>();

  const createCategory = (name: string) => {
    store.dispatch(setIsLoading({
      name: 'creatingCategory',
      value: true
    }));
    networking.post(`/api/v1/work_preparations/categories`, {
      name,
      section_id
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onCategoryCreated(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to create category. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'creatingCategory',
          value: false
        }));
      });
  };

  const openCreateModal = () => {
    presentAlert({
      header: t('Create category'),
      message: t('Enter the name for the category.'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: t('Enter name'),
          value: '',
        }
      ],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Create'),
          handler: (data: { name: string }) => {
            createCategory(data.name);
          }
        }
      ]
    });
  };

  return (
    <BigUp.Buttons.Regular
      title={t('Create new category')}
      onClick={() => openCreateModal()}
      className={'ion-margin-top'}
      color={'primary'}
    />
  );
};

export default CreateButton;
