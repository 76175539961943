import { Geolocation } from '@capacitor/geolocation';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonText
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { EmergencyContact } from '@techlove/easy2use-typings/src/V1/Models/EmergencyContact';
import { call, close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './Emergency.module.scss';
import { networking } from '../../api/networking';
import BigUp from '../../components/UI';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';

const EmergencyPage: React.FC = () => {
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      global: true
    }
  });
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [shouldShareLocation, setShouldShareLocation] = useState(false);

  const onSubmit = (data: any) => {
    networking.post(`/api/v1/me/emergencies/${project?.id}`, data)
      .then((response) => {
        setConfirmationOpen(true);
        methods.reset();
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      });
  };

  const setAlertGlobal = (value: boolean) => {
    const currentValue = methods.watch('global');
    if (currentValue !== value) {
      methods.setValue('global', value);
    }
  };

  const fetchLocation = () => {
    Geolocation.getCurrentPosition()
      .then((location) => {
        methods.setValue('location', {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
      }).catch((error) => {
        switch (error.code) {
          case 1: // User denied us access to location

            toasters.createToast({
              message: i18n.t('You denied access to your location. Please reloas the page and try again.'),
              background: 'var(--ion-color-light)',
            }, 'error');
            setShouldShareLocation(false);
            break;
          default:
            Geolocation.requestPermissions().then(() => fetchLocation());
        }
      });
  };

  useEffect(() => {
    if (shouldShareLocation) {
      fetchLocation();
    } else {
      methods.setValue('location', undefined);
    }
  }, [shouldShareLocation]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            className='ion-padding-top'
            style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}
          >
            <BigUp.Label.V2Thick style={{ paddingLeft: '20px' }} label={t('What happened?')} />
            <BigUp.Textarea
              withValidation={false}
              itemProps={{
                lines: 'none',
                className: 'ion-margin-horizontal ion-margin-bottom',
                style: {
                  borderRadius: '12px',
                  border: '1px solid var(--custom-input-outline-color)',
                  marginTop: '8px',
                },
              }}
              register={'message'}
              placeholder={t('Ex. Fire on floor 3. Evacuate!')}
              rows={5}
            />

            <BigUp.Label.V2Thick style={{ paddingLeft: '20px' }} label={t('Send to')} />
            <div className="ion-padding-horizontal" style={{ display: 'flex', gap: '10px' }}>
              <BigUp.Buttons.Select
                active={methods.watch('global')}
                title={t('All')}
                type='button'
                size='small'
                onClick={() => setAlertGlobal(true)}
              />
              <BigUp.Buttons.Select
                active={!methods.watch('global')}
                title={t('Project manager')}
                type='button'
                size='small'
                onClick={() => setAlertGlobal(false)}
              />
            </div>

            <BigUp.Label.V2Thick style={{ paddingLeft: '20px' }} label={t('Share my location')} />
            <div className="ion-padding-horizontal" style={{ display: 'flex', gap: '10px' }}>
              <BigUp.Buttons.Select
                active={shouldShareLocation}
                title={t('Yes')}
                type='button'
                size='small'
                onClick={() => setShouldShareLocation(true)}
              />
              <BigUp.Buttons.Select
                active={!shouldShareLocation}
                title={t('No')}
                type='button'
                size='small'
                onClick={() => setShouldShareLocation(false)}
              />
            </div>

            <div
              className='ion-padding-horizontal ion-padding-top'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <BigUp.Buttons.Danger title={t('Send')} type='submit' />
            </div>
          </div>
        </form>
      </FormProvider>
      <IonModal
        isOpen={confirmationOpen}
        onDidDismiss={() => setConfirmationOpen(false)}
        className={styles.transparentModal}
      >

        <IonContent className='ion-padding' style={{ '--background': 'transparent' }}>
          <IonGrid className={['ion-text-center', styles.modalContents].join(' ')}>
            <IonRow className='ion-justify-content-end'>
              <IonCol size='auto'>
                <IonIcon size='large' icon={close} onClick={() => setConfirmationOpen(false)} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol size='auto'>
                <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 45.2499C0 20.3971 20.1472 0.249939 45 0.249939C69.8528 0.249939 90 20.3971 90 45.2499C90 70.1028 69.8528 90.2499 45 90.2499C20.1472 90.2499 0 70.1028 0 45.2499Z"
                    fill="#4CD964" />
                  <path
                    d="M39.5757 54.8473L63.7682 29.5169C65.2224 27.9943 67.5802 27.9943 69.0344 29.5169C70.4886 31.0395 70.4886 33.5081 69.0344 35.0307L39.5757 65.8749L22.4657 47.9602C21.0115 46.4376 21.0115 43.969 22.4657 42.4464C23.9199 40.9238 26.2776 40.9238 27.7319 42.4464L39.5757 54.8473Z"
                    fill="white" />
                </svg>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center ">
              <IonCol size="auto">
                <IonCol>
                  <h1>{t('Emergency notification sent!')}</h1>
                </IonCol>
                <IonCol>
                  <IonText className={styles.heading}>

                    {t('Do you need to take further actions?')}

                  </IonText>
                </IonCol>

              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonCol size='12'>
                <IonText className={styles.heading}>{t('In case of emergency')}</IonText>
              </IonCol>
              <IonCol>
                <BigUp.Buttons.Danger icon={{ icon: call }} title={t('Call 112')} href='tel:112' />
              </IonCol>
            </IonRow>
            {project?.emergency_contacts && project.emergency_contacts.length > 0 && (
              <IonRow className="ion-justify-content-center">
                <IonCol size="auto" style={{ display: 'flex', flexDirection: 'column' }}>
                  <IonText className={styles.heading}>{t('Contact site manager')}</IonText>
                  {project.emergency_contacts.map((contact: EmergencyContact, index: number) => (
                    <BigUp.Buttons.Gray
                      key={index}
                      icon={{ icon: call }}
                      title={contact.name}
                      href={`tel:${contact.phone_number}`}
                    />
                  ))}
                </IonCol>
              </IonRow>
            )}
            <IonRow className="ion-justify-content-center">
              <IonCol size="auto">
                <BigUp.Buttons.Dark
                  title={t('Go back')}
                  size="default"
                  onClick={() => setConfirmationOpen(false)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </>
  );
};

export default EmergencyPage;
