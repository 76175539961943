import type { E2U } from '@techlove/easy2use-typings';

import tileStyles from './Precalculations.module.scss';
import { useTableStyles } from '../../../../components/UI/Table';

const PrecalculationTile: React.FC<{ attributes: E2U.V1.Models.Precalculation }> = (props) => {
  const styles = useTableStyles();

  return (
    <div className={styles.activity_code_column}>
      <div className={styles.activity_code_column__title}>{props.attributes.name}</div>
      {props.attributes.description && (
        <div className={`${styles.activity_code_column__description} ${tileStyles['text-ellipsis']}`}>
          {`${props.attributes.description}`}
        </div>
      )}
    </div>
  );
};

export default PrecalculationTile;
