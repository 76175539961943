import type { Photo } from '@capacitor/camera';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { networking } from '../api/networking';
import { fileContentHasher } from '../services/fileContentHasher';
import storage from '../storage';

export interface CameraUploadResult {
  photoIsUploaded: boolean;
  handleTakenPhoto: (photos: Photo[]) => void;
  form: ReturnType<typeof useForm>;
  setUploadedToTrue: () => void;
  setUploadedToFalse: () => void;
  getUploadedPhotos: () => E2U.V1.Models.File[];
  setUploadedPhotos: (files: E2U.V1.Models.File[]) => void;
  uploadSelectedPhotos: (apiResourceRoute: string, resourceId: string) => Promise<void>;
  removePhoto: (file: E2U.V1.Models.File) => void;
  filterPhotosFromFiles: (
    files: E2U.V1.Models.File[],
    setUploadedFiles: (files: E2U.V1.Models.File[]) => void
  ) => {
    photos: E2U.V1.Models.File[];
    otherFiles: E2U.V1.Models.File[]
  };
}

const useCameraUpload = (): CameraUploadResult => {
  const [photoIsUploaded, setPhotoIsUploaded] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesToRemove, setFilesToRemove] = useState<E2U.V1.Models.File[]>([]);
  const form = useForm();
  const { t } = useTranslation();

  const setUploadedToTrue = () => {
    setPhotoIsUploaded(true);
  };

  const setUploadedToFalse = () => {
    setPhotoIsUploaded(false);
  };

  const setUploadedPhotos = (files: E2U.V1.Models.File[]) => form.setValue('files', files);
  const getUploadedPhotos = () => form.getValues('files');

  const handleTakenPhoto = (photos: Photo[]) => {
    if (!photos) return;

    const photosArray = Array.from(photos);

    const newFiles: E2U.V1.Models.File[] = [];
    const files: File[] = [];
    Promise.all(photosArray.map((photo) => new Promise((resolve) => {
      const fileName = new Date().getTime() + '.' + photo.format;
      const controller = new AbortController();

      return fetch(photo.dataUrl ? photo.dataUrl : fileName)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], fileName, { type: 'image/jpeg' });
          fileContentHasher(file).then((hash) => {
            newFiles.push({
              id: undefined,
              name: fileName,
              filename: fileName,
              type: 'image',
              path: fileName,
              download_url: '',
              file_hash: hash,
              number_of_revisions: 0,
            });
            files.push(file);
            storage.set(`pending-${hash}`, photo.dataUrl ? photo.dataUrl : fileName);
            resolve('');
          });
        });
    }))).finally(() => {
      const currentFiles = form.getValues('files') || [];
      form.setValue('files', [...currentFiles, ...newFiles]);
      setFilesToUpload((prevFiles) => [...prevFiles, ...files]);
    });
  };

  const uploadPhoto: (file: File) => Promise<
    E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }
    >> = (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.set('name', file.name);
      return new Promise<E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>>((resolve, reject) =>
        networking.post('/api/v1/files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(
            (res: E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>) =>
              resolve(res)
          )
          .catch((error: E2U.V1.Response.Error) => {
            Sentry.captureException(error);
            toast(t('Error while uploading file'), {
              type: 'error'
            });
            reject(error);
          }));
    };

  const uploadSelectedPhotos = (apiResourceRoute: string, resourceId: string) => {
    return new Promise<void>((resolve) => {
      Promise.allSettled<E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>>(
        filesToUpload.map((file) => uploadPhoto(file))
      ).then((results) =>
        Promise.all<void>(results.map((result) =>
          new Promise<void>((resolve) => {
            if (result.status === 'fulfilled') {
              const { value } = result;

              networking.post(apiResourceRoute + '/' + resourceId + '/files/' + value.data.data.id)
                .then(
                  (r) => {
                    resolve();
                  }
                ).catch((err) => {
                  resolve();
                });
            } else {
              resolve();
            }
          })
        ).concat(
          filesToRemove.map((file) =>
            new Promise<void>((resolve) => {
              networking.delete(`/api/v1/files/${file.id}`).then(
                (r) => {
                  resolve();
                }
              ).catch((err) => {
                resolve();
              });
            })
          )
        )).finally(() => {
          resolve();
        })
      );
    });
  };

  const removePhoto = (file: E2U.V1.Models.File) => {
    if (file.id) {
      setFilesToRemove((prev) => [...prev, file]);
    } else {
      setFilesToUpload((prev) => prev.filter((f) => f.name !== file.name));
    }
    setUploadedPhotos(
      getUploadedPhotos().filter((f: E2U.V1.Models.File) => f.file_hash !== file.file_hash)
    );
  };

  const filterPhotosFromFiles = (
    files: E2U.V1.Models.File[],
    setUploadedFiles: (files: E2U.V1.Models.File[]) => void
  ) => {
    const photos = files.filter((file) => file.type === 'image');
    const otherFiles = files.filter((file) => file.type !== 'image');
    setUploadedFiles(otherFiles);
    setUploadedPhotos(photos);
    return {
      photos,
      otherFiles
    };
  };

  return {
    photoIsUploaded,
    handleTakenPhoto,
    form,
    setUploadedToTrue,
    setUploadedToFalse,
    getUploadedPhotos,
    setUploadedPhotos,
    uploadSelectedPhotos,
    removePhoto,
    filterPhotosFromFiles
  };
};

export default useCameraUpload;
