import type { ReactNode } from 'react';

const DesktopWrapper = (
  { alignment, children, paddedPage = true, width }:
    {
      alignment?: string,
      children: ReactNode,
      width?: 'var(--ion-desktop-max-width)' | 'var(--ion-desktop-mid-width)' | string
      paddedPage?: boolean
    }
) => {
  return (
    <div style={{ display: 'flex', justifyContent: `${alignment || 'left'}` }}>
      <div className={
        paddedPage ? 'ion-padding' : ''
      } style={{ maxWidth: `${width || '100%'}`, width: '100%', marginBottom: 20 }}>
        {children}
      </div>
    </div>
  );
};

export default DesktopWrapper;
