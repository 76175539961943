import { IonCol, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { warning } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import MatchingsList from './MatchingsList';
import BigUp from '../../UI';
import AccessModuleList from '../AccessModuleList';

interface MassAccessResultProps {
    content: E2U.V1.Objects.AclSearchResult | null;
    entity: 'projects' | 'clients';
    id: string
}

const MassAccessResult: React.FC<MassAccessResultProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    content = null, entity, id
  } = props;
  const router = useIonRouter();

  const handleSuccessfulGrant = (result: E2U.V1.Objects.AclGrantAccessResult) => {
    router.push(location.pathname.replace('add/review', ''));
  };

  useEffect(() => {
    if (props.content === null) {
      router.push(location.pathname.replace('review', 'import'));
    }
  }, []);

  return (
    <>
      {(content && content.unprocessable && content.unprocessable.length > 0) && <IonRow>
        <IonCol>
          <BigUp.Label.Thick label={t('Failed to match')}/>
          <MatchingsList
            rows={content.unprocessable.map(r => r.message)}
            color={'danger'}
            icon={{
              slot: 'start',
              icon: warning
            }}
          />
          <p>{t('If you\'re not satisfied with the results, you can go back and redo the import with adjusted data.')}</p>
          <BigUp.Buttons.Regular
            title={t('Redo import')}
            routerLink={location.pathname.replace('review', 'import')}
          />
        </IonCol>
      </IonRow>}
      {(content && content.missing && content.missing.length > 0) && <IonRow>
        <IonCol>
          <BigUp.Label.Thick label={t('New users to be invited')}/>
          <MatchingsList
            color={'green'}
            rows={content.missing.map(r => r.id)}
          />
        </IonCol>
      </IonRow>}
      {(content && content.found && content.found.length > 0) && <>
        <IonRow>
          <IonCol>
            <BigUp.Label.Thick label={t('Matching teams and users')}/>
            <MatchingsList
              rows={content.found}
              color={'success'}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BigUp.Label.Thick label={t('Select modules')}/>
            <AccessModuleList
              entity={entity}
              id={id}
              rows={content}
              onSuccessfulGrant={handleSuccessfulGrant}
            />
          </IonCol>
        </IonRow>
      </>}
    </>
  );
};

export default MassAccessResult;
