import { useIonRouter } from '@ionic/react';
import React from 'react';
import { useParams } from 'react-router';

import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import ProjectForm from '../ProjectForm';

interface ProjectCreateProps {
    usesAppLayout?: boolean;
}

const ProjectCreate: React.FC<ProjectCreateProps> = ({ usesAppLayout = true }) => {
  const router = useIonRouter();

  const form = () => (
    <SideMenuV2Layout>
      <ProjectForm newProject onCancel={() => router.goBack()}/>
    </SideMenuV2Layout>
  );

  return (
    usesAppLayout
      ? (
        <SideMenuV2Layout>
          <ProjectForm newProject onCancel={() => router.goBack()}/>
        </SideMenuV2Layout>
      )
      : form()
  );
};

export default ProjectCreate;
