import { networking } from '../api/networking';
import { setIsLoading } from '../reducers/loading';
import { setProjects } from '../reducers/project';
import store from '../store';
import useAcl from './useAcl';
import { setUser } from '../reducers/authentication';

interface FetchProjectsOptions {
  paginate?: boolean;
  page?: number;
  perPage?: number;
  search?: string;
  sort?: string;
  order?: string;
}

const useFetchProjects = (options?: FetchProjectsOptions) => {
  const { projects } = useAcl();
  const searchParams = new URLSearchParams();
  if (options?.paginate) {
    searchParams.append('page', options?.toString() || '1');
    searchParams.append('per_page', options?.toString() || '9999');
  }

  return (force = false) => {
    if (projects && projects.length > 0 && !force) {
      return;
    }

    store.dispatch(setIsLoading({ name: 'projects', value: true }));

    return networking.get('/api/v1/me?with[]=projects')
      .then((response) => {
        store.dispatch(setUser(response.data.data));
        const projects = response.data.data.projects;
        if (typeof projects !== 'undefined' && projects.length > 0) {
          store.dispatch(setProjects(projects));
        }
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'projects', value: false }));
      });
  };
};

export default useFetchProjects;
