import { IonIcon, IonLabel, useIonRouter } from '@ionic/react';
import { peopleCircleOutline } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import ButtonResponsive from '../../Onboarding/Components/buttons/ButtonResponsive';

const CreateTeam: React.FC = () => {
  const router = useIonRouter();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 8 }}>
        {!isDesktop && <BigUp.Label.Regular color='medium' label={t('Create team')} className='ion-no-margin ' />}
        <ButtonResponsive
          type='button'
          onClick={() => router.push(`/tools/${selectedProjectId}/settings/team/create`)}
          mobileButton={{
            children: (<IonIcon color={'primary'} icon={peopleCircleOutline} size='large' />),
            ionicButton: { color: 'none', shape: 'round' },
          }}
          desktopButton={{
            children: (<><IonIcon color={'medium'} icon={peopleCircleOutline} /><IonLabel color='medium'>{t('Create team')}</IonLabel></>),
            ionicButton: { color: 'none' },
          }}
        />
      </div>
    </>
  );
};

export default CreateTeam;
