import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText } from '@ionic/react';
import { serverSharp } from 'ionicons/icons';
import { Link } from 'react-router-dom';

import styles from './EmptyList.module.scss';
import i18n from '../../i18n';

export interface EmptyListProps {
  title: string;
  message: string | JSX.Element;
  icon?: {
    icon: string;
    size: number;
  };
  helperLinks?: {
    title: string;
    url: string;
  }
}

export interface EmptyListStates {
  isLoading?: boolean;
  isEmpty?: boolean;
  error?: string | undefined;
  search?: string;
  messages: {
    error?: { title?: string, message?: string };
    empty?: { title?: string, message?: string };
  };
}

const EmptyList: React.FC<EmptyListProps> = (props) => {
  return (
    <IonGrid className={styles.emptyListWrapper}>
      <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
        <IonCol size='12' className={'ion-no-margin'}>
          <section>
            <IonIcon
              style={{
                fontSize: props.icon?.size ?? 100,
              }}
              icon={props.icon?.icon ?? serverSharp} />
          </section>
        </IonCol>
        <IonText className='ion-text-center' color={'medium'}>
          <h3 className='ion-no-margin'>{props.title}</h3>
          <p className='ion-no-margin'>{props.message}</p>
        </IonText>
        {props.helperLinks && (
          <IonCol size='12' className={'ion-text-center ion-no-padding'}>
            <IonText className='ion-text-center'>
              <Link to={props.helperLinks.url}>{props.helperLinks.title}</Link>
            </IonText>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export const handleEmptyListStates: React.FC<EmptyListStates> = ({ error, isEmpty, isLoading = false, messages, search }): JSX.Element | null => {
  const renderMessages = () => {
    if (isLoading) {
      return (
        <IonGrid className={styles.emptyListWrapper}>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonCol size='12' className={'ion-margin'}>
              <IonSpinner />
            </IonCol>
          </IonRow>
        </IonGrid>
      );
    }
    if (error && !isLoading) {
      return (
        <EmptyList
          title={messages.error?.title ?? i18n.t('Something went wrong')}
          message={error ?? i18n.t('An error occurred')}
        />
      );
    }
    if (isEmpty && !isLoading) {
      return (
        <EmptyList
          title={search ? '' : messages.empty?.title ?? i18n.t('No items found')}
          message={search
            ? i18n.t('No search results for {search}', 'No search results for {search}', {
              search
            })
            : messages.empty?.message ?? i18n.t('There are no items to display')}
        />
      );
    }

    return null;
  };

  return renderMessages();
};

export default EmptyList;
