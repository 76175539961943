import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import styles from './HeaderBackground.module.scss';
import { useAppSelector } from '../../../hooks';

interface HeaderBackgroundProps {
  title: string;
  subtitle: string;
}

const HeaderBackground: React.FC<HeaderBackgroundProps> = ({ subtitle, title }) => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <div className={styles['registration-header-container']}>
      <IonGrid>
        <IonRow className='ion-align-items-center ion-justify-content-between ion-padding-bottom'>
          <IonCol size='2'>
            {isDesktop && (
              <BigUp.Title style={{ color: 'var(--ion-color-light)', textAlign: 'left' }}>
                <h2 className='ion-no-margin ion-margin-start' style={{ fontWeight: 800, letterSpacing: '2px', fontSize: '32px' }}>{t('BigUp')}</h2>
              </BigUp.Title>
            )}
          </IonCol>
          <IonCol size='10' sizeXs='8'>
            <h2>{title}</h2>
            <h5>{subtitle}</h5>
          </IonCol>
          <IonCol size='2'>
            <div></div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default HeaderBackground;
