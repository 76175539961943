import {
  IonCol,
  IonGrid,
  IonIcon, IonImg,
  IonItem,
  IonItemOption,
  IonItemOptions, IonItemSliding,
  IonList,
  IonRow,
  IonSkeletonText, useIonAlert
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { alertCircle, informationCircle, pencil, trash } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import logo from '../../../../assets/fortnox/logo.svg';
import ProjectMappingForm, { columnNames } from '../../../../components/Fortnox/ProjectMappingForm';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
const FortnoxPage: React.FC = () => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isFetchingFortnoxIntegration = useAppSelector((state) => state.loading.isLoading.fetchingFortnoxIntegration);
  const [fortnoxProjectIntegration, setFortnoxProjectIntegration] = useState<any>([]);
  const { integration_id } = useParams<{ integration_id: string }>();
  const [selectedProjectMapping, setSelectedProjectMapping] = useState<any>(null);
  const [presentAlert] = useIonAlert();
  const fetchFortnoxIntegration = () => {
    if (!integration_id) return;
    store.dispatch(setIsLoading({ isLoading: true, key: 'fetchingFortnoxIntegration' }));
    setFortnoxProjectIntegration([]);
    networking.get(`/api/v1/fortnox_project_integrations/${integration_id}?with[]=mappings`)
      .then((res: E2U.V1.Response.Success<any[]>) => {
        setFortnoxProjectIntegration(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Failed to fetch Fortnox integration. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ isLoading: false, key: 'fetchingFortnoxIntegration' }));
      });
  };

  const handleDeleteProjectMapping = (mapping: E2U.V1.Models.FortnoxProjectIntegrationMapping) => {
    presentAlert({
      header: t('Delete mapping'),
      message: t('Are you sure you want to delete configuration "{value}" against Fortnox property {property}?', {
        value: mapping.search_value,
        property: columnNames.find(c => c.id === mapping.column_name)?.value
      }),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Delete'),
          handler: () => {
            store.dispatch(setIsLoading({ isLoading: true, key: 'deletingProjectMapping' }));
            networking.delete(`/api/v1/fortnox_project_integration_mappings/${mapping.id}`)
              .then(() => {
                toasters.success(t('Configuration deleted successfully.'));
                fetchFortnoxIntegration();
              })
              .catch(() => {
                toasters.error(t('Failed to delete configuration. Please try again later or contact support.'));
              })
              .finally(() => {
                store.dispatch(setIsLoading({ isLoading: false, key: 'deletingProjectMapping' }));
              });
          }
        }
      ]
    });
  };

  useEffect(() => {
    fetchFortnoxIntegration();
  }, []);

  useEffect(() => {
    fetchFortnoxIntegration();
  }, [integration_id]);

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonImg src={logo} style={{ maxWidth: '150px' }} className={'ion-margin-bottom'} alt={t('Fortnox logo')} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BigUp.Title label={ t('Fortnox integration for {projectName}', {
              projectName: selectedProject?.name
            }) } />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p className={'ion-no-margin ion-margin-bottom'}>{ t('Initiated at: {date}', { date: DateTime.fromISO(fortnoxProjectIntegration?.created_at ?? '').toLocaleString(DateTime.DATETIME_MED) }) }</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {isFetchingFortnoxIntegration
              ? <IonSkeletonText />
              : <>
                <IonRow className={'ion-margin-bottom'}>
                  <IonCol size={'auto'} className={'ion-padding-end'}>
                    { fortnoxProjectIntegration.external_company_details
                      ? (
                        fortnoxProjectIntegration.is_healthy
                          ? <IonIcon aria-hidden="true" icon={alertCircle} color={'success'} size={'large'} />
                          : <IonIcon aria-hidden="true" icon={informationCircle} color={'warning'} size={'large'} />
                      )
                      : <IonIcon aria-hidden="true" icon={alertCircle} color={'danger'} size={'large'} /> }
                  </IonCol>
                  <IonCol>
                    { fortnoxProjectIntegration.external_company_details
                      ? (
                        fortnoxProjectIntegration.is_healthy
                          ? <p className={'ion-no-margin'}>
                            { t('Integration is connected and either customer or supplier invoices have been read for organisation {name}.', {
                              name: fortnoxProjectIntegration.external_company_details?.Name
                            }) }
                          </p>
                          : <>
                            <p className={'ion-no-margin ion-margin-bottom'}>
                              { t('Integration is connected but no customer or supplier invoices have been read for organisation {name}. Check that you\'ve added the appropriate configuration below.', {
                                name: fortnoxProjectIntegration.external_company_details?.Name
                              }) }
                            </p>
                            <p className={'ion-no-margin'}>
                              <i>{ t('Please be advised that it can take up to a day before the integration is active after proper configuration.', {
                                name: fortnoxProjectIntegration.external_company_details?.Name
                              }) }</i>
                            </p>
                          </>
                      )
                      : <p className={'ion-no-margin'}>
                        { t('Integration is not connected. Please try again, or contact our support.') }
                      </p> }
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Label.Thick label={ t('Existing configuration') } />
                    {fortnoxProjectIntegration?.mappings?.length > 0
                      ? <IonList>
                        {fortnoxProjectIntegration?.mappings?.map((mapping: any, i: number) => (
                          <IonItemSliding key={i}>
                            <IonItem key={i} className={'ion-no-padding'}>
                              { t('Match "{value}" against Fortnox property {property}', {
                                value: mapping.search_value,
                                property: columnNames.find(c => c.id === mapping.column_name)?.value
                              }) }
                            </IonItem>
                            <IonItemOptions slot="end">
                              <IonItemOption color="warning" onClick={() => setSelectedProjectMapping(mapping)}>
                                <IonIcon slot="icon-only" icon={pencil}></IonIcon>
                              </IonItemOption>
                              <IonItemOption color="danger" onClick={() => handleDeleteProjectMapping(mapping)}>
                                <IonIcon slot="icon-only" icon={trash}></IonIcon>
                              </IonItemOption>
                            </IonItemOptions>
                          </IonItemSliding>
                        ))}
                      </IonList>
                      : <>
                        <IonRow>
                          <IonCol>
                            <EmptyList
                              title={ t('Missing configuration') }
                              message={ t('No configurations found for this integration. Please add one in order for imports to work properly.') }
                            />
                          </IonCol>
                        </IonRow>
                      </>}
                    <IonRow className={'ion-margin-top'}>
                      <IonCol>
                        <BigUp.Label.Thick label={ t('Add new configuration') } />
                        <ProjectMappingForm
                          onSaved={() => fetchFortnoxIntegration()}
                          integrationId={integration_id}
                          projectMapping={selectedProjectMapping}
                          resetProjectMapping={() => setSelectedProjectMapping(null)}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </>}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default FortnoxPage;
