import { IonFabButton, IonIcon } from '@ionic/react';

import { ionicColours } from '../../variables';
import type { ButtonsInterface } from '../interface';

const ButtonRound: React.FC<ButtonsInterface> = ({
  backgroundColour,
  buttonSizePixels,
  clickHandler,
  disabled,
  icon,
  textColour,
  type
}) => {
  return (
    <IonFabButton
      className='ion-no-margin'
      disabled={disabled}
      onClick={clickHandler}
      style={{
        width: buttonSizePixels || '40px',
        height: buttonSizePixels || '40px',
        '--color': textColour,
        '--background': backgroundColour ?? ionicColours.danger
      }}
      type={type || 'button'}
    >
      <IonIcon
        icon={icon}
        size={'large'}
      />
    </IonFabButton>
  );
};

export default ButtonRound;
