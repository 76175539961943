import { IonList, useIonRouter } from '@ionic/react';
import {
  checkmarkDoneCircle,
  fileTrayFullOutline,
  lockClosedOutline,
  paperPlaneOutline,
  peopleOutline
} from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import fortnoxLogo from '../../../assets/fortnox/logo.svg';
import Panel from '../../../components/Panels/Panel';
import BigUp from '../../../components/UI';
import useAcl from '../../../hooks/useAcl';

const SettingsPage: React.FC = () => {
  const router = useIonRouter();
  const { project, scopedAbilities } = useAcl();
  const { t } = useTranslation();

  const PROJECT_TOOLS_LIST_ADMIN = [
    {
      subpanelTitle: t('Tags'),
      subpanelIcon: paperPlaneOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_tag_categories_in_projects'),
      route: `settings/tags`,
      value: 'tags',
      only_routing: false
    },
    {
      subpanelTitle: t('Document types'),
      subpanelIcon: fileTrayFullOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_document_types_in_projects'),
      route: `settings/document-types`,
      value: 'document-types',
      only_routing: false
    },
    {
      subpanelTitle: t('Teams'),
      subpanelIcon: peopleOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_teams_in_projects'),
      route: `settings/teams`,
      value: 'teams',
      only_routing: false
    },
    {
      subpanelTitle: t('Fortnox'),
      subpanelIcon: fortnoxLogo,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_initialize_fortnox_authorization_projects'),
      route: `settings/fortnox`,
      value: 'fortnox',
      only_routing: false
    },
    {
      subpanelTitle: t('Access'),
      subpanelIcon: lockClosedOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_acl_in_projects'),
      route: `settings/access`,
      value: 'access',
      only_routing: false
    },
    {
      subpanelTitle: t('Work preparation'),
      subpanelIcon: checkmarkDoneCircle,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_tag_categories_in_projects'),
      route: `settings/work-preparation`,
      value: 'work-preparation',
      only_routing: false
    },
  ];

  return (
    <div style={{ padding: '0 8px' }}>
      <IonList className='ion-padding ion-margin-top' mode='ios'>
        <BigUp.Title label={t('Project Settings')} />
        {location.pathname === `/tools/${project?.id}/settings` && (
          PROJECT_TOOLS_LIST_ADMIN.filter((i) => !i.only_routing).map((item, index) => (
            <React.Fragment key={index}>
              <Panel
                icon={{
                  start: {
                    icon: item.subpanelDisabled ? lockClosedOutline : item.subpanelIcon,
                    color: 'dark'
                  },
                }}
                disabled={item.subpanelDisabled}
                onClick={() => (!item.subpanelDisabled && router.push(item.route ?? ''))}
                panelTitle={item.subpanelTitle}
              />
            </React.Fragment>
          ))
        )}
      </IonList>
    </div>
  );
};

export default SettingsPage;
