import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow, IonText,
  useIonViewWillEnter
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { checkmark, pencil } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './Notice.module.scss';
import NoticeItem from './NoticeItem';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import SkeletonItem from '../../components/SkeletonComponents/SkeletonItem';
import { BigUp } from '../../components/UI';
import Breadcrumbs from '../../components/UI/Breadcrumbs/Breadcrumbs';
import SideMenuLayout from '../../components/UI/SideMenu/SideMenuLayout';
import toasters from '../../components/UI/Toasts';
import { useAppSelector } from '../../hooks';
import { useBreadcrumbConfig } from '../../hooks/useBreadcrumbConfig';
import { setEmergencies } from '../../reducers/emergencies';
import store from '../../store';
import { findNoticeLevel } from '../NoticeBoard/config';
import { getSelectedMethodsLabels } from '../NoticeBoard/Form/defaults';

const Notice: React.FC = () => {
  const [notice, setNotice] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { t } = useTranslation();
  const { notice_uuid } = useParams<{ notice_uuid: string | undefined }>();
  const breadcrumbs = useBreadcrumbConfig();
  const project = useAppSelector(state => state.project.selectedProject);
  const user = useAppSelector(state => state.authentication.user);
  const emergencies = useAppSelector(state => state.emergencies.emergencies);
  const checkIfUserIsAuthor = !!(notice && user?.id === notice.created_by_id);
  const checkIfNoticeIsAcknowledged = notice && notice?.status_for_user === 'acknowledged';
  const searchParams = new URLSearchParams();
  searchParams.append('with[]', 'teams');
  searchParams.append('with[]', 'users');

  const getSelectedNotice = () => {
    if (!notice_uuid) return;
    networking.get(`/api/v1/notices/${notice_uuid}?${searchParams}`).then(
      (response: E2U.V1.Response.Success<E2U.V1.Models.Notice>) => {
        setNotice(response.data.data as E2U.V1.Models.Notice);
      }
    ).catch((error) => {
      Sentry.captureException(error);
    }).finally(() => {
      setLoading(false);
    });
  };

  const redirectToEdit = () => {
    history.push(`/notice-board/${project?.id}/${notice_uuid}/add`);
  };

  const acknowledgeNotice = () => {
    setLoading(true);
    networking.post(`api/v1/notices/${notice_uuid}/status`, {
      status: 'acknowledged'
    })
      .then(() => {
        toasters.createToast({
          message: t('Notice acknowledged'),
          background: 'var(--ion-color-light)',
        }, 'success');
        store.dispatch(setEmergencies(
          emergencies?.filter(
            (emergency: E2U.V1.Models.Notice) => emergency.id !== notice.id
          )
        ));
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        getSelectedNotice();
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    toasters.promiseToast(networking.delete(`/api/v1/notices/${notice_uuid}`), {
      success: { message: t('Notice deleted'), background: 'var(--ion-color-light)' },
      error: { message: t('Could not delete notice'), background: 'var(--ion-color-light)' },
      pending: { message: t('Deleting notice'), background: 'var(--ion-color-light)' }
    })
      .then(() => {
        history.push(`/notice-board/${project?.id}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const checkTeamAndUserLength = (teams: E2U.V1.Models.Team[], users: E2U.V1.Models.User[]) => {
    return teams?.length === 0 && users?.length === 0;
  };

  useIonViewWillEnter(() => {
    getSelectedNotice();
  }, [notice_uuid]);

  const formatDates = (date: string) => {
    return DateTime.fromISO(date, { setZone: true }).toLocaleString(DateTime.DATETIME_MED);
  };

  return (
    <SideMenuLayout baseURL={'notice-board'}>
      <DesktopWrapper width='var(--ion-desktop-mid-width)'>
        <Breadcrumbs data={breadcrumbs.noticeDetailedBreadcrumbs} />
        {checkIfUserIsAuthor && (
          <IonGrid className='ion-padding'>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='4' className='ion-text-start'>
                <BigUp.Buttons.Regular onClick={handleDelete} title={t('Delete')} color={'danger'} />
              </IonCol>
              <IonCol size='4' className='ion-text-end'>
                <BigUp.Buttons.Regular
                  icon={{
                    icon: pencil,
                    slot: 'end'
                  }}
                  onClick={redirectToEdit} title={t('Edit')} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        {loading
          ? <IonGrid className='ion-padding'>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='12'>
                <SkeletonItem amount={4} />
              </IonCol>
            </IonRow>
          </IonGrid>
          : (<>
            <IonList className='ion-padding-vertical'>
              <IonItem lines='none'>
                <IonText>
                  <IonLabel className={`${styles.dateCol} ion-no-margin`}>
                    {notice.publish_at ? formatDates(notice.publish_at) : t('No publish date')} - {notice.expire_at ? formatDates(notice.expire_at) : t('No expiry date')}
                  </IonLabel>
                </IonText>
                <IonIcon id="level-information" slot='end' className='ion-margin-end' size='large'{...findNoticeLevel(notice?.level)} />
                <IonPopover trigger="level-information" side="top" alignment="center">
                  <IonContent class="ion-padding">{t('Notice level: {level}', 'Notice level: {level}', { level: notice?.level })}</IonContent>
                </IonPopover>
              </IonItem>
              <NoticeItem label={t('Subject')}><span>{notice?.subject}</span></NoticeItem>
              <NoticeItem label={t('Comment')}><span>{notice?.message}</span></NoticeItem>
              <NoticeItem label={t('Affected teams')} lines='full'>
                {notice?.teams.map((team: E2U.V1.Models.Team) => <BigUp.Label.Regular key={team.id} label={team.name} className='ion-no-margin' />)}
                {notice?.teams.length === 0 && <BigUp.Label.Regular className='ion-no-margin' label={t('None selected')} />}
              </NoticeItem>
              <NoticeItem label={t('Affected users')} lines='full'>
                {notice?.users.map((user: E2U.V1.Models.User) => <BigUp.Label.Regular key={user.id} label={user.first_name} className='ion-no-margin' />)}
                {notice?.users.length === 0 && <BigUp.Label.Regular className='ion-no-margin' label={t('None selected')} />}
              </NoticeItem>
              {notice?.global === true && (
                <NoticeItem label={t('Notice visibility')}>
                  <span>
                    {t(
                      'This notice is visible to all members in {project}',
                      'This notice is visible to all members in {project}', {
                        project: project?.name
                      })}
                  </span>
                </NoticeItem>
              )}
            </IonList>
            <IonGrid className='ion-padding-end ion-margin-end'>
              <IonRow className='ion-align-items-center ion-justify-content-between'>
                <IonCol size='7'>
                  {checkTeamAndUserLength(notice?.teams, notice?.users) &&
                    <>
                      <IonItem lines='none'>
                        <IonText>
                          <IonLabel className={`${styles.dateCol} ion-no-margin`}>
                            {t('Notifications sent via')}
                          </IonLabel>
                        </IonText>
                      </IonItem>
                      <IonList>
                        {getSelectedMethodsLabels(notice?.notification_methods).map(({ icon, label }) => {
                          return (
                            <IonItem lines='none' key={label}>
                              <IonIcon icon={icon}></IonIcon>
                              <IonLabel>{label}</IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    </>
                  }
                </IonCol>
              </IonRow>

            </IonGrid>
            <IonGrid className='ion-padding'>
              <IonRow>
                <IonCol size='12' className='ion-text-right'>
                  <BigUp.Buttons.Regular
                    {...checkIfNoticeIsAcknowledged && {
                      disabled: true,
                      icon: {
                        icon: checkmark,
                        slot: 'start',
                      }
                    }}
                    onClick={() => acknowledgeNotice()}
                    color={'success'}
                    title={checkIfNoticeIsAcknowledged ? t('Acknowledged') : t('Acknowledge')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </>)}
      </DesktopWrapper>
    </SideMenuLayout>
  );
};

export default Notice;
