import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { pencilSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './Team.module.scss';
import TeamSelection from './TeamSelection';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import toasters from '../../../components/Toasts/Toasts';
import ListTitle from '../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import { setSelectedTeam } from '../../../reducers/team';
import store from '../../../store';
import { AssociatedProjects } from '../AssociatedProjects/AssociatedProjects';
import TeamInvite from '../Invite/TeamInvite';
import TeamMembers from '../TeamMembers/TeamMembers';

const Team: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const [users, setUsers] = useState<E2U.V1.Models.User[]>([]);
  const { team_id } = useParams<{ team_id: string | undefined }>();
  const team = useAppSelector(state => state.team.selectedTeam);
  const isLoading = useAppSelector(state => state.loading.isLoading.team);
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);

  const fetchTeamById = () => {
    if (typeof team_id === 'undefined' || team_id === '') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'team', value: true }));
    toasters.promise(networking.get(`/api/v1/teams/${team_id}?with=users,projects`), {
      error: i18n.t('Team does not exist'),
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        store.dispatch(setSelectedTeam(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        if (router.canGoBack()) {
          router.goBack();
        } else if (selectedProjectId) {
          router.push(`/tools/${selectedProjectId}/settings/teams`);
        } else {
          router.push('/tools');
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'team', value: false }));
      });
  };

  const handleTeamActions = (action: 'edit' | 'invite') => {
    if (action === 'edit') return router.push(`/tools/${selectedProjectId}/team/${team?.id}/edit`);
    if (action === 'invite') return router.push(`/tools/${selectedProjectId}/team/${team?.id}/invite`);
  };

  const fetchAllUsers = () => {
    networking.get('/api/v1/users?per_page=9999')
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.User>>) => {
        setUsers(response.data.data.records);
      });
  };

  useEffect(() => {
    if (!team) {
      fetchTeamById();
    }
  }, []);

  useEffect(() => {
    fetchTeamById();
  }, [team_id]);

  return (
    <DesktopWrapper>
      <IonGrid>
        <IonRow className='ion-align-items-center ion-margin-top ion-margin-bottom'>
          <IonCol size='7' >
            <IonText>
              <h1 className={styles.teamNameTitle}>
                {team?.name}
                {isLoading && <IonSpinner className='ion-margin-start' />}
              </h1>
            </IonText>
          </IonCol>
          <IonCol size='5' className='ion-text-right'>
            <IonButton color='secondary' onClick={() => handleTeamActions('edit')}>{t('Edit')} <IonIcon slot='end' icon={pencilSharp} /></IonButton>
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center'>
          <IonCol size='7'>
            <ListTitle label={i18n.t('Projects')} />
          </IonCol>
          <IonCol size='5' className='ion-text-center ion-margin-top'>
            <TeamSelection type={'projects'} fetchTeam={fetchTeamById} />
          </IonCol>
        </IonRow>
        <IonRow className='ion-margin-top ion-margin-bottom'>
          <IonCol>
            <AssociatedProjects fetchTeam={fetchTeamById} />
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center '>
          <IonCol size='7'>
            <ListTitle label={i18n.t('Members')} />
          </IonCol>
          <IonCol size='5' className='ion-text-center ion-margin-top'>
            <TeamSelection type={'users'} users={users} fetchTeam={fetchTeamById} fetchAllUsers={fetchAllUsers} />
          </IonCol>
        </IonRow>
        <IonRow className='ion-margin-top ion-margin-bottom'>
          <IonCol>
            <TeamMembers fetchTeam={fetchTeamById} />
          </IonCol>
        </IonRow>
        <IonRow className='ion-margin-top'>
          <IonCol>
            <TeamInvite fetchTeam={fetchTeamById} />
          </IonCol>
        </IonRow>
        {/*  TODO: Implement at a later stage
          <IonRow className='ion-margin-top '>
              <IonCol>
                <ListTitle label={i18n.t('Sent invites')} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-margin-top '>
              <IonCol>
                <TeamInvitedUsers />
              </IonCol>
            </IonRow>
          */}
      </IonGrid>
    </DesktopWrapper>
  );
};

export default Team;
