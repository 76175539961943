import { IonIcon, useIonAlert, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { EconomyChange } from '@techlove/easy2use-typings/src/V1/Models/EconomyChange';
import { ellipsisHorizontalOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { handleSource } from '../../../../components/ListsComponents/rowList/handlers';
import type { Sources } from '../../../../components/ListsComponents/rowList/interface';
import BigUp from '../../../../components/UI';
import useAcl from '../../../../hooks/useAcl';
import shareUrl from '../../../../tools/shareUrl';

const ActionColumn: React.FC<{
    type: Sources;
    attributes: E2U.V1.Models.NotPostedRow | E2U.V1.Models.EconomyChange | E2U.V1.Models.EconomyReport;
    callbacks: {
        onDelete(id: string): void;
    }
}> = (props) => {
  const { attributes, callbacks, type } = props;

  const { project } = useAcl();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const baseUrl = process.env.REACT_APP_SHARE_BASE_URL;
  const source = handleSource(type, project?.id);

  return (
    <div data-clickable={true}>
      <IonIcon
        data-clickable
        id={`display-filter-actions-${attributes.id}`}
        icon={ellipsisHorizontalOutline}/>
      <BigUp.Popovers.Default
        usesID={true}
        items={[
          {
            label: t('Share'),
            value: t('Share'),
            onClick: () => {
              if (typeof source === 'object') {
                return shareUrl(
                  undefined,
                  `${t('Here is the link to {type} {name}', 'Here is the link to {type} {name}', {
                    type: type === 'deviations' ? 'deviation' : 'change',
                    name: (props.attributes as EconomyChange).name
                  })}`,
                  `${baseUrl}${source.routes.base}/${attributes.id}`,
                  t(`Let me know if there is something you are wondering about.`),
                  'share'
                );
              }
            }
          },
          {
            label: t('Delete'),
            value: t('Delete'),
            onClick: () => {
              presentAlert({
                header: t('Are you sure?'),
                message: t('This action can not be undone!'),
                buttons: [
                  {
                    text: t('Cancel'),
                    role: 'cancel'
                  },
                  {
                    text: t('Delete'),
                    role: 'confirm',
                    handler: () => callbacks.onDelete(attributes.id as string)
                  },
                ],
              });
            }
          },
          {
            label: t('Details'),
            value: t('Details'),
            onClick: () => {
              if (typeof source === 'object') {
                router.push(`${source.routes.base}/${attributes.id}`);
              }
            }
          }
        ]}
        actionId={`display-filter-actions-${attributes.id}`}/>
    </div>
  );
};

export default ActionColumn;
