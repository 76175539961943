import type { E2U } from '@techlove/easy2use-typings';
import React, { useMemo } from 'react';

import { useTableStyles } from '../../../../../components/UI/Table';

const ActivityCodeColumn: React.FC<{
    value: any;
    attributes: E2U.V1.Models.PrecalculationRow | E2U.V1.Models.EconomyMonitoringRow;
}> = (props) => {
  const styles = useTableStyles();
  const name = props.attributes.name || props.attributes.activity_code?.name;
  const code = props.attributes?.stamped_activity_code || props.attributes.activity_code?.code;
  const activityCodeName = useMemo(() => {
    return props.attributes?.activity_code?.name || '';
  }, [props.attributes]);

  return (
    <div className={styles.activity_code_column}>
      <div className={styles.activity_code_column__title}>
        {name}
      </div>
      <div className={styles.activity_code_column__description}>
        {`${code} - ${activityCodeName}`}
      </div>
    </div>
  );
};

export default ActivityCodeColumn;
