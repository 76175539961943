import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';

import { ionicColours } from '../../variables';
import styles from '../ButtonStyles.module.scss';
import type { ButtonsInterface } from '../interface';

const ButtonCTA: React.FC<ButtonsInterface> = ({
  backgroundColour,
  buttonShadow,
  children,
  clickHandler,
  content,
  disabled,
  expand,
  hasWidth,
  icon,
  ionButtonProps = {},
  leftIcon,
  linkTo,
  shouldSubmit = true,
  textColour
}) => {
  return (
    <IonButton
      {...ionButtonProps}
      {...expand && { expand }}
      type={shouldSubmit ? 'submit' : 'button'}
      size={'default'}

      disabled={disabled}
      onClick={clickHandler}
      fill={'solid'}
      style={{
        '--box-shadow': buttonShadow,
        '--color': textColour,
        '--background': backgroundColour ?? ionicColours.primary,
        margin: '0 auto',
        ...hasWidth && { width: '100%' }
      }}
      className={styles['ion-button-cta']}
      routerLink={linkTo}
    >
      {leftIcon !== undefined
        ? (<IonIcon icon={leftIcon} className={styles['left-icon']} />)
        : (<React.Fragment />)}
      {content}
      {icon !== undefined ? (<IonIcon icon={icon} />) : (<React.Fragment />)}
      {children && children}
    </IonButton>
  );
};

export default ButtonCTA;
