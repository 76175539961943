import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';

import { networking } from '../../../../api/networking';
import { useTableStyles } from '../../../../components/UI/Table';

const ActivityCode: React.FC<{ attributes: E2U.V1.Models.ControlOfExecution }> = (props) => {
  const styles = useTableStyles();
  const [activityCode, setActivityCode] = useState<E2U.V1.Models.ActivityCode>();

  const getActivityCode = () => {
    networking.get(`/api/v1/activity_codes/${props.attributes.activity_code_id}`)
      .then((response) => setActivityCode(response.data.data));
  };

  useEffect(() => {
    getActivityCode();
  }, []);

  return (
    <div className={styles.activity_code_column}>
      <div>{`${activityCode?.code} - ${activityCode?.name}`}</div>
    </div>
  );
};

export default ActivityCode;
