import { useIonPopover, useIonRouter } from '@ionic/react';
import { addSharp } from 'ionicons/icons';
import type { MouseEventHandler } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';

const AddNewButton: React.FC = () => {
  const router = useIonRouter();
  const project = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => project?.id, [project]);
  const { t } = useTranslation();
  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath: `/notice-board/:uuid/add`,
    onNavigate: (to: string) => {
      router.push(to);
    },
  });

  const handleCreateNotice: MouseEventHandler = (e) => {
    if (selectedProjectId) {
      router.push(`/notice-board/${selectedProjectId}/add`);
    } else {
      present({
        event: e as any,
        dismissOnSelect: true,
      });
    }
  };

  return (
    <BigUp.Buttons.Regular
      title={t('Add new')}
      onClick={handleCreateNotice}
      icon={{ slot: 'end', icon: addSharp }}
      color={'secondary'}
    />
  );
};

export default AddNewButton;
