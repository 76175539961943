import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { onboardingPath, optionalOnboardingRoute } from '../constants/onboarding';
import useFetchProject from '../hooks/useFetchProject';
import Onboarding from '../pages/Onboarding';

interface WrappedParams extends RouteProps {
  path: string;
}
export const WrappedRoute: React.FC<WrappedParams> = ({
  path,
  ...rest
}) => {
  const sanitisedPath = (path.endsWith('/') ? path.slice(0, -1) : path);
  return (
    <ProtectedRoute
      {...rest}
      path={rest.exact ? [`${sanitisedPath}`, `${sanitisedPath}${optionalOnboardingRoute}`] : path}
    >
      <Route path={`${sanitisedPath}${onboardingPath}`}>
        <Onboarding path={`${sanitisedPath}${onboardingPath}`} />
      </Route>
      {rest.children}

      <WrappedRouteListener path={sanitisedPath} />
    </ProtectedRoute>
  );
};

export const WrappedRouteListener: React.FC<WrappedParams> = (props) => {
  const fetchProject = useFetchProject();
  const { uuid } = useParams<{ uuid: string | undefined }>();

  const sanitisedPath = (props.path.endsWith('/') ? props.path.slice(0, -1) : props.path);

  const fetchProjectIfNeeded = () => {
    if (/\/(tools|financials|notice-board)\/\:uuid.*/.test(sanitisedPath)) {
      fetchProject();
    }
  };

  useEffect(() => {
    fetchProjectIfNeeded();
  }, []);

  useEffect(() => {
    fetchProjectIfNeeded();
  }, [uuid]);

  return <></>;
};
