import { IonCol, IonGrid, IonIcon, IonLoading, IonRow, IonTitle, useIonRouter } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import styles from './PermissionDenied.module.scss';
import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import toasters from '../../components/Toasts/Toasts';
import BigUp from '../../components/UI';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../hooks';
import useAcl from '../../hooks/useAcl';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';

const PermissionDenied: React.FC = () => {
  const { t } = useTranslation();
  const { client, isOnboarded } = useAcl();
  const router = useIonRouter();
  const isRequestingAccess = useAppSelector(state => state.loading.isLoading.requestAccess);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const clientId = useMemo(() => client?.id, [client]);
  const { state } = useLocation<{
    type?: 'project' | 'client';
    required_abilities: string[];
    requested_route: string;
  }>();

  const isUserOnboarded = isOnboarded();

  const requestAccess = () => {
    store.dispatch(setIsLoading({ key: 'requestAccess', value: true }));
    networking.post('/api/v1/me/acl/request-access', {
      [`${state.type}_id`]: state.type === 'project' ? selectedProjectId : clientId,
      abilities: state.required_abilities,
      requested_route: state.requested_route
    })
      .then(() => {
        toasters.success(t('Access request sent successfully.'));
        if (router.canGoBack()) {
          router.goBack();
        } else {
          router.push('/tools');
        }
      })
      .catch(() => {
        toasters.error(t('Failed to send access request. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ key: 'requestAccess', value: false }));
      });
  };

  useEffect(() => {
    if (!state || !state.type || !state.required_abilities || !state.requested_route) {
      if (router.canGoBack()) {
        router.goBack();
      } else {
        router.push('/tools');
      }
    }
  }, []);

  return (
    <SideMenuV2Layout>
      <DesktopWrapper width={'800px'} alignment={'center'}>
        <div className={'ion-padding'}>
          <IonGrid
            className={'ion-padding ion-text-center ion-align-items-center ion-justify-content-center'}>
            <IonRow>
              <IonCol>
                <IonIcon icon={closeCircle} color={'danger'}
                  className={styles['PermissionDenied-icon']} />
                {/* <BigUp.Title color={'primary'}>{t('You lack access')}</BigUp.Title> */}
                <h1 className={styles['PermissionDenied-title']}>
                  {t('You lack access')}
                </h1>
                <p>{t('Hello! It appears that you are trying to use a feature that is not included in your current package. If you want access to this feature, you can easily upgrade your plan. Click \'Request access\' and we\'ll help you find the right option. Thanks from Team BigUp!')}</p>
              </IonCol>
            </IonRow>
            <IonRow className={'ion-justify-content-center'}>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Regular title={t('Request access')} onClick={() => requestAccess()} />
              </IonCol>
            </IonRow>
            <IonRow className={'ion-justify-content-center'}>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Regular title={t('Go to home')}
                  routerLink={isUserOnboarded ? '/tools' : '/introduction'} />
              </IonCol>
              {router.canGoBack() && <IonCol size={'auto'}>
                <BigUp.Buttons.Regular title={t('Go back')} onClick={() => router.goBack()} />
              </IonCol>}
            </IonRow>
          </IonGrid>
        </div>
      </DesktopWrapper>
      <IonLoading isOpen={isRequestingAccess} message={t('Requesting access...')} />
    </SideMenuV2Layout>
  );
};

export default PermissionDenied;
