import { headsetOutline, homeOutline, personOutline } from 'ionicons/icons';

import i18n from '../../i18n';

interface MenuListItems {
    itemIcon: string;
    directTo: string;
    linkText: string;
    direction: 'forward' | 'back' | 'none';
  }

  interface Path {
    url: string;
    pattern: string[];
    tab: string;
    title: string;
  }

export const paths: Path[] = [
  {
    url: '/notice-board',
    pattern: ['/notice-board'],
    tab: 'notice-board',
    title: i18n.t('Notice board')
  },
  {
    url: '/tools',
    pattern: ['/tools'],
    tab: 'tools',
    title: i18n.t('Project tools')
  },
  {
    url: '/financials',
    pattern: ['/financials'],
    tab: 'financials',
    title: i18n.t('Economy tools')
  },
];

export const MENU_LIST: MenuListItems[] = [
  {
    itemIcon: homeOutline,
    directTo: '/tools',
    linkText: i18n.t('Home'),
    direction: 'back'
  },
  {
    itemIcon: headsetOutline,
    directTo: '/support',
    linkText: i18n.t('Support'),
    direction: 'forward'
  },
  {
    itemIcon: personOutline,
    directTo: '/profile/settings',
    linkText: i18n.t('User settings'),
    direction: 'forward'
  },

];
