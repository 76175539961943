import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useMemo } from 'react';

import { networking } from '../api/networking';
import { useAppSelector } from '../hooks';
import { setAbilities } from '../reducers/authentication';
import { setIsLoading } from '../reducers/loading';
import type { AclAbility } from '../routes/ProtectedRoute';
import store from '../store';

export interface AclScopeInterface {
  uuid: string;
  type: 'project' | 'client';
}

const useAcl = () => {
  const user = useAppSelector(state => state.authentication.user);
  const project = useAppSelector(state => state.project.selectedProject);
  const abilities = useAppSelector((state) => state.authentication.abilities);

  const client = useMemo(() => {
    if (user?.clients?.length) {
      // @todo: allow for multiple clients
      // As of right now, there is no UI allowing user to choose
      // between Clients (if connected to multiple). This will
      // probably be pulled out from state variable (Redux)
      // later on.

      return user?.clients[0] satisfies E2U.V1.Models.Client;
    }

    return null;
  }, [user?.clients]);

  const projects = useMemo(() => {
    return user?.projects ?? [];
  }, [user?.projects]);

  const clientAbilities = useMemo(() => {
    if (client) {
      return (abilities.client[client.id ?? ''] ?? []).map(a => a.name);
    }

    return [];
  }, [abilities, client]);

  const projectAbilities = useMemo(() => {
    if (project) {
      return (abilities.client[project.id ?? ''] ?? []).map(a => a.name);
    }

    return [];
  }, [abilities, project]);

  const scopedAbilities = useMemo(() => {
    if (project && projectAbilities.length) {
      return projectAbilities;
    } else if (client && clientAbilities.length) {
      return clientAbilities;
    }

    return [];
  }, [abilities, projectAbilities, clientAbilities]);

  const hasAbilityTo = (abilities: string | string[]) => Array.isArray(abilities)
    ? abilities.every(ability => scopedAbilities.includes(ability))
    : scopedAbilities.includes(abilities);

  const fetchPermissions = (props?: { scope?: AclScopeInterface }) => {
    const urlSearchParams = new URLSearchParams();

    let scope: AclScopeInterface;

    if (typeof props?.scope === 'undefined' || !props?.scope) {
      if (client) {
        scope = {
          uuid: client.id as string,
          type: 'client'
        } satisfies AclScopeInterface;
      } else {
        return null;
      }
    } else {
      scope = props.scope;
    }

    urlSearchParams.append(scope.type + '_id', scope.uuid);

    store.dispatch(setIsLoading({ name: 'abilities', value: true }));
    networking.get(`/api/v1/me/acl/list/abilities?${urlSearchParams.toString()}`)
      .then((result: E2U.V1.Response.Success<AclAbility[]>) => {
        store.dispatch(setAbilities({
          id: scope.uuid,
          type: scope.type,
          abilities: result.data.data
        }));
      })
      .catch((error) => {
        Sentry.captureException(error);
        store.dispatch(setAbilities({
          id: scope.uuid,
          type: scope.type,
          abilities: []
        }));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'abilities', value: false }));
      });
  };

  const isOnboarded = () => {
    return (client || projects.length > 0);
  };

  return {
    client,
    project,
    projects,
    abilities,
    scopedAbilities,
    clientAbilities,
    fetchPermissions,
    hasAbilityTo,
    isOnboarded,
  };
};

export default useAcl;
