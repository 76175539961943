import { IonRow, IonToast, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { Notice } from '@techlove/easy2use-typings/src/V1/Models/Notices';
import { close, warning } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './EmergencyAlert.module.scss';
import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import useModal from '../../hooks/useModal';
import { setEmergencies } from '../../reducers/emergencies';
import store from '../../store';
import BigUp from '../UI';

type Emergency = Notice & {
  project_id: string;
}

const EmergencyAlert: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const modal = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const emergencies = useAppSelector((state) => state.emergencies.emergencies) as Emergency[] | undefined;
  const user = useAppSelector((state) => state.authentication.user);

  const fetchEmergencies = () => {
    if (!user?.id) return;
    networking
      .get(`api/v1/me/emergencies`)
      .then((response) => {
        const { data } = response.data;

        if (data.length === 0) {
          return;
        }

        if (data.length > 1) {
          setIsMultiple(true);
        }

        store.dispatch(setEmergencies(data));
        setIsOpen(true);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const checkEmergencies = () => {
    if (!emergencies || emergencies.length === 0) {
      setIsOpen(false);
      setIsMultiple(false);
      return;
    }
    setIsOpen(true);

    if (emergencies.length > 1) {
      setIsMultiple(true);
    }
  };

  const toastHandler = () => {
    if (!emergencies) return;
    if (isMultiple) {
      modal.openModal();
      return false;
    }
    router.push(`/notice-board/${emergencies[0].project_id}/notice/${emergencies[0].id}`);
    return false;
  };

  useEffect(() => {
    fetchEmergencies();
  }, [user?.id]);

  useEffect(() => {
    checkEmergencies();
  }, [emergencies]);

  return (
    <>
      <IonToast
        isOpen={emergencies && isOpen}
        message={isMultiple
          ? t('{count} ongoing emergencies!', '{count} ongoing emergencies!', { count: emergencies?.length })
          : t('Ongoing site emergency!')}
        className={styles.emergencyAlert}
        icon={warning}
        buttons={[
          {
            text: t('View'),
            handler: toastHandler
          }
        ]}
      />
      <BigUp.Modal.Wrapper
        isOpen={modal.isModalOpen}
        dismiss={modal.closeModal}
        header={{
          title: t('Ongoing emergencies'),
          end: {
            icon: close,
            onClick: modal.closeModal
          }
        }}
      >
        {emergencies && emergencies.map((emergency) => (
          <IonRow className="ion-justify-content-center" key={emergency.id} style={{ marginBottom: '8px' }}>
            <BigUp.Buttons.Danger
              title={emergency.subject}
              onClick={() => {
                router.push(`/notice-board/${emergency.project_id}/notice/${emergency.id}`);
                modal.closeModal();
              }}
              style={{ width: '100%' }}
            />
          </IonRow>
        ))}
      </BigUp.Modal.Wrapper>
    </>
  );
};

export default EmergencyAlert;
