import {
  IonCol,
  IonContent, IonLoading,
  IonPage, IonRow, isPlatform, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import AppVersion from '../../../components/AppVersion';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import { setPasswordReset } from '../../../reducers/authentication';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import ConsumePasswordResetForm from '../ConsumePasswordResetForm';
import styles from '../ConsumePasswordResetForm/ConsumePasswordResetForm.module.scss';
import loginStyles from '../Login.module.scss';

interface ConsumePasswordResetProps { }

const ConsumePasswordResetPage: React.FC<ConsumePasswordResetProps> = () => {
  const { t } = useTranslation();
  const isFetchingPasswordReset: boolean = useAppSelector((state) => state.loading.isLoading.loadingPasswordReset);
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const router = useIonRouter();
  const isDesktop = isPlatform('desktop');
  const fetchPasswordReset = () => {
    if (typeof uuid === 'undefined' || uuid === '') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'loadingPasswordReset', value: true }));
    networking.get(`/api/v1/users/reset_password/${uuid}`).then((response: E2U.V1.Response.Success<any>) => { // todo:fix typing
      store.dispatch(setPasswordReset(response.data.data));
    }).catch(() => {
      toasters.error(t('Password reset link is invalid or expired'));
      router.push('/login');
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'loadingPasswordReset', value: false }));
    });
  };

  useEffect(() => {
    fetchPasswordReset();
  }, []);

  useEffect(() => {
    fetchPasswordReset();
  }, [uuid]);

  return (
    <IonPage>
      <IonContent id={ loginStyles['login-page'] } scrollY={ false }>
        <IonLoading isOpen={ isFetchingPasswordReset }/>

        <div className={ `${loginStyles['login-box']} ${!isDesktop ? loginStyles['login-box--mobile'] : ''}` }>
          <section>
            <img src={ BigUp.Logo } alt={ t('BigUp logo') } className={ styles['bigup-logo'] }/>
            <p>{ t('Welcome to BigUp!') }</p>
          </section>
          <div
            className={ `${loginStyles['login-form-container']} ${!isDesktop ? loginStyles['login-form-container--mobile'] : ''}` }>
            <div className={ loginStyles['form-container-title'] }>
              <h6>
                { t('Password reset') }
              </h6>
            </div>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol size='auto'>
                <ConsumePasswordResetForm/>
              </IonCol>
            </IonRow>
          </div>
        </div>

        {isPlatform('capacitor') &&
          <div className={styles['app-version-container-login-page']}>
            <AppVersion />
          </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default ConsumePasswordResetPage;
