import { IonCol, IonGrid, IonIcon, IonRow, IonSearchbar, useIonPopover } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, filterOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Table.module.scss';
import BigUp from '../';
import type { DataItemProps, FilterProps, SourceUrlProps, TableColumnProps, TableProps } from './interfaces';
import { networking } from '../../../api/networking';
import useScrollbarWidth from '../../../hooks/useScrollbarWidth';
import SkeletonItem from '../../SkeletonComponents/SkeletonItem';

const TableColumn: React.FC<TableColumnProps> = (props) => {
  const classes = [
    styles[`text__${props.alignment ?? 'center'}`],
  ];

  if (props.className) {
    classes.push(props.className);
  }

  return (
    <IonCol onClick={props.onClick}
      className={classes.join(' ')}
      size={props?.size || null}
      sizeLg={props.sizes?.lg || '4'}
      sizeMd={props.sizes?.md || '4'}
      sizeSm={props.sizes?.sm || '4'}
      sizeXl={props.sizes?.xl || '4'}
      sizeXs={props.sizes?.xs || '4'}
    >
      {props.children}
      {props.actions}
    </IonCol>
  );
};

const Table: React.FC<TableProps> = (props) => {
  const { columns, filters, reducers, sourceUrl, timestamp } = props;
  const { t } = useTranslation();

  const [rows, setRows] = useState(props.rows ?? []);
  const [allRows, setAllRows] = useState(props.rows ?? []);
  const [isLoading, setIsLoading] = useState(props.loading ?? false);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const tableHead = useRef<HTMLIonRowElement>(null);
  const tableBody = useRef<HTMLDivElement>(null);
  const [tableScrollYPosition, setTableScrollYPosition] = useState(0);
  const scrollBarWidth = useScrollbarWidth();
  const sourceUrlJson = useMemo(() => JSON.stringify(sourceUrl), [sourceUrl]);
  const [present] = useIonPopover(BigUp.Popovers.Default, {
    items: (filters ?? []).map((filter: FilterProps) => {
      return {
        ...filter,
        value: filter.label,
        onClick: () => {
          filter.callback(allRows, setRows);
        },
      };
    })
  });

  const fetchRows = (url: string | SourceUrlProps) => {
    let args = {
      search: searchQuery,
      direction: sortDirection,
      per_page: 9999,
    };

    if (typeof url === 'object' && url satisfies SourceUrlProps) {
      args = Object.assign({}, args, url.args);
      url = url.url;
    }

    if (columns[sortColumn]?.sortable) {
      args = Object.assign({}, args, {
        sort_by: columns[sortColumn].key
      });
    }

    const urlSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(args)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          urlSearchParams.append(key, v);
        });
      } else {
        urlSearchParams.set(key, value);
      }
    }
    setIsLoading(true);
    networking.get(`${url}?${urlSearchParams.toString()}`)
      .then((response) => {
        let rows = [];

        if (typeof response.data.data.current_page !== 'undefined') {
          rows = response.data.data.records;
        } else if (typeof response.data.data.rows !== 'undefined') {
          rows = response.data.data.rows;
        } else {
          rows = response.data.data;
        }

        setRows(rows);
        setAllRows(rows);
      })
      .catch((e) => {
        console.error('[Table] Error fetching source data: ', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const runSearch = (query: string) => {
    setSearchQuery(query);
  };

  const runSort = (i: number) => {
    setSortColumn(i);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const getValueByDotNotation = (
    row: { [key: string]: any },
    key: string,
    strict = false
  ) => {
    if (key.includes('.')) {
      let value = row;

      key.split('.').forEach((key) => {
        value = value[key];
      });

      return value;
    }

    return row[key] ?? (strict ? null : key);
  };

  const handleBodyScroll = () => {
    if (tableBody.current) {
      const { scrollLeft } = tableBody.current;
      setTableScrollYPosition(scrollLeft || 0);
    }
  };

  useEffect(() => {
    if (sourceUrl) {
      columns.forEach((column, i) => {
        if (column?.default_sort) {
          setSortColumn(i);

          if (column.default_sort_direction) {
            setSortDirection(column.default_sort_direction);
          }

          return false;
        }
      });

      fetchRows(sourceUrl);
    }
  }, []);

  useEffect(() => {
    if (tableHead.current) {
      tableHead.current.scrollLeft = tableScrollYPosition;
    }
  }, [tableScrollYPosition]);

  useEffect(() => {
    if (!sourceUrl) {
      return;
    }
    fetchRows(sourceUrl);
  }, [sortColumn, sortDirection, searchQuery]);

  useEffect(() => {
    const callback = props.callbacks?.onRenderComplete;

    if (callback && !searchQuery) {
      callback(rows, setRows);
    }
  }, [allRows, searchQuery]);

  useEffect(() => {
    if (timestamp && sourceUrl) {
      fetchRows(sourceUrl);
    }
  }, [timestamp]);

  useEffect(() => {
    if (sourceUrl) {
      fetchRows(sourceUrl);
    }
  }, [sourceUrlJson]);

  return (
    <IonGrid>
      <IonRow className={styles.wrapper}>
        <IonCol className={styles.container}>
          <div className={styles.header}>
            <IonRow>
              <IonCol sizeMd={filters ? '11' : '12'}
                sizeXs={filters ? '10' : '12'}>
                <div className={styles.header__search}>
                  <IonSearchbar onIonInput={(e) => runSearch(e.detail.value as string)}
                    animated={true}
                    placeholder={t('Search')}
                    className={styles.header__search_box}></IonSearchbar>
                </div>
              </IonCol>
              {filters && (
                <IonCol sizeMd='1'
                  sizeXs='2'
                  className={styles.header__filters}
                  onClick={(e) => present({
                    event: e.nativeEvent,
                    dismissOnSelect: true
                  })}
                >
                  <IonIcon
                    icon={filterOutline}
                    className={styles.header__filters_icon}
                  />
                </IonCol>
              )}
            </IonRow>
            <IonRow ref={tableHead} className={styles.header__headings} style={{
              marginRight: `${(tableBody.current && tableBody.current.scrollHeight > tableBody.current.clientHeight) ? scrollBarWidth : 0}px`,
            }}>
              {columns.map((column: DataItemProps, i) => {
                const { key, label, sortable, ...rest } = column;

                return (
                  <TableColumn key={i}
                    onClick={() => sortable ? runSort(i) : null}
                    className={`${styles.header__headings_column} ${sortable ? styles.header__headings_column_sortable : ''}`} {...rest}>
                    {label}
                    {sortable && <IonIcon
                      icon={(sortColumn === i && sortDirection === 'desc') ? chevronUpOutline : chevronDownOutline}
                      className={styles.header__headings_actions_sort} />}
                  </TableColumn>
                );
              })}
            </IonRow>
          </div>

          <div className={styles.body}>
            <div id="body"
              ref={tableBody}
              onScroll={() => handleBodyScroll()}
              className={styles.body__values}
              style={{
                maxHeight: rows.length ? (props.height || '535px') : 'auto',
              }}
            >
              {isLoading
                ? <SkeletonItem
                  sizes={{
                    sizeXs: '12',
                    sizeSm: '12',
                    sizeMd: '12',
                    sizeLg: '12'
                  }}
                  amount={5}
                />
                : (
                  rows.length
                    ? rows.map((row, a) => {
                      const { onRowClick } = props.callbacks ?? {};
                      const classes = [styles.body__values_row];

                      if ((a % 2 === 0)) {
                        classes.push(styles.body__values_oddrow);
                      }

                      return (
                        <IonRow key={a}
                          onClick={(e) => {
                            if (onRowClick) {
                              const target = e.target as HTMLElement;

                              if (!target.dataset?.clickable) { // allow element to have it's own click event
                                onRowClick(row);
                              }
                            }
                          }}
                          className={classes.join(' ')}>
                          {columns.map((column: DataItemProps, b) => {
                            const { key, ...rest } = column;

                            let body = (row[key] ?? getValueByDotNotation(row, key, true));

                            if (typeof reducers !== 'undefined' && reducers[key]) {
                              body = reducers[key](body);
                            }

                            if (column.body) {
                              if (typeof column.body === 'string') {
                                body = React.createElement(column.body, {
                                  value: body,
                                  attributes: row,
                                });
                              } else {
                                body = React.cloneElement(column.body, {
                                  value: body,
                                  attributes: row,
                                });
                              }
                            }

                            return (
                              <TableColumn key={b}
                                className={styles.body__values_column} {...rest}>
                                {body ?? <i>{t('Missing value')}</i>}
                              </TableColumn>
                            );
                          })}
                        </IonRow>
                      );
                    })
                    : (
                      <IonRow className={styles.body__values_empty}>
                        <IonCol className={styles.body__values_empty_text}>
                          {t('No data to display')}
                        </IonCol>
                      </IonRow>
                    )
                )}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export const useTableStyles = () => {
  return styles;
};

export default Table;
