import { IonBackdrop } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import styles from './Backdrop.module.scss';
import { useAppSelector } from '../../../hooks';

const Backdrop: React.FC = () => {
  const isOpen = useAppSelector(state => state.navigation.isBackdropVisible);
  return (
    <div className={classNames(styles.Backdrop, isOpen && styles['Backdrop--open'])}>
      <IonBackdrop className={styles.Backdrop} visible={true} />
    </div>
  );
};

export default Backdrop;
