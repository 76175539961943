import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSpinner,
  useIonRouter
} from '@ionic/react';
import classNames from 'classnames';
import { flagOutline, logOutOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AppVersion from '../../../components/AppVersion';
import Avatar from '../../../components/Avatar';
import { MENU_LIST } from '../../../components/PageMenuHeader/navLinks';
import BigUp from '../../../components/UI';
import BankID from '../../../components/UI/BankID/BankID';
import LanguageSelect from '../../../components/UI/Select/LanguageSelect';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import useLogout from '../../../hooks/useLogout';
import useModal from '../../../hooks/useModal';
import modalStyle from '../../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../Onboarding/Components/PersonalOverview/PersonalOverview';
import styles from '../Profile/Profile.module.scss';

const Profile: React.FC = () => {
  const clearUserDetails = useLogout();
  const toggleOnboardingModal = useModal();
  const user = useAppSelector(state => state.authentication.user);
  const router = useIonRouter();
  const { t } = useTranslation();

  return (
    <SideMenuV2Layout>
      <div className={'ion-padding-horizontal'}>
        <IonGrid className='ion-padding-vertical ion-margin-vertical'>
          <IonRow className='ion-justify-content-start ion-align-items-center'>
            <IonCol size='auto'>
              <Avatar.AvatarImage
                onClick={toggleOnboardingModal.openModal}
                avatarSource='userImage'
                style={{ width: 100, height: 100, }}
              />
            </IonCol>
            <IonCol>
              <IonRow className='ion-justify-content-center ion-align-items-center'>
                <IonCol>
                  <IonItem className='ion-no-padding' lines='none'>
                    <IonLabel className='ion-no-margin'>
                      <h2 style={{ fontWeight: 600, width: '100%' }}>{t('Name')}:</h2>
                      <p style={{ fontSize: 16 }}>
                        {
                          !user
                            ? <IonSpinner
                              className={
                                classNames(
                                  'ion-no-padding ion-no-margin',
                                  styles.loading
                                )
                              }
                              name={'crescent'}
                            />
                            : `${user?.first_name} ${user?.last_name}`
                        }
                      </p>
                    </IonLabel>
                  </IonItem>
                  <IonItem className='ion-no-padding' lines='none'>
                    <IonLabel className='ion-no-margin'>
                      <h2 style={{ fontWeight: 600, width: '100%' }}>{t('Email')}:</h2>
                      <p style={{ fontSize: 16 }}>
                        {
                          !user
                            ? <IonSpinner
                              className={
                                classNames(
                                  'ion-no-padding ion-no-margin',
                                  styles.loading
                                )
                              }
                              name={'crescent'}
                            />
                            : `${user?.email}`
                        }
                      </p>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonList>
          {MENU_LIST.map(item => (
            <IonItem key={item.linkText} lines='full' button onClick={() => router.push(item.directTo, item.direction)}>
              <IonIcon icon={item.itemIcon} className='ion-margin-end'></IonIcon>
              <IonLabel className='ion-no-margin'>
                {item.linkText}
              </IonLabel>
            </IonItem>
          ))}
          <IonItem style={{ '--inner-padding-end': '8px' }} lines='full'>
            <IonIcon icon={flagOutline} className='ion-margin-end' />
            <LanguageSelect />
          </IonItem>
          <IonItem lines='full' button onClick={clearUserDetails}>
            <IonIcon icon={logOutOutline} className='ion-margin-end' />
            <IonLabel className='ion-no-margin' color={'dark'}>{t('Logout')}</IonLabel>
          </IonItem>
        </IonList>
        {user?.ssn
          ? (
            <IonGrid>
              <IonRow className='ion-align-items-center ion-justify-content-center'>
                <BigUp.Label.Regular label={t('Authenticated with ')} style={{ fontSize: 18 }} /><BankID.BankIdIcon color='black' width={60} />
              </IonRow>
            </IonGrid>
          )
          : <IonGrid className='ion-margin-top ion-padding-top'>
            <IonRow className='ion-justify-content-center'>
              <IonCol style={{ maxWidth: '250px' }}>
                <BigUp.Buttons.BankIdAuthenticate />
              </IonCol>
            </IonRow>
          </IonGrid>
        }
        <div className={styles['app-version-container']}>
          <h2>{t('BigUp')}</h2>
          <AppVersion />
        </div>
      </div>
      <IonModal
        isOpen={toggleOnboardingModal.isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={toggleOnboardingModal.closeModal}
      >
        <PersonalOverview togglePersonalOverview={toggleOnboardingModal.toggleModal} />
      </IonModal >
    </SideMenuV2Layout>
  );
};

export default Profile;
