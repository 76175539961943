import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import BigUp from '../../../../../components/UI';
import toasters from '../../../../../components/UI/Toasts';
import useAcl from '../../../../../hooks/useAcl';
import i18n from '../../../../../i18n';
import { setSelectedPrecalculation } from '../../../../../reducers/precalculations';
import store from '../../../../../store';

const DuplicatePrecalculation: React.FC = () => {
  const history = useHistory();
  const { projects } = useAcl();
  const [selectedProject, setSelectedProject] = useState<E2U.V1.Models.Project>();
  const { historicalPrecalculationId, precalculationId, uuid } = useParams<{
    precalculationId: string | undefined,
    uuid: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      project_id: '',
    },
  });

  const precalculationType = {
    precalculation: {
      endpoint: `/api/v1/precalculations/${precalculationId}/duplicate`,
      id: precalculationId,
    },
    historical: {
      endpoint: `/api/v1/precalculation_histories/${historicalPrecalculationId}/duplicate`,
      id: historicalPrecalculationId,
    }
  };

  const checkPrecalculationType = () => {
    return precalculationId ? precalculationType.precalculation : precalculationType.historical;
  };

  const submitDuplicate: SubmitHandler<FieldValues> = (data: FieldValues) => {
    networking.post(checkPrecalculationType().endpoint, {
      ...data,
      project_id: selectedProject ?? '',
    }).then(
      (response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
        toasters.createToast({
          message: i18n.t('Precalculation duplicated'),
          background: 'var(--ion-color-light)',
        }, 'success');
        methods.reset();
        setSelectedProject(undefined);
        if (uuid) {
          history.push(`/financials/${uuid}/precalculations/${response.data.data.id}`);
        } else {
          history.push(`/financials/precalculations/${response.data.data.id}`);
        }
        store.dispatch(setSelectedPrecalculation(response.data.data));
      }
    ).catch((error) => {
      toasters.createToast({
        message: i18n.t('Error duplicating precalculation'),
        background: 'var(--ion-color-light)',
      }, 'error');
      Sentry.captureException(error);
    });
  };

  return (
    <IonGrid className='ion-padding'>
      <IonRow>
        <IonCol>
          <BigUp.Title label={i18n.t('Duplicate Precalculation')} />
        </IonCol>
      </IonRow>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitDuplicate)}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Input
                  helperText={i18n.t('A name is required')}
                  name='name'
                  label={i18n.t('Name')}
                  placeholder={i18n.t('Name')}
                  register={'name'}
                  validation={{
                    required: i18n.t('Name is required') as string,
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BigUp.Textarea
                  itemProps={{
                    lines: 'none',
                    className: 'ion-no-padding'
                  }}
                  name='description'
                  label={i18n.t('Description')}
                  placeholder={i18n.t('Description')}
                  register={'description'}
                  helperText={i18n.t('Description is optional')}
                />
              </IonCol>
            </IonRow>

            <IonRow className='ion-justify-content-end'>

              <IonCol size='12' className='ion-text-right'>
                <IonItem className='ion-no-padding'>
                  <IonLabel className='ion-no-margin'>
                    {i18n.t('Attach to project')}
                  </IonLabel>
                  <IonSelect
                    value={selectedProject}
                    placeholder={i18n.t('Select a project')}
                    onIonChange={e => setSelectedProject(e.detail.value)}
                  >
                    <IonSelectOption value={''}>{i18n.t('No project')}</IonSelectOption>
                    {projects?.map((project) => (
                      <IonSelectOption key={project.id}
                        value={project.id}>{project.name}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end ion-margin-vertical">
              <IonCol size='5'>
                <BigUp.Buttons.Regular
                  expand="block"
                  type='submit'
                  title={i18n.t('Duplicate')}
                  color='success'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </IonGrid>
  );
};

export default DuplicatePrecalculation;
