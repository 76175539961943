import type { AxiosResponse } from 'axios';
import axios from 'axios';

const getCompanyInfo = async (
  organizationNumber: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/roaring/company/information/${organizationNumber}`);
};

const getCompanyHistory = async (
  organizationNumber: string,
  from?: string,
  to?: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/roaring/company/history/${organizationNumber}?from=${from}&to=${to}`);
};

const getMultipleCompanyInfo = async (
  organizationNumbers: string[]
): Promise<AxiosResponse<any>> => {
  return axios.post(`/api/roaring/company/information`, organizationNumbers);
};

const getCompanySearch = async (
  pageSize?: number,
  organizationNumber?: string,
  name?: string,
  address?: string[],
  county?: string[],
  city?: string[],
  zip?: string[]
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/roaring/company/search?orgnr=${organizationNumber}&name=${name}&address=${address}&town=${city}&zipCode=${zip}&county=${county}&pageSize=${pageSize}`);
};

const getCompanyRating = async (
  organizationNumber: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/roaring/company/rating/${organizationNumber}`);
};

const getCompanySigningRights = async (
  organizationNumber: string,
  personNumber: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/roaring/company/signing_right/${organizationNumber}/person/${personNumber}`);
};

const roaringNetworking = {
  getCompanyInfo,
  getCompanyHistory,
  getMultipleCompanyInfo,
  getCompanySearch,
  getCompanyRating,
  getCompanySigningRights,
};

export default roaringNetworking;
