import type { IonIcon } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { ComponentProps } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

export interface NoticeAccessFilterProps {
  data: NoticeSelector<LevelIds, LevelValues>[];
  selected: string | null;
  handleSelection: (value: AccessIds) => void;
}

type AccessIds = 'last 7 days' | 'onsite' | 'last 14 days' | 'ever' | string
type AccessValues = 'Last week' | 'Onsite' | 'Last two weeks' | 'Ever' | string
type LevelIds = 'info' | 'warning' | 'danger' | string
type LevelValues = 'Information' | 'Warning' | 'Danger' | string

export type NotificationOptions = 'email_notification' | 'push_notification' | 'text_notification';

export interface NoticeSelector<T, K> {
  id: T;
  value: K;
}

export type DateIDs = 'notice-publish' | 'notice-unpublish'
export type DateRegisters = 'publish_at' | 'expire_at'
export type DatetimeRegisterReturn = Omit<Omit<UseFormRegisterReturn, 'max'>, 'min'> & {
  max?: string;
  min?: string;
};

export type DefaultValues = {
  access_filter_data: NoticeSelector<AccessIds, AccessValues>[];
  default_levels: NoticeSelector<LevelIds, LevelValues>[];
  notifications_type: NotificationOptions[];
  default_dates: {
    publish: string;
    unpublish: string;
  };
};

export interface Notice {
  publish: string;
  unpublish: string;
  notification_type: NotificationOptions;
  subject: string;
  message: string;
  notice_level: NoticeSelector<LevelIds, LevelValues>;
  access_filter: NoticeSelector<AccessIds, AccessValues>;
}

export enum NotificationMethod {
  NONE = 0,
  SMS = 1,
  EMAIL = 2,
  PUSH = 4,
  SMS_EMAIL = 3,
  SMS_PUSH = 5,
  EMAIL_PUSH = 6,
  SMS_EMAIL_PUSH = 7
}
export type Level = 'danger' | 'warning' | 'info' | 'emergency';
export type Visibility = 'seen' | 'unseen' | 'archived' | 'all';

export type NotificationTypes = {
  key: keyof typeof NotificationMethod;
  label: string;
  icon: ComponentProps <typeof IonIcon>['icon'];
}

type ProjectAccessFilter = 'onsite' | 'ever' | 'last 7 days' | 'last 14 days';

export interface NoticeFormValues {
  subject: string;
  id: string;
  message: string;
  publish_at?: string;
  expire_at?: string;
  project_access_filter?: ProjectAccessFilter;
  notification_methods?: NotificationMethod | number;
  level: Level;
  visibility: Visibility;
  users?: E2U.V1.Models.User[];
  teams?: E2U.V1.Models.Team[];
}

export interface EntitySelectProps {
  relationship: 'teams' | 'projects' | 'users';
  model?: 'projects';
  modelId?: string;
  label: string;
  fieldLabel: string;
  modalTitle: string;
}

export interface NoticeLevelProps {
  defaultValue?: string;
}
