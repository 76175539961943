import { IonCard, IonCardContent, IonCheckbox, IonCol, IonGrid, IonIcon, IonLabel, IonModal, IonRow, IonText, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { arrowForward, document } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import File from './File';
import { networking } from '../../../../../../api/networking';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import BigUp from '../../../../../../components/UI';
import { useAppSelector } from '../../../../../../hooks';
import i18n from '../../../../../../i18n';
import { setIsLoading } from '../../../../../../reducers/loading';
import { setOnboardingStageThree, setProjectFiles } from '../../../../../../reducers/onboarding';
import store from '../../../../../../store';
import type { OnboardingInterface } from '../../../../interfaces/Onboarding.interface';
import modalStyle from '../../../containers/styles/ModalContainers.module.scss';
import OnboardingHeader from '../../../header/FormHeaderTitle';
import styles from '../../styles/OnboardingStageThree.module.scss';

const OnboardingStageThree: React.FC<OnboardingInterface> = () => {
  const [hasReadFile, setHasReadFile] = useState<{ [id: string]: { has_accepted: boolean } }>({});
  const [selectedFile, setSelectedFile] = useState<E2U.V1.Models.ProjectAccessRequirement>();
  const project = useAppSelector(state => state.onboarding.selectedProject);
  let onboardingStageThreeClone: Partial<E2U.V1.Objects.ProjectAccessRequest> = structuredClone(useAppSelector(store => store.onboarding.onboardingStageThree));
  const onboardingStageThree: Partial<E2U.V1.Objects.ProjectAccessRequest> = useAppSelector(store => store.onboarding.onboardingStageThree);

  const isLoading: boolean = useAppSelector(
    (state) => state.loading.isLoading.fileList
  );

  const projectFiles: E2U.V1.Models.ProjectAccessRequirement[] = useAppSelector(
    (state) => state.onboarding.projectFiles
  );

  const { t } = useTranslation();
  const router = useIonRouter();

  const {
    handleSubmit,
    register,
    setValue
  } = useForm<FieldValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: onboardingStageThreeClone
  });

  const fetchProjectFiles = () => {
    store.dispatch(setIsLoading({ name: 'fileList', value: true }));
    networking.get(`/api/v1/projects/${project?.id}/access_requirements`)
      .then((response) => {
        store.dispatch(setProjectFiles(response.data.data.records));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'fileList', value: false }));
      });
  };

  const submitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setOnboardingStageThree(data));
    updateAcceptedFile();
    router.push('/site-access/summary');
  };

  const onPrevious = () => {
    updateAcceptedFile();
    router.goBack();
  };

  const openFile = (file: E2U.V1.Models.ProjectAccessRequirement) => {
    setSelectedFile(file);
  };

  const onReadFile = (file: E2U.V1.Models.ProjectAccessRequirement) => {
    updateFileValue(file, true);
    dismissFile();
  };

  const onDeclineFile = (file: E2U.V1.Models.ProjectAccessRequirement) => {
    updateFileValue(file, false);
    dismissFile();
  };

  const updateFileValue = (file: E2U.V1.Models.ProjectAccessRequirement, value: boolean) => {
    if (!file.id) {
      return;
    }
    setValue(`project_files.${file.id}.has_accepted`, value);
    setHasReadFile({
      ...hasReadFile,
      [file.id]: { has_accepted: value }
    });
  };

  const dismissFile = () => {
    setSelectedFile(undefined);
  };

  const updateAcceptedFile = () => {
    onboardingStageThreeClone.project_files = hasReadFile;
    store.dispatch(setOnboardingStageThree(onboardingStageThreeClone));
  };

  useEffect(() => {
    onboardingStageThreeClone = structuredClone(onboardingStageThree);
  }, [onboardingStageThree]);

  useEffect(() => {
    setHasReadFile(onboardingStageThree.project_files ?? {});
  }, []);

  useEffect(() => {
    fetchProjectFiles();
  }, []);

  const hasReadStatus = projectFiles.some(doc => {
    const docId: any = doc.id;
    return !(hasReadFile[docId] && hasReadFile[docId].has_accepted);
  });

  return (
    <>
      <OnboardingHeader title={t('Confirmation of understanding of the project files')} />
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={styles['onboarding-stage-three']}>
          <IonGrid>
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <IonCol size="auto" >
                <BigUp.Label.Thick className='ion-no-margin' label={t('Tap to open file')} />
              </IonCol>
              <IonCol size="auto" >
                <IonLabel className={styles['tap-to-open-document-label']}>
                  {t('Confirmed')}
                </IonLabel>
              </IonCol>
            </IonRow>
            {isLoading
              ? <h5>{t('Loading project files')}</h5>
              : (
                <React.Fragment>
                  {projectFiles.map((doc: any, index) => {
                    return (
                      <div key={index}>
                        <IonRow className="ion-justify-content-between ion-align-items-center" >
                          <IonCol size="9">
                            <IonCard className="ion-text-left ion-no-margin">
                              <IonCardContent
                                onClick={() => {
                                  openFile(doc);
                                }}
                              >
                                <IonIcon icon={document} size={'large'} />
                                <IonText color={'tertiary'}>
                                  <IonLabel>{doc.name}</IonLabel>
                                </IonText>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                          <IonCol size="auto">
                            {(doc.id &&
                              hasReadFile[doc.id])
                              ? (
                                <IonCheckbox
                                  disabled={true}
                                  checked={(doc.id && hasReadFile[doc.id]) ? hasReadFile[doc.id].has_accepted : false}
                                  {...register(
                                    `project_files.${doc.id}.accepted`
                                  )}
                                />
                              )
                              : <IonCheckbox disabled={true} checked={false} />
                            }
                          </IonCol>
                        </IonRow>
                      </div>
                    );
                  })}
                </React.Fragment>
              )
            }

          </IonGrid>

          <IonModal
            isOpen={!!selectedFile}
            onIonModalDidDismiss={dismissFile}
            className={modalStyle['project-file-modal-container']}
          >
            <File
              onReadFile={onReadFile}
              file={selectedFile}
              onDismiss={dismissFile}
              onDecline={onDeclineFile}
            />
          </IonModal>

          <StepButtons
            leftTitle={t('Previous')}
            leftIonColor={'none'}
            leftOnClick={onPrevious}
            rightSubmit={'submit'}
            rightDisabled={isLoading || hasReadStatus}
            rightIonColor={'secondary'}
            rightTitle={t('Next')}
            rightIcon={arrowForward}
          />
        </div>
      </form>

    </>
  );
};

export default OnboardingStageThree;
