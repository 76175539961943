import { IonBadge, IonCol, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonModal, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { format, subDays } from 'date-fns';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import type { DatetimeRegisterReturn } from '../NoticeBoard/Form/form.interface';

const BirthdatePicker: React.FC = () => {
  const { t } = useTranslation();
  const methods = useFormContext();

  function registerDatetime(register: UseFormRegisterReturn) {
    return register as DatetimeRegisterReturn;
  }

  const adjustAndFormatDate = (dateString: string) => {
    const parsedDate = new Date(dateString);
    const adjustedDate = subDays(parsedDate, 1);
    return format(adjustedDate, 'yyyy-MM-dd');
  };

  const handleDateChange = (e: CustomEvent) => {
    const selectedDate = e.detail.value as string;
    const adjustedDate = adjustAndFormatDate(selectedDate);
    methods.setValue('birth_date', adjustedDate);
  };

  return (
    <IonRow>
      <IonCol>
        <IonItem className="ion-no-padding" style={{}}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <IonLabel className={'ion-no-margin'} style={{ fontSize: '0.8rem' }}>
              {t('Birth date')}
            </IonLabel>
            <IonDatetimeButton className='ion-no-margin' datetime="birth_date" placeholder="yyyy-mm-dd" />
          </div>
        </IonItem>
        <IonBadge color="danger" hidden={!methods.formState.errors.birth_date}>
          {methods.formState.errors.birth_date?.message}
        </IonBadge>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            max={format(new Date(), 'yyyy-MM-dd')}
            showDefaultButtons
            onIonChange={handleDateChange}
            presentation='date'
            color={'primary'}
            id="birth_date"
            placeholder={'yyyy-mm-dd'}
            {...registerDatetime(methods.register('birth_date', {
              required: i18n.t('Please enter your birth date') as keyof E2U.V1.Models.User,
            }))}
          />
        </IonModal>
      </IonCol>
    </IonRow>

  );
};

export default BirthdatePicker;
