import { IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import type { SelectOption, SelectProps } from './interface';
import styles from './SelectOutlined.module.scss';
import SelectOutlinedBase from './SelectOutlinedBase';
import { capitalize } from '../../../tools/capitalizeString';

const SelectOutlined: React.FC<SelectProps<SelectOption>> = (props) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  return (
    <SelectOutlinedBase {...props}>
      <IonSelect
        labelPlacement={props.labelPlacement || 'stacked'}
        interface={props.interface || 'alert'}
        placeholder={props.placeholder || ''}
        value={props.selected}
        {...methods.register(props.register)}
        onIonChange={(e) => props.handleSelection(e.detail.value)}
      >
        {props.data.map((section, index) => {
          const value = section.value === null ? t('None') : section.value;
          return (
            <IonSelectOption key={index} value={section.id}>
              {capitalize(value)}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </SelectOutlinedBase>
  );
};

export default SelectOutlined;
