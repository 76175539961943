import { IonAvatar, IonIcon, IonLabel } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { people } from 'ionicons/icons';
import React from 'react';

import { getGravatarPicture } from '../../../../hooks/useProfilePicture';
interface AccessRowLabelProps {
  row: E2U.V1.Objects.AclFoundEntity;
}

const AccessRowLabel: React.FC<AccessRowLabelProps> = ({ row }) => {
  return (
    <>
      {!('email' in row && row.email !== null && row.email !== '')
        ? <IonIcon slot="start" icon={people} />
        : <IonAvatar slot="start">
          <img src={getGravatarPicture('email' in row ? (row?.email ?? '') : '') ?? ''} alt={row.name} />
        </IonAvatar>
      }
      <IonLabel>{row.name}</IonLabel>
    </>
  );
};

export default AccessRowLabel;
