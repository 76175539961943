import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { type FieldValues, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import CategoryForm from './form/CategoryForm';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import SkeletonItem from '../../../components/SkeletonComponents/SkeletonItem';
import BigUp from '../../../components/UI';
import FilterChips from '../../../components/UI/Chips/FilterChips';
import DeleteRecord from '../../../components/UI/DeleteRecord';
import toasters from '../../../components/UI/Toasts';
import i18n from '../../../i18n';

const EditDocumentTypePage: React.FC = () => {
  const history = useHistory();
  const [alert] = useIonAlert();
  const { category_id, uuid } = useParams<{ uuid: string | undefined, category_id: string | undefined }>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<E2U.V1.Models.Type | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<E2U.V1.Models.Tag[]>([]);

  const methods = useForm<{
    name: string,
    color: string,
    tags: E2U.V1.Models.TagCategory[]
  }>({
    mode: 'onTouched',
    defaultValues: {
      ...selectedDocumentType,
      tags: [],
    }
  });

  const getSelectedCategory = () => {
    setIsLoading(true);
    networking.get(`/api/v1/document_types/${category_id}?with=tags`)
      .then((response) => {
        setSelectedDocumentType(response.data.data);
        setSelectedTags(response.data.data.tags);
      })
      .finally(() => setIsLoading(false));
  };

  const handleTagSelection: SubmitHandler<E2U.V1.Models.Tag[]> = async (data) => {
    setSelectedTags(data);
  };

  const handleChipClick = (tag: E2U.V1.Models.Tag) => {
    const newTags = selectedTags.filter((t) => t.id !== tag.id);
    setSelectedTags(newTags);
  };

  const handleCategoryEdit: SubmitHandler<FieldValues> = async (data) => {
    try {
      const response = await networking.put(`/api/v1/document_types/${selectedDocumentType?.id}`, {
        name: data.name,
        color: data.color,
        tags: selectedTags
      });

      const existingTags = selectedDocumentType?.tags ?? [];

      const tagsToRemove = existingTags.filter(
        (tag: E2U.V1.Models.Tag) => !selectedTags.some((st: E2U.V1.Models.Tag) => st.id === tag.id)
      );
      const tagsToAdd = selectedTags.filter(
        (tag: E2U.V1.Models.Tag) => tag.id && !existingTags.some((et) => et.id === tag.id)
      );

      const removeTagActions = tagsToRemove.map((tag: E2U.V1.Models.Tag) =>
        networking.delete(`/api/v1/document_types/${selectedDocumentType?.id}/tags/${tag.id}`)
      );

      const addTagActions = tagsToAdd.map((tag: E2U.V1.Models.Tag) =>
        networking.post(`/api/v1/document_types/${selectedDocumentType?.id}/tags/${tag.id}`)
      );

      await Promise.all([...addTagActions, ...removeTagActions]);

      toasters.createToast({
        message: i18n.t('Updated type: {name}', 'Updated type: {name}', { name: response.data.data.name }),
        background: 'var(--ion-color-light)',
      }, 'success');

      await handleTagSelection(selectedTags);
    } catch (error: any) {
      Sentry.captureException(error);
      if (error.response?.status === 409) {
        toasters.createToast({
          message: i18n.t('Type already exists'),
          background: 'var(--ion-color-light)'
        }, 'error');
        return;
      }
      toasters.createToast({
        message: i18n.t('Something went wrong!'),
        background: 'var(--ion-color-light)'
      }, 'error');
    }
  };

  const handleCategoryDelete = async () => {
    try {
      await networking.delete(`/api/v1/document_types/${selectedDocumentType?.id}`);
      history.replace(`/tools/${uuid}/settings/document-types`);
      toasters.createToast({
        message: i18n.t('Deleted type: {name}', 'Deleted type: {name}', { name: selectedDocumentType?.name }),
        background: 'var(--ion-color-light)'
      }, 'success');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const deleteConfirmation = () => {
    alert({
      header: i18n.t('Delete type'),
      message: i18n.t('Are you sure you want to delete type {name}?', 'Are you sure you want to delete type {name}?', {
        name: selectedDocumentType?.name
      }),
      buttons: [
        { text: i18n.t('Cancel'), role: 'cancel', cssClass: 'secondary' },
        { text: i18n.t('Yes'), handler: handleCategoryDelete }
      ]
    });
  };

  useEffect(() => {
    if (category_id) {
      getSelectedCategory();
    }
  }, [category_id]);

  useEffect(() => {
    if (selectedDocumentType) {
      const mappedTags = selectedDocumentType?.tags?.reduce((acc: any, tag: E2U.V1.Models.Tag) => {
        const categoryIndex = acc.findIndex((cat: any) => cat.id === tag.category_id);
        if (categoryIndex > -1) {
          acc[categoryIndex].tags.push(tag);
        } else {
          acc.push({ id: tag.category_id, tags: [tag] });
        }
        return acc;
      }, []);

      methods.reset({
        name: selectedDocumentType.name,
        color: selectedDocumentType.color,
        tags: mappedTags,
      });
    }
  }, [selectedDocumentType, methods]);

  return (
    <>
      {isLoading
        ? <SkeletonItem amount={5} />
        : (
          <DesktopWrapper>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleCategoryEdit)}>
                <IonGrid className='ion-padding'>
                  <BigUp.Title
                    label={i18n.t('Edit type')}
                    color={'dark'}
                    style={{ paddingTop: '35px', marginBottom: '16px', fontWeight: 800 }}
                  />
                  <CategoryForm />
                  <RelatedMultiSelectEdit
                    button='wide'
                    expand='block'
                    color='secondary'
                    value={selectedTags}
                    model='tags'
                    hideSelected={true}
                    displayFields={['name']}
                    label='Select tags'
                    onChange={(data) => handleTagSelection(data)}
                  />
                  <IonRow>
                    <IonCol size='12'>
                      <FilterChips
                        data={selectedTags ?? []}
                        renderChipLabel={(tag) => tag.name}
                        chipIcon={close}
                        onChipClick={(tag) => handleChipClick(tag)}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid className='ion-padding'>
                  <IonRow>
                    <DeleteRecord
                      record=''
                      hasPadding={false}
                      colSize='10'
                      label={i18n.t('Do you want to delete type {name}?', 'Do you want to delete type {name}?', { name: selectedDocumentType?.name })}
                      onDelete={() => deleteConfirmation()}
                    />
                    <IonCol className="ion-text-end ion-margin-top" size='3'>
                      <BigUp.Buttons.Regular
                        type="submit"
                        color='secondary'
                        disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                        title={i18n.t('Save')}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FormProvider>
          </DesktopWrapper>
        )}
    </>
  );
};

export default EditDocumentTypePage;
