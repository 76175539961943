import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import { ellipsisHorizontal } from 'ionicons/icons';
import React from 'react';

import styles from './FileCard.module.scss';
import { checkFileType } from '../../../tools/checkFileType';

interface FileCardProps {
  onEllipsisClick: (e: React.MouseEvent<HTMLIonButtonElement> | undefined) => void;
  name: string;
  subLabel?: string;
  fileType: string;
  size?: 'full' | 'default';
}

const FileCard: React.FC<FileCardProps> = (props) => {
  return (
    <div className={classNames(styles.container, props.size === 'full' && styles['container--full'])}>
      <section className={styles.header}>
        <IonButton
          fill='clear'
          className={styles['ellipsis-button']}
          mode="md"
          onClick={props.onEllipsisClick}
        >
          <IonIcon
            slot="icon-only"
            size="small"
            color="medium"
            icon={ellipsisHorizontal}
          />
        </IonButton>
      </section>
      <article className={styles.content}>
        <div className={styles['icon-container']}>
          <IonIcon icon={checkFileType(props.name) as string} style={{ fontSize: '46px' }} />
        </div>
        <div className={styles['text-container']}>
          <IonLabel className={styles['file-name']}>{props.name}</IonLabel>
          <IonLabel className={styles['uploaded-at']}>
            {props.subLabel}
          </IonLabel>
        </div>
      </article>
    </div>
  );
};

export default FileCard;
