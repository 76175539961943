import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { people, person } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { EntitySelectProps } from './form.interface';
import { networking } from '../../../api/networking';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import InputLabel from '../../../components/UI/InputLabel';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';

const EntitySelect: React.FC<EntitySelectProps> = ({ fieldLabel, label, modalTitle, model, relationship }) => {
  const methods = useFormContext();
  const project = useAppSelector(state => state.project.selectedProject);
  const selectedEntities = methods.watch(relationship);
  const selectedAlternativeEntities = methods.watch(relationship === 'teams' ? 'users' : 'teams');

  useEffect(() => {
    methods.trigger('teams');
    methods.trigger('users');
    methods.trigger('global');
  }, [selectedEntities, methods.watch('global')]);

  return (
    <>
      <IonRow className={'ion-align-items-center'}>
        <IonCol className={'ion-no-padding'}>
          <InputLabel margin={'ion-no-margin ion-margin-vertical'}>{fieldLabel}</InputLabel>
        </IonCol>
        <IonCol size={'5'} className={'ion-no-padding'}>
          <Controller
            name={relationship}
            control={methods.control}
            rules={{
              validate: (value) => {
                return (value.length > 0 || selectedAlternativeEntities.length > 0 || methods.getValues('global'))
                  ? true
                  : i18n.t('At least one {entity} or {alternativeEntity} is required.', {
                    entity: relationship === 'teams' ? i18n.t('team') : i18n.t('user'),
                    alternativeEntity: relationship === 'teams' ? i18n.t('user') : i18n.t('team')
                  }).toString();
              }
            }}
            render={({ field }) => (
              <RelatedMultiSelectEdit
                button={'wide'}
                expand='block'
                model={'projects'}
                relationship={relationship}
                modelId={project?.id}
                label={label}
                action='submit'
                value={selectedEntities ?? []}
                modalTitle={modalTitle}
                onChange={(value) => methods.setValue(relationship, value)}

                displayFields={
                  relationship === 'teams'
                    ? ['name']
                    : ['first_name', 'last_name']
                }
                hideSelected
              />
            )}
          />
        </IonCol>
      </IonRow>
      {selectedEntities.length
        ? (
          <IonGrid style={{ maxHeight: 250, overflow: 'scroll' }} className='ion-margin-bottom'>
            {selectedEntities.map((e: any) => {
              return (
                <IonRow key={e.id}>
                  <IonCol size='12' className={'ion-no-padding'}>
                    <IonItem className={'ion-no-padding'}>
                      <IonIcon icon={relationship === 'teams' ? people : person} className='ion-margin-end' color='medium' />
                      <IonLabel className='ion-text-nowrap ion-no-margin'>
                        {e.name || e.first_name + ' ' + e.last_name}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        )
        : (
          <IonGrid>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='12' className={'ion-no-padding ion-margin-bottom'}>
                <IonItem className={'ion-no-padding'}>
                  <IonLabel className='ion-text-nowrap ion-no-margin'>
                    {i18n.t(`No ${relationship} selected`)}
                  </IonLabel>

                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        )
      }
    </>
  );
};

export default EntitySelect;
