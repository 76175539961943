import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks';

export const useChartData = () => {
  const { t } = useTranslation();
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const selectedVersion = useAppSelector((state) => state.precalculations.selectedVersion);

  const securedTotal = {
    precalculation: {
      value: selectedPrecalculation?.secured_costs,
      param: '1',
    },
    historical: {
      value: selectedVersion?.secured_costs,
      param: '1',
    },
  };

  const remainingTotal = {
    precalculation: {
      value: (selectedPrecalculation?.total ?? 0) - (selectedPrecalculation?.secured_costs ?? 0),
      param: '0',
    },
    historical: {
      value: (selectedVersion?.total ?? 0) - (selectedVersion?.secured_costs ?? 0),
      param: '0',
    },
  };

  const pieChartData = {
    precalculation: [
      {
        value: remainingTotal.precalculation.value,
        name: t('Remaining'),
      },
      {
        value: securedTotal.precalculation.value,
        name: t('Secured Costs'),
      },
    ],
    historical: [
      {
        value: remainingTotal.historical.value,
        name: t('Remaining'),
      },
      {
        value: securedTotal.historical.value,
        name: t('Secured Costs'),
      },
    ],
  };

  return { securedTotal, remainingTotal, pieChartData };
};
