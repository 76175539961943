import { IonCol, IonGrid, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

import WorkUpValueLineChart from './Graphs/WorkUpValueLineChart';
import styles from './PaymentSchedule.module.scss';
import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import type { DataItemProps } from '../../../../components/UI/Table/interfaces';
import { useAppSelector } from '../../../../hooks';
import { setIsLoading } from '../../../../reducers/loading';
import { setPaymentSchedule, setWorkupGraphData } from '../../../../reducers/payment-schedule';
import { defaultFormatter } from '../../../../tools/formatNumber';

const PaymentSchedule: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();
  const [lastReload, setLastReload] = useState<number | null | any>(new Date());
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const paymentScheduleId = useAppSelector((state) => state.paymentSchedule.paymentSchedule?.id);
  const isLoadingPaymentSchedule = useAppSelector(state => state.loading.isLoading.paymentSchedule);
  const location = useLocation();
  const sourceUrl = useMemo(
    () => paymentScheduleId ? `/api/v1/payment_schedules/${paymentScheduleId}/rows` : undefined,
    [paymentScheduleId]
  );
  const productionBudgetUrl: string = useMemo(
    () => location?.pathname?.replace('payment-schedule', 'production-budget') ?? '',
    [location?.pathname]
  );
  const tableColumns: DataItemProps[] = [
    {
      key: 'invoice_date',
      label: t('Invoice date'),
      sortable: true,
      alignment: 'left',
      sizes: {
        lg: '4',
        md: '3',
        sm: '4',
        xl: '2',
        xs: '5',
      },
    },
    {
      key: 'payment_date',
      label: t('Payment date'),
      sortable: true,
      sizes: {
        lg: '4',
        md: '3',
        sm: '4',
        xl: '2',
        xs: '4',
      },
    },
    {
      key: 'total_net',
      label: t('Quantity net'),
      sortable: true,
      alignment: 'center',
      sizes: {
        lg: '4',
        md: '3',
        sm: '4',
        xl: '2',
        xs: '6',
      },
    },
    {
      key: 'total_vat',
      label: t('Vat 25%'),
      sortable: true,
      alignment: 'center',
      sizes: {
        lg: '4',
        md: '3',
        sm: '4',
        xl: '2',
        xs: '6',
      },
    },
    {
      key: 'total_gross',
      label: t('am. + VAT'),
      sortable: true,
      alignment: 'center',
      sizes: {
        lg: '4',
        md: '3',
        sm: '4',
        xl: '2',
        xs: '6',
      },
    },
    {
      key: 'total_paid_net',
      label: t('Invoice ack net'),
      sortable: true,
      alignment: 'center',
      sizes: {
        lg: '4',
        md: '3',
        sm: '2',
        xl: '2',
        xs: '6',
      },
    },
    {
      key: 'total_paid_vat',
      label: t('Invoice ack + VAT'),
      sortable: true,
      alignment: 'center',
      sizes: {
        lg: '4',
        md: '3',
        sm: '2',
        xl: '2',
        xs: '6',
      },
    }
  ];

  const fetchWorkupGraphData = () => {
    if (!selectedProjectId) return;
    store.dispatch(setIsLoading({ name: 'workupGraphData', value: true }));
    toasters.promise(networking.get(`/api/v1/projects/${selectedProjectId}/workup_rate`), {
      error: t('Error while getting workup graph data')
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.LineGraphData>) => {
        store.dispatch(setWorkupGraphData(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'workupGraphData', value: false })
        );
      });
  };

  const fetchPaymentSchedule = () => {
    if (!selectedProjectId) return;
    const endpoint = `/api/v1/projects/${selectedProjectId}/payment_schedule?with=rows`;
    store.dispatch(setIsLoading({ name: 'paymentSchedule', value: true }));
    toasters.promise(
      networking.get(endpoint), { error: t('Error while getting payment schedule') })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.PaymentSchedule>>) => {
        if (response.data.data.records.length > 0) {
          store.dispatch(setPaymentSchedule(response.data.data.records[0]));
          fetchWorkupGraphData();
        } else {
          store.dispatch(setPaymentSchedule(undefined));
        }
        setLastReload(new Date());
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => store.dispatch(setIsLoading({ name: 'paymentSchedule', value: false })));
  };

  useEffect(() => {
    fetchPaymentSchedule();
    fetchWorkupGraphData();
  }, []);

  useEffect(() => {
    fetchPaymentSchedule();
    fetchWorkupGraphData();
  }, [selectedProjectId]);

  console.log('Source url and payment schedule', sourceUrl, paymentScheduleId);

  return (
    <DesktopWrapper>
      <div className={styles['payment-schedule-accordion-container']}>
        <div className="ion-no-padding" slot="content">
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.Title label={t('Payment schedule')} />
              </IonCol>
            </IonRow>
          </IonGrid>
          {(selectedProjectId && paymentScheduleId) && (
            <WorkUpValueLineChart />
          )}
          <IonGrid className='ion-margin-top'>
            <IonRow className='ion-justify-content-start ion-align-items-center'>
              <IonCol size='auto'>
                <BigUp.Label.V2Thick label={t('Overview')} />
              </IonCol>
            </IonRow>
          </IonGrid>
          {
            (selectedProjectId && !isLoadingPaymentSchedule)
              ? (
                sourceUrl
                  ? (
                    <BigUp.Table
                      timestamp={lastReload}
                      sourceUrl={sourceUrl}
                      columns={tableColumns}
                      reducers={{
                        total_net: defaultFormatter,
                        total_vat: defaultFormatter,
                        total_gross: defaultFormatter,
                        total_paid_net: defaultFormatter,
                        total_paid_vat: defaultFormatter,
                      }}
                    />
                  )
                  : (
                    <EmptyList
                      title={t('Payment schedule missing')}
                      message={
                        <IonRow className={'ion-align-items-center ion-justify-content-center'}>
                          <IonCol>
                            {t('You need to generate a production budget to proceed.')}
                          </IonCol>
                          <IonCol size={'auto'}>
                            <BigUp.Buttons.Secondary
                              size={'small'}
                              title={t('Go to budget')}
                              routerLink={productionBudgetUrl}
                            />
                          </IonCol>
                        </IonRow>
                      }
                    />
                  )
              )
              : <SkeletonTextThreeLines />
          }
        </div>
      </div>
    </DesktopWrapper>
  );
};
export default PaymentSchedule;
