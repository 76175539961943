import { IonBadge, IonItem, IonLabel, IonRange } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import styles from './Range.module.scss';
import type RangeProps from './RangeTypes';

export const Range: React.FC<RangeProps> = ({ badge, children, register, rowCount, rowIndex, validation, ...props }) => {
  const methods = useFormContext();
  return (
    <div className={styles['range-wrapper']}>
      <IonItem className='ion-no-margin ion-no-padding' lines='none'>
        <IonLabel className='ion-no-margin' color={'medium'}>
          {badge.label.label}
        </IonLabel>
        <IonBadge slot='end'>
          {badge.title}
        </IonBadge>
      </IonItem>
      <section className={styles['range-container']}>
        <IonRange
          pin
          snaps
          aria-required
          aria-valuemin={1}
          placeholder={props.range.placeholder}
          register={register}
          onIonChange={props.range.onIonChange}
          max={props.range.max}
          min={props.range.min}
        />
      </section>
    </div>
  );
};
