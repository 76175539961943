import { useIonRouter } from '@ionic/react';
import React from 'react';
import { useLocation, useParams } from 'react-router';

import { networking } from '../../api/networking';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import useAcl from '../../hooks/useAcl';
import i18n from '../../i18n';
import { setSelectedProject } from '../../reducers/project';
import store from '../../store';
import SelectOptions from '../UI/Select/SelectOption';
import type { ColourVariables } from '../UI/variables';

interface ProjectSelectorProps {
    currentTab: string | undefined;
    color?: ColourVariables;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  color = 'var(--ion-color-light)', currentTab
}) => {
  const router = useIonRouter();
  const { fetchPermissions, projects } = useAcl();
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const location = useLocation();

  const isValidUUID = (id: string) => {
    const uuidRegex = new RegExp(`^${matchUuid}$`, 'i');
    return uuidRegex.test(id);
  };

  const navigateWithNewUUID = (projectId: string) => {
    if (currentTab === 'tools') {
      router.push(`/tools`, 'none');
      return;
    }
    if (uuid) {
      router.push(location.pathname.replace(uuid, projectId), 'none');
      return;
    }
    router.push(`${location.pathname}/${projectId}`, 'none');
  };

  const selectProject = (id: string | undefined) => {
    if (!id) return;
    if (isValidUUID(id) || uuid) {
      networking.get(`/api/v1/projects/${id}`)
        .then((response) => {
          fetchPermissions({
            scope: {
              uuid: id,
              type: 'project'
            }
          });
          store.dispatch(setSelectedProject(response.data.data));
        })
        .then(() => {
          navigateWithNewUUID(id);
        });
    }
  };

  return (typeof projects !== 'undefined' && projects.length)
    ? (
      <SelectOptions
        placeholder={i18n.t('Select project')}
        selectedColour={color}
        selectedTextSize='18px'
        itemPadding='ion-no-padding'
        options={projects || []}
        getId={(option) => option.id}
        getName={(option) => option.name}
        selectedOption={selectedProject}
        setSelectedOption={(option) => selectProject(option?.id)}
        childrenPosition='top'
      >
      </SelectOptions>
    )
    : <></>;
};

export default ProjectSelector;
