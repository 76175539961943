import { IonCol, IonGrid, IonModal, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ControlOfExecution.module.scss';
import RecordView from './RecordView/RecordView';
import { Tile } from './Tiles';
import AclCurtain from '../../../components/AclCurtain';
import DesktopWrapper from '../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import BigUp from '../../../components/UI';
import type { DataItemProps } from '../../../components/UI/Table/interfaces';
import { useAppSelector } from '../../../hooks';
import { formatToYYYYMMDD } from '../../../tools/formatDates';

const ControlOfExecution: React.FC = () => {
  const [controlInformationModal, setControlInformationModal] = useState<boolean>(false);
  const [lastControlFetch, setLastControlFetch] = useState<number>(Date.now());
  const [recordViewOpen, setRecordViewOpen] = useState<boolean>(false);
  const [defaultEdit, setDefaultEdit] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string | undefined>();
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const { t } = useTranslation();

  const sourceUrl = `/api/v1/projects/${selectedProjectId}/control_of_executions`;
  const tableColumns: DataItemProps[] = [
    {
      key: 'name',
      label: t('Name'),
      alignment: 'left',
      sizes: { xs: '6', sm: '5', md: '4', lg: '3', xl: '2', },
      body: <Tile.Description attributes={{} as E2U.V1.Models.ControlOfExecution} />,
    },
    {
      key: 'activity_code_id',
      label: t('Activity code'),
      alignment: 'left',
      sizes: { xs: '7', sm: '5', md: '4', lg: '3', xl: '2', },
      body: <Tile.ActivityCode attributes={{} as E2U.V1.Models.ControlOfExecution} />,
    },

    {
      key: 'created_at',
      label: t('Created at'),
      alignment: 'left',
      sizes: { xs: '7', sm: '5', md: '4', lg: '3', xl: '2', },
    },
    {
      key: 'has_been_attested',
      label: t('Status'),
      sizes: { xs: '7', sm: '5', md: '4', lg: '3', xl: '2', },
      alignment: 'left',
      body: <Tile.Attested attributes={{} as E2U.V1.Models.ControlOfExecution} />,
    },
    {
      key: 'assigned_user_id',
      label: t('Assigned user'),
      sizes: { xs: '7', sm: '5', md: '4', lg: '3', xl: '2', },
      alignment: 'left',
      body: <Tile.Assignee attributes={{} as E2U.V1.Models.ControlOfExecution} />,
    },
  ];

  const fetchRecords = () => setLastControlFetch(Date.now());
  const toggleAddNewControl = () => setControlInformationModal(!controlInformationModal);

  const toggleRecordView = (recordId: string | undefined = undefined) => {
    setSelectedRecordId(recordId);
    setRecordViewOpen(!recordViewOpen);

    if (recordViewOpen) {
      setDefaultEdit(false);
    }
  };

  const resetOnDismiss = () => {
    setRecordViewOpen(false);
    setDefaultEdit(false);
  };

  const addControl = () => {
    setDefaultEdit(true);
    toggleRecordView();
    toggleAddNewControl();
  };

  useEffect(() => {
    fetchRecords();
  }, [selectedProjectId]);

  return (
    <React.Fragment>
      <DesktopWrapper>
        <IonGrid>
          <IonRow>
            <IonCol>
              <BigUp.Title label={t('Control of execution')} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12' className='ion-text-right ion-margin-bottom ion-margin-right '>
              <AclCurtain requiredAbilities={['can_store_control_of_executions_in_projects']}>
                <BigUp.Buttons.Secondary
                  size='default'
                  title={t('Add new control')}
                  onClick={addControl}
                  icon={{ icon: add }}
                />
              </AclCurtain>
            </IonCol>
          </IonRow>
          {!selectedProjectId
            ? <SkeletonTextThreeLines />
            : <BigUp.Table
              timestamp={lastControlFetch}
              sourceUrl={sourceUrl}
              columns={tableColumns}
              reducers={{
                created_at: (value: string) => formatToYYYYMMDD(value),
              }}
              callbacks={{
                onRowClick: (record: E2U.V1.Models.ControlOfExecution) => toggleRecordView(record.id),
              }}
            />}
        </IonGrid>
      </DesktopWrapper>

      <IonModal
        className={styles['form-and-details-modal']}
        isOpen={recordViewOpen}
        onIonModalDidDismiss={resetOnDismiss}>
        <RecordView
          fetchRecords={fetchRecords}
          onClose={resetOnDismiss}
          recordId={selectedRecordId}
          defaultEdit={defaultEdit}
          onCreation={(recordId: string) => setSelectedRecordId(recordId)}
        />
      </IonModal>
    </React.Fragment>
  );
};

export default ControlOfExecution;
