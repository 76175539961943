import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { close } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './Edit.module.scss';
import CASH_FLOW_EDIT_LIST from './EditInterface';
import { networking } from '../../../../../../api/networking';
import toasters from '../../../../../../components/Toasts/Toasts';
import { BigUp } from '../../../../../../components/UI';
import { formatToYYYYMMDD } from '../../../../../../tools/formatDates';
import formatNumber from '../../../../../../tools/formatNumber';

interface EditProps {
  onModalDismiss(): void;

  refreshCashFlow: (updateIndex?: boolean) => void;
  row: E2U.V1.Models.CashFlowRow | undefined;
  rowCount: number;
  rowIndex: number;
  isModalOpen: boolean;
}

const Edit: React.FC<EditProps> = (props) => {
  const { t } = useTranslation();
  const [applyToMonths, setApplyToMonths] = useState<number>(0);
  const [grossTotal, setGrossTotal] = useState('');

  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      project_management_amount: 0,
      net_cost_amount: 0,
      fee_amount: 0,
      grand_total: 0,
      apply_to_months: 0,
    }
  });

  const watchedValues = methods.watch([
    'project_management_amount',
    'net_cost_amount',
    'fee_amount'
  ]);
  const [
    projectManagement,
    netCost,
    fee
  ] = watchedValues;

  const netTotal = parseFloat(projectManagement) + parseFloat(netCost) + parseFloat(fee);

  const handleValueChange = () => {
    setGrossTotal((netTotal).toFixed(2));
  };

  const toastMessages = {
    pending: t('Updating manual cash flow'),
    success: t('Updated manual cash flow.'),
    error: t('Could not update cash flow')
  };

  const saveManualCashFlow: SubmitHandler<FieldValues> = (data: FieldValues) => {
    if (Object.keys(methods.formState.errors).length > 0) {
      return;
    }

    return toasters.promise(networking.put(`/api/v1/cash_flow_rows/${props.row?.id}`, data), {
      pending: toastMessages.pending,
      success: toastMessages.success,
      error: toastMessages.error
    })
      .then(() => {
        props.refreshCashFlow(false);
        props.onModalDismiss();
      })
      .catch((error: E2U.V1.Response.Error<E2U.V1.Models.CashFlowRow>) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        if (!methods.formState.errors) {
          props.onModalDismiss();
          props.refreshCashFlow(true);
        }
      });
  };

  const onRangeChange = (e: number) => {
    setApplyToMonths(e);
    methods.setValue('apply_to_months', e);
  };

  useEffect(() => {
    if (props.row) {
      methods.reset({
        project_management_amount: props.row.project_management_amount,
        net_cost_amount: props.row.net_cost_amount,
        fee_amount: props.row.fee_amount,
        grand_total: props.row.grand_total,
        apply_to_months: 0,
      });
    }
  }, [props.row, methods.reset]);

  useEffect(() => {
    handleValueChange();
  }, [watchedValues, methods.setValue]);

  const notCalculatedGrossTotal = useMemo(() => {
    if (!isNaN(parseFloat(grossTotal))) {
      return formatNumber(Math.round(parseInt(grossTotal)));
    } else {
      return '-';
    }
  }, [grossTotal]);

  return (
    <React.Fragment>
      <IonModal isOpen={props.isModalOpen} onIonModalDidDismiss={props.onModalDismiss}
        className={styles['cash-flow-edit-manual-modal']}>
        <IonHeader className='ion-no-border'>
          <IonToolbar className='ion-margin-top'>
            <IonTitle className={'ion-text-center'}>
              <BigUp.Label.V2Thick label={formatToYYYYMMDD(props.row?.due_date)} />
            </IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={props.onModalDismiss} className='icon-only'>
                <IonIcon icon={close} size='large' />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollY={true}>
          <IonGrid className={'ion-padding'}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(saveManualCashFlow)}>
                <IonGrid>
                  {CASH_FLOW_EDIT_LIST.map((input) => {
                    return (
                      <IonRow className='ion-margin-bottom ion-align-items-center' key={input.register}>
                        <IonCol>
                          <BigUp.OutlinedInput
                            isCurrency
                            clearInput
                            id={input.register}
                            name={input.register}
                            type={'text'}
                            inputMode='decimal'
                            customLabel={input.label}
                            placeholder={input.placeholder}
                            register={input.register}
                            validation={{
                              required: true,
                            }}
                          />
                        </IonCol>
                      </IonRow>
                    );
                  })}
                  <div style={{ marginLeft: 4, marginRight: 8 }}>
                    <BigUp.Label.Indicator
                      indicator={{
                        color: 'none',
                        shape: 'none',
                      }}
                      label={{
                        start: {
                          label: t('Gross total'),
                        },
                        end: {
                          label: notCalculatedGrossTotal,
                          color: 'medium'
                        }
                      }}
                    />
                  </div>
                  {props.rowCount > 2 && (
                    <IonRow className='ion-margin-bottom ion-margin-top ion-align-items-center' key={'apply_to_months'}>
                      <IonCol>
                        <BigUp.Range
                          register={'apply_to_months'}
                          range={{
                            placeholder: t('Apply to months'),
                            onIonChange: (e) => onRangeChange(e.detail.value as number),
                            min: 0,
                            max: props.rowCount - props.rowIndex - 1
                          }}
                          rowCount={0}
                          rowIndex={0}
                          badge={{
                            label: {
                              label: t('Apply to months')
                            },
                            title: `+ ${applyToMonths} ${t('month(s)')}`
                          }}
                        />
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top ion-padding-top'>
                    <IonCol size='auto' className='ion-margin-top'>
                      <BigUp.Buttons.Primary
                        type='submit'
                        title={t('Save')}
                        disabled={
                          !methods.formState.isValid ||
                          methods.formState.isSubmitting
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FormProvider>
          </IonGrid>
        </IonContent>
      </IonModal>
    </React.Fragment>
  );
};

export default Edit;
