import { IonTextarea } from '@ionic/react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import styles from './TextareaOutlined.module.scss';
import type { TextareaProps } from '../Textareas/interface';

const TextareaOutlined: React.FC<TextareaProps<any>> = (props) => {
  const methods = useFormContext();

  return (
    <>
      {props.customLabel && (
        <BigUp.Label.Thick
          label={`${props.customLabel} ${props.validation?.required ? '*' : ''}`}
          className={`ion-no-margin ${styles['outlined-textarea-label']}`}
          position='stacked'
        />
      )}
      <IonTextarea
        mode='md'
        fill='outline'
        className={`ion-margin-left ${styles['outlined-textarea']}`}
        autoCapitalize='sentences'
        clearInput={false}
        inputmode={props.inputMode}
        placeholder={props.placeholder}
        autoGrow={true}
        debounce={300}
        helperText={props.helperText && props.helperText}
        {...props}
        {...props.register && methods.register(props.register, {
          ...props.validation,
        })}
      />
      {/*       {error && (
        <div className={`ion-no-margin ${styles['outlined-textarea-error-badge']}`}>
          <ValidationBadge>
            {error}
          </ValidationBadge>
        </div>
      )} */}
    </>
  );
};

export default TextareaOutlined;
