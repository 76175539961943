import { IonIcon, IonModal } from '@ionic/react';
import { addSharp, chevronDown, filterSharp } from 'ionicons/icons';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RelatedMultiSelectList from './RelatedMultiSelectList';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import BigUp from '../../../UI';
import ButtonRound from '../../../UI/Buttons/Round/ButtonRound';
import EmptyList from '../../../UI/EmptyList';
import ItemShadowNoIcons from '../../../UI/Items/components/ItemShadowNoIcons';
import SelectOutlinedBase from '../../../UI/Select/SelectOutlinedBase';
import { customBorder } from '../../../UI/variables';
import type { SelectList, SelectListEntities } from '../MultiSelectList';
import styles from '../MultiSelectList.module.scss';

const RelatedMultiSelectEdit: React.FC<SelectList> = (props) => {
  const { closeModal, isModalOpen, toggleModal } = useModal();
  const [currentValue, setCurrentValue] = useState<SelectListEntities[`${typeof model}`][]>(props.value ?? []);
  const { model } = props;
  const { t } = useTranslation();
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);

  const handleChange = (results: SelectListEntities[`${typeof model}`][]) => {
    setCurrentValue(results);
  };

  const handleDelete = (result: SelectListEntities[`${typeof model}`]) => {
    setCurrentValue(currentValue.filter((item) => item.id !== result.id));
  };

  const getDisplayValue = (result: any | SelectListEntities[`${typeof model}`]): string => {
    return props.displayFields?.map((field: string | string[]) => Array.isArray(field)
      ? result[field[0]][field[1]]
      : result[field]).join(' ') ?? '';
  };

  useEffect(() => {
    if (JSON.stringify(props.value) !== JSON.stringify(currentValue)) {
      props.onChange(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(props.value ?? []);
  }, []);

  useEffect(() => {
    setCurrentValue(props.value ?? []);
  }, [props.value]);

  const V2Component = useMemo(() => (props.color && props.color === 'primary') ? BigUp.Buttons.Primary : BigUp.Buttons.Secondary, [props.color]);

  return (
    <>
      <>
        {props.button === 'select' && (
          <SelectOutlinedBase label={props?.label} onClick={() => toggleModal()}>
            <BigUp.Label.Regular
              label={
                (props.value && props.value.length > 0)
                  ? (props.value.length === 1)
                    ? getDisplayValue(props.value[0])
                    : `${t('{amount} Selected', {
                      amount: props.value.length
                    })}`
                  : t(props?.label ?? t('Select'))
              }
              className={'ion-no-margin ion-margin-vertical'}
            />
            <IonIcon icon={chevronDown} slot={'end'} />
          </SelectOutlinedBase>
        )}
        {props.button === 'v2' && (
          <V2Component
            size={props.buttonSize ?? 'default'}
            title={t(props?.label ?? t('Select'))}
            onClick={() => toggleModal()}
            icon={{ icon: props.icon?.icon || filterSharp }}
          />
        )}
        {props.button === 'wide' && (
          <BigUp.Buttons.Regular
            size='small'
            expand={props.expand}
            title={t(props?.label ?? t('Select'))}
            onClick={() => toggleModal()}
            color={props.color || 'primary'}
          />
        )}
        {props.button === 'solid' && (
          <BigUp.Buttons.Responsive
            padding={20}
            expand='block'
            isDesktop={isDesktop}
            color={isDesktop ? 'secondary' : 'none'}
            title={t(props?.label ?? t('Select'))}
            onClick={() => toggleModal()}
            icon={{ icon: props.icon?.icon || filterSharp, color: 'secondary' }}
          />
        )}
        {props.button === 'round' && (
          <>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
              <BigUp.Label.Regular label={props?.label} className='ion-no-margin' />
              <ButtonRound
                icon={addSharp}
                backgroundColour={'none'}
                textColour={'primary'}
                clickHandler={toggleModal}
              />
            </div>
          </>
        )

        }
      </>
      {
        !props.hideSelected && currentValue.map((
          result: SelectListEntities[`${typeof model}`], index
        ) => {
          return (
            <ItemShadowNoIcons
              key={index}
              description={getDisplayValue(result)}
              subLabel={moment(result.created_at).format('YYYY-MM-DD')}
              deleteHandler={() => handleDelete(result)}
              hasBorder={true}
              borderColour={customBorder.borderColourGreen} />
          );
        })
      }

      <IonModal isOpen={isModalOpen} onIonModalDidDismiss={() => closeModal()} className={styles['multi-select-list-modal']}>
        {model.length === 0 && (
          <div className={styles['empty-list-container']}>
            <EmptyList message={t(`No {type} found`, { type: model })} title={t(`Create some {type} to get started.`, { type: model })} />
          </div>
        )}
        <RelatedMultiSelectList
          postSelected={props.postSelected}
          shouldSubmit={props.shouldSubmit}
          category={props.category}
          action='submit'
          toggleModal={toggleModal}
          modalTitle={props.modalTitle || t('Inspectors')}
          onChange={handleChange}
          value={currentValue}
          hideSelected={props.hideSelected}
          model={props.model}
          modelId={props.modelId}
          relationship={props.relationship}
          displayFields={props.displayFields}
          records={props.records}
          keyField={props.keyField}
          queryArgs={props.queryArgs}
          callbackResults={props.callbackResults}
          callbackName={props.callbackName}
        />
      </IonModal>
    </>
  );
};

export default RelatedMultiSelectEdit;
