import React from 'react';

import NoticeBoardContent from './NoticeBoardContent';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';
import useFlashNotice from '../../hooks/useFlashNotice';
import FlashNotice from '../Notice/FlashNotice';

const NoticeBoard: React.FC = () => {
  const { dummy } = useFlashNotice();
  return (
    <SideMenuV2Layout>
      <NoticeBoardContent />
      {dummy && <FlashNotice />}
    </SideMenuV2Layout>
  );
};

export default NoticeBoard;
