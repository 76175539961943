import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonLoading, IonModal, IonToolbar } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import BigUp from '..';
import { networking } from '../../../api/networking';
import i18n from '../../../i18n';
import storage from '../../../storage';

interface FilePreviewModalProps {
  fileContent: string;
  file: E2U.V1.Models.File;
  isModalOpen: boolean;
  closeModal: () => void;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ closeModal, file, isModalOpen }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const [photoContent, setPhotoContent] = useState<{
    [fileHash: string]: string
  }>({});

  const getFileContent = () => {
    const endpoint = `/api/v1/files/${file.id}/export?base64=true`;
    networking.get(endpoint)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.FileHistory>) => {
        setFileContent(`data:${response.headers['content-type']};base64,${response.data}`);
      })
      .catch(() => {
        Sentry.captureMessage('Failed to get file content');
        setFileContent('');
      });
  };

  const updatePhotoPreviews = () => {
    storage.get(`pending-${file.file_hash}`)
      .then((data) => {
        if (data && file.type === 'image') {
          setPhotoContent((prev) => ({
            ...prev,
            [`pending-${file.file_hash}`]: data
          }));
        }
      });
  };

  useEffect(() => {
    updatePhotoPreviews();
    getFileContent();
  }, [file]);

  return (
    <IonModal
      isOpen={isModalOpen}
      onIonModalDidDismiss={closeModal}
      style={{
        '--height': '80%',
        padding: ' 1rem'
      }}>
      <IonHeader>
        <IonToolbar>
          <BigUp.Title className='ion-padding' label={file.name} />
          <IonButtons slot="end" >
            <IonButton onClick={closeModal}>{t('Close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-no-padding'>
        <div>
          <TransformWrapper pinch={{ step: 5 }} ref={transformComponentRef} initialScale={1} >
            <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
              {file.type === 'pdf' && (
                <Document
                  loading={<IonLoading isOpen={true} />}
                  file={fileContent}
                  options={options}
                >
                  <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
                </Document>
              )}
              {file.type === 'image' && (
                <IonImg
                  src={`${photoContent[`pending-${file.file_hash}`]}`}
                  alt={i18n.t('File: {name}', 'File: {name}', { name: file?.name })}
                />
              )}
            </TransformComponent>
          </TransformWrapper>
        </div>
      </IonContent>
    </IonModal >
  );
};

export default FilePreviewModal;
