import { useTranslation } from 'react-i18next';

import type { EconomyMonitoringRadialChartProps } from './graphs';
import BigUp from '../../../../../components/UI';
import type { GraphProps } from '../../../../../components/UI/Graphs/RadialChartWidget';
import type { Indicator } from '../../../../../components/UI/Graphs/types/RadialChartWidget';
import formatNumber from '../../../../../tools/formatNumber';

const EconomyMonitoringRadialChart: React.FC<EconomyMonitoringRadialChartProps<GraphProps>> = ({
  data,
  incoming,
  outgoing,
  size = '100%',
  total,
}) => {
  const { t } = useTranslation();
  const indicators: Indicator[] = [
    {
      color: 'var(--ion-color-danger)',
      shape: 'circle',
      labelStart: t('Outgoing'),
      labelEnd: formatNumber(outgoing).toString(),
    },
    {
      color: 'var(--bigup-gradient-secondary)',
      shape: 'circle',
      labelStart: t('Incoming'),
      labelEnd: formatNumber(incoming).toString(),
    },
    {
      color: 'var(--ion-color-medium-shade)',
      shape: 'circle',
      labelStart: t('Total'),
      labelEnd: formatNumber(total).toString(),
    },
  ];

  return (
    <BigUp.Graphs.RadialChartWidget
      title="Overview"
      data={data}
      text={t('Incoming')}
      indicators={indicators}
      size={size || '100%'}
    />
  );
};

export default EconomyMonitoringRadialChart;
