import { IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import styles from './WorkUpValueLineChart.module.scss';
import BigUp from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import { defaultFormatter } from '../../../../../tools/formatNumber';

const WorkUpValueLineChart = () => {
  const { t } = useTranslation();
  const workupGraphData = useAppSelector(state => state.paymentSchedule.workupGraphData);
  const isLoadingWorkupGraphData = useAppSelector(state => state.loading.isLoading.workupGraphData);

  const graphData = (workupGraphData ?? []).map((item) => ({
    name: item.date,
    value: Math.round(item.total)
  }));

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <div style={{ overflowY: 'visible' }}>
      <h3 className='ion-margin-top ion-margin-start ion-margin-bottom'>
        {t('Work-up value')}
      </h3>
      <BigUp.Widget.ChartContainer
        dimensions={{
          height: 230,
          width: 800,

        }}
        padding={{
          top: 16,
          bottom: 0,
          right: 0,
        }}
      >
        <div className={styles['chart-container']}>
          {isLoadingWorkupGraphData
            ? <IonSpinner />
            : (
              <LineChart
                width={isDesktop ? 750 : 300}
                height={230}
                style={{ paddingTop: '4px', }}
                data={graphData}
                defaultShowTooltip
              >
                <Tooltip
                  formatter={defaultFormatter}
                  wrapperStyle={{
                    backgroundColor: 'var(--ion-color-light)',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  contentStyle={{
                    border: 'none'
                  }}
                />
                <XAxis
                  tick={<></>}
                  ticks={[]}
                  tickSize={0}
                  dataKey={'name'}
                />
                <YAxis
                  width={100}
                  tickFormatter={defaultFormatter}
                />
                <Line type="monotone" dataKey="value" stroke="gray" />
              </LineChart>
            )}
        </div>
      </BigUp.Widget.ChartContainer>
    </div>
  );
};

export default WorkUpValueLineChart;
