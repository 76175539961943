import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusColumn: React.FC<{
  value: null | number | string;
  labels?: {
    [key: string | number]: string;
  }
}> = ({
  labels = {
    unknown: 'Not stated',
    1: 'Yes',
    0: 'No',
  }, value
}) => {
  const { t } = useTranslation();
  const label = labels[value === null ? 'unknown' : value];

  return (
    <span>{t(label as string)}</span>
  );
};

export default StatusColumn;
