import { IonCol, IonContent, IonGrid, IonHeader, IonModal, IonRow, IonToolbar, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import SectionForm from './Form';
import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

interface CreateButtonProps {
  onSectionCreated: (section: any) => void;
  type: 'projects' | 'clients';
  id: string;
}

interface CreateSectionFormData {
  name: string;
  required_note: boolean;
}

const CreateButton: React.FC<CreateButtonProps> = ({ id, onSectionCreated, type }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openCreateModal = () => {
    setIsModalOpen(true);
  };

  const handleSectionCreated = (section: any) => {
    setIsModalOpen(false);
    onSectionCreated(section);
  };

  return (
    <>
      <BigUp.Buttons.Regular
        title={t('Create new section')}
        onClick={() => openCreateModal()}
        className={'ion-margin-top'}
        color={'primary'}
      />
      <IonModal isOpen={isModalOpen}>
        <IonHeader>
          <IonToolbar>
            <BigUp.Title
              label={t('Create section')}
              className={'ion-padding-horizontal'}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className={'ion-padding'}>
            <SectionForm
              onSectionCreated={handleSectionCreated}
              type={type}
              id={id}
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CreateButton;
