import { DateTime } from 'luxon';

import i18n from '../i18n';

export const formatToYYYYMMDD = (date: string | undefined) => {
  const formattedDate = date?.split('T')[0];
  return formattedDate;
};

export const formatToNiceDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const dateTime = DateTime.fromISO(date);
  if (!dateTime.isValid) {
    return '';
  }
  const now = DateTime.now();
  if (dateTime.hasSame(now, 'hour')) {
    return i18n.t('{minutes} minutes ago', { minutes: Math.floor(now.diff(dateTime, 'minutes').minutes) });
  }
  if (dateTime.toISODate() === now.toISODate()) {
    return i18n.t('{time}', { time: dateTime.toLocaleString(DateTime.TIME_SIMPLE) });
  }
  if (dateTime.minus({ days: 1 }).toISODate() === now.minus({ days: 1 }).toISODate()) {
    return i18n.t('Yesterday at {time}', { time: dateTime.toLocaleString(DateTime.TIME_SIMPLE) });
  }
  return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
};
