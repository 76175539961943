import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import JourneySelection from '../../components/ClientAccount/JourneySelection';
import DesktopWrapper from '../../components/DesktopWrapper';
import BigUp from '../../components/UI';

const IntroductionPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <DesktopWrapper width='600px' alignment={'center'} paddedPage={false}>
      <IonGrid>
        <IonRow>
          <IonCol className={'ion-margin-top'}>
            <BigUp.Label.Thick
              label={t('Are you an project admin looking to get started with BigUp? Look no further!')}/>
            <p className={'ion-no-margin ion-margin-bottom'}>{t('Get started using BigUp for your projects with our simple onboarding process. Choose between getting a call-up within 30 seconds, or supplying necessary information in your own pace.')}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <JourneySelection/>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopWrapper>
  );
};

export default IntroductionPage;
