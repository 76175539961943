import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions, IonItemSliding,
  IonList, IonModal, IonToolbar,
  useIonAlert, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronForward, pencil, trash } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import SectionForm from './Form';
import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';

interface SectionsSettingPageProps {
  type: 'projects' | 'clients';
  sections: any[];
  onSectionChange: () => void;
}

const SectionsList: React.FC<SectionsSettingPageProps> = ({ onSectionChange, sections, type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id } = useParams<{ client_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const ionListRef = React.useRef<HTMLIonListElement>(null);
  const [selectedSection, setSelectedSection] = useState<any>();

  const doDeleteSection = (section: any) => {
    networking.delete(`/api/v1/work_preparations/sections/${section.id}`)
      .then(() => {
        onSectionChange();
        toasters.success(t('Section successfully removed'));
        ionListRef.current?.closeSlidingItems();
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to remove section.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'deletingSection',
          value: false
        }));
      });
  };

  const handleSectionDeletion = (section: any) => {
    store.dispatch(setIsLoading({
      name: 'deletingSection',
      value: true
    }));
    presentAlert({
      header: t('Delete section'),
      message: t('Are you sure you want to delete this section?'),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          handler: () => doDeleteSection(section)
        }
      ]
    });
  };

  const doRenameSection = (section: any, newName: string) => {
    store.dispatch(setIsLoading({
      name: 'renamingSection',
      value: true
    }));
    networking.put(`/api/v1/work_preparations/sections/${section.id}`, {
      name: newName
    })
      .then(() => {
        onSectionChange();
        toasters.success(t('Section successfully renamed'));
        ionListRef.current?.closeSlidingItems();
      })
      .catch(() => {
        toasters.error(t('Failed to rename section. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'renamingSection',
          value: false
        }));
      });
  };

  const handleSectionEdit = (section: any) => {
    setSelectedSection(section);
    ionListRef.current?.closeSlidingItems();
  };

  const navigateSection = (section: any) => {
    if (type === 'projects') {
      router.push(`/tools/${id}/settings/work-preparation/section/${section.id}`);
    } else {
      router.push(`/clients/${id}/settings/work-preparation/section/${section.id}`);
    }
  };

  const handleSectionCreated = (section: any) => {
    setSelectedSection(undefined);
    onSectionChange();
  };

  return (
    <>
      {(sections && sections.length > 0)
        ? <IonList ref={ionListRef}>
          {sections.map((n, i) =>
            <IonItemSliding key={i}>
              <IonItem button={true} onClick={() => navigateSection(n)}>
                <BigUp.Label.Regular label={n.name} className={'ion-no-margin'} />
                <IonIcon icon={chevronForward} slot={'end'} />
              </IonItem>
              <IonItemOptions>
                <IonItemOption onClick={() => handleSectionEdit(n)}>
                  <IonIcon icon={pencil} slot={'end'} />
                </IonItemOption>
                <IonItemOption onClick={() => handleSectionDeletion(n)} color={'danger'}>
                  <IonIcon icon={trash} slot={'end'} />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          )}
        </IonList>
        : <EmptyList
          title={t('No sections found')}
          message={t('Create a new section to get started.')}
        />}
      <IonModal isOpen={!!selectedSection}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BigUp.Buttons.Regular
                title={t('Cancel')}
                onClick={() => setSelectedSection(undefined)}
              />
            </IonButtons>
            <BigUp.Title
              label={t('Edit section {name}', { name: selectedSection?.name })}
              className={'ion-padding-horizontal'}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className={'ion-padding'}>
            <SectionForm
              onSectionCreated={handleSectionCreated}
              type={type}
              id={id ?? ''}
              section={selectedSection}
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default SectionsList;
