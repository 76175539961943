import { IonPage, IonRouterOutlet, useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';

import SiteAccessRequestContentWrapper from './SiteAccessRequestContentWrapper';
import PageMenuHeader from '../../components/PageMenuHeader/PageMenuHeader';
import { useAppSelector } from '../../hooks';
import OnboardingStageOne from '../Onboarding/Components/form/Stages/StageOne/OnboardingStageOne';
import OnboardingStageThree from '../Onboarding/Components/form/Stages/StageThree/OnboardingStageThree';
import OnboardingStageTwo from '../Onboarding/Components/form/Stages/StageTwo/OnboardingStageTwo';
import OnboardingFormSummary from '../Onboarding/Components/form/Stages/summary/OnboardingFormSummary';

const SiteAccessRequestPages: React.FC = () => {
  const router = useIonRouter();
  const project = useAppSelector(state => state.onboarding.selectedProject);
  const { t } = useTranslation();

  useEffect(() => {
    if (!project) {
      router.push('/tools', 'none', 'replace');
    }
  }, [project?.id]);

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route
          exact
          path={'/site-access/details'}
          render={({ match }: any) => (
            <IonPage>
              <PageMenuHeader headerTitle={t('Personal details')} canGoBack goBackTo="/tools" />
              <SiteAccessRequestContentWrapper>
                <OnboardingStageOne />
              </SiteAccessRequestContentWrapper>
            </IonPage>
          )}
        />
        <Route
          exact
          path={'/site-access/certificates'}
          render={({ match }: any) => (
            <IonPage>
              <PageMenuHeader headerTitle={t('Certificates')} canGoBack goBackTo="/site-access/details" />
              <SiteAccessRequestContentWrapper>
                <OnboardingStageTwo />
              </SiteAccessRequestContentWrapper>
            </IonPage>
          )}
        />
        <Route
          exact
          path={'/site-access/project-documents'}
          render={({ match }: any) => (
            <IonPage>
              <PageMenuHeader headerTitle={t('Requirements')} canGoBack goBackTo="/site-access/certificates" />
              <SiteAccessRequestContentWrapper>
                <OnboardingStageThree />
              </SiteAccessRequestContentWrapper>
            </IonPage>
          )}
        />
        <Route
          exact
          path={'/site-access/summary'}
          render={({ match }: any) => (
            <IonPage>
              <PageMenuHeader headerTitle={t('Summary')} canGoBack goBackTo="/site-access/project-documents" />
              <SiteAccessRequestContentWrapper>
                <OnboardingFormSummary />
              </SiteAccessRequestContentWrapper>
            </IonPage>
          )} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default SiteAccessRequestPages;
