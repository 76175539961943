import type { SVG } from './types';

export const ProductionBudget = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '45'} height={height || '45'} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.06823 31.6337L4.49949 30.359C3.16574 27.3787 2.5989 24.1121 2.85059 20.8567C3.10229 17.6013 4.16452 14.4606 5.94053 11.7207C7.71653 8.98085 10.1499 6.72891 13.0188 5.17004C15.8878 3.61117 19.1012 2.79492 22.3663 2.79565H23.7641V23.2712L5.06823 31.6337Z" fill="url(#paint0_linear_1392_23296)" />
      <path d="M26.5587 5.8064V25.0833L8.26636 33.2667C9.89414 35.9147 12.173 38.1016 14.8858 39.619C17.5985 41.1364 20.6547 41.9337 23.763 41.9349C33.7828 41.9349 41.9347 33.783 41.9347 23.7632C41.9347 14.6939 35.2557 7.1553 26.5587 5.8064Z" fill="url(#paint1_linear_1392_23296)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23296" x1="13.2783" y1="2.79565" x2="13.2783" y2="31.6337" gradientUnits="userSpaceOnUse">
          <stop stop-color="#19E05E" />
          <stop offset="1" stop-color="#0062FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_1392_23296" x1="25.1005" y1="5.8064" x2="25.1005" y2="41.9349" gradientUnits="userSpaceOnUse">
          <stop stop-color="#19E05E" />
          <stop offset="1" stop-color="#0062FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
