
import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import type { SourceUrlProps } from '../../../components/UI/Table/interfaces';
import { useAppSelector } from '../../../hooks';
import { setShouldRefetchDocuments } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { formatToNiceDate } from '../../../tools/formatDates';
import DocumentsListTable from '../../Document/DocumentsListTable';

const DocumentCategoryPage: React.FC = () => {
  const [category, setCategory] = useState<E2U.V1.Models.Type>();
  const isLoadingType = useAppSelector(state => state.loading.isLoading.documentType);
  const { category_id } = useParams<{ uuid: string, category_id: string }>();
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const projectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const router = useIonRouter();
  const lastReloadTs = useAppSelector(state => state.file.lastReloadTs);
  const parsedCategoryId = useMemo(() => category_id === 'uncategorized' ? 'none' : category_id, [category_id]);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const fetchCategory = () => {
    if (!category_id || parsedCategoryId === 'none') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'documentType', value: true }));
    networking.get(`/api/v1/document_types/${category_id}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        setCategory(response.data.data);
      })
      .catch((error: E2U.V1.Response.Error) => {
        toasters.error(t('Document category not found'));
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'documentType', value: false }));
      });
  };

  const sourceUrl: SourceUrlProps = useMemo(() => {
    const filters = [{
      field: 'document_type',
      value: [parsedCategoryId]
    }];
    return {
      url: `/api/v1/projects/${projectId}/documents`,
      args: { filters: JSON.stringify(filters) }
    };
  }, [parsedCategoryId, projectId]);

  useEffect(() => {
    fetchCategory();
  }, [category_id]);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    store.dispatch(setShouldRefetchDocuments(undefined));
  }, [selectedProjectId]);

  return (
    <SideMenuV2Layout title={category?.name ?? t('Uncategorized')} showDocumentActionsButton={true}>
      {
        isLoadingType
          ? (
            <SkeletonTextThreeLines />
          )
          : (
            <div className='ion-padding'>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    {isDesktop && <IonGrid>
                      <IonRow>
                        <IonCol>
                          <BigUp.Title label={category?.name} />
                        </IonCol>
                      </IonRow>
                    </IonGrid>}
                    <BigUp.Table
                      timestamp={lastReloadTs}
                      sourceUrl={ sourceUrl }
                      columns={
                        [{
                          key: 'name',
                          label: t('Name'),
                          alignment: 'left',
                          sizes: {
                            xs: '7',
                            sm: '7',
                            md: '8',
                            lg: '9',
                            xl: '9'
                          },
                          sortable: true
                        }, {
                          key: 'updated_at',
                          label: t('Last updated'),
                          alignment: 'right',
                          sizes: {
                            xs: '5',
                            sm: '5',
                            md: '4',
                            lg: '3',
                            xl: '3'
                          },
                          sortable: true
                        }]
                      }
                      reducers={{
                        updated_at: (value: string) => formatToNiceDate(value),
                      }}
                      callbacks={{
                        onRowClick: (row: E2U.V1.Models.Document) => router.push(`/tools/${projectId}/documents/${row.id}`),
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )
      }
    </SideMenuV2Layout>
  );
};

export default DocumentCategoryPage;
