import React, { useEffect } from 'react';
import type { Accept } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import styles from './DragAndDrop.module.scss';
interface DragAndDropProps {
  onFilesSelect: (files: File[]) => void;
  multiple?: boolean;
  files?: File[];
  children: React.ReactNode;
  acceptedFileTypes?: Accept;
  uploadOnDrop?: (files: File[]) => React.ReactNode;
}

const DragAndDrop: React.FC<DragAndDropProps> = (
  { acceptedFileTypes = undefined, children, multiple = true, onFilesSelect, uploadOnDrop }: DragAndDropProps
) => {
  const { acceptedFiles, getInputProps, getRootProps, inputRef } = useDropzone({
    multiple,
    maxSize: 1073741824,
    accept: acceptedFileTypes
  });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onFilesSelect(acceptedFiles);
      acceptedFiles.length = 0;
      acceptedFiles.splice(0, acceptedFiles.length);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if ((acceptedFiles && acceptedFiles.length > 0) && uploadOnDrop) {
      uploadOnDrop && uploadOnDrop(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div className={styles['drag-and-drop--container']} {...getRootProps()} >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DragAndDrop;
