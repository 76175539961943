import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import { InputSkeleton } from './Input';
import type { InputProps } from './Input.interface';
import styles from './Input.module.scss';

const OutlinedInput: React.FC<InputProps<any>> = (props) => {
  const methods = useFormContext();

  const errorStyle = useMemo(() => {
    const hasError = !!methods.formState.errors[props.register];

    return {
      '--border-color': hasError ? 'var(--ion-color-danger)' : '',
      '--background': hasError ? 'var(--ion-color-danger-shade)' : '',
    };
  }, [methods.formState.errors[props.register], props.register]);

  return (
    <>
      {props.customLabel && (
        <BigUp.Label.Regular
          label={`${props.customLabel} ${props.validation.required ? '*' : ''}`}
          className={`ion-no-margin ${styles['outlined-input-label']}`}
          position='stacked'
          {...(methods.formState.errors[props.register] ? { color: 'danger' } : {})}
        />
      )}
      <InputSkeleton
        showErrorBadge={props.showErrorBadge || false}
        mode='md'
        fill='outline'
        style={errorStyle}
        className={`${styles['outlined-input']}`}
        {...props}
      />
    </>
  );
};

export default OutlinedInput;
