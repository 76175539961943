import type { E2U } from '@techlove/easy2use-typings';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../../components/UI';
import { useTableStyles } from '../../../../components/UI/Table';
import type { ColourVariables } from '../../../../components/UI/variables';

const Attested: React.FC<{ attributes: E2U.V1.Models.ControlOfExecution }> = (props) => {
  const { t } = useTranslation();

  const colorMap: Map<boolean, ColourVariables> = new Map([
    [props.attributes.requires_supervisor && !props.attributes.has_been_attested, 'var(--ion-color-danger)'],
    [props.attributes.has_been_attested && !props.attributes.requires_supervisor, 'var(--ion-color-success)'],
    [!props.attributes.has_been_attested && !props.attributes.requires_supervisor, 'var(--ion-color-medium)']
  ]);

  const labelMap: Map<boolean, string> = new Map([
    [props.attributes.requires_supervisor && !props.attributes.has_been_attested, t('Supervision required')],
    [props.attributes.has_been_attested && !props.attributes.requires_supervisor, t('Attested')],
    [!props.attributes.has_been_attested && !props.attributes.requires_supervisor, t('Unattested')]
  ]);

  const label = labelMap.get(true) || '';
  const color = colorMap.get(true) || 'var(--ion-color-medium)';

  const styles = useTableStyles();
  return (
    <div className={styles.activity_code_column}>
      <BigUp.Label.Indicator
        label={{
          gap: '0px',
          start: { label },
          end: { label: undefined }
        }}
        indicator={{
          color,
          shape: 'circle'
        }}
      />
    </div>
  );
};

export default Attested;
