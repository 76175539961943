import { useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useParams } from 'react-router';

import i18n from '../../../../../i18n';
import { setActivityCodes } from '../../../../../reducers/ActivityCodes';
import store from '../../../../../store';
import formatNumber from '../../../../../tools/formatNumber';

const ChangesColumn: React.FC<{
    value: number;
    editable: boolean;
    attributes: E2U.V1.Models.EconomyMonitoringRow;
}> = (props) => {
  const { attributes: row, editable, value } = props;

  const router = useIonRouter();
  const { uuid } = useParams<{ uuid: string }>();

  return row && (
    <span onClick={() => {
      if (editable) {
        store.dispatch(setActivityCodes([row.activity_code]));
        router.push(`/financials/${uuid}/changes/create`);
      }
    }}
    >
      {
        value === 0
          ? editable
            ? '+ ' + i18n.t('add')
            : value
          : formatNumber(value)
      }
    </span>
  );
};

export default ChangesColumn;
