import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { columns } from './columns';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import formatNumber from '../../../tools/formatNumber';
import shareUrl from '../../../tools/shareUrl';
import ActivityCodeColumn from '../Subpanels/Precalculations/Columns/ActivityCodeColumn';

const MonitoringReport: React.FC = () => {
  const [lastTimestamp, setLastTimestamp] = useState(new Date());
  const [selectedMonitoring, setSelectedMonitoring] = useState<E2U.V1.Models.EconomyMonitoring & { document_name?: string, document_description?: string }>();

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { monitoring_uuid, uuid } = useParams<{ uuid: string | undefined, monitoring_uuid: string | undefined }>();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { t } = useTranslation();

  const fetchMonitoringReport = () => {
    if (typeof monitoring_uuid === 'undefined' || monitoring_uuid === '') {
      toasters.createToast({
        message: t('No monitoring found'),
        background: 'var(--ion-color-light)',
      }, 'error');
      history.push('/financials');
      return;
    }
    networking.get(`/api/v1/economy_monitorings/${monitoring_uuid}`)
      .then((resSelectedMonitoring) => {
        setSelectedMonitoring(resSelectedMonitoring.data.data);
      });
  };

  const deleteHandler = (id: string | undefined) => {
    presentAlert({
      header: t('Are you sure you want to delete this report?'),
      message: selectedMonitoring?.document_name,
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          role: 'confirm',
          handler: () => deleteRow(id)
        },
      ],
    });
  };

  const deleteRow = (id: string | undefined) => {
    toasters
      .promiseToast(
        networking.delete(`/api/v1/economy_monitorings/${id}`),
        {
          success: {
            message: t('Successfully deleted report.')
          },
          pending: {
            message: t('Deleting report...')
          },
          error: {
            message: t("Couldn't delete report")
          }
        }
      )
      .finally(() => {
        history.goBack();
      });
  };

  useEffect(() => {
    setLastTimestamp(new Date());
    fetchMonitoringReport();
  }, [monitoring_uuid]);

  return (
    <React.Fragment>
      <DesktopWrapper>
        <IonGrid className='ion-no-padding'>
          <IonRow
            className={'ion-no-padding ion-justify-content-space-end ion-align-items-center'}
            style={{
              ...isDesktop && { width: '100%' },
              borderRadius: '8px',
              marginBottom: '16px',
              margin: '8px'
            }}
          >
            <IonCol size='6' sizeMd='6' sizeSm='6' sizeXs='12' style={{ paddingLeft: '5px', borderRadius: '8px' }}>
              {(typeof selectedMonitoring?.document_name !== 'undefined') && <h3 className="ion-no-margin">
                {selectedMonitoring?.document_name}
              </h3>}
            </IonCol>
            <IonCol size='3' sizeMd='3' sizeSm='3' sizeXs='6'>
              <BigUp.Buttons.Regular
                expand='block'
                color='secondary'
                title={t('Share')}
                onClick={() => shareUrl(
                  undefined,
                  `${t('Here is the link to monitoring')} ${selectedMonitoring?.document_name}: `,
                  `${process.env.REACT_APP_SHARE_BASE_URL}/${uuid}/monitoring/${monitoring_uuid}`,
                  `${t('Let me know if there is something you are wondering about.')}`,
                  'share'
                )}
              />
            </IonCol>

            <IonCol size={'3'} sizeSm='3' sizeXs='6' >
              <BigUp.Buttons.Regular
                expand='block'
                color='danger'
                title={t('Delete')}
                onClick={() => deleteHandler(selectedMonitoring?.id)}
              />
            </IonCol>

          </IonRow>
          <BigUp.Table
            columns={[
              {
                key: 'name',
                label: t('Name'),
                sortable: true,
                sizes: {
                  xl: '2',
                  xs: '6'
                },
                alignment: 'left',
                body: <ActivityCodeColumn
                  value=''
                  attributes={{} as E2U.V1.Models.EconomyMonitoringRow} />,
              },
              ...columns,
            ]}
            reducers={{
              production_budget_total: (value: number) => formatNumber(value),
              changes_total: (value: number) => formatNumber(value),
              deviation_total: (value: number) => formatNumber(value),
              final_forecast_total: (value: number) => formatNumber(value),
              accrude_cost_total: (value: number) => formatNumber(value),
              workup_rate: (value: number) => value ? `${value * 100}%` : '0%',
            }
            }
            sourceUrl={{
              url: `/api/v1/economy_monitorings/${monitoring_uuid}/rows`,
              args: {}
            }}
            timestamp={lastTimestamp}
          />
        </IonGrid>
      </DesktopWrapper>
    </React.Fragment>
  );
};
export default MonitoringReport;
