import { IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { checkmark, close, informationCircleOutline, warning, warningOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './Alert.module.scss';

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  children: React.ReactNode;
}

const Alert: React.FC<AlertProps> = (props) => {
  const { children, type } = props;
  const { t } = useTranslation();

  const icon = {
    success: { icon: checkmark, color: 'success' },
    error: { icon: warningOutline, color: 'danger' },
    warning: { icon: warning, color: 'warning' },
    info: { icon: informationCircleOutline, color: 'primary' },
  }[type];

  const alertClassName = `${styles.alert} ${styles[`alert--${type}`]}`;

  return (
    <section className={alertClassName}>
      <IonRow className='ion-align-items-center ion-justify-content-evenly'>
        <IonCol size='1' className='ion-margin-end'>
          <IonIcon aria-label={t('Error')} className={styles.alert__icon} color={icon.color} icon={icon.icon} size='large' />
        </IonCol>
        <IonCol size='10' className='ion-no-padding'>
          <IonText>
            <span className={styles.alert__content}>
              {children}
            </span>
          </IonText>
        </IonCol>
      </IonRow>
    </section>
  );
};

export default Alert;
