import { useIonAlert } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

interface CreateButtonProps {
  onNodeCreated: (node: any) => void;
  type: 'projects' | 'clients';
  id: string;
}
const CreateButton: React.FC<CreateButtonProps> = ({ id, onNodeCreated, type }) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  const createNode = (name: string) => {
    store.dispatch(setIsLoading({
      name: 'creatingNode',
      value: true
    }));
    const idColumn = type === 'projects' ? 'project_id' : 'client_id';
    networking.post(`/api/v1/work_preparations/${type === 'projects' ? 'project' : 'client'}_nodes`, {
      name,
      [idColumn]: id
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onNodeCreated(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to create template. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'creatingNode',
          value: false
        }));
      });
  };

  const openCreateModal = () => {
    presentAlert({
      header: t('Create template'),
      message: t('Enter the name for the template.'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: t('Enter name'),
          value: '',
        }
      ],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Create'),
          handler: (data: { name: string }) => {
            createNode(data.name);
          }
        }
      ]
    });
  };

  return (
    <BigUp.Buttons.Regular
      title={t('Create new template')}
      onClick={() => openCreateModal()}
      className={'ion-margin-top'}
      color={'primary'}
    />
  );
};

export default CreateButton;
