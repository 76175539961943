
import { IonFooter, isPlatform } from '@ionic/react';
import { cellularOutline, folderOpen, timeOutline } from 'ionicons/icons';
import { useState } from 'react';
import type { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import DeviceFiles from './DeviceFiles';
import Documents from './Documents';
import Latest from './Latest';
import BigUp from '../../..';
import bigUpFolder from '../../../../../components/icons/bigUpFolder/bigupFolder.svg';
import bigUpFolderActive from '../../../../../components/icons/bigUpFolder/bigupFolderActive.svg';
import clock from '../../../../../components/icons/clock/clock.svg';
import clockActive from '../../../../../components/icons/clock/clockActive.svg';
import folder from '../../../../../components/icons/folder/folder.svg';
import folderActive from '../../../../../components/icons/folder/folderActive.svg';
import type { CreateButtonType } from '../../../Tabs/TabToolbar/Tabs';

type TabValues = 'latest' | 'bigup' | 'my-device';
type Platform = 'desktop' | 'mobile';

const Toolbar: React.FC<{ nav: RefObject<HTMLIonNavElement> }> = ({ nav }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('latest');
  const { t } = useTranslation();

  const isDesktop: Platform = isPlatform('desktop')
    ? 'desktop'
    : 'mobile';

  const activeBackground = isDesktop === 'desktop'
    ? 'var(--ion-color-light)'
    : 'var(--ion-color-medium-contrast)';

  const createTabButton: CreateButtonType<TabValues> = (key, label, icon, component) => ({
    label,
    icon: {
      icon,
    },
    onClick: async () => {
      setActiveTab(key);
      await nav.current?.setRoot(component, { nav: nav.current });
    },
    isActive: activeTab === key,
    activeColor: {
      background: activeBackground,
      textColour: 'var(--ion-color-secondary)',
    },
  });

  const tabButtons = [
    createTabButton('latest', t('Latest'),
      activeTab === 'latest' ? clockActive : clock, Latest),
    createTabButton('bigup', t('BigUps unit'),
      activeTab === 'bigup' ? bigUpFolderActive : bigUpFolder, Documents),
    createTabButton('my-device', t('My device'),
      activeTab === 'my-device' ? folderActive : folder
      , DeviceFiles),
  ];

  const handleToolbarPosition = (mode: Platform) => {
    if (mode === 'desktop') {
      return (
        <BigUp.Tabs.TabToolbar buttons={tabButtons} />
      );
    }
    return (
      <IonFooter className='ion-no-border'>
        <BigUp.Tabs.TabToolbar buttons={tabButtons} />
      </IonFooter>
    );
  };

  return handleToolbarPosition(isDesktop);
};

export default Toolbar;
