import { IonCol, IonGrid, IonItem, IonRow, IonText, useIonViewWillEnter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useCallback, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { certificateInputFields, typeSelectOptions } from './inputs';
import styles from './UploadCertificateForm.module.scss';
import { networking } from '../../../api/networking';
import DesktopWrapper from '../../../components/DesktopWrapper';
import BigUp from '../../../components/UI';
import EmptyList from '../../../components/UI/EmptyList';
import ReturnChevronText from '../../../components/UI/Return/ReturnChevronText';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import useFocusNextInput from '../../../hooks/useFocusNextInput';
import useModal from '../../../hooks/useModal';
import i18n from '../../../i18n';

const UploadCertificateForm: React.FC = () => {
  const { certificateId } = useParams<{ certificateId: string }>();
  const filePreviewModal = useModal();
  const [selectedFile, setSelectedFile] = useState<any>();
  // const [certificateHasExpiration, setCertificateHasExpiration] = useState<boolean>(true);
  const user = useAppSelector(state => state.authentication.user);
  const { focusNextInput, formRef } = useFocusNextInput();
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();
  const photos = cameraProps.getUploadedPhotos();
  const files = fileProps.getUploadedFiles();
  const fileLimit = 1;
  const uploads = [...(files || []), ...(photos || [])];
  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      active: false,
      type: 'hot_work',
      certificate_name: '',
      issuing_organization: '',
      issue_date: '',
      expiration_date: null,
      validitiy_period: '',
      files: [],
    },
  });
  const active = methods.watch('active');

  const handleNextInput = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === 'Enter') {
      focusNextInput(e.target as HTMLInputElement);
    }
  };

  const fetchCertificate = useCallback(() => {
    if (!certificateId) return;

    networking.get(`/api/v1/certificates/${certificateId}?with=files`)
      .then((response) => {
        const certificate = response.data.data;
        methods.setValue('name', certificate.name);
        methods.setValue('issuing_organization', certificate.issuing_organization);
        methods.setValue('issue_date', certificate.issue_date);
        methods.setValue('expiration_date', certificate.expiration_date);
        methods.setValue('type', certificate.type);
        methods.setValue('files', certificate.files);
        methods.setValue('active', certificate.active);
      });
  }, [certificateId, methods]);

  const handleSave: SubmitHandler<FieldValues> = (data: FieldValues) => {
    if (data.active) {
      data.expiration_date = null;
    }
    const request = certificateId
      ? networking.put(`/api/v1/certificates/${certificateId}`, data)
      : networking.post('/api/v1/certificates', {
        ...data,
        user_id: user?.id,
      });
    request
      .then((response) => {
        toasters.createToast({
          message: 'Certificate saved',
          background: 'var(--ion-color-light)',
        }, 'success');

        const finishedPromises: Promise<any>[] = [];
        finishedPromises.push(
          Promise.allSettled([
            cameraProps.uploadSelectedPhotos('/api/v1/certificates', response.data.data.id || ''),
            fileProps.uploadSelectedFiles('/api/v1/certificates', response.data.data.id || ''),
          ])
        );
      });
  };

  const handlePreviewToggle = (action: 'open' | 'close', file?: E2U.V1.Models.File) => {
    action === 'open' ? setSelectedFile(file) : setSelectedFile(null);
    action === 'open' ? filePreviewModal.openModal() : filePreviewModal.closeModal();
  };

  const handleRemoveFile = (file: E2U.V1.Models.File) => {
    if (certificateId) {
      networking.delete(`/api/v1/files/${file.id}`).then(() => {
        fetchCertificate();
      });
    } else {
      if (file.type === 'image') {
        cameraProps.removePhoto(file);
      } else {
        fileProps.removeFile(file);
      }
    }
  };

  useIonViewWillEnter(() => {
    fetchCertificate();

    methods.watch('active', (isActive: boolean) => {
      if (isActive) {
        methods.setValue('expiration_date', null);
      }
    });
  }, [certificateId, methods]);

  const certificateFiles = methods.getValues('files');
  const today = new Date();
  const hundredYearsAgo = new Date(today.setFullYear(today.getFullYear() - 100));
  const minDate = hundredYearsAgo.toISOString().split('T')[0];

  return (
    <DesktopWrapper width='500px'>
      <div style={{ paddingTop: '18px' }}>
        <ReturnChevronText direction='back' text={i18n.t('Settings')} color='none' fill={'none'} routeTo='/profile/settings' />
      </div>
      <IonGrid className='ion-padding-start'>
        <IonRow>
          <IonCol>
            <BigUp.Title label={certificateId ? i18n.t('Edit certificate') : i18n.t('Upload certificate')} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(handleSave)} ref={formRef} className='ion-padding-horizontal'>
          <IonGrid className='ion-padding-sm' >
            {certificateInputFields.map((field, index) => (
              <IonRow key={index}>
                <IonCol className='ion-no-padding ion-padding-top'>
                  <BigUp.OutlinedInput
                    onKeyUp={handleNextInput}
                    id={field.register}
                    type='text'
                    inputMode='text'
                    customLabel={field.label}
                    placeholder={field.placeholder}
                    register={field.register}
                    name={field.register}
                    validation={field.validation}
                  />
                </IonCol>
              </IonRow>
            ))}
            <IonRow>
              <IonCol className={`ion-no-padding ion-padding-top`}>
                <BigUp.SelectOutlined
                  data={typeSelectOptions}
                  handleSelection={(value: any) => methods.setValue('type', value)}
                  selected={methods.getValues('type')}
                  placeholder={i18n.t('Select certificate type')}
                  label={`${i18n.t('Certificate type')} *`}
                  register='type'
                  validation={{ required: 'A type is required' }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={`ion-no-padding ion-padding-top`}>
                <BigUp.Datepicker.DatepickerOutlined
                  min={minDate}
                  label={`${i18n.t('Issue date')} *`}
                  datetimeId='issue-date'
                  dateRegister='issue_date'
                  presentation='date'
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className={`ion-no-padding ion-padding-top`}>
                <BigUp.Datepicker.DatepickerOutlined
                  disabled={methods.getValues('active') === true}
                  label={`${i18n.t('Expiration date')} *`}
                  datetimeId='expiration-date'
                  dateRegister='expiration_date'
                  presentation='date'
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines='none' className='ion-no-padding' style={{ '--inner-padding-end': '0' }} type='button'>
                  <BigUp.Checkbox
                    labelPlacement='start'
                    checked={active}
                    justify='space-between'
                    handleCheckbox={(e: any) => {
                      methods.setValue('active', e);
                      methods.setValue('expiration_date', null);
                    }}
                  >
                    {i18n.t('No expiration date')}
                  </BigUp.Checkbox>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid className='ion-padding-vertical'>
            <IonRow>
              <IonCol size='12'>
                <BigUp.Label.Thick label={i18n.t('Files')} />
              </IonCol>
              <IonCol size='12'>
                <IonText>
                  <span className='ion-no-margin'>
                    {i18n.t('Take a photo of your physical certificate or upload a digital certificate.')}
                  </span>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='6' className={styles['upload-certificate-button-col']}>
                <BigUp.ActionSheets.MediaUpload
                  cameraProps={cameraProps}
                  fileProps={fileProps}
                  disabled={certificateFiles.length || uploads.length >= fileLimit}
                />
              </IonCol>
              <IonCol size='auto'>
                <BigUp.Label.Regular label={`${i18n.t('Uploaded files ')} ${uploads.length || 0}/${fileLimit}`} />
              </IonCol>
            </IonRow>
          </IonGrid>

          {(certificateFiles.length === 0 && uploads.length === 0) && (
            <EmptyList
              title={i18n.t('No file has been uploaded')}
              message={i18n.t('Upload a file to get started')}
            />
          )}

          {(certificateFiles.length > 0
            ? certificateFiles
            : uploads).map((file: any, index: number) => (
            <BigUp.Item.FileItem
              key={index}
              icon={{
                onClick: () => handleRemoveFile(file)
              }}
              onPreview={() => handlePreviewToggle('open', file)}
              name={file.filename || file.name}
              description={file.filename || file.name}
              source={file}
            />
          ))}

          {selectedFile && (
            <BigUp.Modal.FilePreviewModal
              fileContent={selectedFile.file}
              file={selectedFile}
              isModalOpen={filePreviewModal.isModalOpen}
              closeModal={() => handlePreviewToggle('close')}
            />
          )}

          <IonGrid className='ion-padding-vertical'>
            <IonRow className='ion-justify-content-center'>
              <IonCol size='8'>
                <BigUp.Buttons.Regular
                  shape='round'
                  expand='block'
                  disabled={!methods.formState.isValid}
                  type='submit'
                  title={i18n.t('Save certificates')}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </DesktopWrapper>
  );
};

export default UploadCertificateForm;
