import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import type { ColourVariables } from '../variables';

export interface ReturnInterfaceExtraInfo {
  subpanel?: string;
  subpanelCategory?: string;
  subpanelCategoryUuid?: string;
}

interface ReturnInterface {
  color: string;
  text: string;
  fill: any;
  routeTo?: string;
  extraInfo?: ReturnInterfaceExtraInfo;
  textColour?: ColourVariables;
  direction?: 'back' | 'forward';
}

const ReturnChevronText: React.FC<ReturnInterface> = ({ color, direction, extraInfo, fill, routeTo, text, textColour }) => {
  const history = useIonRouter();

  return (
    <IonButton
      mode='ios'
      color={color}
      onClick={() => routeTo ? history.push(routeTo, direction) : history.goBack()}
      fill={fill}>
      <IonIcon icon={chevronBack} style={{ color: textColour || 'black' }} />
      <p className='ion-no-margin' style={{ color: textColour || 'black' }}>
        {text}
      </p>
    </IonButton>
  );
};

export default ReturnChevronText;
