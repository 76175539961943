import {
  IonCol,
  IonRow,
} from '@ionic/react';
import classNames from 'classnames';
import {
  chevronForward,
  lockClosed,
  lockOpen,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks';
import { setSubmenuLocked } from '../../../../../reducers/navigation';
import storage from '../../../../../storage';
import store from '../../../../../store';
import BigUp from '../../../index';
import styles from '../SideMenuV2.module.scss';

interface SideMenuV2SubmenuProps {
  isOpen?: boolean
}

const LockSubmenu: React.FC<SideMenuV2SubmenuProps> = (props) => {
  const { t } = useTranslation();
  const menuIsPinned = useAppSelector((state) => state.navigation.submenuLocked);

  const togglePinnedMenu = () => {
    storage.set('pinnedMenu', !menuIsPinned)
      .then(() => {
        store.dispatch(setSubmenuLocked(!menuIsPinned));
      });
  };

  useEffect(() => {
    storage.get('pinnedMenu')
      .then((value) => {
        store.dispatch(setSubmenuLocked(value));
      });
  }, []);

  return (
    <IonRow
      className={classNames(
        'ion-align-items-center',
        styles['side-menu--sub--pin'],
        (props.isOpen || menuIsPinned) ? 'ion-justify-content-end' : 'ion-justify-content-center'
      )}
      onClick={togglePinnedMenu}
      role={'button'}
    >
      <IonCol size={'auto'} title={menuIsPinned ? t('Detach menu') : t('Lock menu')}>
        <BigUp.Buttons.Icon
          id={'pin-menu'}
          icon={ {
            icon: menuIsPinned
              ? lockClosed
              : (
                props.isOpen
                  ? lockOpen
                  : chevronForward
              )
          }}
          padding={16}
          color={'light'}
        />
      </IonCol>
    </IonRow>
  );
};

export default LockSubmenu;
