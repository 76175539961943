import React from 'react';
import { Redirect } from 'react-router';

import SkeletonTextThreeLines from '../../components/SkeletonComponents/SkeletonTextThreeLines';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../hooks';
import useAcl from '../../hooks/useAcl';

const DefaultRoute: React.FC = () => {
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);
  const user = useAppSelector(state => state.authentication.user);
  const { isOnboarded } = useAcl();

  return (
    <SideMenuV2Layout paddedPage={true}>
      {
        isLoadingUser
          ? (
            <SkeletonTextThreeLines/>
          )
          : (
            user
              ? (
                <Redirect to={ isOnboarded() ? '/tools' : '/introduction' }/>
              )
              : (
                <Redirect to="/login"/>
              )
          )
      }
    </SideMenuV2Layout>
  );
};

export default DefaultRoute;
