import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Contact from './ContactTile';
import type { EmergencyContact } from './types';
import { networking } from '../../api/networking';
import RelatedMultiSelectEdit from '../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../components/UI';
import { useAppSelector } from '../../hooks';

const EmergencyContactsPage: React.FC = () => {
  const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContact[]>([]);
  const [lastReload, setLastReload] = useState<number>();
  const [deleteAlert] = useIonAlert();
  const { t } = useTranslation();

  const project = useAppSelector((state) => state.project.selectedProject);

  const handleDelete = (emergencyContactId: string) => {
    return networking.delete(`/api/v1/emergency_contacts/${emergencyContactId}`)
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const onSubmit: SubmitHandler<FieldValues> = (currentSelectedUsers) => {
    const originalUserIds = emergencyContacts.map((contact) => contact.user.id);
    const currentUserIds = currentSelectedUsers.map((user: E2U.V1.Models.User) => user.id);
    const usersToAdd = currentSelectedUsers.filter((user: E2U.V1.Models.User) => !originalUserIds.includes(user.id));
    const usersToRemove = originalUserIds.filter(contactId => !currentUserIds.includes(contactId));

    const addUserRequests = usersToAdd.map((user: E2U.V1.Models.User) => {
      return networking.post(`/api/v1/projects/${project?.id}/emergency_contacts`, {
        user_id: user.id
      }).catch((error) => Sentry.captureException(error));
    });

    const removeUserRequets = usersToRemove.map(contactId => {
      const emergencyContact = emergencyContacts.find(ec => ec.user_id === contactId);
      if (emergencyContact) {
        return handleDelete(emergencyContact.id);
      }

      return false;
    }).filter((req) => req !== false);

    Promise.all([...addUserRequests, ...removeUserRequets]).then(() => setLastReload(Date.now()));
  };

  const confirmDelete = (id: string, name: string) => {
    deleteAlert({
      header: t('Remove {user} as an emergency contact?', 'Remove {user} as an emergency contact?', { user: name }),
      message: t('You can always add them back later.'),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
        },
        {
          text: t('Delete'),
          handler: () => {
            handleDelete(id).then(() => setLastReload(Date.now()));
          }
        }
      ]
    });
  };

  useEffect(() => {
    setLastReload(Date.now());
  }, []);

  return (
    <>
      <IonGrid>
        <IonRow className='ion-padding ion-margin-vertical'>
          <IonCol>
            <BigUp.Title label={t('Emergency Contacts')} />
          </IonCol>
        </IonRow>
        <IonRow className='ion-justify-content-end'>
          <IonCol size='auto'>
            <RelatedMultiSelectEdit
              action='submit'
              button='v2'
              buttonSize='small'
              displayFields={['name']}
              hideSelected
              icon={{ icon: add }}
              label={t('Add emergency contacts')}
              modalTitle={t('Select emergency contacts')}
              model='projects'
              modelId={project?.id}
              relationship={project?.id ? 'users' : undefined}
              onChange={() => {
              }}
              postSelected={(data: E2U.V1.Models.User[]) => onSubmit(data)}
              value={emergencyContacts.map((contact) => contact.user)}
            />
          </IonCol>
        </IonRow>

        <BigUp.Table
          sourceUrl={{ url: `/api/v1/projects/${project?.id}/emergency_contacts`, args: { per_page: '9999' } }}
          timestamp={lastReload}
          columns={[
            {
              key: 'id',
              label: t('Name'),
              sortable: true,
              alignment: 'left',
              body: <Contact attributes={{} as EmergencyContact} />,
              sizes: { xs: '6', sm: '5', md: '4', lg: '3', xl: '3', },
            },
            {
              key: 'phone_number',
              label: t('Phone number'),
              sortable: true,
              alignment: 'left',
              sizes: { xs: '5', sm: '5', md: '4', lg: '3', xl: '3', },
            },
            {
              key: 'email',
              alignment: 'left',
              label: t('Email'),
              sortable: true,
              sizes: { xs: '5', sm: '5', md: '4', lg: '3', xl: '3', },
            },
          ]}
          callbacks={{
            onRowClick: (row) => {
              confirmDelete(row.id, row.name);
            },
            onRenderComplete: (rows) => setEmergencyContacts(rows)
          }}
        />
      </IonGrid>
    </>
  );
};

export default EmergencyContactsPage;
