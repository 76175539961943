import { IonModal } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Route, Switch, matchPath, useHistory, useLocation, useParams } from 'react-router';

import ModalFormContainer from './Components/containers/ModalFormContainer';
import modalStyle from './Components/containers/styles/ModalContainers.module.scss';
import OnboardingStageOne from './Components/form/Stages/StageOne/OnboardingStageOne';
import OnboardingStageThree from './Components/form/Stages/StageThree/OnboardingStageThree';
import OnboardingStageTwo from './Components/form/Stages/StageTwo/OnboardingStageTwo';
import OnboardingFormSummary from './Components/form/Stages/summary/OnboardingFormSummary';
import ModalFormContainerHeader from './Components/header/FormHeader';
import type { OnboardingInterface } from './interfaces/Onboarding.interface';
import { networking } from '../../api/networking';
import toasters from '../../components/Toasts/Toasts';
import { matchOnboarding, onboardingStages } from '../../constants/onboarding';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { resetAllStages, setOnboardingStageOne, setOnboardingStageThree, setOnboardingStageTwo } from '../../reducers/onboarding';

const Onboarding: React.FC<OnboardingInterface> = (props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { onboardingUuid } = useParams<{ onboardingUuid: string | undefined }>();

  const store = useStore();
  const location = useLocation();
  const history = useHistory();

  const selectedProject = useParams<{ onboardingUuid: string }>().onboardingUuid;

  const isLoading = useAppSelector((state) => state.loading.isLoading.accessRequest);

  const processAccessRequestResponse = (response: E2U.V1.Models.ProjectAccessRequest) => {
    const personInformation = response.personal_information;
    if (personInformation) {
      store.dispatch(setOnboardingStageOne(
        {
          personal_number: personInformation.personal_number,
          first_name: personInformation.first_name,
          last_name: personInformation.last_name,
          company_name: personInformation.company_name,
          organization_number: personInformation.organization_number
        }
      ));
    }
    store.dispatch(setOnboardingStageTwo(
      {
        hot_work: response.hot_work,
        hot_work_certificates: response.hot_work_certificates,
        work_from_lift: response.work_from_lift,
        work_from_lift_certificates: response.work_from_lift_certificates,
        heavy_lifting: response.heavy_lifting,
        heavy_lifting_certificates: response.heavy_lifting_certificates,
        other_certificates: response.other_certificates,
        confirm_stage_two: true
      }
    ));

    const accessRequirements = response.access_requirements;

    if (accessRequirements) {
      const projectFiles: any = {};
      accessRequirements.forEach(
        (accessRequirement) => (projectFiles[accessRequirement.id ?? 0] = {
          has_accepted: accessRequirement.pivot.completed
        })
      );

      store.dispatch(setOnboardingStageThree(
        { project_files: projectFiles }
      ));
    }
  };

  const fetchSubmittedFormData = async () => {
    store.dispatch(setIsLoading({ name: 'accessRequest', value: true }));

    networking.get(`/api/v1/projects/${onboardingUuid}/access_requests/own`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.ProjectAccessRequest>) => {
        processAccessRequestResponse(response.data.data);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'accessRequest', value: false }));
      });
  };

  const closeModal = () => {
    store.dispatch(resetAllStages(undefined));
    setShowModal(false);

    if (props.dismiss) {
      props.dismiss();
    }
  };

  const formComponents = [
    {
      url: 'information',
      stage: 1,
      component: (
        <OnboardingStageOne toggleOnboarding={closeModal} dismiss={props.dismiss} path={props.path} />
      ),
      componentID: 'OnboardingStageOne'
    },
    {
      url: 'certificates',
      stage: 2,
      component: (
        <OnboardingStageTwo path={props.path} />
      ),
      componentID: 'OnboardingStageTwo'
    },
    {
      url: 'project-files',
      stage: 3,
      component: (<OnboardingStageThree path={props.path} />),
      componentID: 'OnboardingStageThree'
    },
    {
      url: 'summary',
      stage: 4,
      component: (<OnboardingFormSummary />),
      componentID: 'OnboardingFormSummary'
    }
  ];

  useEffect(() => {
    if (!showModal) {
      history.push(history.location.pathname.replace(RegExp(matchOnboarding), '')
      );
    }
  }, [showModal]);

  const renderComponents = formComponents.map((c, componentID) => {
    return <Route path={`${props.path}/${c.url}`} key={componentID}>{c.component}</Route>;
  });

  useEffect(() => {
    fetchSubmittedFormData();
    return () => {
      closeModal();
    };
  }, []);

  return (
    <IonModal
      isOpen={showModal}
      className={modalStyle['onboarding-process-modal']}
      onIonModalDidDismiss={() => closeModal()}
    >
      <ModalFormContainerHeader toggleOnboarding={closeModal} onboardingRouting={formComponents} />
      <ModalFormContainer toggleOnboarding={props.toggleOnboarding} dismiss={props.dismiss}>
        {isLoading
          ? (null)
          : (
            <Switch>
              {renderComponents}
              <Route path={`${props.path}`}>
                {formComponents[0].component}
              </Route>
            </Switch>
          )
        }
      </ModalFormContainer>
    </IonModal>
  );
};

export default Onboarding;
