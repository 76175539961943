import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, isPlatform } from '@ionic/react';
import { informationCircleSharp } from 'ionicons/icons';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import PasswordChange from './PasswordChange';
import UpdateUser from './UpdateUser';
import styles from './UserSettingsStyle.module.scss';
import UserCertificateList from '../../../../components/UI/List/UserCertificateList';
import ListTitle from '../../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import PermissionsList from '../PermissionsList';

const UserSettingsForm: React.FC = () => {
  const user = useAppSelector(state => state.authentication);

  const userSettings = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: user.user
  });

  const passwordUpdate = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      password_confirmation: ''
    }
  });

  return (
    <div className={'ion-margin-top'}>
      <FormProvider {...userSettings}>
        <UpdateUser />
      </FormProvider>

      <UserCertificateList />

      <FormProvider {...passwordUpdate}>
        <PasswordChange />
      </FormProvider>
      <>
        {((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) && (
          <>
            <div className='ion-margin'>
              <ListTitle label={i18n.t('App permissions')} />
            </div>
            <PermissionsList />
          </>
        )}
      </>
      <div className={styles['user-setting-form-container']}>
        <IonGrid>
          <IonRow className='ion-justify-content-center'>
            <IonCol size='12' className='ion-margin-bottom'>
              <ListTitle label={i18n.t('Need support?')} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <IonItem className='ion-no-padding ion-no-margin' detail={true}>
                <IonIcon icon={informationCircleSharp} size='large' color='medium' />
                <a href={`/support`} style={{ textDecoration: 'none' }}>
                  <IonLabel className='ion-no-margin ion-margin-start ' color={'dark'}>
                    <h2>{i18n.t('Go to our support page')}</h2>
                  </IonLabel>
                </a>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default UserSettingsForm;
