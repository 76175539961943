import {
  IonContent,
  IonGrid,
  IonMenu,
} from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import LockSubmenu from './LockSubmenu';
import { useAppSelector } from '../../../../../hooks';
import styles from '../SideMenuV2.module.scss';

interface SideMenuV2SubmenuProps {
  submenuVisible: boolean,
  setSubmenuVisible: (visible: boolean) => void;
  hidden: boolean;
}

const ToolsSubmenu: React.FC<SideMenuV2SubmenuProps> = (props) => {
  const menuIsPinned = useAppSelector((state) => state.navigation.submenuLocked);
  const { setSubmenuVisible, submenuVisible } = props;

  return (
    <IonMenu
      className={
        classNames(
          styles['side-menu--sub--root'],
          props.hidden ? styles['side-menu--sub--hidden'] : '',
          (submenuVisible || menuIsPinned)
            ? styles['side-menu--sub--show']
            : '',
        )
      }
      contentId="main"
      onMouseEnter={() => setSubmenuVisible(true)}
      onMouseLeave={() => setSubmenuVisible(false)}
    >
      <IonContent
        className={ classNames(styles['side-menu--sub--bg'], 'ion-no-padding') }
      >
        <IonGrid
          className={
            classNames(
              styles['side-menu--sub'],
            )
          }
        >
          {props.children}
          <LockSubmenu isOpen={submenuVisible} />
        </IonGrid>
      </IonContent>
    </IonMenu>
  );
};

export default ToolsSubmenu;
