import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import styles from './InputModal.module.scss';
import type { InputModalProps } from './interface';
import checkmark from '../../icons/checkmark.svg';

const InputModal: React.FC<InputModalProps> = (props) => {
  const { t } = useTranslation();

  const {
    button: { disabled, loading, onClick, title: buttonTitle, type },
    input,
    modal: { description, isOpen, onDismiss, successMessage, title },
    onSubmit,
  } = props;

  return (
    <IonModal isOpen={isOpen} onIonModalDidDismiss={onDismiss} className={styles['input-modal']}>
      <IonToolbar mode='ios' color={'light'} >
        <IonTitle>{title}</IonTitle>
        <IonButtons slot='end'>
          <IonButton onClick={onDismiss}>
            <IonIcon slot="icon-only" icon={close} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent scrollY={false}>
        <form onSubmit={onSubmit}>
          <IonGrid>
            <IonRow className='ion-justify-content-center'>
              <IonCol size='10' className=' ion-text-center'>
                <BigUp.Typography.Description>
                  <IonText>
                    {description}
                  </IonText>
                </BigUp.Typography.Description>
              </IonCol>
            </IonRow>
            {!successMessage && (
              <IonRow>
                <IonCol className='ion-padding'>
                  <BigUp.OutlinedInput {...input} defaultValue={props.defaultValue} />
                </IonCol>
              </IonRow>
            )}
            <IonRow className='ion-justify-content-center'>
              <IonCol size='auto'>
                {successMessage
                  ? <IonIcon icon={checkmark} className={successMessage ? styles['success-icon'] : ''} />
                  : (
                    <BigUp.Buttons.Primary
                      loading={loading}
                      onClick={onClick}
                      {...type !== undefined && {
                        type: 'submit'
                      }}
                      title={buttonTitle || t('Save')}
                      disabled={disabled}
                    />
                  )
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default InputModal;
