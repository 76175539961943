import { IonGrid, IonIcon, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import FileList from './FileList';
import styles from './Settings.module.scss';
import { networking } from '../../../../api/networking';
import attachmentsSVG from '../../../../components/icons/attachments.svg';
import BigUp from '../../../../components/UI';
import DragAndDrop from '../../../../components/UI/DragAnddrop/DragAndDrop';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useFileUpload from '../../../../hooks/useFileUpload';
import i18n from '../../../../i18n';

const SiteAccessRequestsSettings = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [accessRequirements, setAccessRequirements] = useState<
    E2U.V1.Models.ProjectAccessRequirement[]
  >([]);
  const {
    filesToUpload,
    form: fileForm,
    handleFileSelection,
    onlyUploadFiles,
    resetFilesToUpload
  } = useFileUpload();
  const files = fileForm.watch('files', []) as E2U.V1.Models.File[];
  const [alert] = useIonAlert();
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const fileUploadLabel = isDesktop ? t('Drop your files here to upload') : t('Click here to add files to upload');

  const getRequirements = () => {
    networking
      .get(`/api/v1/projects/${uuid}/access_requirements`)
      .then((response) => {
        setAccessRequirements(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.createToast(
          {
            message: i18n.t('Failed to get project requirements'),
            background: 'var(--ion-color-light)',
          },
          'error'
        );
      });
  };

  const getRequirementFiles = () => {
    return accessRequirements.map((requirement) => ({
      name: requirement.file?.name ?? '',
      file_hash: requirement.file?.file_hash,
      id: requirement.file?.id,
      type: requirement.file?.type,
      export_url: requirement.file?.export_url,
    }));
  };

  const uploadRequirements = () => {
    const filePromises = onlyUploadFiles();
    Promise.all(filePromises)
      .then((results) => {
        resetFilesToUpload();
        const files = results.map((result) => result.data.data);
        const promises = files.map((file) =>
          networking.post(`/api/v1/project_access_requirements`, {
            project_id: uuid,
            file: file.id,
            name: file.name,
            file_id: file.id,
          })
            .catch((error) => {
              Sentry.captureException(error);
            })
        );

        const allUploads = Promise.allSettled(promises);

        toasters
          .promiseToast(
            allUploads,
            {
              pending: {
                message: t('Uploading files'),
                background: 'var(--ion-color-light)',
              },
              success: {
                message: t('All files uploaded successfully'),
                background: 'var(--ion-color-light)',
                textColour: 'var(--ion-color-dark)',
              },
              error: {
                message: t('Some files could not be uploaded'),
                background: 'var(--ion-color-light)',
                textColour: 'var(--ion-color-dark)',
              },
            }
          )
          .then(() => {
            getRequirements();
          })
          .catch((error) => {
            Sentry.captureException(error);
          });
      });
  };

  const deleteRequirement = (id: string | undefined) => {
    networking
      .delete(`/api/v1/project_access_requirements/${id}`)
      .then(() => {
        getRequirements();
        toasters.createToast(
          {
            message: i18n.t('File deleted successfully'),
            background: 'var(--ion-color-light)',
          },
          'success'
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        toasters.createToast(
          {
            message: i18n.t('Failed to delete file'),
            background: 'var(--ion-color-light)',
          },
          'error'
        );
      });
  };

  const confirmDelete = (id: string | undefined) => {
    alert({
      header: i18n.t('Delete file'),
      message: i18n.t('Are you sure you want to delete this file?'),
      buttons: [
        {
          text: i18n.t('Cancel'),
          role: 'cancel',
        },
        {
          text: i18n.t('Delete'),
          handler: () => deleteRequirement(id),
        },
      ],
    });
  };

  useEffect(() => {
    getRequirements();
  }, []);

  useEffect(() => {
    if (filesToUpload.length > 0) {
      uploadRequirements();
    }
  }, [filesToUpload]);

  useEffect(() => {
    if (accessRequirements.length > 0) {
      fileForm.setValue('files', getRequirementFiles() as any);
    }
  }, [accessRequirements]);

  return (
    <IonGrid className={styles.settings}>
      <div className={styles['settings--project-documents-header']}>
        <BigUp.Label.V2Thick label={t('Project documents')} color='medium' />
      </div>

      <div className={styles['settings--project-documents-container']}>
        <DragAndDrop
          uploadOnDrop={uploadRequirements}
          onFilesSelect={(files) => {
            handleFileSelection(files);
          }}
        >
          <div className={styles['file-upload-container']}>
            <div className={styles['file-upload-icon']}>
              <IonIcon
                icon={attachmentsSVG}
                className={styles['file-upload-icon-inner']}
                color='primary'
              />
            </div>
            <BigUp.Label.Regular className='ion-no-margin' label={fileUploadLabel} color={'medium'} />
          </div>
        </DragAndDrop>
        <FileList
          photos={[]}
          subLabel={t('Project document')}
          files={files}
          handleDeleteRequest={(file) => {
            const id = accessRequirements.find(
              (requirement) =>
                requirement.file?.file_hash ===
                file.file_hash
            )?.id;
            confirmDelete(id);
          }}
        />
      </div>
    </IonGrid>
  );
};

export default SiteAccessRequestsSettings;
