import type { E2U } from '@techlove/easy2use-typings';
import { useTranslation } from 'react-i18next';

import tileStyles from './Tiles.module.scss';
import { useTableStyles } from '../../../../components/UI/Table';

const Description: React.FC<{ attributes: E2U.V1.Models.ControlOfExecution }> = (props) => {
  const styles = useTableStyles();
  const { t } = useTranslation();
  return (
    <div className={styles.activity_code_column}>
      <div className={styles.activity_code_column__title}>{props.attributes.name}</div>
      <div className={`${styles.activity_code_column__description} ${tileStyles['text-ellipsis']}`}>
        {props.attributes.description !== null || '' ? `${props.attributes.description}` : t('No description')}
      </div>
    </div>
  );
};

export default Description;
