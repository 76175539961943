import type { Photo } from '@capacitor/camera';
import { IonCol, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';

import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import FileSelectionButton from '../../../pages/Tools/ControlOfExecution/AddFilesModal/FileSelectionButton';
import TakePhotoButton from '../../../pages/Tools/ControlOfExecution/AddFilesModal/TakePhotoButton';
import RelatedMultiSelectEdit from '../../Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';

interface AmendmentActionsProps {
  activityCodes: E2U.V1.Models.ActivityCode[],
  setSelectedActivityCodes: React.Dispatch<React.SetStateAction<E2U.V1.Models.ActivityCode[]>>,
  uploadFromCamera: (files: Photo[]) => void,
  handleFileSelection: (files: File[]) => void
}

export const AmendmentActions = (
  { activityCodes, handleFileSelection, setSelectedActivityCodes, uploadFromCamera }: AmendmentActionsProps
) => {
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  return (
    <>
      <IonRow>
        <IonCol sizeSm={'3'}>
          <TakePhotoButton
            expand={'block'}
            onTakePhotoClick={(files) => uploadFromCamera(files)} />
        </IonCol>
        <IonCol sizeSm={'3'}>
          <FileSelectionButton
            expand={'block'}
            onFilesSelect={(files) => handleFileSelection(files)} />
        </IonCol>
        <IonCol sizeLg='6' className={'ion-text-right ion-margin-right'}>
          <RelatedMultiSelectEdit
            {...isDesktop && { button: 'solid' }}
            records={activityCodes}
            model="activity_codes"
            modalTitle={i18n.t('Select act.codes')}
            {...isDesktop && { label: i18n.t('Select act.codes') }}
            onChange={setSelectedActivityCodes}
            displayFields={['code', 'name']}
            hideSelected
          />
        </IonCol>
      </IonRow>
    </>
  );
};
