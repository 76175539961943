import {
  IonAvatar,
  IonBackButton, IonCol,
  IonGrid,
  IonHeader, IonItem, IonModal,
  IonRow, IonToolbar
} from '@ionic/react';
import classNames from 'classnames';
import { chevronBack } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { paths } from './navLinks';
import backgroundImage from '../../assets/images/Economy_desktop.jpg';
import { useAppSelector } from '../../hooks';
import useFetchProjects from '../../hooks/useFetchProjects';
import useModal from '../../hooks/useModal';
import useProfilePicture from '../../hooks/useProfilePicture';
import modalStyle from '../../pages/Onboarding/Components/containers/styles/ModalContainers.module.scss';
import PersonalOverview from '../../pages/Onboarding/Components/PersonalOverview/PersonalOverview';
import { setCurrentTab } from '../../reducers/navigation';
import store from '../../store';
import ProjectSelector from '../ProjectSelector';
import { BigUp } from '../UI';
import styles from './PageMenuHeader.module.scss';

const PageMenuHeader: React.FC<{ headerTitle?: string, canGoBack?: boolean, goBackTo?: string }> = ({
  canGoBack,
  goBackTo,
  headerTitle
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const fetchProjects = useFetchProjects();
  const profilePicture = useProfilePicture();
  const project = useAppSelector(store => store.project.selectedProject);
  const { closeModal, isModalOpen, openModal, toggleModal } = useModal();
  const currentTab: string | undefined = useAppSelector(state => state.navigation.currentTab);
  const isDesktop: boolean = useAppSelector(state => state.desktopView.isDesktop);

  const handleUrlChange = () => {
    const tabData = project?.id && paths.find((t) => t.pattern.some((p) => matchPath(location.pathname, {
      path: p,
      exact: p === '/'
    }))
    );
    if (tabData) {
      store.dispatch(setCurrentTab(tabData.tab !== '' ? tabData.tab : ''));
    } else {
      store.dispatch(setCurrentTab(location.pathname.split('/')[1] || ''));
    }
  };
  const checkRoute = ['/tools', '/projects', '/notice-board', '/profile'].some((path) => location.pathname === path);

  const shouldNotRenderProjectSelector = () => {
    return (
      (headerTitle && headerTitle !== '') ||
      isDesktop ||
      location.pathname.match(/\/settings\/\w+/) ||
            location.pathname.match(/\/precalculation\/\w+/) ||
            location.pathname.match(/\/site-access\/\w+/) ||
            location.pathname.match('/financials/precalculation?') ||
            location.pathname.match('/financials/precalculations') ||
            location.pathname.match('/financials/precalculations?') ||
            location.pathname.match('/profile?') ||
            location.pathname.match('/projects?') ||
            location.pathname.match('/tools/create-project?')
    );
  };

  const shouldNotRenderAvatar = () => {
    return (
      location.pathname.match(/\/site-access\/\w+/)
    );
  };

  useEffect(() => {
    if (typeof location.pathname !== 'undefined') {
      handleUrlChange();
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <IonHeader className='ion-no-border'>
        <IonToolbar style={{
          backgroundImage: `var(--ion-color-primary-gradient), url(${backgroundImage})`,
          backgroundSize: 'cover',
          objectFit: 'cover',
          backgroundPosition: 'center',
          boxShadow: 'var(--ion-color-light-box-shadow)'
        }}>
          <IonGrid className={classNames('ion-no-padding', styles['page-menu-header--grid'])}>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <IonCol className='ion-text-left' size='auto'>
                {(!isDesktop)
                  ? !checkRoute &&
                                    (
                                      <IonBackButton style={{ marginLeft: '8px', marginBottom: '8px' }} text={''}
                                        color={'light'} icon={chevronBack} defaultHref={'/tools'}/>
                                    )
                  : (
                    <Link to={'/'}>
                      <img src={BigUp.Logo} alt={t('BigUp logo')} className={styles['page-menu-header--logo']} />
                    </Link>
                  )
                }
              </IonCol>
              {shouldNotRenderProjectSelector()
                ? <IonCol>
                  <BigUp.Title color={'light'} style={{
                    paddingLeft: checkRoute ? '16px' : 0,
                    marginLeft: !checkRoute ? '16px' : '0px',
                    fontSize: '18px'
                  }} label={headerTitle}/>
                </IonCol>
                : <IonCol sizeXs='8' sizeMd='4'><ProjectSelector currentTab={currentTab}/></IonCol>
              }
              <IonCol size='auto' class='ion-text-end'>
                {!shouldNotRenderAvatar() && (
                  <IonItem color="none" lines="none" button detail={false}
                    onClick={() => openModal()}>
                    <IonAvatar>
                      <img src={profilePicture} alt={`${t('Users profile picture')}`}/>
                    </IonAvatar>
                  </IonItem>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonModal
        isOpen={isModalOpen}
        className={modalStyle['personaloverview-modal']}
        onIonModalDidDismiss={closeModal}
      >
        <PersonalOverview togglePersonalOverview={toggleModal}/>
      </IonModal>
    </>
  );
};

export default PageMenuHeader;
