import { IonIcon, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronDown, chevronForward, documentAttach, image } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from './config';
import FileButtons from './FileButtons';
import styles from './FileList.module.scss';
import { networking } from '../../api/networking';
import i18n from '../../i18n';
import FileSelectionButton from '../../pages/Tools/ControlOfExecution/AddFilesModal/FileSelectionButton';
import shareUrl from '../../tools/shareUrl';
import FadeInContainer from '../Animated/FadeInContainer';
import BigUp from '../UI';

interface FileListProps {
  files: E2U.V1.Models.File[];
  photos: E2U.V1.Models.File[];
  is_editing?: boolean;
  handleFileNameChanged?: (file: E2U.V1.Models.File, newFileName: string) => void;
}

const HistoricalFileList: React.FC<FileListProps> = (props) => {
  const { files, is_editing, photos } = props;
  const { t } = useTranslation();
  const [showHistoricalUploads, setShowHistoricalUploads] = useState<{ [id: string]: { visible: boolean, history: E2U.V1.Models.FileHistory[] } }>({});

  const getFileHistory = (id: string) => {
    networking.get(`/api/v1/files/${id}/history`)
      .then((response) => {
        setShowHistoricalUploads((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            history: response.data.data.records || []
          }
        }));
      });
  };

  const toggleHistoricalUploads = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    setShowHistoricalUploads((prev) => {
      const isVisible = !prev[id]?.visible;
      if (isVisible && !prev[id]?.history) {
        getFileHistory(id);
      }
      return {
        ...prev,
        [id]: {
          ...prev[id],
          visible: isVisible,
          history: prev[id]?.history || []
        }
      };
    });
  };

  const downloadFile = (file: E2U.V1.Models.File | E2U.V1.Models.FileHistory, is_file_history = false) => {
    const checkFileHistoryEndpoint = `${is_file_history ? `file_histories/${file.id}/share` : `files/${file.id}/share`}`;

    if (file.id === undefined) {
      const downloadLink = document.createElement('a');
      downloadLink.download = file.name;
      downloadLink.click();
      return;
    }

    networking.get(`/api/v1/${checkFileHistoryEndpoint}`)
      .then((response) => {
        window.location.assign(response.data.data.url);
      });
  };

  const shareFile = (file: E2U.V1.Models.FileHistory | E2U.V1.Models.File, is_file_history = false) => {
    const checkFileHistoryEndpoint = `${is_file_history ? `file_histories/${file.id}/share` : `files/${file.id}/share`}`;
    networking.get(`/api/v1/${checkFileHistoryEndpoint}`)
      .then((response) => {
        shareUrl(
          undefined,
          `${t('Here is the link to {type} {name}', 'Here is the link to {type} {name}', {
            type: file.type === 'image' ? 'image' : 'file', name: file.name
          })}`,
          `${response.data.data.url}`,
          t(`Let me know if there is something you are wondering about.`),
          'share'
        );
      });
  };

  const replaceFile: (file: File, id: E2U.V1.Models.File['id']) => Promise<E2U.V1.Response.Success<E2U.V1.Models.FileHistory & {
    filename: E2U.V1.Models.FileHistory['filename']
  }>> = (file: File, id: E2U.V1.Models.FileHistory['id']) => {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise<
      E2U.V1.Response.Success<E2U.V1.Models.FileHistory & {
        filename: E2U.V1.Models.FileHistory['filename']
      }>>((resolve, reject) =>
        networking.post(`/api/v1/files/${id}/replace`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((res: E2U.V1.Response.Success<E2U.V1.Models.FileHistory & { filename: string }>) => {
            resolve(res);
            getFileHistory(id as string);
            props.handleFileNameChanged &&
              props.handleFileNameChanged(res.data.data as any, res.data.data.name);
            config.fileToasts('success', t('File replaced')).createToast();
          })
          .catch((error: E2U.V1.Response.Error) => {
            Sentry.captureException(error);
            reject(error);
            config.fileToasts('error', t('Failed to replace file')).createToast();
          }));
  };

  const fileList = files.concat(photos);
  const memoizedFileList = React.useMemo(() => fileList, [fileList]);

  return (
    <IonList inset={false}>
      {memoizedFileList.map((file: E2U.V1.Models.File) => {
        const fileId = file.id || '';
        const fileState = showHistoricalUploads[fileId] || { visible: false, history: [] };
        return (
          <React.Fragment key={fileId}>
            <IonItem
              onClick={(e) => toggleHistoricalUploads(e, fileId)}
              style={{
                borderLeft: fileState.visible && '5px solid var(--ion-color-primary)'
              }}
              button={true}
              lines='full'
              detail={false}
              className={`ion-no-padding ${styles['item-inner-padding-end']}`}
            >
              <IonIcon slot="start" icon={file.type === 'image' ? image : documentAttach} color='medium' />
              <BigUp.Label.Regular color={'dark'} className='ion-no-margin ion-margin-end' label={file.name} />
              <BigUp.Label.Regular
                slot='end'
                color={'medium'}
                label={
                  `${file.number_of_revisions === null
                    ? '1'
                    : file.number_of_revisions + 1
                  }`
                }
              />
              <IonIcon color='medium' slot="end" icon={fileState.visible ? chevronDown : chevronForward} />
            </IonItem>
            {fileState.visible && (
              <FadeInContainer isVisible={fileState.visible}>
                <IonListHeader className='ion-no-padding ion-margin-top'>
                  <BigUp.Title label={i18n.t('Latest version')} />
                </IonListHeader>

                <IonItem detail={false} color={'light'} className={`ion-no-padding ${styles['item-inner-padding-end']}`}>
                  <BigUp.Label.Regular color={'dark'} className='ion-no-margin ion-margin-end ion-text-nowrap' label={file.name} />
                  <FileButtons
                    file={file}
                    edit={is_editing || false}
                    downloadFile={() => downloadFile(file)}
                    shareFile={() => shareFile(file)}
                    handleFileNameChanged={props.handleFileNameChanged}
                  />
                </IonItem>

                <IonListHeader className='ion-no-padding ion-margin-top'>
                  <BigUp.Title label={i18n.t('Previous versions')} />
                </IonListHeader>
                {fileState.history && fileState.history.length === 0 && (
                  <IonItem detail={false} color={'light'} className={`ion-no-padding ion-margin-bottom ${styles['item-inner-padding-end']}`}>
                    <BigUp.Label.Regular color={'dark'} className='ion-no-margin ion-margin-end' label={i18n.t('No previous versions')} />
                  </IonItem>
                )}
                {fileState.history &&
                  fileState.history.map((historicalFile, i) => {
                    const uploadDate = DateTime.fromISO(historicalFile.created_at ?? '').toLocaleString(DateTime.DATETIME_MED);
                    return (
                      <React.Fragment key={i}>
                        <IonItem detail={false} color={'light'} className={`ion-no-padding ion-margin-bottom ${styles['item-inner-padding-end']}`}>
                          <IonLabel className='ion-no-margin'>
                            <BigUp.Label.Thick className='ion-no-margin ion-margin-end' label={uploadDate} />
                            <BigUp.Label.Regular color={'dark'} className='ion-no-margin ion-margin-end ion-text-nowrap' label={historicalFile?.name} />
                          </IonLabel>
                          <FileButtons
                            refetch={() => getFileHistory(fileId)}
                            file={file}
                            historicalFile={historicalFile}
                            edit={is_editing || false}
                            downloadFile={() => downloadFile(historicalFile, true)}
                            shareFile={() => shareFile(historicalFile, true)}
                            handleFileNameChanged={props.handleFileNameChanged}
                          />
                        </IonItem>
                      </React.Fragment>
                    );
                  })}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FileSelectionButton
                    responsiveButton={false}
                    responsiveButtonColour='medium'
                    responsiveButtonFill='clear'
                    label={i18n.t('Add new version')}
                    multiple={false}
                    onFilesSelect={(files: File[]) => replaceFile(files[0], file.id)}
                  />
                </div>
              </FadeInContainer>
            )}
          </React.Fragment>
        );
      })}
    </IonList>
  );
};

export default HistoricalFileList;
