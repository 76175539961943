import { IonDatetimeButton, IonItem, IonModal } from '@ionic/react';
import type { ComponentProps } from 'react';

import BigUp from '..';
import styles from './DatepickerOutlined.module.scss';
import DateTimeContent from '../Dates/DatetimeContent';

interface DatepickerOutlinedProps {
  label: string;
  datetimeId: string;
  dateRegister: string;
  presentation?: ComponentProps<typeof DateTimeContent>['presentation'];
  disabled?: boolean;
  min?: string | any;
  max?: string | any;
}

const DatepickerOutlined: React.FC<DatepickerOutlinedProps> = ({ dateRegister, datetimeId, disabled = false, label, max, min, presentation = 'date' }) => {
  return (
    <div className={styles['date-picker-outlined-container']}>
      <BigUp.Label.Thick style={{ fontWeight: 600 }} label={label} className={`ion-no-margin`} />
      <IonItem className='ion-no-padding' lines='none'>
        <IonDatetimeButton disabled={disabled} datetime={datetimeId} className={`ion-no-margin`} />
      </IonItem>
      <IonModal keepContentsMounted={true}>
        <DateTimeContent min={min} max={max} presentation={presentation} id={datetimeId} dateRegister={dateRegister} />
      </IonModal>
    </div>
  );
};

export default DatepickerOutlined;
