import type { Photo } from '@capacitor/camera';
import { IonCol, IonGrid, IonIcon, IonLoading, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';

import styles from './DocumentForm.module.scss';
import { networking } from '../../../api/networking';
import attachmentsSVG from '../../../components/icons/attachments.svg';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import toasters from '../../../components/Toasts/Toasts';
import { BigUp } from '../../../components/UI';
import DragAndDrop from '../../../components/UI/DragAnddrop/DragAndDrop';
import TextareaOutlined from '../../../components/UI/Textareas/TextareaOutlined';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';
import { setShouldRefetchDocuments } from '../../../reducers/file';
import { setIsLoading } from '../../../reducers/loading';
import { arraysAreEqual } from '../../../tools/compareArrays';
import { formatFilename } from '../../../tools/formatFilename';
import FileList from '../../Tools/SiteAccessRequests/Settings/FileList';
import TagForm from '../Tags/TagForm';

interface UploadDocumentFormProps {
  handleDocumentSaved?: (document: E2U.V1.Models.Document) => void
  handleCloseModal?: () => void;
}
const DocumentForm: React.FC<UploadDocumentFormProps> = (props: UploadDocumentFormProps) => {
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const store = useStore();
  const { t } = useTranslation();

  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();

  const files = fileProps.getUploadedFiles() ?? [];
  const photos = cameraProps.getUploadedPhotos() ?? [];

  const prevFilesRef = useRef(files);
  const prevPhotosRef = useRef(photos);
  const uploadListCheck = files.concat(photos).length <= 0;
  const filesChanged = !arraysAreEqual(prevFilesRef.current, files);
  const photosChanged = !arraysAreEqual(prevPhotosRef.current, photos);
  const isLoadingDocument: boolean = useAppSelector((state) => state.loading.isLoading.document);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const history = useHistory();
  const document: (E2U.V1.Models.Document & {
    activity_codes?: E2U.V1.Models.ActivityCode[]
  }) | undefined = useAppSelector((state) => state.file.selectedFile);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const fileUploadLabel = useMemo(() => {
    return isDesktop ? t('Drop your files here to upload') : t('Click here to add files to upload');
  }, [isDesktop]);

  const methods = useForm<E2U.V1.Models.Document & {
    local_tags: E2U.V1.Models.TagCategory[],
    local_activity_codes: E2U.V1.Models.ActivityCode[]
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: undefined,
      description: undefined,
      local_tags: [],
      local_activity_codes: [],
    }
  });
  const { acceptedFiles } = useDropzone({ multiple: false, maxSize: 1073741824 });
  const activityCodes = methods.watch('local_activity_codes') as string[];

  const triggerIfUploads = async () => {
    if (files.length > 0 || photos.length > 0) {
      await methods.trigger();
    }
  };

  const handleFileSelectUpdate = () => {
    if (acceptedFiles && acceptedFiles[0]) {
      return (
        methods.setValue('name', formatFilename(acceptedFiles[0].name))
      );
    }
  };

  const handleDisableButtons = (button: 'upload' | 'submit') => {
    if (button === 'upload') {
      return ((methods.formState.isSubmitting || isLoadingDocument));
    }
    if (button === 'submit') {
      return (methods.formState.isSubmitting || isLoadingDocument) ||
        (!methods.formState.isValid || Object.keys(validationErrors).length > 0);
    }
  };

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'uploadingFile', value: true }));
    if (selectedProject && selectedProject.id) {
      data.project_id = selectedProject.id;
    }
    if (data.name === '') {
      delete data.name;
    }
    if (data.description === '') {
      delete data.description;
    }
    const selectedActivityCodes: E2U.V1.Models.ActivityCode[] = data.local_activity_codes ?? [];
    const selectedTags: E2U.V1.Models.Tag[] = data.local_tags.flatMap((tagCategory: E2U.V1.Models.TagCategory) => tagCategory.tags ?? []);
    delete data.local_activity_codes;
    delete data.local_tags;
    const request = (document && document.id)
      ? networking.put(`/api/v1/documents/${document.id}`, data)
      : networking.post('/api/v1/documents', data);
    return toasters.promise(new Promise((resolve, reject) => {
      request.then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Document>) => {
          Promise.allSettled([
            fileProps.uploadSelectedFiles('/api/v1/documents', response.data?.data?.id ?? ''),
            cameraProps.uploadSelectedPhotos('/api/v1/documents', response.data?.data?.id ?? ''),
          ])
            .then(() => {
              const documentId = response.data?.data?.id;

              // Activity codes
              const existingActivityCodes = document?.activity_codes ?? [];
              const activityCodesToRemove = existingActivityCodes.filter(
                (activityCode: E2U.V1.Models.ActivityCode) => !selectedActivityCodes.some((sac) => sac.id === activityCode.id)
              ).map((activityCode: E2U.V1.Models.ActivityCode) => networking.delete(`/api/v1/documents/${response.data?.data?.id}/activity_codes/${activityCode.id}`));
              const activityCodesToAdd = selectedActivityCodes.filter(
                (activityCode: E2U.V1.Models.ActivityCode) => !existingActivityCodes.some((eac) => eac.id === activityCode.id)
              ).map((activityCode: E2U.V1.Models.ActivityCode) => networking.post(`/api/v1/documents/${response.data?.data?.id}/activity_codes/${activityCode.id}`));

              // Tags
              const existingTagSelection = document?.related_tags ?? [];
              const tagsToRemove = existingTagSelection.filter(
                (tag: E2U.V1.Models.Tag) => !selectedTags.some((st) => st.id === tag.id)
              ).map((tag: E2U.V1.Models.Tag) => networking.delete(`/api/v1/documents/${response.data?.data?.id}/related_tags/${tag.id}`));

              const tagsToAdd = selectedTags.filter(
                (tag: E2U.V1.Models.Tag) => typeof tag.id !== 'undefined' && !existingTagSelection.some(
                  (est) => est.id === tag.id
                )
              ).map((tag: E2U.V1.Models.Tag) => networking.post(`/api/v1/documents/${response.data?.data?.id}/related_tags/${tag.id}`));

              const tagsToCreate = selectedTags.filter(
                (tag: E2U.V1.Models.Tag) => typeof tag.id === 'undefined'
              ).map((tag: E2U.V1.Models.Tag) => new Promise((resolve, reject) => {
                networking.post(`/api/v1/tags`, {
                  name: tag.name,
                  category_id: tag.category_id,
                  color: '#000000'
                }).then((response: E2U.V1.Response.Success<E2U.V1.Models.Tag>) => {
                  networking.post(`/api/v1/documents/${documentId}/related_tags/${response.data?.data?.id}`).then(() => resolve(response))
                    .catch((error) => reject(error));
                }).catch((error) => reject(error));
              }));

              Promise.allSettled([
                ...tagsToRemove,
                ...tagsToAdd,
                ...tagsToCreate,
                ...activityCodesToRemove,
                ...activityCodesToAdd
              ]).then(() => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      ).catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.Document>) => {
          if (error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === 'Validation failed'
          ) {
            setValidationErrors(error.response.data.data);
          } else {
            Sentry.captureException(error);
          }
          reject(error);
        }
      );
    }), {
      pending: (document && document.id) ? t('Saving document') : i18n.t('Creating document'),
      success: (document && document.id) ? t('Document saved') : i18n.t('Document created'),
      error: (document && document.id) ? t('Could not save document') : i18n.t('Could not create document')
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'uploadingFile', value: false }));
      if (props.handleDocumentSaved) {
        store.dispatch(setShouldRefetchDocuments(undefined));
        props.handleDocumentSaved(document);
      } else if (props.handleCloseModal) {
        store.dispatch(setShouldRefetchDocuments(undefined));
        props.handleCloseModal();
      } else {
        history.push(`/tools/${selectedProject?.id}/documents/${document?.id}`);
      }
    });
  };

  useEffect(() => {
    if (acceptedFiles) {
      handleFileSelectUpdate();
    }
    if (filesChanged || photosChanged) {
      triggerIfUploads();
    }
    prevFilesRef.current = files;
    prevPhotosRef.current = photos;
  }, [files, photos, methods.trigger]);

  const handleExistingDocument = () => {
    if (typeof document !== 'undefined') {
      if (methods.formState.defaultValues?.name !== '') { methods.setValue('name', document.name); }
      methods.setValue('description', document.description);
      if (document.files && document.files.length) {
        cameraProps.filterPhotosFromFiles(document.files, fileProps.setUploadedFiles);
      }
      if (document.related_tags && document.related_tags.length) {
        const tagsPerCategory: E2U.V1.Models.TagCategory[] = document.related_tags.reduce((acc: E2U.V1.Models.TagCategory[], tag: E2U.V1.Models.Tag) => {
          const existingCategory = acc.find((tc) => tc.id === tag.category_id);
          if (existingCategory) {
            existingCategory.tags?.push(tag);
          } else {
            acc.push({
              id: tag.category?.id,
              name: tag.category?.name ?? '',
              tags: [tag],
              color: tag.category?.color ?? '#000000'
            });
          }
          return acc;
        }, []);
        methods.setValue('local_tags', tagsPerCategory);
      }
      if (document.activity_codes && document.activity_codes.length) {
        methods.setValue('local_activity_codes', document.activity_codes);
      }
    }
  };

  const handleFileSelection = (files: File[]) => {
    fileProps.handleFileSelection(files);
  };

  const handleRemoveFile = (file: E2U.V1.Models.File) => {
    if (file.type === 'image') {
      cameraProps.removePhoto(file);
    } else {
      fileProps.removeFile(file);
    }
  };

  useEffect(() => {
    handleExistingDocument();
  }, []);

  useEffect(() => {
    handleExistingDocument();
  }, [document]);

  return (
    <section className={styles['file-form']}>
      {(isLoadingDocument)
        ? <IonLoading isOpen={true} />
        : <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <IonGrid>
              <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-bottom'>
                <IonCol size='12'>
                  <BigUp.OutlinedInput
                    validation={{
                      required: false,
                      minLength: 3,
                      maxLength: 255,
                    }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    customLabel={t('Name')}
                    placeholder={t('E.g. floor plan')}
                    register={'name'}
                  />
                </IonCol>
              </IonRow>

              <IonRow className={'ion-margin-bottom'}>
                <IonCol>
                  <TextareaOutlined
                    itemProps={{ className: 'ion-no-padding', }}
                    inputMode='text'
                    autoCapitalize='sentences'
                    customLabel={t('Description (optional)')}
                    placeholder={t('Describe what the document contains')}
                    register={'description'}
                    rows={3}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <BigUp.Label.Thick label={t('Files')} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <DragAndDrop
                    onFilesSelect={handleFileSelection}
                  >
                    <div className={styles['file-upload-container']}>
                      <div className={styles['file-upload-icon']}>
                        <IonIcon
                          icon={attachmentsSVG}
                          className={styles['file-upload-icon-inner']}
                          color='primary'
                        />
                      </div>
                      <BigUp.Label.Regular className='ion-no-margin' label={fileUploadLabel} color={'medium'} />
                    </div>
                  </DragAndDrop>
                </IonCol>
              </IonRow>
              {((files && files.length > 0) || (photos && photos.length > 0)) && <IonRow>
                <IonCol>
                  <FileList
                    subLabel={''}
                    photos={photos}
                    files={files}
                    removeFile={handleRemoveFile}
                    removePhoto={handleRemoveFile}
                  />
                </IonCol>
              </IonRow>}
              <IonRow>
                <IonCol className={'ion-margin-top'}>
                  <RelatedMultiSelectEdit
                    label={t('Activity code')}
                    title={t('Activity code')}
                    model='activity_codes'
                    displayFields={['code', 'name']}
                    button='select'
                    value={activityCodes}
                    onChange={(data: E2U.V1.Models.ActivityCode[]) => methods.setValue('local_activity_codes', data)}
                    hideSelected={true}
                    modalTitle={t('Select activity code')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <TagForm model={'local_tags'} />
            <IonGrid>

              <IonRow className='ion-justify-content-between ion-margin-top ion-padding-horizontal'>
                <IonCol className='ion-text-left' size='4'>
                  <BigUp.Buttons.Regular
                    color={'light'}
                    expand='full'
                    onClick={() => props.handleCloseModal && props.handleCloseModal()}
                    title={i18n.t('Cancel')}
                  />
                </IonCol>
                <IonCol className='ion-text-right' size='4'>
                  <BigUp.Buttons.Regular
                    expand='full'
                    type='submit'
                    color={'secondary'}
                    disabled={uploadListCheck || handleDisableButtons('submit')}
                    title={(document && document.id) ? t('Save') : i18n.t('Create')}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>
      }
    </section >
  );
};

export default DocumentForm;
