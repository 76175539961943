import { IonPage, IonRouterOutlet, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import { networking } from '../../api/networking';
import MobilePanels from '../../components/MobilePanels';
import Toast from '../../components/Toasts/Toast';
import toasters from '../../components/Toasts/Toasts';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { setSelectedProject } from '../../reducers/project';
import { setAll, setIsLockable } from '../../reducers/subpanelLock';
import { AbilitiesWatchedRoute } from '../../routes/AbilitiesWatchedRoute';
import ProjectCreate from '../Projects/ProjectCreate/ProjectCreate';
import ToolList from '../Tools/ToolList';
import MonitoringReport from './Pages/MonitoringReport';
import CashFlow from './Subpanels/CashFlow/CashFlow';
import Change from './Subpanels/Changes/Change';
import Changes from './Subpanels/Changes/Changes';
import CreateChange from './Subpanels/Changes/CreateChange';
import EditChange from './Subpanels/Changes/EditChange';
import CreateDeviation from './Subpanels/Deviations/CreateDeviation';
import Deviation from './Subpanels/Deviations/Deviation';
import Deviations from './Subpanels/Deviations/Deviations';
import EditDeviation from './Subpanels/Deviations/EditDeviation';
import EconomyMonitoring from './Subpanels/EconomyMonitoring/EconomyMonitoring';
import EconomyReport from './Subpanels/EconomyReports/EconomyReport';
import PaymentSchedule from './Subpanels/PaymentSchedule/PaymentSchedule';
import AddPrecalculationRows from './Subpanels/Precalculations/AddPrecalculationRows';
import DuplicatePrecalculation from './Subpanels/Precalculations/DuplicatePrecalculation/DuplicatePrecalculation';
import PrecalculationDetail from './Subpanels/Precalculations/PrecalculationDetail';
import PrecalculationsList from './Subpanels/Precalculations/PrecalculationsList';
import ProductionBudget from './Subpanels/ProductionBudget/ProductionBudget';
import ProjectInformation from './Subpanels/ProjectInformation/ProjectInformation';
import useAcl from '../../hooks/useAcl';

const FinancialsPage: React.FC = () => {
  const store = useStore();
  const history = useIonRouter();
  const { t } = useTranslation();
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const subpanelsLockAll = useAppSelector((state) => state.lockSubpanels.isLocked);
  const subpanelLockable = useAppSelector((state) => state.lockSubpanels.isLockable);
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);
  const fetchProject = () => {
    if (
      typeof project === 'undefined' ||
      typeof project?.id === 'undefined'
    ) {
      Toast(t('Project not found'), 'error');
      history.push('/financials');
      return;
    }
    store.dispatch(setIsLoading({ name: 'economy', value: true }));

    toasters
      .promise(networking.get(`/api/v1/projects/${project?.id}`), {
        error: t('Error while loading projects')
      })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        store.dispatch(setSelectedProject(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'economy', value: false }));
      });
  };

  useEffect(() => {
    store.dispatch(setAll(project?.locked));
    store.dispatch(setIsLockable(project?.locked));
  }, [project]);

  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute path={`/financials/:uuid(${matchUuid})/project-information`}
          exact={true}
          requiredAbilities={['can_change_status_projects']}>
          <SideMenuV2Layout>
            <ProjectInformation fetchProject={fetchProject} project={project}
              isLocked={subpanelsLockAll.projectInformation} isLockable={subpanelLockable} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={`/financials/precalculation_history/:historicalPrecalculationId(${matchUuid})`}>
          <SideMenuV2Layout>
            <PrecalculationDetail />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          exact
          path={`/financials/precalculations`}
          requiredAbilities={['can_view_precalculations']}>
          <SideMenuV2Layout paddedPage={false}>
            <PrecalculationsList />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute path={`/financials/precalculations/:precalculationId(${matchUuid})/create-project`}
          requiredAbilities={['can_view_precalculations']}>
          <SideMenuV2Layout>
            <ProjectCreate usesAppLayout={false} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          exact
          path={`/financials/precalculations/:precalculationId(${matchUuid})`}
          requiredAbilities={['can_view_precalculations']}>
          <SideMenuV2Layout title={selectedPrecalculation?.name}>
            <PrecalculationDetail />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/precalculations/:precalculationId(${matchUuid})/add-row`}
          requiredAbilities={['can_store_rows_in_precalculations']}>
          <SideMenuV2Layout contentColour='var(--ion-color-light-shade)' paddedPage={false}>
            <AddPrecalculationRows />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          exact
          path={`/financials/:uuid(${matchUuid})/precalculations`}
          requiredAbilities={['can_view_precalculations_in_projects']}>
          <SideMenuV2Layout paddedPage={false}>
            <PrecalculationsList />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/precalculations/:precalculationId(${matchUuid})/duplicate`}
          requiredAbilities={['can_store_precalculations_in_projects']}>
          <SideMenuV2Layout>
            <DuplicatePrecalculation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/precalculations/:precalculationId(${matchUuid})/duplicate`}
          requiredAbilities={['can_update_precalculations']}>
          <SideMenuV2Layout>
            <DuplicatePrecalculation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/precalculations/:precalculationId(${matchUuid})`}
          requiredAbilities={['can_view_precalculations_in_projects']}
          exact>
          <SideMenuV2Layout>
            <PrecalculationDetail />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/precalculation_history/:historicalPrecalculationId(${matchUuid})/duplicate`}
          requiredAbilities={['can_store_precalculation_histories']}>
          <SideMenuV2Layout>
            <DuplicatePrecalculation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/precalculation_history/:historicalPrecalculationId(${matchUuid})/duplicate`}
          requiredAbilities={['can_store_precalculation_histories']}>
          <SideMenuV2Layout>
            <DuplicatePrecalculation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/precalculation_history/:historicalPrecalculationId(${matchUuid})`}
          requiredAbilities={['can_view_precalculation_histories']}
          exact>
          <SideMenuV2Layout>
            <PrecalculationDetail />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/production-budget/`}
          requiredAbilities={['can_view_production_budget_in_projects']}>
          <SideMenuV2Layout paddedPage={false}>
            <ProductionBudget isLocked={subpanelsLockAll.productionBudgets} fetchProject={fetchProject} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/cash-flow/`}
          requiredAbilities={['can_view_manual_cash_flow_in_projects']}
          exact={true}>
          <SideMenuV2Layout>
            <CashFlow onCalculated={fetchProject} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/payment-schedule/`}
          requiredAbilities={['can_view_payment_schedule_in_projects']}>
          <SideMenuV2Layout paddedPage={false}>
            <PaymentSchedule />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/economy-monitoring/`}
          requiredAbilities={['can_view_rows_in_not_posteds']}>
          <SideMenuV2Layout paddedPage={false}>
            <EconomyMonitoring />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/monitoring/:monitoring_uuid(${matchUuid})`}
          requiredAbilities={['can_view_not_posted_in_projects']}>
          <SideMenuV2Layout paddedPage={false}>
            <MonitoringReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/economy-reports/`}
          requiredAbilities={['can_view_economy_monitoring_in_projects']}>
          <SideMenuV2Layout>
            <EconomyReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* DEVIATIONS */}
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/deviations/:deviation_uuid(${matchUuid})/edit`}
          requiredAbilities={['can_update_deviations_in_projects']}
          exact>
          <SideMenuV2Layout>
            <EditDeviation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/deviations/:deviation_uuid(${matchUuid})`}
          requiredAbilities={['can_view_deviations_in_projects']}
          exact>
          <SideMenuV2Layout>
            <Deviation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/deviations/create`}
          requiredAbilities={['can_store_deviations_in_projects']}
          exact>
          <SideMenuV2Layout>
            <CreateDeviation />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/deviations/`}
          requiredAbilities={['can_view_deviations_in_projects']}
          exact>
          <SideMenuV2Layout>
            <Deviations />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* CHANGES */}
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/changes/:change_uuid(${matchUuid})/edit`}
          requiredAbilities={['can_update_hanges_in_projects']}
          exact>
          <SideMenuV2Layout>
            <EditChange />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/changes/:change_uuid(${matchUuid})`}
          requiredAbilities={['can_view_changes_in_projects']}
          exact>
          <SideMenuV2Layout>
            <Change />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/changes/create`}
          requiredAbilities={['can_store_changes_in_projects']}
          exact>
          <SideMenuV2Layout>
            <CreateChange />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/financials/:uuid(${matchUuid})/changes/`}
          requiredAbilities={['can_view_changes_in_projects']}
          exact>
          <SideMenuV2Layout>
            <Changes />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute path={`/financials/:uuid(${matchUuid})`} exact={true}>
          <MobilePanels type={'financials'} />
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute path={'/financials'} exact={true}>
          <ToolList description={''} />
        </AbilitiesWatchedRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};
export default FinancialsPage;
