import { IonCol, IonContent, IonPage, IonRow, isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Login.module.scss';
import AppVersion from '../../components/AppVersion';
import LoginForm from '../../components/LoginForm';
import BigUp from '../../components/UI';
import { setUser } from '../../reducers/authentication';
import store from '../../store';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = isPlatform('desktop');

  useEffect(() => {
    store.dispatch(setUser(null));
  }, []);

  return (
    <IonPage>
      <IonContent id={styles['login-page']} scrollY={false}>
        {/* TODO:
          Add proper source and poster for video when available.
          Comment this back out and clear the background image styles in './Login.module.scss'
          Comment back #background-video in './Login.module.scss'
          */}
        {/* <video
          id={styles['background-video']}
          autoPlay
          loop
          muted
          playsInline
          poster="#"
        >
          <source src="#" type="video/mp4" />
        </video> */}

        <div className={`${styles['login-box']} ${!isDesktop ? styles['login-box--mobile'] : ''}`}>
          <section>
            <img src={BigUp.Logo} alt={t('BigUp logo')} className={styles['bigup-logo']} />
            <p>{t('Welcome to BigUp!')}</p>
          </section>
          <div className={`${styles['login-form-container']} ${!isDesktop ? styles['login-form-container--mobile'] : ''}`}>
            <div className={styles['form-container-title']}>
              <h6>
                {t('Sign in')}
              </h6>
            </div>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
              <IonCol size='auto'>
                <LoginForm />
              </IonCol>
            </IonRow>
            <div className={styles['create-account-link']}>
              <p>{t('Don\'t have an account?')}</p>
              <Link to='/registration'>{t('Sign up')}</Link>
            </div>
          </div>
        </div>

        {isPlatform('capacitor') &&
          <div className={styles['app-version-container-login-page']}>
            <AppVersion />
          </div>
        }
      </IonContent>
    </IonPage >
  );
};

export default Login;
