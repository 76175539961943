import { IonPage, IonRouterOutlet } from '@ionic/react';
import React from 'react';

import { matchUuid } from '../../constants/matchUuid';
import AssessmentList from '../../pages/WorkPreparation/AssessmentList';
import PreparationForm from '../../pages/WorkPreparation/PreparationForm';
import ProtectedRoute from '../ProtectedRoute';

const WorkPreparationsRouteGroup: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <ProtectedRoute exact path={'/work-preparation'} component={PreparationForm} />
        {/* <ProtectedRoute path={`/work-preparations/:uuid(${matchUuid})`} component={WorkPreparations} /> */}
        <ProtectedRoute path={`/work-preparation/:uuid(${matchUuid})`} component={PreparationForm} />
        <ProtectedRoute path={`/work-preparation/assessments/*`} component={AssessmentList} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default WorkPreparationsRouteGroup;
