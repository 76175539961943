import type { E2U } from '@techlove/easy2use-typings';
import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';

import type { InputProps } from '../../../../../../components/UI/Inputs/Input.interface';
import i18n from '../../../../../../i18n';
import { regex } from '../../../../../../regex/regex';

export const validatePersonNumber = (personId: string) => {
  return Personnummer.valid(personId);
};

export const validateOrganizationNumber = (organizationNumber: string) => {
  return Organisationsnummer.valid(organizationNumber);
};

export const FORM_VALUES:InputProps<E2U.V1.Models.User>[] =
 [
   {
     id: 'personal_number',
     name: 'personal_number',
     label: i18n.t('Personal ID-number'),
     inputMode: 'numeric',
     placeholder: i18n.t('yyyymmddxxxx'),
     type: 'text',
     autocomplete: 'bday',
     enterkeyhint: 'next',
     required: true,
     maxlength: 30,
     minlength: 2,
     register: 'personal_number',
     validation: {
       required: { value: true, message: i18n.t('Personal ID-number is required') as keyof E2U.V1.Models.User },
       pattern: {
         value: /^[0-9-]+$/,
         message: i18n.t('Invalid format, only numbers and hyphens are allowed'),
       },
     }
   },
   {
     id: 'first_name',
     name: 'first_name',
     autocomplete: 'given-name',
     enterkeyhint: 'next',
     label: i18n.t('First name'),
     type: 'text',
     inputMode: 'text',
     placeholder: i18n.t('First name'),
     register: 'first_name',
     validation: {
       required: i18n.t('Your first name is required') as keyof E2U.V1.Models.User,
       maxLength: { value: 50, message: i18n.t('Name is too long'), },
       minLength: { value: 1, message: i18n.t('Name is too short'), },
       pattern: { value: regex.regex_alphabetical, message: i18n.t('First name must be alphabetical') },
     }
   },
   {
     id: 'last_name',
     name: 'last_name',
     autocomplete: 'family-name',
     enterkeyhint: 'next',
     label: i18n.t('Last name'),
     type: 'text',
     inputMode: 'text',
     placeholder: i18n.t('Last name'),
     register: 'last_name',
     validation: {
       required: i18n.t('Your last name is required') as keyof E2U.V1.Models.User,
       maxLength: { value: 50, message: i18n.t('Name is too long'), },
       minLength: { value: 1, message: i18n.t('Name is too short'), },
       pattern: { value: regex.regex_alphabetical, message: i18n.t('Last name must be alphabetical') },
     }
   },
   {
     name: 'company_name',
     id: 'company_name',
     autocomplete: 'organization',
     enterkeyhint: 'next',
     label: i18n.t('Company'),
     placeholder: i18n.t('fills automatically'),
     type: 'text',
     inputmode: 'text',
     register: 'company_name',
     readonly: true,
     validation: {
       required: i18n.t('Company name is required') as keyof E2U.V1.Models.User,
       maxLength: { value: 50, message: i18n.t('Company name is too long'), },
       minLength: { value: 1, message: i18n.t('Company name is too short'), },
       pattern: { value: regex.regex_company, message: i18n.t('Company name must be alphabetical') },
     }
   },
   {
     name: 'organization_number',
     id: 'organization_number',
     autocomplete: 'organization',
     enterkeyhint: 'enter',
     label: i18n.t('Organization number'),
     placeholder: i18n.t('org.nr'),
     inputmode: 'numeric',
     type: 'text',
     register: 'organization_number',
     validation: {
       validate: (value: string) => validateOrganizationNumber(value) || i18n.t('Organization number is invalid') as keyof E2U.V1.Models.User,
       required: i18n.t('Organization number is required') as keyof E2U.V1.Models.User,
       maxLength: { value: 50, message: i18n.t('Organization number is too long'), },
       minLength: { value: 1, message: i18n.t('Organization number is too short'), },
     }
   }
 ];
