import { App } from '@capacitor/app';
import type { IonLabel } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';

import BigUp from './UI';

const AppVersion = ({ rest }: {
  rest?:
  ComponentProps<typeof IonLabel>
}) => {
  const [appVersion, setAppVersion] = useState<string>('');

  const appInformation = async () => {
    try {
      const info = await App.getInfo();
      setAppVersion(info.version);
    } catch (error) {
      return (
        Sentry.captureException(error)
      );
    }
  };

  useEffect(() => {
    appInformation();
  }, []);

  return (
    <BigUp.Label.Regular className='ion-no-margin' style={{ fontSize: 20 }} label={appVersion} />
  );
};

export default AppVersion;
