import type { SVG } from './types';

export const WorkPreparation = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '34'} height={height || '40'} viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.87512 39.25C1.51045 39.25 1.16071 39.1051 0.902851 38.8473C0.644988 38.5894 0.500122 38.2397 0.500122 37.875V3.85492C0.50022 3.49466 0.594683 3.1407 0.774106 2.8283C0.953528 2.51589 1.21165 2.25593 1.52278 2.0743C2.56262 1.47016 4.65778 0.75 8.75012 0.75C11.9479 0.75 15.5246 2.01414 18.6802 3.12875C21.2214 4.0268 23.6216 4.875 25.2501 4.875C27.3465 4.86857 29.4206 4.44498 31.3517 3.62891C31.5868 3.52965 31.8429 3.49012 32.097 3.51384C32.3512 3.53757 32.5955 3.62381 32.8082 3.76488C33.0209 3.90595 33.1954 4.09747 33.3161 4.32236C33.4369 4.54724 33.5001 4.79851 33.5001 5.05375V23.905C33.4998 24.2391 33.4021 24.5659 33.2189 24.8454C33.0357 25.1248 32.7751 25.3449 32.4689 25.4785C31.7204 25.8059 28.9875 26.875 25.2501 26.875C23.1756 26.875 20.5768 26.2614 17.826 25.6109C14.7339 24.8804 11.5371 24.125 8.75012 24.125C5.58161 24.125 3.95997 24.6045 3.25012 24.9079V37.875C3.25012 38.2397 3.10526 38.5894 2.84739 38.8473C2.58953 39.1051 2.23979 39.25 1.87512 39.25Z" fill="url(#paint0_linear_1392_23263)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23263" x1="17.0001" y1="0.75" x2="17.0001" y2="39.25" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FDBF01" />
          <stop offset="1" stop-color="#C51C01" />
        </linearGradient>
      </defs>
    </svg>
  );
};
