
import { IonContent, IonModal, IonNav, IonSearchbar, IonToolbar, isPlatform } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import BigUp from '../..';
import Latest from './Contents/Latest';
import Toolbar from './Contents/Toolbar';
import styles from './index.module.scss';

interface FileModal {
  trigger: string;
}

const FileUploadTest: React.FC<FileModal> = (props) => {
  const isDesktop = isPlatform('desktop') ? 'desktop' : 'mobile';
  const nav = useRef<HTMLIonNavElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const location = useLocation();

  const didPresent = () => {
    if (nav.current) {
      nav.current.setRoot(Latest, { nav: nav.current });
    }
  };

  const dismiss = () => {
    if (modal.current) {
      modal.current.dismiss();
    }
  };

  useEffect(() => {
    dismiss();
    return () => {
      if (nav.current) {
        nav.current.setRoot(() => { });
      }
    };
  }, [location.pathname]);

  return (
    <IonModal
      ref={modal}
      trigger={props.trigger}
      onDidPresent={didPresent}
      backdropDismiss={true}
      className={styles[`file-upload-modal--${isDesktop}`]}
    >
      <BigUp.Modal.ModalHeader
        title={t('Selected files to upload')}
        end={{
          button: {
            title: t('Save'),
          },
          onClick: () => {
            console.log('Save selected files, clear selection and close modal');
          }
        }}
        start={{
          button: {
            title: t('Cancel'),
          },
          onClick: () => {
            dismiss();
            console.log('Clear selected files and close modal');
          }
        }}
      />

      <Toolbar nav={nav} />

      <IonToolbar className='ion-no-padding' color={'light'}>
        <IonSearchbar mode={'ios'} />
      </IonToolbar>

      <IonContent style={{ '--background': 'var(--ion-color-light-shade)' }}>
        <IonNav ref={nav}></IonNav>
      </IonContent>
    </IonModal>
  );
};

export default FileUploadTest;
