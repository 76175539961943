import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions, IonItemSliding,
  IonList,
  useIonAlert, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronForward, pencil, trash } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import EmptyList from '../../../components/UI/EmptyList';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import BigUp from '../../UI';

interface NodesSettingPageProps {
  type: 'projects' | 'clients';
  nodes: any[];
  onNodeChange: () => void;
}

const NodesList: React.FC<NodesSettingPageProps> = ({ nodes, onNodeChange, type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id } = useParams<{ client_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const ionListRef = React.useRef<HTMLIonListElement>(null);

  const doDeleteNode = (node: any) => {
    networking.delete(`/api/v1/work_preparations/${type === 'projects' ? 'project' : 'client'}_nodes/${node.id}`)
      .then(() => {
        onNodeChange();
        toasters.success(t('Template successfully removed'));
        ionListRef.current?.closeSlidingItems();
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to remove node.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'deletingNode',
          value: false
        }));
      });
  };

  const handleNodeDeletion = (node: any) => {
    store.dispatch(setIsLoading({
      name: 'deletingNode',
      value: true
    }));
    presentAlert({
      header: t('Delete template'),
      message: t('Are you sure you want to delete this template?'),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          handler: () => doDeleteNode(node)
        }
      ]
    });
  };

  const doRenameNode = (node: any, newName: string) => {
    store.dispatch(setIsLoading({
      name: 'renamingNode',
      value: true
    }));
    networking.put(`/api/v1/work_preparations/${type === 'projects' ? 'project' : 'client'}_nodes/${node.id}`, {
      name: newName
    })
      .then(() => {
        onNodeChange();
        toasters.success(t('Template successfully renamed'));
        ionListRef.current?.closeSlidingItems();
      })
      .catch(() => {
        toasters.error(t('Failed to rename template. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'renamingNode',
          value: false
        }));
      });
  };

  const handleNodeEdit = (node: any) => {
    presentAlert({
      header: t('Rename template'),
      message: t('Enter the new name for the template.'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: t('Enter name'),
          value: node?.name ?? '',
        },
      ],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('Rename'),
          handler: (data: { name: string }) => {
            doRenameNode(node, data.name);
          }
        }
      ]
    });
  };

  const navigateNode = (node: any) => {
    if (type === 'projects') {
      router.push(`/tools/${id}/settings/work-preparation/node/${node.id}`);
    } else {
      router.push(`/clients/${id}/settings/work-preparation/node/${node.id}`);
    }
  };

  return (
    <>
      {(nodes && nodes.length > 0)
        ? <IonList ref={ionListRef}>
          {nodes.map((n, i) =>
            <IonItemSliding key={i}>
              <IonItem button={true} onClick={() => navigateNode(n)}>
                <BigUp.Label.Regular label={n.name} className={'ion-no-margin'} />
                <IonIcon icon={chevronForward} slot={'end'} />
              </IonItem>
              <IonItemOptions>
                <IonItemOption onClick={() => handleNodeEdit(n)}>
                  <IonIcon icon={pencil} slot={'end'} />
                </IonItemOption>
                <IonItemOption onClick={() => handleNodeDeletion(n)} color={'danger'}>
                  <IonIcon icon={trash} slot={'end'} />
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>
          )}
        </IonList>
        : <EmptyList
          title={t('No templates found')}
          message={t('Create a new template to get started.')}
        />}
    </>
  );
};

export default NodesList;
