import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { networking } from '../api/networking';
import { fileContentHasher } from '../services/fileContentHasher';

export interface FileUploadResult {
  handleFileSelection: (files: File[] | null) => void;
  removeFile: (file: E2U.V1.Models.File) => void;
  filesToUpload: File[];
  form: ReturnType<typeof useForm>;
  getUploadedFiles: () => E2U.V1.Models.File[];
  setUploadedFiles: (files: E2U.V1.Models.File[]) => void;
  uploadSelectedFiles: (apiResourceRoute: string, resourceId: string) => Promise<void>;
  onlyUploadFiles: () => Promise<E2U.V1.Response.Success<E2U.V1.Models.File>>[];
  uploadFile: (file: File) => Promise<E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>>;
  resetFilesToUpload: () => void;
}

const useFileUpload = (): FileUploadResult => {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesToRemove, setFilesToRemove] = useState<E2U.V1.Models.File[]>([]);
  const form = useForm();
  const { t } = useTranslation();

  const setUploadedFiles = (files: E2U.V1.Models.File[]) => {
    form.setValue('files', files);
  };
  const getUploadedFiles = () => form.getValues('files');

  const handleFileSelection = (files: File[] | null) => {
    if (!files) return;

    const fileArray = Array.from(files);
    const newFiles: E2U.V1.Models.File[] = [];
    Promise.all(fileArray.map((file) => {
      return new Promise<void>((resolve) => fileContentHasher(file).then((hash) => {
        newFiles.push({
          id: undefined,
          name: file.name,
          filename: file.name,
          type: 'pdf' || 'image',
          path: file.name,
          download_url: '',
          file_hash: hash,
          number_of_revisions: 0,
        });
        resolve();
      }));
    }))
      .then(() => {
        const currentFiles = form.getValues('files') || [];
        form.setValue('files', [...currentFiles, ...newFiles]);
        setFilesToUpload((prevFiles) => [...prevFiles, ...fileArray]);
      });
  };

  const removeFile = (file: E2U.V1.Models.File) => {
    if (file.id) {
      setFilesToRemove((prev) => [...prev, file]);
    } else {
      setFilesToUpload((prev) => prev.filter((f) => f.name !== file.filename));
    }
    setUploadedFiles(
      getUploadedFiles().filter((f: E2U.V1.Models.File) => f.file_hash !== file.file_hash)
    );
  };

  const uploadFile: (file: File) => Promise<
    E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }
    >> = (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.set('name', file.name);
      return new Promise<E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>>((resolve, reject) =>
        networking.post('/api/v1/files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(
            (res: E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>) =>
              resolve(res)
          )
          .catch((error: E2U.V1.Response.Error) => {
            Sentry.captureException(error);
            toast(t('Error while uploading file'), {
              type: 'error'
            });
            reject(error);
          }));
    };

  const uploadSelectedFiles = (apiResourceRoute: string, resourceId: string) => {
    return new Promise<void>((resolve) => {
      Promise.allSettled<E2U.V1.Response.Success<E2U.V1.Models.File & { filename: string }>>(
        onlyUploadFiles()
      ).then((results) =>
        Promise.all<void>(
          results.map((result) =>
            new Promise<void>((resolve) => {
              if (result.status === 'fulfilled') {
                const { value } = result;
                networking.post(apiResourceRoute + '/' + resourceId + '/files/' + value.data.data.id)
                  .then((r) => {
                    resolve();
                  })
                  .catch((err) => {
                    resolve();
                  });
              } else {
                resolve();
              }
            })
          ).concat(
            filesToRemove.map((file) =>
              new Promise<void>((resolve) => {
                networking.delete(`/api/v1/files/${file.id}`).then(
                  (r) => {
                    resolve();
                  }
                ).catch((err) => {
                  resolve();
                });
              })
            )
          )).finally(() => {
          resolve();
        })
      );
    });
  };

  const onlyUploadFiles: () => Promise<E2U.V1.Response.Success<E2U.V1.Models.File>>[] = () => {
    return filesToUpload.map((file) => uploadFile(file));
  };

  const resetFilesToUpload = () => {
    setFilesToUpload([]);
  };

  return {
    filesToUpload,
    form,
    uploadFile,
    getUploadedFiles,
    handleFileSelection,
    removeFile,
    setUploadedFiles,
    uploadSelectedFiles,
    onlyUploadFiles,
    resetFilesToUpload
  };
};

export default useFileUpload;
