import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import i18n from '../../../../i18n';

export const projectInputs: InputProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    disabled: true,
    labelPlacement: 'start',
    register: 'company',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Project'),
    placeholder: i18n.t('Project'),
    labelPlacement: 'start',
    disabled: true,
    register: 'project',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Contact person'),
    placeholder: i18n.t('Contact person'),
    labelPlacement: 'start',
    register: 'contact_person',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Work activities'),
    labelPlacement: 'start',
    register: 'work_operation',
    placeholder: i18n.t('Work activities'),
    validation: {
      required: '*',
    },
  },
];
