import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { PrecalculationsRadialChartProps } from './PrecalculationsRadialChart';
import BigUp from '../../../../../components/UI';
import type { GraphProps } from '../../../../../components/UI/Graphs/RadialChartWidget';
import type { Indicator } from '../../../../../components/UI/Graphs/types/RadialChartWidget';
import type { ColourVariables } from '../../../../../components/UI/variables';
import { useAppSelector } from '../../../../../hooks';
import formatNumber from '../../../../../tools/formatNumber';

const PrecalculationsRadialChart: React.FC<PrecalculationsRadialChartProps<GraphProps>> = ({
  pieChartData,
  remainingTotal,
  selectedPrecalculation,
}) => {
  const { t } = useTranslation();
  const { precalculationId } = useParams<{ precalculationId?: string }>();
  const version = useAppSelector((state) => state.precalculations.selectedVersion);

  const data = precalculationId
    ? pieChartData.precalculation
    : pieChartData.historical;

  const indicators: Indicator[] = precalculationId
    ? [
      {
        color: 'var(--bigup-gradient-primary)',
        shape: 'circle',
        labelStart: 'Remaining',
        labelEnd: formatNumber(remainingTotal.precalculation.value).toString(),
      },
      {
        color: 'var(--bigup-gradient-secondary)',
        shape: 'circle',
        labelStart: 'Secured',
        labelEnd: selectedPrecalculation.secured_costs !== undefined ? formatNumber(selectedPrecalculation.secured_costs).toString() : ''

      },
      {
        color: 'var(--ion-color-medium-shade)',
        shape: 'circle',
        labelStart: 'Total',
        labelEnd: selectedPrecalculation.total !== undefined ? formatNumber(selectedPrecalculation.total).toString() : ''

      },
    ]
    : [
      {
        color: '--bigup-gradient-primary' as ColourVariables,
        labelStart: 'Remaining',
        labelEnd: formatNumber(remainingTotal.historical.value).toString(),
      },
      {
        color: '--bigup-gradient-secondary' as ColourVariables,
        labelStart: 'Secured',
        labelEnd: formatNumber(version?.secured_costs ?? 0).toString()
      },
      {
        color: '--ion-color-medium-shade' as ColourVariables,
        labelStart: 'Total',
        labelEnd: formatNumber(version?.total ?? 0).toString(),
      },
    ];

  return (
    <BigUp.Graphs.RadialChartWidget
      title="Overview"
      text={t('Secured')}
      data={data}
      indicators={indicators}
      size="100%"
    />
  );
};

export default PrecalculationsRadialChart;
