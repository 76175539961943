import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import type { TFunction } from 'i18next';

interface HeaderSimpleProps {
  title?: TFunction;
  defaultHref?: string | undefined
}
const HeaderSimple: React.FC<HeaderSimpleProps> = ({ defaultHref, title }) => {
  const router = useIonRouter();
  return (
    <IonHeader className='ion-no-border'>
      <IonToolbar className='ion-padding-vertical'>
        <IonButtons slot='start'>
          <IonBackButton defaultHref={defaultHref || router.routeInfo.lastPathname} />
        </IonButtons>
        <IonTitle>{title || router.routeInfo.pathname}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderSimple;
