import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonTextarea, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { Project } from '@techlove/easy2use-typings/src/V1/Models/Project';
import React, { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CURRENCY_INPUT_LIST, EDIT_PROJECT_INPUT_LIST } from './EditProjectLists';
import { networking } from '../../api/networking';
import toasters from '../../components/Toasts/Toasts';
import StepButtons from '../../components/Toolbar/StepButtons';
import { BigUp } from '../../components/UI';
import { ValidationBadge } from '../../components/UI/Inputs/ValidationBadge';
import SelectDropdown from '../../components/UI/SelectDropdown';
import { ionicColours } from '../../components/UI/variables';
import { useAppSelector } from '../../hooks';
import useFetchData from '../../hooks/useFetch';
import useFetchProjects from '../../hooks/useFetchProjects';
import { setPrecalculations } from '../../reducers/precalculations';
import { setSelectedProject } from '../../reducers/project';
import store from '../../store';
import formatNumber from '../../tools/formatNumber';

interface EditProjectProps {
  onCancel?: () => void;
  onModalClose?: () => void;
  children?: React.ReactNode;
  newProject?: boolean;
}

export const label_styles = {
  fontWeight: 800,
  letterSpacing: '1px',
  fontSize: '12px'
};

const ProjectForm: React.FC<EditProjectProps> = ({ children, newProject = false, onCancel, onModalClose }) => {
  const [error, setError] = useState<any | null>(null);
  const { t } = useTranslation();
  const router = useIonRouter();
  const { fetchData } = useFetchData<E2U.V1.Models.Project[], undefined | any>({ initialData: null });
  const fetchProjects = useFetchProjects();
  const { precalculationId } = useParams<{ precalculationId: string }>();
  const [projectCategories, setProjectCategories] = useState<any[]>([]);
  const [precalculation, setPrecalculation] = useState<E2U.V1.Models.Precalculation>();
  const selectedProject = useAppSelector(state => state.project.selectedProject as any);

  const defaultValuesCondition = (selectedProject && !newProject);
  const defaultValues = {
    name: defaultValuesCondition ? selectedProject.name : '',
    description: defaultValuesCondition ? selectedProject.description : '',
    living_area: defaultValuesCondition ? formatNumber(selectedProject.living_area) as any : '',
    other_area: defaultValuesCondition ? formatNumber(selectedProject.other_area) as any : '',
    project_category_id: defaultValuesCondition ? selectedProject.project_category_id : '',
    total_area: defaultValuesCondition ? formatNumber(selectedProject.total_area) as any : '',
  };
  const methods = useForm<Project>({ defaultValues });

  const fetchPrecalculation = () => {
    networking.get(`api/v1/precalculations/${precalculationId}`)
      .then((response) => {
        setPrecalculation(response.data.data);
      });
  };
  const putPrecalculation = (projectId: E2U.V1.Models.Project['id']) => {
    networking.put(`/api/v1/precalculations/${precalculationId}`, { project_id: projectId })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const fetchSuggestedName = () => {
    networking.get('/api/v1/projects/suggested_name')
      .then((response) => {
        methods.setValue('name', response.data.data.suggested_name);
        methods.trigger('name');
      });
  };

  const closeModal = () => {
    onCancel && onCancel();
  };

  const projectCategoriesRoute = `/api/v1/project_categories`;

  const onSubmit: SubmitHandler<E2U.V1.Models.Project> = (data) => {
    const isUpdating = Boolean(selectedProject?.id && !newProject);
    const postData = {
      ...data,
      project_category_id: data.project_category_id,
    };

    const saveRequest = isUpdating
      ? networking.put(`/api/v1/projects/${selectedProject?.id}`, postData)
      : networking.post<E2U.V1.Requests.Create.Project>('/api/v1/projects', postData);
    toasters.promise(saveRequest, {
      pending: isUpdating
        ? t('Saving project')
        : t('Creating project'),
      success: isUpdating
        ? t('Successfully saved project')
        : t('Successfully created project'),
      error: isUpdating
        ? t('Failed saving project')
        : t('Failed creating project')
    })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        const projectId = response.data.data.id;

        fetchProjects();

        if (precalculationId) {
          putPrecalculation(projectId);
        }

        store.dispatch(setPrecalculations(undefined));
        store.dispatch(setSelectedProject(response.data.data));
        router.push(`/financials/${projectId}/project-information`);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        setError(error.response?.data.message);
      })
      .finally(() => {
        if (isUpdating) {
          closeModal();
        }
      });
  };

  const onThen = (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<Project[]>>) => {
    setProjectCategories(response.data.data.records);
  };

  const onCatch = (error: E2U.V1.Response.Error<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Project>>) => {
    Sentry.captureException(error);
  };

  useEffect(() => {
    if (precalculation) {
      methods.reset((formValues) => ({
        ...formValues,
        name: formValues.name || precalculation.name || '',
        description: formValues.description || precalculation.description || '',
      }));
    }
  }, [precalculation, methods]);

  useEffect(() => {
    fetchData(projectCategoriesRoute, onThen, onCatch);
  }, []);

  useEffect(() => {
    if (newProject && !methods.getValues('name')) {
      fetchSuggestedName();
    }
  }, [newProject]);

  useEffect(() => {
    if (precalculationId) {
      fetchPrecalculation();
    }
  }, [precalculationId]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>

          <IonGrid className='ion-padding ion-margin-top'>
            <IonRow>
              <IonCol className='ion-margin-bottom'>
                <BigUp.Title label={t('Create a new project')} />
              </IonCol>
            </IonRow>
            <React.Fragment>

              {EDIT_PROJECT_INPUT_LIST.map((input, i) => {
                return (
                  <IonRow className='ion-margin-bottom ion-align-items-center' key={i}>
                    <IonCol size='12' style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}
                      className='ion-padding-start'>
                      <BigUp.Input
                        label={t(input.label)}
                        labelPlacement='stacked'
                        register={input.register}
                        type={input.inputType}
                        inputMode={input.inputMode}
                        placeholder={input.placeholder}
                        serverError={error}
                        validation={{
                          required: {
                            value: true,
                            message: t('Project name is required')
                          },
                          maxLength: {
                            value: 255,
                            message: t('Project name is too long'),
                          },
                          minLength: {
                            value: 3,
                            message: t('Project name is too short'),
                          },
                        }
                        }
                      />

                    </IonCol>
                  </IonRow>
                );
              })}

              <IonRow className='ion-margin-bottom'>
                <IonCol style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}>
                  <SelectDropdown
                    style={{
                      color: 'var(--ion-color-dark)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      overflow: 'hidden',
                    }}
                    data={projectCategories}
                    onValueChange={(value) => methods.setValue('project_category_id', value)}
                    placeholder={t('Select category')}
                    label={t('Project category')}
                  />
                </IonCol>
              </IonRow>

              {CURRENCY_INPUT_LIST.map((input, i) => {
                const { maxLength, minLength, pattern, requiredMessage, ...rest } = input;
                return (
                  <IonRow className='ion-margin-bottom ion-align-items-center' key={i}>
                    <IonCol size='12' style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}
                      className='ion-padding-start'>
                      <BigUp.Input key={i} validation={{
                        required: {
                          value: true,
                          message: requiredMessage || t('This field is required'),
                        },
                        minLength,
                        maxLength
                      }} {...rest} />
                    </IonCol>
                  </IonRow>
                );
              })}
              <IonRow className='ion-margin-bottom ion-align-items-center'>
                <IonCol size={'12'} style={{ borderLeft: `4px solid ${ionicColours.secondary}` }}>
                  <IonLabel className='ion-margin-left' color={'medium'} style={{ ...label_styles }}>
                    {t('Description')}
                  </IonLabel>
                  <IonItem>
                    <IonTextarea
                      autoGrow
                      aria-required
                      aria-aria-valuemax={5000}
                      aria-aria-valuemin={3}
                      autoCapitalize='sentences'
                      placeholder={t('Enter description')}
                      {...methods.register('description', {
                        maxLength: {
                          value: 5000,
                          message: t('Project description is too long'),
                        },
                        minLength: {
                          value: 3,
                          message: t('Project description is too short'),
                        },
                      })}
                    />
                  </IonItem>

                  {methods.formState.errors.description && (
                    <ValidationBadge marginLeft={true}>
                      {methods.formState.errors.description.message}
                    </ValidationBadge>
                  )}
                </IonCol>
              </IonRow>
            </React.Fragment>
          </IonGrid>
          {children}

          <StepButtons
            rightOnClick={() => undefined}
            leftOnClick={onModalClose}
            leftTitle={t('Cancel')}
            rightSubmit={'submit'}
            rightDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            leftIonColor='none'
            rightIonColor='secondary'
            rightTitle={t('Save')}
          />
        </form>

      </FormProvider>
    </React.Fragment>

  );
};

export default ProjectForm;
