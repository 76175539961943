import { IonButton } from '@ionic/react';

import styles from '../styles/ButtonContainer.module.scss';
import type { Button } from '../types';

type Dimensions = {
  size?: number;
};

type ButtonProps = Button & Dimensions;

const ButtonContainer: React.FC<ButtonProps> = (props) => {
  const { size = 1, ...rest } = props;
  const width = 66 * size;
  const height = 66 * size;

  return (
    <IonButton
      fill='clear'
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
      className={styles.buttonContainer}
      {...rest}>
      {rest.children}
    </IonButton>
  );
};

export default ButtonContainer;
