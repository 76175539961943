import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonNote, IonRow, IonSearchbar, IonText, IonTitle, IonToolbar, useIonAlert, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { addSharp, trash } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './Preparations.module.scss';
import { networking } from '../../../api/networking';
import ProjectSelector from '../../../components/ProjectSelector';
import EmptyList from '../../../components/UI/EmptyList';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';

const WorkPreparations: React.FC = () => {
  const { t } = useTranslation();
  const [protocols, setProtocols] = useState([]);
  const { uuid } = useParams<{ uuid: string }>();
  const router = useIonRouter();
  const project = useAppSelector(state => state.project.selectedProject);
  const [alert] = useIonAlert();
  const ionListRef = useRef<HTMLIonListElement>(null);

  const getPreparationProtocols = () => {
    if (!project?.id) return;
    networking.get(`/api/v1/projects/${uuid || project?.id}/work_preparation_protocols?with=entries.reports`)
      .then(res => {
        console.log(res.data.data.records);
        setProtocols(res.data.data.records);
      });
  };
  const deletePreparationProtocol = (id: string) => {
    networking.delete(`/api/v1/work_preparations/protocols/${id}`)
      .then(res => {
        console.log(res);
        toasters.createToast({
          message: t('Deleted protocol'),
          background: 'var(--ion-color-light)'
        }, 'success');
        ionListRef.current?.closeSlidingItems()
          .then(() => {
            getPreparationProtocols();
          });
      });
  };

  const showTodayIfToday = (date: string) => {
    const today = new Date().toISOString().split('T')[0];
    return date === today ? t('Today') : null;
  };

  const showYesterdayIfYesterday = (date: string) => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
    return date === yesterday ? t('Yesterday') : null;
  };

  const formatDate = (date: string) => {
    const parsedDate = new Date(date);
    const day = parsedDate.getDate().toString().padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderDatesAccordingly = (date: string) => {
    const parsedDate = date.split('T')[0];

    return (
      showTodayIfToday(parsedDate) ||
      showYesterdayIfYesterday(parsedDate) ||
      formatDate(parsedDate)
    );
  };

  const confirmDelete = (id: string, name: string) => {
    alert({
      header: t('Delete protocol'),
      message: t('Are you sure you want to delete protocol: {protocol}', 'Are you sure you want to delete protocol: {protocol}', {
        protocol: name
      }),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          handler: () => deletePreparationProtocol(id)
        }
      ]
    });
  };

  useEffect(() => {
    getPreparationProtocols();
  }, [uuid]);

  useIonViewWillEnter(() => {
    getPreparationProtocols();
  }, [project?.id, uuid]);
  const emptyListMessage = project?.id ? t('No preparations found for this project') : t('');
  return (
    <>
      <IonHeader className='ion-no-border'>
        <IonToolbar className='ion-padding-top'>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/tools' />
          </IonButtons>
          <IonTitle>{t('Work preparations')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => project?.id
              ? router.push(`/tools/work-preparation/${project.id}`)
              : router.push('/tools/work-preparation')}>
              <IonIcon slot="icon-only" ios={addSharp} />
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <IonContent className={`ion-padding ${styles['preparation-page-content']}`}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ProjectSelector currentTab={undefined} color='var(--ion-color-dark)' />
            </IonCol>
          </IonRow>
        </IonGrid>
        {!protocols.length
          ? (
            <div className={styles['empty-protocol-list-container']}>

              <EmptyList
                helperLinks={{
                  title: t('Create new preparation'),
                  url: project?.id ? `/tools/work-preparation/${project.id}` : '/tools/work-preparation'
                }}
                title={t('No preparations found')}
                message={emptyListMessage}
              />
            </div>
          )
          : (
            <IonList color={'medium'} className={`ion-margin-top ${styles['protocol-list-container']}`} ref={ionListRef}>
              <IonSearchbar color={'light'}></IonSearchbar>
              <IonGrid>
                <IonRow className="ion-justify-content-between">
                  <IonCol size='auto'>
                    <IonLabel className="ion-no-margin">
                      {t('Name')}
                    </IonLabel>
                  </IonCol>
                  <IonCol size='auto'>
                    <IonLabel className="ion-no-margin">
                      {t('Date')}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {protocols && protocols.map((protocol: any, i: number) => {
                return (
                  <IonItemSliding key={i} >
                    <IonItem
                      style={{ '--background': i % 2 === 0 ? 'var(--ion-color-light)' : 'var(--ion-color-light-shade)', }}
                      lines="none"
                      className={`ion-no-padding ${styles['protocol-list-item']}`}
                      button
                      detail={false}
                      onClick={() => router.push(`/tools/preparation/${protocol.id}`)}
                    >
                      <IonGrid>
                        <IonRow className='ion-align-items-center ion-justify-content-between'>
                          <IonCol size='auto'>
                            <IonLabel className={styles['activity-label']}>
                              <IonText >
                                {protocol.activity || 'No name'}
                              </IonText>
                              <br />
                              <IonNote color="medium" className="ion-text-wrap">
                                {protocol.work_operation}
                              </IonNote>
                            </IonLabel>
                          </IonCol>
                          <IonCol size='auto'>
                            <IonLabel className={styles['date-label']}>
                              <IonText>
                                {renderDatesAccordingly(protocol.created_at)}
                              </IonText>
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItemOptions side="end">
                      <IonItemOption color="danger" expandable>
                        <IonIcon slot="icon-only" onClick={() => confirmDelete(protocol.id, protocol.activity)} icon={trash} />
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                );
              })}

            </IonList>
          )}

      </IonContent>
    </>
  );
};

export default WorkPreparations;
