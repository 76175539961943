import { IonCheckbox, IonLabel } from '@ionic/react';
import { truncate } from 'lodash';
import { useFormContext } from 'react-hook-form';

import type { CheckboxLabelProps, CheckboxProps } from './interface';
const Checkbox: React.FC<CheckboxProps<CheckboxLabelProps>> = (props) => {
  const methods = useFormContext();

  return (
    <IonCheckbox
      labelPlacement={props.labelPlacement || 'end'}
      value={props.value}
      checked={props.value}
      onIonChange={(e) => props.handleCheckbox(e.detail.checked)}
      {...props}
      disabled={props.disabled ?? false}
    >
      {props.labelProps?.label}
      {props.children}
      <br />
      {props.subNote && (
        <IonLabel className='ion-no-margin ion-text-nowrap' color='medium'>{truncate(props.subNote, { length: 40 })}</IonLabel>
      )}
    </IonCheckbox>
  );
};

export default Checkbox;
