import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.scss';
import AuthenticatedListener from './AuthenticatedListener';
import { BankIdProvider } from './bankid/useBankId';
import AppUrlListener from './components/AppUrlListener';
import EmergencyAlert from './components/EmergencyAlert/EmergencyAlert';
import MediaChangeHandler from './components/Injectors/MediaChangeHandler';
import RedirectHandler from './components/Injectors/RedirectHandler';
import { NetworkInterceptor } from './components/NetworkInterceptors/NetworkInterceptor';
import useLogin from './hooks/useLogin';
import NotificationListener from './NotificationListener';
import FinancialsPage from './pages/Financials/FinancialsPage';
import PermissionDenied from './pages/PermissionDenied';
import ProfilePage from './pages/ProfilePage';
import SiteAccessRequestPages from './pages/SiteAccessRequest';
import { AbilitiesWatchedRoute } from './routes/AbilitiesWatchedRoute';
import DocumentsRouteGroup from './routes/groups/Documents';
import ProjectsRouteGroup from './routes/groups/Projects';
import ToolsRouteGroup from './routes/groups/Tools';
import WorkPreparationsRouteGroup from './routes/groups/WorkPreparation';
import RouteContent from './routes/index';
import NoticeBoardRouteGroup from './routes/NoticeBoard';
import store from './store';

setupIonicReact();

const App: React.FC = () => {
  const login = useLogin();
  return (
    <Provider store={ store }>
      <IonApp>
        <IonReactRouter>
          <BankIdProvider
            baseURL={ process.env.REACT_APP_API_BASE_URL ?? '' }
            handleToken={ (token, router) => login.handleBankIdLogin(token, router) }
          >
            <NetworkInterceptor/>
            <AppUrlListener/>
            <RedirectHandler/>
            <MediaChangeHandler/>
            <EmergencyAlert/>
            <Suspense fallback="Loading...">
              <IonRouterOutlet>
                <AbilitiesWatchedRoute path='/tools'>
                  <ToolsRouteGroup/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path='/documents'>
                  <DocumentsRouteGroup/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path="/work-preparation">
                  <WorkPreparationsRouteGroup/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path={ '/financials' }>
                  <FinancialsPage/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path="/profile">
                  <ProfilePage/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path={ `/projects` }>
                  <ProjectsRouteGroup/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path="/notice-board">
                  <NoticeBoardRouteGroup/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path={ '/unauthorized' }>
                  <PermissionDenied/>
                </AbilitiesWatchedRoute>
                <AbilitiesWatchedRoute path='/site-access'>
                  <SiteAccessRequestPages/>
                </AbilitiesWatchedRoute>
                <RouteContent/>
              </IonRouterOutlet>
            </Suspense>
            <NotificationListener/>
            <AuthenticatedListener/>
          </BankIdProvider>
        </IonReactRouter>
      </IonApp>
      <ToastContainer className={ styles['toast-holder'] }/>
    </Provider>
  );
};

export default App;
