import { IonPage, IonRouterOutlet } from '@ionic/react';

import UploadCertificateForm from './Certificates/UploadCertificateForm';
import Profile from './Profile';
import SettingsPage from './Settings';
import { matchUuid } from '../../constants/matchUuid';
import AppLayout from '../../layout/AppLayout';
import { AbilitiesWatchedRoute } from '../../routes/AbilitiesWatchedRoute';

const ProfilePage: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute path={`/profile/:uuid(${matchUuid})`}>
          <Profile />
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={`/profile/settings`} exact={true}>
          <SettingsPage />
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={'/profile/certificates/new'} exact={true}>
          <AppLayout scroll>
            <UploadCertificateForm />
          </AppLayout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={`/profile/certificates/:certificateId(${matchUuid})/edit`} exact={true}>
          <AppLayout scroll>
            <UploadCertificateForm />
          </AppLayout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={'/profile'} exact={true}>
          <Profile />
        </AbilitiesWatchedRoute>
      </IonRouterOutlet>
    </IonPage>

  );
};
export default ProfilePage;
