import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ItemShadowNoIcons from '../../components/UI/Items/components/ItemShadowNoIcons';
import { ionicColours } from '../../components/UI/variables';

interface ProjectCardProps {
  project: E2U.V1.Models.Project;
  onClick?: (project: E2U.V1.Models.Project) => void;
  href?: any;

}

const ProjectCard: React.FC<ProjectCardProps> = ({ href, onClick, project }: ProjectCardProps) => {
  const { t } = useTranslation();
  return (
    <ItemShadowNoIcons
      onItemClick={() => onClick && onClick(project)}
      hasBorder={true}
      borderColour={project.has_site_access
        ? `5px solid ${project.checked_in && project.has_site_access
          ? ionicColours.success
          : ionicColours.danger
        }`
        : `5px solid ${ionicColours.medium}`}
      label={project.description ?? t('No project description added')}
      description={project.name}
      subLabel={''} />
  );
};

export default ProjectCard;
