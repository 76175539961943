import { IonContent } from '@ionic/react';

import styles from './styles/Documents.module.scss';

const Documents = () => {
  return (
    <IonContent className='ion-padding' style={{ '--background': 'var(--ion-color-light-shade)' }}>
      Documents
    </IonContent>
  );
};

export default Documents;
