import { IonCol, IonGrid, IonIcon, IonLabel, IonRow, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { close, pencil, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import PaddedModalContent from '../../../../components/UI/modals/PaddedModalContent';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import ButtonResponsive from '../../../Onboarding/Components/buttons/ButtonResponsive';
import styles from '../ControlOfExecution.module.scss';
import DetailModal from '../DetailModal/DetailModal';
import EditModal from '../EditModal/EditModal';

export interface RecordViewInterface {
  onClose: () => void;
  recordId?: string
  defaultEdit?: boolean;
  fetchRecords: () => void;
  onCreation: (recordId: string) => void;
}

const RecordView: React.FC<RecordViewInterface> = ({
  defaultEdit = false,
  fetchRecords,
  onClose,
  onCreation,
  recordId
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(defaultEdit);
  const [record, setRecord] = useState<E2U.V1.Models.ControlOfExecution>();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const user = useAppSelector(state => state.authentication.user);

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const fetchSelectedRecord = (record: string, preventToggleEdit = true) => {
    const endpoint = `/api/v1/control_of_executions/${record}`;
    const searchParams = new URLSearchParams();
    searchParams.append('with[]', 'assignees');
    searchParams.append('with[]', 'files');
    searchParams.append('with[]', 'activityCode');

    toasters
      .promise(networking.get(`${endpoint}?${searchParams.toString()}`),
        { error: i18n.t('Could not get controls') }
      )
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.ControlOfExecution>) => {
        setRecord(response.data.data);
        if (!preventToggleEdit) toggleEdit();
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      });
  };

  const handleControlDelete = () => {
    toasters
      .promise(networking.delete(`/api/v1/control_of_executions/${recordId}`),
        {
          error: i18n.t('Could not delete control'),
          success: i18n.t('Deleted control')
        }
      )
      .finally(() => {
        fetchRecords();
        onClose();
      });
  };

  const initiateControlDeletion = () => {
    presentAlert({
      header: i18n.t('Confirm deletion'),
      message: i18n.t('Are you sure you want to delete the control {recordName}?', 'Are you sure you want to delete the control {recordName}?', {
        recordName: record?.name
      }),
      buttons: [
        {
          text: i18n.t('No'),
          role: 'cancel',
        },
        {
          text: i18n.t('Yes'),
          role: 'confirm',
          handler: (values: { message: string }) => {
            handleControlDelete();
          }
        }
      ]
    });
  };

  useEffect(() => {
    if (typeof recordId === 'undefined') return;
    fetchSelectedRecord(recordId);
  }, []);

  const handleRefetch = (newRecordId: string | undefined, fetchAll?: boolean) => {
    if (newRecordId) {
      onCreation(newRecordId);
      fetchSelectedRecord(newRecordId, false);
    } else if (typeof recordId !== 'undefined') {
      fetchSelectedRecord(recordId, false);
    }

    if (fetchAll) {
      fetchRecords();
    }
  };

  return (
    <React.Fragment>
      <PaddedModalContent>
        <IonGrid>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <IonCol size='auto'>
              <BigUp.Title label={t('Create control')}></BigUp.Title>
            </IonCol>
            <IonCol size='auto'>
              <BigUp.Buttons.Icon
                fill='clear'
                onClick={onClose}
                icon={{
                  icon: close,
                  size: 'large'
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {(isEdit || record?.assigned_user_id !== user?.id)
          ? <React.Fragment />
          : <div className={styles['record-view-actions']}>
            <ButtonResponsive
              onClick={() => initiateControlDeletion()}
              mobileButton={{
                ionicButton: { color: 'none', shape: 'round' },
                children: <IonIcon icon={trash} color='danger' size='large' />
              }}
              desktopButton={{
                ionicButton: { color: 'danger' },
                children: (
                  <React.Fragment>
                    <IonIcon icon={trash} />
                    <IonLabel className='ion-no-margin'>{t('Delete')}</IonLabel>
                  </React.Fragment>
                )
              }}
            />
            <ButtonResponsive
              onClick={() => toggleEdit()}
              mobileButton={{
                ionicButton: { color: 'none', shape: 'round' },
                children: <IonIcon icon={pencil} color='primary' size='large' />
              }}
              desktopButton={{
                ionicButton: { color: 'primary' },
                children: (
                  <React.Fragment>
                    <IonIcon icon={pencil} />
                    <IonLabel className='ion-no-margin'>{t('Edit')}</IonLabel>
                  </React.Fragment>
                )
              }}
            />
          </div>
        }

        {isEdit
          ? <EditModal
            toggleBack={() => setIsEdit(false)}
            fetchOnSubmit={handleRefetch}
            onDelete={() => initiateControlDeletion()}
            record={record as any}
          />
          : <DetailModal
            booleans={{ modal: true, }}
            onClose={onClose}
            fetchRecords={fetchRecords}
            onAttested={() => fetchSelectedRecord(recordId as string)}
            record={record}
          />
        }
      </PaddedModalContent>
    </React.Fragment>
  );
};

export default RecordView;
