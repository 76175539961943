import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import { handleSource } from '../../../../components/ListsComponents/rowList/handlers';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import useAcl from '../../../../hooks/useAcl';
import i18n from '../../../../i18n';
import ActionColumn from '../Columns/ActionColumn';

const Changes: React.FC = () => {
  const { t } = useTranslation();
  const { project } = useAcl();
  const router = useIonRouter();
  const [updatedAt, setUpdatedAt] = useState<number>(Date.now());
  const { uuid } = useParams<{ uuid: string | undefined }>();

  const deleteChange = (id: string) => {
    toasters.promise(networking.delete(`/api/v1/economy_changes/${id}`), {
      success: i18n.t('Change deleted'),
      error: i18n.t('Could not delete change'),
    });
  };

  return (
    <DesktopWrapper>
      <IonGrid>
        <IonRow className={'ion-align-items-center'}>
          <IonCol>
            <BigUp.Title label={t('Changes')}/>
          </IonCol>
        </IonRow>
      </IonGrid>

      {project && <BigUp.Table columns={[
        {
          key: 'name',
          label: t('Reference'),
          alignment: 'left',
          sizes: {
            xs: '6',
            xl: '10'
          }
        },
        {
          key: 'actions',
          label: t('Actions'),
          sortable: false,
          body: <ActionColumn type="changes"
            attributes={{} as E2U.V1.Models.EconomyChange}
            callbacks={{
              onDelete: (id: string) => {
                deleteChange(id);
                setUpdatedAt(Date.now());
              }
            }}/>,
          sizes: {
            xs: '6',
            xl: '2'
          },
        }
      ]}
      callbacks={{
        onRowClick: (row: E2U.V1.Models.EconomyChange) => {
          const target = handleSource('changes', project?.id);

          if (typeof target === 'object') {
            router.push(`${target.routes.base}/${row.id}`);
          }
        }
      }}
      sourceUrl={{
        url: `/api/v1/projects/${uuid}/changes`,
        args: {
          'with[]': 'rows'
        }
      }}
      timestamp={updatedAt}/>}
    </DesktopWrapper>
  );
};

export default Changes;
