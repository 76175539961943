import { IonIcon, IonItem, IonList } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { ComponentProps } from 'react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../UI';
import AccessRowLabel from '../../AccessList/AccessRowLabel';

interface MatchingsListProps {
  rows: (string|E2U.V1.Objects.AclFoundEntity)[];
  icon?: ComponentProps<typeof IonIcon>;
  color: string;
}

const MatchingsList: React.FC<MatchingsListProps> = ({ color, icon, rows }) => {
  const [limit, setLimit] = useState<number>(7);
  const { t } = useTranslation();
  const visibleRows = useMemo(
    () => rows.slice(0, limit),
    [rows, limit]
  );

  return (
    <>
      <IonList>
        {visibleRows.map((row, index) =>
          <IonItem key={index}>
            { icon && <IonIcon {...icon} /> }
            {typeof row !== 'string' ? <AccessRowLabel row={row} /> : row}
          </IonItem>
        )}
        {rows.length > visibleRows.length && (
          <IonItem
            button={true}
            onClick={() => setLimit(limit + 10)}
          >
            <i>
              <BigUp.Label.Thick
                label={t('... and {count} more not matching.', { count: rows.length - visibleRows.length })}
                color={color}
              />
            </i>&nbsp;
            <BigUp.Label.Regular
              label={t('Show more')}
            />
          </IonItem>
        )}
      </IonList>
    </>
  );
};

export default MatchingsList;
