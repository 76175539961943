import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonTextarea
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../../api/networking';
import toasters from '../../../../../components/Toasts/Toasts';
import i18n from '../../../../../i18n';

const NotPostedRowsColumn: React.FC<{
  editable: boolean;
  attributes: E2U.V1.Models.NotPostedRow;
  callbacks: {
    onUpdate: () => void;
  }
}> = (props) => {
  const { attributes: row, callbacks, editable } = props;

  const { t } = useTranslation();
  const [addEconomyMonitoring, setAddEconomyMonitoring] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);

  const methods = useForm<Partial<E2U.V1.Models.NotPostedRow>>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      not_posted_cost_total: row?.not_posted_cost_total ?? 0,
      not_posted_cost_total_comment: row?.not_posted_cost_total_comment ?? '',
    }
  });

  const notPostedCostTotal = methods.getValues('not_posted_cost_total');
  const notPostedCostTotalComment = methods.getValues('not_posted_cost_total_comment');

  const toggleAddEconomyMonitoring = () => {
    if (editable) {
      setAddEconomyMonitoring(!addEconomyMonitoring);
      callbacks.onUpdate();
    }
  };

  const onSubmit = (data: Partial<E2U.V1.Models.NotPostedRow>) => {
    toasters.promise(
      networking.put(`/api/v1/not_posted_rows/${row?.id}`, data),
      {
        pending: i18n.t('Adding economy monitoring.'),
        success: i18n.t('Economy monitoring added.'),
        error: i18n.t('Error while adding economy monitoring.'),
      }
    )
      .then(() => {
        toggleAddEconomyMonitoring();
        callbacks.onUpdate();
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {

      });
  };

  useEffect(() => {
    setUpdate(!update);
  }, [notPostedCostTotal, notPostedCostTotalComment]);

  return (
    <React.Fragment>
      {row && (
        <span
          onClick={() => {
            toggleAddEconomyMonitoring();
          }}>
          {
            row.not_posted_cost_total === 0
              ? editable
                ? '+ ' + i18n.t('add')
                : row.not_posted_cost_total
              : row.not_posted_cost_total
          }
        </span>
      )}

      <IonModal
        isOpen={addEconomyMonitoring}
        onDidDismiss={() => {
          setAddEconomyMonitoring(false);
        }}
      >
        <IonContent className='ion-padding'>
          <h1>{t('Not posted')}</h1>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <IonGrid>
              <IonRow className='ion-justify-items-start ion-align-items-start'>
                <IonCol size='12' className='ion-margin-top'>
                  <IonItem className='ion-no-padding'>
                    <IonText color='medium' className='ion-margin-bottom'>
                      {t('Current Value')}: {row?.not_posted_cost_total}
                    </IonText>
                    <IonText color='medium' className='ion-margin-bottom'>
                      {t('Previous Value')}: {row?.previous_not_posted_cost_total}
                    </IonText>
                  </IonItem>
                  <IonItem className='ion-no-padding'>
                    <IonLabel position="stacked" className='ion-no-margin'>
                      {t('Set Quantity')}:
                    </IonLabel>
                    <IonInput
                      {
                        ...methods.register(
                          'not_posted_cost_total',
                          {
                            required: true
                          }
                        )
                      }
                      type='number'
                      inputmode='numeric'
                      placeholder="Enter amount" />
                  </IonItem>
                </IonCol>
                <IonCol size='12' className='ion-margin-top'>
                  <IonItem className='ion-no-padding' counter>
                    <IonLabel position="stacked" className='ion-no-margin'>
                      {t('Comment')}:
                    </IonLabel>
                    <IonTextarea
                      {
                        ...methods.register('not_posted_cost_total_comment', {
                          required: true
                        })
                      }
                      autoGrow
                      placeholder={i18n.t('Enter a description')}
                      maxlength={140} />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className='ion-margin-bottom'>
              <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
                <IonCol size='6' className='ion-text-center'>
                  <IonButton color={'medium'} onClick={() => toggleAddEconomyMonitoring()}>
                    {t('cancel')}
                  </IonButton>
                </IonCol>
                <IonCol size='6' className='ion-text-center'>
                  <IonButton
                    type='submit'
                    disabled={
                      typeof update !== 'undefined' && (
                        methods.formState.isDirty ||
                        !methods.formState.isValid
                      )
                    }
                    onClick={() => {
                      onSubmit(methods.getValues());
                    }}>
                    {t('submit')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonModal>

    </React.Fragment>
  );
};
export default NotPostedRowsColumn;
