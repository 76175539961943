import { IonItem } from '@ionic/react';
import React from 'react';

import BigUp from '..';
import type { SelectOption, SelectProps } from './interface';
import styles from './SelectOutlined.module.scss';
import type { IonicItemProps } from '../Items/Items';

const SelectOutlinedBase: React.FC<{
  label?: string;
  itemProps?: IonicItemProps;
  children: React.ReactNode;
  onClick?: () => void;
}> = (props) => {
  return (
    <>
      {props.label && (
        <BigUp.Label.Thick
          style={{ fontWeight: 600 }}
          label={props.label}
          className={`ion-no-margin`}
        />
      )}
      <IonItem
        {...props.itemProps}
        className={`ion-no-padding ${styles['outlined-select-item']}`}
        lines='none'
        onClick={props.onClick}
      >
        {props.children}
      </IonItem>
    </>
  );
};

export default SelectOutlinedBase;
