import { IonList, useIonRouter } from '@ionic/react';
import {
  lockClosed,
} from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import useEconomyMenuOptions from '../../hooks/useEconomyMenuOptions';
import useToolsMenuOptions from '../../hooks/useToolsMenuOptions';
import AppLayout from '../../layout/AppLayout';
import { setAll, setIsLockable } from '../../reducers/subpanelLock';
import store from '../../store';
import Panel from '../Panels/Panel';
import BigUp from '../UI';
import type { BaseUrlOptions } from '../UI/SideMenu/SideMenuLayout';
import SideMenuV2Layout from '../UI/SideMenu/V2/SideMenuV2Layout';

interface MobilePanelsProps {
  type: BaseUrlOptions;
}

const MobilePanels: React.FC<MobilePanelsProps> = ({ type }) => {
  const { t } = useTranslation();
  const project = useAppSelector(state => state.project.selectedProject);
  const router = useIonRouter();
  const { economyMenuItems } = useEconomyMenuOptions();
  const { toolsMenuItems } = useToolsMenuOptions();
  const menuItems = type === 'notice-board' ? [] : (type === 'tools' ? toolsMenuItems : economyMenuItems);

  const generatePanelListTitle = (type: BaseUrlOptions) => {
    switch (type) {
      case 'financials':
        return t('Economy');
      case 'tools':
        return t('Tools');
      case 'notice-board':
        return t('Notice Board');
      default:
        return '';
    }
  };
  useEffect(() => {
    store.dispatch(setAll(project?.locked));
    store.dispatch(setIsLockable(project?.locked));
  }, [project]);

  return (
    <SideMenuV2Layout>
      <div style={{ padding: '0 8px' }}>
        <IonList className='ion-padding ion-margin-top' mode='ios'>
          <BigUp.Title label={generatePanelListTitle(type)} className={'ion-no-margin ion-no-padding ion-margin-bottom'} />
          {menuItems.map((item, index) => (
            <Panel
              key={index}
              onClick={() => !item.subpanelDisabled && router.push(item.route ?? '')}
              panelTitle={item.subpanelTitle}
              icon={{
                start: {
                  icon: item.subpanelIcon,
                  color: item.subpanelDisabled ? 'medium' : 'dark'
                },
                end: {
                  color: item.subpanelDisabled ? 'medium' : 'primary',
                  icon: item.subpanelDisabled ? lockClosed : undefined
                }
              }}
              disabled={item.subpanelDisabled}
            />
          ))}
        </IonList>
      </div>
    </SideMenuV2Layout>
  );
};

export default MobilePanels;
