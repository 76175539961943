import type { AlertOptions } from '@ionic/react';
import { IonAlert } from '@ionic/react';
import { add } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import { projectInputs } from './inputs';
import { networking } from '../../../../api/networking';
import RelatedMultiSelectEdit from '../../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import BigUp from '../../../../components/UI';
import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import toasters from '../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../hooks';
import useFetchProjects from '../../../../hooks/useFetchProjects';
import usePermissionHandler from '../../../../hooks/usePermissions';
import { setSelectedProject } from '../../../../reducers/project';
import store from '../../../../store';

const Project: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);
  const fetchProjects = useFetchProjects();
  const methods = useFormContext();
  const location = useLocation();
  const { uuid } = useParams<{ uuid: string }>();
  const project = useAppSelector((state) => state.project.selectedProject);
  const { checkPermissionsForProjectAndTools } = usePermissionHandler();
  const { t } = useTranslation();

  const fetchAndSetProjectById = (projectId: string) => {
    networking.get(`/api/v1/projects/${projectId}`)
      .then((response) => {
        const projectData = response.data.data;
        store.dispatch(setSelectedProject(projectData));
        methods.setValue('project', projectData.name);
        setSelectedProjectId(projectData.id);
        if (location.pathname !== `/tools/work-preparation/${projectId}`) {
          history.pushState({}, '', `/tools/work-preparation/${projectId}`);
        }
      })
      .catch(() => {
        toasters.createToast({
          message: t('Error while looking for project'),
          background: 'var(--ion-color-light)',
        }, 'error');
      });
  };

  const handleSelectedProject = (projectId: string) => {
    fetchAndSetProjectById(projectId);
    setIsOpen(false);
  };

  const showErrorOutline = (item: InputProps<any>): boolean => {
    const isTouched = methods.formState.touchedFields[item.register] ?? false;
    const required = item.validation.required;
    const isSet = !methods.watch(item.register);

    return isTouched && required && isSet;
  };

  useEffect(() => {
    if (uuid && uuid !== project?.id) {
      fetchAndSetProjectById(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (project?.id) {
      methods.setValue('project', project?.name);
      methods.setValue('project_id', project?.id);
      setSelectedProjectId(project?.id);
    }
  }, [project]);

  const handleAlert = () => {
    fetchProjects(true);
    setIsOpen(true);
  };

  const inputs = checkPermissionsForProjectAndTools('read', 'read')?.map(project => ({
    label: project.name || '',
    type: 'radio',
    value: project.id || '',
    checked: project.id === selectedProjectId,
  })) || [];

  const items = projectInputs.map((inputProp, index) => ({
    id: (index + 1).toString(),
    errorOutline: (showErrorOutline(inputProp) ?? false),
    content: <BigUp.Input {...inputProp} lines={false} />,
  }));
  const activityCodes = methods.watch('activity_codes') as string[];

  return (
    <FormProvider {...methods}>
      <BigUp.ItemList
        header={{ title: t('Project') }}
        items={items}
        actionButton={{
          id: 'present-alert',
          title: t('Select project'),
          onClick: () => handleAlert(),
        }}
        extraRow={{
          hasExtraRow: true,
          item: { detail: false, button: false },
          content:
            <>
              <BigUp.Label.Regular className='ion-no-margin' color={'dark'} label={t('Activity Codes')} />
              <RelatedMultiSelectEdit
                button='v2'
                buttonSize='small'
                model='activity_codes'
                label={(activityCodes.length > 1)
                  ? t('{amount} selected', { amount: activityCodes.length })
                  : (activityCodes.length === 1
                    ? `${activityCodes[0]?.code} ${activityCodes[0]?.name}`
                    : t('Add codes'))}
                action='submit'
                icon={{ icon: add }}
                hideSelected
                value={activityCodes}
                modalTitle={t('Select activity codes')}
                onChange={(data) => methods.setValue('activity_codes', data)}
                displayFields={['code', 'name']}
              />
            </>
        }}
      />
      <IonAlert
        isOpen={isOpen}
        inputs={inputs as AlertOptions[]}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
          },
          {
            text: t('Select'),
            role: 'confirm',
            handler: (projectId) => handleSelectedProject(projectId || uuid),
          },
        ]}
        onDidDismiss={() => setIsOpen(false)}
      />
    </FormProvider>
  );
};

export default Project;
