import { IonDatetimeButton, IonItem, IonModal } from '@ionic/react';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DateTimeContent from '../../../components/UI/Dates/DatetimeContent';
import InputLabel from '../../../components/UI/InputLabel';

const NoticePublishHandling: React.FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const publishAt = watch('publish_at', new Date().toISOString());
  return (
    <>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Publish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>
          <IonDatetimeButton slot='start' datetime="notice-publish" className='ion-margin-top' />
        </IonItem>
      </div>
      <div className='ion-margin-bottom'>
        <InputLabel margin={'ion-no-margin'} stacked>{t('Unpublish date')}</InputLabel>
        <IonItem className='ion-no-padding' lines='none'>

          <IonDatetimeButton datetime="notice-unpublish" className='ion-margin-top' />
        </IonItem>
      </div>
      <IonModal keepContentsMounted={true}>
        <DateTimeContent
          id='notice-publish'
          dateRegister='publish_at'
        />
      </IonModal>

      <IonModal keepContentsMounted={true}>
        <DateTimeContent
          id='notice-unpublish'
          dateRegister='expire_at'
          min={publishAt}
        />
      </IonModal>
    </>
  );
};

export default NoticePublishHandling;
