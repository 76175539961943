import { IonLoading } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import store from '../../store';
import toasters from '../Toasts/Toasts';
import BigUp from '../UI';

interface DocumentTypeFormProps {
  open: boolean;
  onClose: () => void;
  onSaved: (type: E2U.V1.Models.Type) => void;
}

const DocumentTypeFormModal: React.FC<DocumentTypeFormProps> = ({ onClose, onSaved, open }) => {
  const { t } = useTranslation();
  const savingDocumentType = useAppSelector(state => state.loading.isLoading.savingDocumentType);

  const methods = useForm<E2U.V1.Models.Type>({
    defaultValues: {
      name: '',
      color: '#000000'
    }
  });

  const handleSaveType: SubmitHandler<E2U.V1.Models.Type> = (data) => {
    store.dispatch(setIsLoading({ name: 'savingDocumentType', value: true }));
    (
      data.id
        ? networking.put(`/api/v1/document_types/${data.id}`, data)
        : networking.post('/api/v1/document_types', data)
    )
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        toasters.success(t('Document category saved'));
        methods.reset();
        onSaved(response.data.data);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        toasters.error(t('Failed to save document category'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'savingDocumentType', value: false }));
      });
  };

  return (
    <FormProvider {...methods}>
      <BigUp.Modal.InputModal
        modal={{
          isOpen: open,
          onDismiss: onClose,
          title: t('Name your category'),
          description: t('Give your document category a clear and descriptive name that reflects its content or purpose.')
        }}
        button={{
          title: t('Create'),
          disabled: methods.formState.isSubmitting || !methods.formState.isValid,
          type: 'submit'
        }}
        input={{
          placeholder: t('Enter a name'),
          register: 'name',
          validation: {
            required: true,
            minLength: 3,
            maxLength: 50
          }
        }}
        onSubmit={methods.handleSubmit(handleSaveType)}
      />
      <IonLoading
        isOpen={savingDocumentType}
        message={t('Saving document category...')}
      />
    </FormProvider>
  );
};

export default DocumentTypeFormModal;
