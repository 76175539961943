
import React from 'react';

import type { BankIdHandlerProps } from './bankid.interfaces';
import BankIdHandler from './BankIDHandler';

const BankIdLogin: React.FC<BankIdHandlerProps> = ({ type = 'authenticate' }) => {
  return <BankIdHandler type={type} />;
};

export default BankIdLogin;
