import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import { closeOutline, personCircleOutline } from 'ionicons/icons';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import useProfilePicture from '../../../../hooks/useProfilePicture';
import i18n from '../../../../i18n';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';
import styles from '../modals/styles/PersonalOverview.module.scss';

const PersonalOverviewToolbar: React.FC<OnboardingInterface> = (props) => {
  const router = useIonRouter();
  const user = useAppSelector(state => state.authentication.user);
  const profilePicture = useProfilePicture();

  const handleProfileRedirect = () => {
    router.push('/profile');
    props.togglePersonalOverview!();
  };

  return (
    <React.Fragment>
      <IonToolbar className={`${styles['personaloverview-toolbar']} ion-no-padding`}>
        <IonGrid className='ion-no-padding'>
          <IonRow class="ion-justify-content-between ion-text-center">
            <IonCol size="auto">
              <IonButtons>
                <IonButton color={'light'} shape="round" fill="solid"
                  onClick={() => props.togglePersonalOverview!()}>
                  <IonIcon icon={closeOutline} size="large" color="secondary" />
                </IonButton>
              </IonButtons>
            </IonCol>
            <IonCol size="6" className={styles['onboarding-avatar-container']}>
              <IonAvatar>
                <IonImg
                  alt={i18n.t('Employee profile picture for {name}', 'Employee profile picture for {name}', { name: user?.first_name })}
                  src={profilePicture} />
              </IonAvatar>
              <p>{`${user?.first_name} ${user?.last_name}`}</p>
            </IonCol>
            <IonCol size="auto">
              <IonButtons>
                <IonButton
                  onClick={handleProfileRedirect}
                  style={{ backgroundColor: 'none !important' }}
                  size="large"
                  shape="round"
                  fill="clear" color='none'>
                  <IonIcon icon={personCircleOutline} size="large" color="light" />
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </React.Fragment>
  );
};

export default PersonalOverviewToolbar;
