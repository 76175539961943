import {
  IonButton,
  IonButtons,
  IonCol, IonContent, IonHeader, IonItem, IonModal,
  IonRow,
  IonTitle, IonToolbar
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';
import AccessModuleList from '../AccessModuleList';
import MatchingsList from '../MassAccessResult/MatchingsList';

interface QuickAccessFormProps {
    onAccessGranted?: (result: E2U.V1.Objects.AclGrantAccessResult) => void;
    entity: 'clients' | 'projects';
    id: string;
}

interface QuickAccessFormData {
    recipient: string;
}

const QuickAccessForm: React.FC<QuickAccessFormProps> = (props) => {
  const methods = useForm<QuickAccessFormData>();
  const { t } = useTranslation();
  const [moduleSelectionOpen, setModuleSelectionOpen] = React.useState<boolean>(false);
  const [aclSearchResponse, setAclSearchResponse] = React.useState<E2U.V1.Objects.AclSearchResult | null>(null);

  const fetchAvailablePolicies: SubmitHandler<QuickAccessFormData> = (data) => {
    networking.post(`/api/v1/acl/search`, {
      targets: [data.recipient]
    }).then((response: E2U.V1.Response.Success<E2U.V1.Objects.AclSearchResult>) => {
      if (response.data.data.found.length === 0 && response.data.data.missing.length === 0) {
        toasters.error(t('No matching team or user found'));
        return;
      }
      setAclSearchResponse(response.data.data);
      setModuleSelectionOpen(true);
    }).catch((error) => {
      toasters.error(error.message);
    }).finally(() => {
      methods.reset();
    });
  };

  const handleSuccessfulGrant = (result: E2U.V1.Objects.AclGrantAccessResult) => {
    setModuleSelectionOpen(false);
    setAclSearchResponse(null);
    if (props.onAccessGranted) {
      props.onAccessGranted(result);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(fetchAvailablePolicies)}
          style={{
            width: '100%'
          }}>
          <IonItem className='ion-no-padding' style={{ '--inner-padding-end': 'none' }}>
            <BigUp.Input
              lines={false}
              helperText={undefined}
              itemProps={{
                lines: 'none'
              }}
              register={'recipient'}
              validation={{
                required: t('Team code or user email is required'),
              }}
              placeholder={t('Enter team code or user email')}
              labelPlacement={undefined}
              fill={undefined}
              clearInput={true}
            />
            <BigUp.Buttons.Regular
              type={'submit'}
              slot={'end'}
              size={'small'}
              title={t('Add')}
            />
          </IonItem>
        </form>
      </FormProvider>
      <IonModal
        isOpen={moduleSelectionOpen}
        onDidDismiss={() => setModuleSelectionOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setModuleSelectionOpen(false)}>{
                t('Cancel')
              }</IonButton>
            </IonButtons>
            <IonTitle>{t('Review and assign access')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {aclSearchResponse && aclSearchResponse.missing && aclSearchResponse.missing.length > 0 && <>
            <IonRow>
              <IonCol>
                <BigUp.Label.Thick label={t('New users to be invited')}/>
              </IonCol>
            </IonRow>
            <MatchingsList
              rows={aclSearchResponse.missing.map(r => r.id)}
              color={'success'}
            />
          </>}
          <IonRow className={'ion-margin-top'}>
            <IonCol>
              <BigUp.Label.Thick label={t('Select licenses to assign')}/>
            </IonCol>
          </IonRow>
          <AccessModuleList
            entity={props.entity}
            id={props.id}
            rows={aclSearchResponse}
            handleClose={() => setModuleSelectionOpen(false)}
            onSuccessfulGrant={handleSuccessfulGrant}
          />
        </IonContent>
      </IonModal>
    </>
  );
};

export default QuickAccessForm;
