import type { SVG } from './types';

export const WorkingEnvironment = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;

  return (
    <svg
      width={width || 41}
      height={height || 43}
      viewBox="0 0 41 43"
      {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.1046 7.48763C40.0848 7.15106 39.9601 6.83136 39.7505 6.5797C39.541 6.32805 39.2587 6.15899 38.9489 6.09959C31.3463 4.63809 28.1726 3.58152 21.0781 0.148138C20.896 0.0599938 20.6986 0.0144043 20.4989 0.0144043C20.2991 0.0144043 20.1017 0.0599938 19.9197 0.148138C12.8251 3.58152 9.6514 4.63809 2.04886 6.09959C1.73902 6.15899 1.45674 6.32805 1.24719 6.5797C1.03765 6.83136 0.912967 7.15106 0.893101 7.48763C0.554722 13.2423 1.2763 18.6042 3.04027 23.4247C4.48477 27.3567 6.62253 30.9538 9.33675 34.0196C14.0363 39.3618 19.0258 41.6821 19.9777 42.0927C20.3159 42.2393 20.6941 42.2393 21.0324 42.0927C21.9842 41.6821 26.9738 39.3618 31.6733 34.0196C34.3831 30.9527 36.5166 27.3557 37.9575 23.4247C39.7214 18.6042 40.443 13.2423 40.1046 7.48763ZM28.5909 14.5606L18.8527 26.6142C18.7275 26.7696 18.5739 26.896 18.4015 26.9856C18.229 27.0751 18.0413 27.1259 17.8498 27.135H17.7918C17.4238 27.135 17.0704 26.9805 16.8075 26.7046L12.4832 22.1657C12.3513 22.0272 12.2461 21.8622 12.1737 21.6801C12.1013 21.4981 12.0631 21.3025 12.0612 21.1046C12.0574 20.705 12.2019 20.3201 12.463 20.0346C12.7241 19.7492 13.0803 19.5865 13.4533 19.5825C13.8263 19.5784 14.1856 19.7333 14.452 20.013L17.7039 23.4304L26.4666 12.5887C26.5875 12.4391 26.7346 12.3164 26.8997 12.2277C27.0648 12.1391 27.2446 12.0861 27.4288 12.0718C27.613 12.0576 27.798 12.0823 27.9733 12.1447C28.1486 12.207 28.3107 12.3058 28.4503 12.4352C28.59 12.5647 28.7045 12.7224 28.7873 12.8993C28.87 13.0762 28.9195 13.2688 28.9328 13.4661C28.9461 13.6635 28.923 13.8617 28.8648 14.0495C28.8066 14.2373 28.7144 14.411 28.5936 14.5606H28.5909Z" fill="url(#paint0_linear_1392_23272)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23272" x1="20.4989" y1="0.0144043" x2="20.4989" y2="42.2026" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EEDC5B" />
          <stop offset="1" stop-color="#F19A1B" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default WorkingEnvironment;
