import React, { useMemo } from 'react';
import { Redirect, Route, matchPath } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import SideMenuV2Layout from '../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../hooks';
import useAcl from '../hooks/useAcl';
/*
Control of execution: can_view_control_of_executions_in_projects
Working Environment: can_view_working_environments_in_projects
Site Access Request: can_view_access_requests_in_projects
Documents: can_view_documents_in_projects
Worksites: can_view_work_sites_in_projects
Map: can_view_work_sites_in_projects
Work Preparations: can_view_protocols_in_projects
Economy

Project precalculations: can_view_precalculations_in_projects
Production budget: can_view_production_budget_in_projects
Cash flow: can_view_manual_cash_flow_in_projects
Payment schedule: can_view_payment_schedule_in_projects
Economy monitoring: can_view_not_posted_in_projects
Economy reports: can_view_economy_monitoring_in_projects
Deviations: can_view_deviations_in_projects
Changes: can_view_changes_in_projects
*/
type Abilities =
    'can_view_control_of_executions_in_projects'
    | 'can_view_working_environments_in_projects'
    | 'can_view_access_requests_in_projects'
    | 'can_view_documents_in_projects'
    | 'can_view_work_sites_in_projects'
    | 'can_view_protocols_in_projects'
    | 'can_view_precalculations_in_projects'
    | 'can_view_production_budget_in_projects'
    | 'can_view_manual_cash_flow_in_projects'
    | 'can_view_payment_schedule_in_projects'
    | 'can_view_not_posted_in_projects'
    | 'can_view_economy_monitoring_in_projects'
    | 'can_view_deviations_in_projects'
    | 'can_view_changes_in_projects'
    | string;

export type AclAbility = {
    id: string;
    name: Abilities;
}

type ProtectedRouteProps = RouteProps

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { isOnboarded } = useAcl();
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);
  const user = useAppSelector(state => state.authentication.user);

  const handlePathMatching = () => {
    return (
      !matchPath(location.pathname, '/login') &&
            !matchPath(location.pathname, '/reset') &&
            !matchPath(location.pathname, '/join') &&
            !matchPath(location.pathname, '/registration') &&
            !matchPath(location.pathname, '/support')
    );
  };

  const routeAccessibleForNonBoarded = () => {
    return (
      matchPath(location.pathname, '/profile') ||
            matchPath(location.pathname, '/client-account') ||
            matchPath(location.pathname, '/introduction')
    );
  };

  return (<Route
    {...props}
    render={() => !user
      ? handlePathMatching()
        ? (
          isLoadingUser ? props.children : <Redirect to="/login" />
        )
        : <></>
      : (
        (!isOnboarded() && !routeAccessibleForNonBoarded())
          ? <Redirect to="/introduction"/>
          : props.children
      )
    }
  />);
};

export default ProtectedRoute;
