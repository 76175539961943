import type { SVG } from './types';

export const ProjectInformation = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '33'} height={height || '45'} viewBox="0 0 33 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.7779 3.40479H25.7305C25.583 3.40464 25.4389 3.36007 25.3171 3.2769C25.1953 3.19372 25.1014 3.07579 25.0475 2.93846C24.7653 2.2113 24.2701 1.58631 23.6267 1.14537C22.9833 0.704424 22.2217 0.468077 21.4417 0.467285H11.5515C10.7716 0.468077 10.01 0.704424 9.36658 1.14537C8.72318 1.58631 8.22795 2.2113 7.94576 2.93846C7.89193 3.07579 7.79798 3.19372 7.67616 3.2769C7.55434 3.36007 7.4103 3.40464 7.2628 3.40479H6.21539C4.65725 3.40479 3.16292 4.02376 2.06114 5.12553C0.959364 6.22731 0.340393 7.72164 0.340393 9.27979V38.6548C0.340393 40.2129 0.959364 41.7073 2.06114 42.809C3.16292 43.9108 4.65725 44.5298 6.21539 44.5298H26.7779C28.336 44.5298 29.8304 43.9108 30.9321 42.809C32.0339 41.7073 32.6529 40.2129 32.6529 38.6548V9.27979C32.6529 7.72164 32.0339 6.22731 30.9321 5.12553C29.8304 4.02376 28.336 3.40479 26.7779 3.40479ZM22.3597 9.27979H10.6336C10.244 9.27979 9.87046 9.12504 9.59501 8.8496C9.31957 8.57415 9.16483 8.20057 9.16483 7.81104C9.16483 7.4215 9.31957 7.04792 9.59501 6.77247C9.87046 6.49703 10.244 6.34229 10.6336 6.34229H22.3597C22.7492 6.34229 23.1228 6.49703 23.3983 6.77247C23.6737 7.04792 23.8285 7.4215 23.8285 7.81104C23.8285 8.20057 23.6737 8.57415 23.3983 8.8496C23.1228 9.12504 22.7492 9.27979 22.3597 9.27979Z" fill="url(#paint0_linear_1392_23287)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23287" x1="16.4966" y1="0.467285" x2="16.4966" y2="44.5298" gradientUnits="userSpaceOnUse">
          <stop stop-color="#C2689E" />
          <stop offset="1" stop-color="#612680" />
        </linearGradient>
      </defs>
    </svg>
  );
};
