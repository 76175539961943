import { checkmarkCircleSharp, closeCircleSharp, informationSharp, warningSharp } from 'ionicons/icons';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';

import { ToastIcon, getDefaultToastConfig } from './config';
import type { ToastContent, ToastTypes } from './interface';
import type { ColourVariables, IonicThemeColours } from '../variables';

const createToast = (content: ToastContent, type: ToastTypes) => {
  let toastTypeFunction: (message: ToastContent['message'], options?: ToastOptions) => void;
  let defaultIconColour: IonicThemeColours;
  let defaultIcon: HTMLIonIconElement['icon'];
  let defaultBackground: ColourVariables;
  let defaultTextColour: ColourVariables;

  switch (type) {
    case 'info':
      toastTypeFunction = toast.info;
      defaultIconColour = 'primary';
      defaultIcon = informationSharp;
      defaultBackground = 'var(--ion-color-light)';
      defaultTextColour = 'var(--ion-color-medium)';
      break;
    case 'warning':
      toastTypeFunction = toast.warning;
      defaultIconColour = 'warning';
      defaultIcon = warningSharp;
      defaultBackground = 'var(--ion-color-light)';
      defaultTextColour = 'var(--ion-color-medium)';
      break;
    case 'error':
      toastTypeFunction = toast.error;
      defaultIconColour = 'danger';
      defaultIcon = closeCircleSharp;
      defaultBackground = 'var(--ion-color-light)';
      defaultTextColour = 'var(--ion-color-medium)';
      break;
    case 'success':
      toastTypeFunction = toast.success;
      defaultIconColour = 'success';
      defaultIcon = checkmarkCircleSharp;
      defaultBackground = 'var(--ion-color-light)';
      defaultTextColour = 'var(--ion-color-medium)';
      break;
    default:
      toastTypeFunction = toast.info;
      defaultIconColour = 'primary';
      defaultIcon = informationSharp;
      defaultBackground = 'var(--ion-color-light)';
      defaultTextColour = 'var(--ion-color-medium)';
      break;
  }

  const {
    background = defaultBackground,
    icon = defaultIcon,
    iconColour = defaultIconColour,
    message,
    textColour = defaultTextColour
  } = content;

  toastTypeFunction(message, {
    ...getDefaultToastConfig(background, textColour),
    icon: ToastIcon(icon, iconColour),
  });
};

export default createToast;
