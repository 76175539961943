import { useIonRouter, useIonViewWillEnter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { ellipsisHorizontal, serverSharp } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import { useDeleteConfirmation } from '../../../hooks/useDeleteConfirmation';
import useModal from '../../../hooks/useModal';
import EmptyList from '../EmptyList';
import toasters from '../Toasts';

export interface Certificate {
  name: string;
  type: string;
  id: string;
  user_id: string;
  issuing_organization: string;
  issue_date: string;
  expiration_date: string;
  user: E2U.V1.Models.User;
  validated: boolean;
  files: E2U.V1.Models.File[];
}

const UserCertificateList: React.FC = () => {
  const router = useIonRouter();
  const filePreviewModal = useModal();
  const { handleDeleteConfirmation } = useDeleteConfirmation({});
  const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const user = useAppSelector(state => state.authentication.user);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const { t } = useTranslation();

  const fetchCertificates = () => {
    networking.get(`/api/v1/users/${user?.id}/certificates?with=files`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<Certificate>>) => {
        setCertificates(response.data.data.records);
        console.log(response.data.data.records);
      });
  };

  const handlePreviewToggle = (action: 'open' | 'close', certificate?: any) => {
    if (action === 'open') {
      setSelectedCertificate(certificate);
      filePreviewModal.openModal();
    } else {
      setSelectedCertificate(null);
      filePreviewModal.closeModal();
    }
  };

  const handleDelete = (id: string) => {
    networking.delete(`/api/v1/certificates/${id}`)
      .then(() => {
        toasters.createToast({
          message: t('Certificate deleted'),
          background: 'var(--ion-color-light)',
        }, 'success');
        fetchCertificates();
      });
  };

  const popoverListItems = (id: string, file?: E2U.V1.Models.File) => [
    {
      value: 'Delete',
      label: t('Delete'),
      onClick: () => handleDeleteConfirmation(() => handleDelete(id),
        t('Confirm delete'),
        t('Are you sure you want to delete this certificate?')
      )
    },
    {
      value: 'Edit',
      label: t('Edit'),
      onClick: () => router.push(`/profile/certificates/${id}/edit`)
    },
    {
      value: 'cancel',
      label: t('Cancel'),
      onClick: () => console.log('handle something')
    },
  ];

  const items = certificates.map((certificate, i) => ({
    id: certificate.id,
    content: (
      <>
        <BigUp.Item.FileItem
          triggerID={`trigger-item-${i}`}
          key={certificate.id}
          name={certificate.name}
          description={certificate.issuing_organization}
          source={certificate.files[0]}
          icon={{
            icon: {
              icon: ellipsisHorizontal,
            }
          }}
          onPreview={() => handlePreviewToggle('open', certificate)}
        />
        {isDesktop
          ? <BigUp.Popovers.Default
            items={popoverListItems(certificate.id)}
            actionId={`trigger-item-${i}`}
          />
          : <BigUp.ActionSheets.ActionSheet
            header={certificate.name}
            items={popoverListItems(certificate.id)}
            actionId={`trigger-item-${i}`}
          />
        }
      </>
    ),
  }));

  useIonViewWillEnter(() => {
    fetchCertificates();
  }, []);

  return (
    <>

      <BigUp.ItemList
        items={items}
        customItem={true}
        header={{
          title: t('Certificates and permissions'),
          actionIcon: {
            onClick: () => router.push('/profile/certificates/new'),
          }
        }}
      />
      {selectedCertificate && (
        <BigUp.Modal.FilePreviewModal
          fileContent={selectedCertificate.file}
          file={selectedCertificate.files[0]}
          isModalOpen={filePreviewModal.isModalOpen}
          closeModal={() => handlePreviewToggle('close')}
        />
      )}
      {!items.length && (
        <EmptyList
          icon={{ size: 60, icon: serverSharp }}
          title={t('No certificates has been uploaded')}
          message={''}
          helperLinks={{
            title: t('Press here to upload'),
            url: '/profile/certificates/new'
          }}
        />
      )}
    </>
  );
};

export default UserCertificateList;
