import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';

interface SectionFormProps {
  onSectionCreated: (section: any) => void;
  type: 'projects' | 'clients';
  id: string;
  section?: any;
}

interface SectionFormData {
  id: string | null;
  name: string;
  required_note: boolean;
}

const SectionForm: React.FC<SectionFormProps> = ({ onSectionCreated, section, type }) => {
  const { t } = useTranslation();
  const { node_id } = useParams<{ node_id: string }>();
  const methods = useForm<SectionFormData>({
    defaultValues: {
      id: null,
      name: '',
      required_note: false
    }
  });
  const requiredNote = methods.watch('required_note');

  const createSection: SubmitHandler<SectionFormData> = (data) => {
    store.dispatch(setIsLoading({
      name: 'creatingSection',
      value: true
    }));
    const idColumn = type === 'projects' ? 'project_node_id' : 'client_node_id';
    const networkType = data.id ? networking.put : networking.post;
    const idSuffix = data.id ? `/${data.id}` : '';
    networkType(`/api/v1/work_preparations/sections${idSuffix}`, {
      id: data.id,
      name: data.name,
      required_note: data.required_note,
      [idColumn]: node_id
    })
      .then((res: E2U.V1.Response.Success<any>) => {
        onSectionCreated(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Something went wrong when trying to create section. Please try again later or contact support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'creatingSection',
          value: false
        }));
      });
  };

  const handleExistingSection = () => {
    if (!section) {
      return;
    }
    methods.setValue('id', section.id);
    methods.setValue('name', section.name);
    methods.setValue('required_note', section.required_note);
  };

  useEffect(() => {
    handleExistingSection();
  }, [section]);

  useEffect(() => {
    handleExistingSection();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(createSection)}>
        <IonGrid>
          <IonRow>
            <IonCol>
              <BigUp.Input
                register={'name'}
                label={t('Name')}
                required
                validation={{
                  required: t('Name is required')
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <BigUp.Checkbox
                checked={requiredNote}
                labelProps={{
                  label: t('Required note')
                }}
                handleCheckbox={() => methods.setValue('required_note', !requiredNote)}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <BigUp.Buttons.Regular
                title={t('Create')}
                type={'submit'}
                color={'primary'}
                className={'ion-margin-top'}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default SectionForm;
