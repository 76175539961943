import { IonPage, IonRouterOutlet } from '@ionic/react';
import React from 'react';

import { AbilitiesWatchedRoute } from './AbilitiesWatchedRoute';
import { matchUuid } from '../constants/matchUuid';
import Notice from '../pages/Notice/Notice';
import NoticeFormPage from '../pages/NoticeBoard/Form';
import NoticeBoard from '../pages/NoticeBoard/NoticeBoard';

const NoticeBoardRouteGroup: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute
          path={`/notice-board/:uuid(${matchUuid})/:notice_uuid(${matchUuid})?/add`}
          exact={true}
        >
          <NoticeFormPage />
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/notice-board/:uuid(${matchUuid})/notice/:notice_uuid(${matchUuid})`}
          exact={true}
        >
          <Notice />
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/notice-board/:uuid(${matchUuid})?`}
          exact={true}
        >
          <NoticeBoard />
        </AbilitiesWatchedRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default NoticeBoardRouteGroup;
