import { useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import {
  calculatorOutline, calendarClearOutline,
  clipboardOutline, cubeOutline, fileTrayFullOutline, gitNetworkOutline,
  logoEuro, medicalOutline, statsChartOutline
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { networking } from '../api/networking';
import Toast from '../components/Toasts/Toast';
import toasters from '../components/Toasts/Toasts';
import { useAppSelector } from '../hooks';
import i18n from '../i18n';
import type { SubpanelItem } from '../pages/DesktopCards';
import CashFlow from '../pages/Financials/Subpanels/CashFlow/CashFlow';
import PrecalculationsList from '../pages/Financials/Subpanels/Precalculations/PrecalculationsList';
import ProductionBudget from '../pages/Financials/Subpanels/ProductionBudget/ProductionBudget';
import ProjectInformation from '../pages/Financials/Subpanels/ProjectInformation/ProjectInformation';
import { setIsLoading } from '../reducers/loading';
import { setSelectedProject } from '../reducers/project';
import store from '../store';
import useAcl from './useAcl';

const useEconomyMenuOptions = () => {
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const subpanelsLockAll = useAppSelector((state) => state.lockSubpanels.isLocked);
  const subpanelLockable = useAppSelector((state) => state.lockSubpanels.isLockable);
  const { scopedAbilities } = useAcl();
  const router = useIonRouter();
  const { t } = useTranslation();

  const fetchProject = () => {
    if (
      typeof selectedProject === 'undefined' ||
            typeof selectedProject?.id === 'undefined'
    ) {
      Toast(t('Project not found'), 'error');
      router.push('/financials');
      return;
    }
    store.dispatch(setIsLoading({ name: 'economy', value: true }));

    toasters
      .promise(networking.get(`/api/v1/projects/${selectedProject?.id}`), {
        error: i18n.t('Error while loading projects')
      })
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Project>) => {
        store.dispatch(setSelectedProject(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'economy', value: false }));
      });
  };

  const ECONOMY_TOOLS_ACCORDION_LIST: SubpanelItem[] = [
    {
      subpanelTitle: i18n.t('Project information'),
      subpanelIcon: clipboardOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${selectedProject?.id}/project-information`,
      component: <ProjectInformation
        fetchProject={fetchProject}
        project={selectedProject}
        isLocked={subpanelsLockAll.projectInformation}
        isLockable={subpanelLockable}
      />,
      value: 'project-information',
      locked: subpanelsLockAll.projectInformation
    },
    {
      subpanelTitle: i18n.t('Project precalculations'),
      subpanelIcon: calculatorOutline,
      subpanelActiveColour: 'var(--ion-color-danger)',
      subpanelDisabled: !scopedAbilities.includes('can_view_precalculations_in_projects'),
      route: `${selectedProject?.id}/precalculations`,
      component: <PrecalculationsList
        isLocked={subpanelsLockAll.precalculations}
        isLockable={subpanelLockable}/>,
      value: 'precalculations',
      locked: subpanelsLockAll.precalculations,

    },
    {
      subpanelTitle: i18n.t('Production budget'),
      subpanelIcon: statsChartOutline,
      subpanelActiveColour: 'rgb(246, 154, 235)',
      subpanelDisabled: !scopedAbilities.includes('can_view_production_budget_in_projects'),
      route: `${selectedProject?.id}/production-budget`,
      component: <ProductionBudget isLocked={subpanelsLockAll.productionBudgets} fetchProject={fetchProject}/>,
      value: 'production-budget',
      locked: subpanelsLockAll.productionBudgets
    },
  ];

  const ONLY_ON_LOCK: SubpanelItem[] = [
    {
      subpanelTitle: i18n.t('Cash flow'),
      subpanelIcon: gitNetworkOutline,
      subpanelActiveColour: 'lightgreen',
      subpanelDisabled: !scopedAbilities.includes('can_view_manual_cash_flow_in_projects'),
      route: `${selectedProject?.id}/cash-flow`,
      component: <CashFlow onCalculated={fetchProject}/>,
      value: 'cash-flow'
    },
    {
      subpanelTitle: i18n.t('Payment schedule'),
      subpanelIcon: calendarClearOutline,
      subpanelActiveColour: 'rgb(74, 237, 237)',
      subpanelDisabled: !scopedAbilities.includes('can_view_payment_schedule_in_projects'),
      route: `${selectedProject?.id}/payment-schedule`,
      value: 'payment-schedule'
    },
    {
      subpanelTitle: i18n.t('Economy monitoring'),
      subpanelIcon: fileTrayFullOutline,
      subpanelActiveColour: 'var(--ion-color-success)',
      subpanelDisabled: !scopedAbilities.includes('can_view_not_posted_in_projects'),
      route: `${selectedProject?.id}/economy-monitoring`,
      value: 'economy-monitoring'
    },
    {
      subpanelTitle: i18n.t('Economy reports'),
      subpanelIcon: logoEuro,
      subpanelActiveColour: 'var(--ion-color-secondary)',
      subpanelDisabled: !scopedAbilities.includes('can_view_economy_monitoring_in_projects'),
      route: `${selectedProject?.id}/economy-reports`,
      value: 'economy-reports'
    },
    {
      subpanelTitle: i18n.t('Deviations'),
      subpanelIcon: medicalOutline,
      subpanelActiveColour: 'var(--ion-color-danger)',
      subpanelDisabled: !scopedAbilities.includes('can_view_deviations_in_projects'),
      route: `${selectedProject?.id}/deviations`,
      value: 'deviations'
    },
    {
      subpanelTitle: i18n.t('Changes'),
      subpanelIcon: cubeOutline,
      subpanelActiveColour: 'var(--ion-color-medium)',
      subpanelDisabled: !scopedAbilities.includes('can_view_changes_in_projects'),
      route: `${selectedProject?.id}/changes`,
      value: 'changes',
    },
  ];

  const economyMenuItems: SubpanelItem[] = [
    ...ECONOMY_TOOLS_ACCORDION_LIST,
    ...(subpanelsLockAll.subpanelsOnlyOnLock ? ONLY_ON_LOCK : []),
  ];

  return {
    economyMenuItems
  };
};

export default useEconomyMenuOptions;
