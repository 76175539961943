
import type { SVG } from './types';

export const SiteAccessRequest = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;
  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '46'} height={height || '46'} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.0642 1.43848H12.9392C11.4147 1.44014 9.95316 2.04648 8.87518 3.12445C7.79721 4.20242 7.19087 5.66399 7.18921 7.18848V38.8135C7.19087 40.338 7.79721 41.7995 8.87518 42.8775C9.95316 43.9555 11.4147 44.5618 12.9392 44.5635H33.0642C34.5887 44.5618 36.0503 43.9555 37.1282 42.8775C38.2062 41.7995 38.8125 40.338 38.8142 38.8135V7.18848C38.8125 5.66399 38.2062 4.20242 37.1282 3.12445C36.0503 2.04648 34.5887 1.44014 33.0642 1.43848ZM29.9628 25.5624C30.6429 26.2964 30.9753 27.2838 30.8963 28.3422C30.7409 30.4499 29.1335 32.165 27.3142 32.165C25.4949 32.165 23.8849 30.4499 23.7312 28.3422C23.654 27.2731 23.9837 26.2839 24.6602 25.557C25.3367 24.8302 26.272 24.4385 27.3142 24.4385C27.8096 24.4314 28.301 24.5276 28.757 24.7212C29.2131 24.9147 29.6238 25.2012 29.9628 25.5624ZM17.2517 7.18848C17.2517 6.80723 17.4032 6.44159 17.6727 6.17201C17.9423 5.90243 18.308 5.75098 18.6892 5.75098H27.3142C27.6955 5.75098 28.0611 5.90243 28.3307 6.17201C28.6003 6.44159 28.7517 6.80723 28.7517 7.18848C28.7517 7.56973 28.6003 7.93536 28.3307 8.20494C28.0611 8.47453 27.6955 8.62598 27.3142 8.62598H18.6892C18.308 8.62598 17.9423 8.47453 17.6727 8.20494C17.4032 7.93536 17.2517 7.56973 17.2517 7.18848ZM34.2322 39.8763C34.1286 39.9962 34.0001 40.0918 33.8556 40.1565C33.711 40.2213 33.5541 40.2535 33.3957 40.251H21.2327C21.0743 40.2535 20.9174 40.2213 20.7729 40.1565C20.6283 40.0918 20.4998 39.9962 20.3962 39.8763C20.284 39.7419 20.2034 39.5839 20.1605 39.414C20.1176 39.2442 20.1134 39.0669 20.1483 38.8952C20.4403 37.3176 21.3504 35.9924 22.7807 35.0625C24.0511 34.2368 25.6611 33.7822 27.3142 33.7822C28.9673 33.7822 30.5773 34.2368 31.8477 35.0625C33.278 35.9924 34.1882 37.3176 34.4802 38.8952C34.515 39.0669 34.5109 39.2442 34.4679 39.414C34.425 39.5839 34.3444 39.7419 34.2322 39.8763Z" fill="url(#paint0_linear_1392_23274)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23274" x1="23.0017" y1="1.43848" x2="23.0017" y2="44.5635" gradientUnits="userSpaceOnUse">
          <stop stop-color="#24C6DC" />
          <stop offset="0.605" stop-color="#777FDB" />
          <stop offset="1" stop-color="#A658DB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
