import type { E2U } from '@techlove/easy2use-typings';

import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import i18n from '../../../i18n';
import { regex } from '../../../regex/regex';

const worksite_inputs_fields: InputProps<E2U.V1.Models.WorkSite>[] = [
  {
    label: i18n.t('Name'),
    placeholder: i18n.t('Enter name'),
    labelPlacement: 'stacked',
    register: 'name' as keyof E2U.V1.Models.WorkSite,
    type: 'text',
    inputmode: 'text',
    validation: {
      required: i18n.t('A name is required') as string,
      min: {
        value: 2,
        message: i18n.t('Name must be at least 2 characters') as string
      },
      pattern: {
        value: regex.regex_name,
        message: i18n.t('Name must be alphabetical') as string
      }
    }
  },
  {
    label: i18n.t('Address'),
    placeholder: i18n.t('Enter address'),
    labelPlacement: 'stacked',
    register: 'address' as keyof E2U.V1.Models.WorkSite,
    type: 'text',
    inputmode: 'text',
    validation: {
      required: i18n.t('Address is required') as string,
      min: {
        value: 2,
        message: i18n.t('Address must be at least 2 characters') as string
      },
    }
  },
  {
    label: i18n.t('Zip Code'),
    placeholder: i18n.t('Enter zip code'),
    labelPlacement: 'stacked',
    register: 'zip_code' as keyof E2U.V1.Models.WorkSite,
    type: 'text',
    inputMode: 'numeric',
    validation: {
      required: i18n.t('Zip Code is required') as string,
      minLength: {
        value: 5,
        message: i18n.t('Zip Code must be at least 5 characters') as string
      },
      pattern: {
        value: regex.regex_numeric,
        message: i18n.t('Zip Code must be a number') as string
      }
    }
  },
  {
    label: i18n.t('City'),
    placeholder: i18n.t('Enter city'),
    labelPlacement: 'stacked',
    register: 'city' as keyof E2U.V1.Models.WorkSite,
    type: 'text',
    inputMode: 'text',
    validation: {
      required: i18n.t('City is required')as string,
      min: {
        value: 2,
        message: i18n.t('City must be at least 2 characters') as string
      },
    }
  },
];

export default worksite_inputs_fields;
