import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react';
import classNames from 'classnames';
import type { ComponentProps } from 'react';
import React from 'react';

import styles from './FabButton.module.scss';
import { useAppSelector } from '../../../../hooks';
import { setIsBackdropVisible } from '../../../../reducers/navigation';
import store from '../../../../store';
import BigUp from '../../index';

type HTMLGenericProps<T> = React.DetailedHTMLProps<
  React.HTMLAttributes<T>, T
>
type FabProps = ComponentProps<typeof IonFab> &
  HTMLGenericProps<HTMLIonFabElement>;

export interface FabButtonProps extends FabProps {
  icon: ComponentProps<typeof IonIcon>;
  button?: ComponentProps<typeof IonFabButton>;
  list?: ComponentProps<typeof IonFabList> & {
    buttons: (
      ComponentProps<typeof IonFabButton>
      & {
        icon?: ComponentProps<typeof IonIcon>,
        label?: string,
      }
    )[];
  }
}
const FabButton: React.FC<FabButtonProps> = (props) => {
  const backdropIsVisible = useAppSelector(state => state.navigation.isBackdropVisible);
  const toggleOpen = () => store.dispatch(setIsBackdropVisible(!backdropIsVisible));

  return (
    <IonFab {...props}>
      {props.children}
      <IonFabButton
        disabled={props.button?.disabled}
        {...props.button}
        id={props.id}
        color={props.color}
        className={classNames(styles['FabButton--button'], props.button?.className ?? '')}
        onClick={(event) => {
          if (props.button?.onClick) {
            props.button.onClick(event);
          }
          toggleOpen();
        }}
        onAbort={(event) => {
          if (props.button?.onAbort) {
            props.button.onAbort(event);
          }
          toggleOpen();
        }}
      >
        <IonIcon {...props.icon}></IonIcon>
      </IonFabButton>
      {props.list && (
        <IonFabList {...props.list} className={classNames(styles['FabButton--list'], props.list.className ?? '')}>
          {props.list.buttons.map((button, index) => (
            <React.Fragment key={index}>
              {button.label && <BigUp.Label.V2Thick label={button.label} />}
              <IonFabButton
                {...button}
                onClick={(event) => {
                  if (button.onClick) {
                    button.onClick(event);
                  }
                  toggleOpen();
                }}
              >
                {button.icon && <IonIcon {...button.icon}></IonIcon>}
              </IonFabButton>
            </React.Fragment>
          ))}
        </IonFabList>
      )}
    </IonFab>
  );
};

export default FabButton;
