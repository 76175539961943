import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useParams } from 'react-router';

import MassAccessForm from '../../../components/AccessManagement/MassAccessForm';
import MassAccessResult from '../../../components/AccessManagement/MassAccessResult';
import BigUp from '../../../components/UI';
import { matchUuid } from '../../../constants/matchUuid';
import { useAppSelector } from '../../../hooks';

interface MassAssignmentPageProps {
  type: 'clients' | 'projects';
}

const MassAssignmentPage: React.FC<MassAssignmentPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const { tool } = useParams<{ tool?: string }>();
  const router = useIonRouter();
  const { client_id } = useParams<{ client_id: string }>();
  const [content, setContent] = useState<E2U.V1.Objects.AclSearchResult|null>(null);
  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const handleContentProcessed = (recipients: E2U.V1.Objects.AclSearchResult) => {
    setContent(recipients);
    router.push(router.routeInfo.pathname.replace('/import', '/review'));
  };

  useEffect(() => {
    if (tool === undefined || tool === '') {
      router.push(router.routeInfo.pathname + '/import');
    }
  }, []);

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Grant multiple access')} />
          </IonCol>
        </IonRow>
        {type === 'clients'
          ? <>
            <Route path={`/client-account/:client_id(${matchUuid})/access/add/review`} exact>
              <MassAccessResult
                content={content}
                entity={'clients'}
                id={client_id}
              />
            </Route>
            <Route path={`/client-account/:uuid(${matchUuid})/access/add/import`} exact>
              <MassAccessForm onContentProcessed={handleContentProcessed} />
            </Route>
          </>
          : <>
            <Route path={`/tools/:uuid(${matchUuid})/settings/access/add/review`} exact>
              <MassAccessResult
                content={content}
                entity={'projects'}
                id={selectedProject?.id ?? ''}
              />
            </Route>
            <Route path={`/tools/:uuid(${matchUuid})/settings/access/add/import`} exact>
              <MassAccessForm onContentProcessed={handleContentProcessed} />
            </Route>
          </>}
      </IonGrid>
    </div>
  );
};

export default MassAssignmentPage;
