import {
  IonCol, IonGrid,
  IonLoading, IonRow, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import styles from './ConsumePasswordResetForm.module.scss';
import { networking } from '../../../api/networking';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
const passwordLength = {
  min: 8,
  max: 255
};
const ConsumePasswordResetForm: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();
  const passwordReset: E2U.V1.Models.PasswordReset | undefined = useAppSelector((state) => state.authentication.passwordReset);
  const isChangingPassword: boolean = useAppSelector((state) => state.loading.isLoading.changingPassword);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[] }>({});
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  });
  const { handleSubmit, register, watch } = methods;
  const errors = methods.formState.errors;

  const passwordConfirmation = watch('password', '');
  const router = useIonRouter();

  const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({
      name: 'changingPassword',
      value: true
    }));
    networking.post(`/api/v1/users/reset_password/${passwordReset?.token}/reset`, data)
      .then(() => {
        toasters.success(t('Password has been changed successfully. Please login with your new password.'));
        router.push('/login');
      })
      .catch((error: E2U.V1.Response.Error) => {
        if (error && error.response && error.response.data && error.response.data.message && error.response.data.message === 'Validation failed') {
          setValidationErrors(error.response.data.data);
        }
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'changingPassword',
          value: false
        }));
      });
  };
  return (
    <>
      {!isChangingPassword &&
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <IonGrid>
              {(validationErrors && Object.values(validationErrors).length > 0) && (
                <BigUp.Cards.Alert type='error'>
                  {t('Something went wrong. Please review the errors below, and try again.')}
                </BigUp.Cards.Alert>
              )}
              <IonRow>
                <IonCol className='ion-margin-bottom'>
                  <BigUp.OutlinedInput
                    placeholder={t('Enter password')}
                    type={'password'}
                    id={'password'}
                    name={'password'}
                    register={'password'}
                    customLabel={t('Password')}
                    forceError={errors.password?.message as string}
                    showErrorBadge
                    enterkeyhint='next'
                    validation={{
                      required: {
                        value: true,
                        message: t('Password is required')
                      },
                      minLength: {
                        value: 8,
                        message: t('Must be at least 8 characters')
                      },
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      },
                      maxLength: {
                        value: 255,
                        message: t('Password needs to be at most 255 characters')
                      }
                    }}
                    disabled={isChangingPassword}
                    serverError={
                      (validationErrors && validationErrors.password && validationErrors.password.length)
                        ? validationErrors.password.join(' ')
                        : undefined
                    }
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <BigUp.OutlinedInput
                    customLabel={t('Password confirmation')}
                    placeholder={t('Enter confirmation of password')}
                    type={'password'}
                    id={'password_confirmation'}
                    name={'password_confirmation'}
                    register={'password_confirmation'}
                    enterkeyhint='done'
                    forceError={errors.password_confirmation?.message as string}
                    showErrorBadge
                    validation={{
                      required: {
                        value: true,
                        message: t('Password is required')
                      },
                      minLength: {
                        value: passwordLength.min,
                        message: t(
                          'Must be at least {length} characters',
                          'Must be at least {length} characters', {
                            length: passwordLength.min
                          }
                        )
                      },
                      pattern: {
                        value: /(?=.*\d)(?=.*[a-ö])(?=.*[A-Ö])(?=.*[@$!%*#?&])/,
                        message: t('Password needs at least one small character, one large character, one digit and one special character (any of @$!%*#?&)')
                      },
                      maxLength: {
                        value: passwordLength.max,
                        message: t(
                          'Password needs to be at most {length} characters',
                          'Password needs to be at most {length} characters', {
                            length: passwordLength.max
                          }
                        )
                      },
                      validate: (value: string) => value === passwordConfirmation || t('Passwords do not match')
                    }}
                    disabled={isChangingPassword}
                    serverError={
                      (validationErrors && validationErrors.password_confirmation && validationErrors.password_confirmation.length)
                        ? validationErrors.password_confirmation.join(' ')
                        : undefined
                    }
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className={styles['button-wrapper']}>
              <IonRow className='ion-justify-content-space-between ion-align-items-center'>
                <IonCol size='12'>
                  <BigUp.Buttons.Primary
                    loading={isChangingPassword}
                    expand='full'
                    title={t('Change password')}
                    type='submit'
                    disabled={!methods.formState.isValid || methods.formState.isSubmitting || isChangingPassword}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </FormProvider>
      }
      <IonLoading isOpen={isChangingPassword} />
    </>
  );
};

export default ConsumePasswordResetForm;
