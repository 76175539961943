import React from 'react';

import BigUp from '..';
import type { BankIdButtonProps } from './bankid.interfaces';
import type { ButtonProps } from '../Buttons/interface';

type ButtonTypes = BankIdButtonProps & ButtonProps;

const BankIdButton: React.FC<ButtonTypes> = ({ children, onClick, ...props }) => {
  return (
    <BigUp.Buttons.Primary
      style={{
        '--background': 'var(--ion-color-bankid)',
        '--padding-start': 0,
        '--padding-end': 0,
        '--padding-top': '8px',
        '--padding-bottom': '8px',
      }}
      {...props}
      onClick={onClick}
    >
      {children}
    </BigUp.Buttons.Primary>
  );
};

export default BankIdButton;
