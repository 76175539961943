import { IonCol, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import CreateTeam from './CreateTeam';
import { networking } from '../../../api/networking';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import type { SelectListEntities } from '../../../components/Search/RelatedMultiSelect/MultiSelectList';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setTeams } from '../../../reducers/team';
import store from '../../../store';
import type { TeamsProps } from '../interfaces';

const TeamActionRow: React.FC<TeamsProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const teams = useAppSelector(state => state.team.teams);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const selectionActions = async (teamId: string, method: 'POST' | 'DELETE') => {
    const endpoint = `/api/v1/projects/${uuid}/teams/${teamId}`;
    await ({
      POST: networking.post,
      DELETE: networking.delete
    }[method])(endpoint)
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleSelection: SubmitHandler<FieldValues> = async (data) => {
    const existingIds = teams?.map((team: E2U.V1.Models.Team) => team.id) ?? [];

    const selectedIds = data.map((item: any) => item.id);
    const itemsToRemove = existingIds.filter(id => !selectedIds.includes(id));
    const itemsToAdd = data.filter((item: any) => !existingIds.includes(item.id));

    const removeActions = itemsToRemove.map((id: any) => () => selectionActions(id, 'DELETE'));

    const addActions = itemsToAdd.map((item: { id: string; }) => () => selectionActions(item.id, 'POST'));

    await executeActions([...removeActions, ...addActions])
      .finally(() => {
        networking.get(`/api/v1/projects/${uuid}/teams`)
          .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Team[]>>) => {
            store.dispatch(setTeams(response.data.data.records));
          });
      });
  };

  const executeActions = async (actions: (() => Promise<void>)[]) => {
    await Promise.all(actions.map(action => action()));
  };

  return (
    <IonRow className='ion-justify-content-end ion-margin-top ion-align-items-center'>
      <IonCol className='text-align-center'>
        <CreateTeam />

        <RelatedMultiSelectEdit
          {...isDesktop && { button: 'solid' }}
          records={props.teams}
          model='teams'
          label='Add teams'
          action='submit'
          value={teams}
          modalTitle={i18n.t('Select teams')}
          onChange={() => { }}
          postSelected={(data: SelectListEntities[]) => handleSelection(data)}
          displayFields={['name']}
          hideSelected
        />
      </IonCol>
    </IonRow >
  );
};

export default TeamActionRow;
