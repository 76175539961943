import type { IonIcon } from '@ionic/react';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonThumbnail } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { trashBinOutline } from 'ionicons/icons';
import { type ComponentProps, useEffect, useState } from 'react';

import BigUp from '..';
import imagePlaceholder from '../../../assets/img-placeholder.webp';
import storage from '../../../storage';

interface FileItemProps {
  name: string;
  description: string;
  source?: E2U.V1.Models.File;
  onPreview: () => void;
  icon?: {
    icon?: ComponentProps<typeof IonIcon>;
    onClick?: () => void;
  };
  triggerID?: string;
}

const FileItem: React.FC<FileItemProps> = ({ description, icon, name, onPreview, source, triggerID }) => {
  const [photoContent, setPhotoContent] = useState<{
    [fileHash: string]: string
  }>({});

  const updatePhotoPreviews = () => {
    storage.get(`pending-${source?.file_hash}`)
      .then((data) => {
        if (data) {
          setPhotoContent((prev) => ({
            ...prev,
            [`pending-${source?.file_hash}`]: data
          }));
        }
      });
  };

  const fileIconMap = new Map([
    ['pdf', BigUp.Icons.Pdf],
    ['png', BigUp.Icons.Png],
  ]);

  const fileExtension = (source?.filename ?? '').split('.').pop() || '';
  const IconComponent = fileIconMap.get(fileExtension);

  useEffect(() => {
    updatePhotoPreviews();
  }, [source]);

  return (
    <IonGrid className="ion-no-padding">
      <IonRow className='ion-align-items-center ion-justify-content-start'>
        <IonCol size='10' style={{ paddingLeft: '8px' }}>
          <IonItem lines='none' button detail={false}

            onClick={source?.type === 'image' ? () => onPreview() : undefined} className='ion-no-padding' >
            {source?.type === 'image'
              ? (
                <IonThumbnail slot="start" style={{ '--border-radius': '10px', '--size': '50px' }}>
                  <img
                    alt={name}
                    src={photoContent[`pending-${source?.file_hash}`]}
                    onError={() => setPhotoContent(imagePlaceholder as any)}
                  />
                </IonThumbnail>
              )
              : (IconComponent && (
                <div className='ion-padding-end'>
                  <IconComponent size='40px' />
                </div>
              ))
            }
            <IonLabel className="ion-text-nowrap ion-no-margin">
              <h4 style={{ fontWeight: 400 }}>{name}</h4>
              <p className='ion-no-margin'>{description}</p>
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol size='2'>
          <BigUp.Buttons.Icon
            className='ion-margin-start ion-padding-start'
            id={triggerID}
            mode='ios'
            color={'light'}
            icon={{
              icon: icon?.icon?.icon || trashBinOutline,
              color: icon?.icon?.color || 'dark',
              style: { fontSize: '30px' },
              ...icon?.icon,
            }}
            onClick={icon?.onClick}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default FileItem;
