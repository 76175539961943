import { IonItem, IonToggle } from '@ionic/react';
import type { ComponentProps, JSXElementConstructor } from 'react';

import type { HTMLGenericProps } from '../generic.interface';
import styles from './Toggle.module.scss';

type ConstructJSXElementComponent<T extends JSXElementConstructor<JSX.Element>, K> = ComponentProps<T> & HTMLGenericProps<K>;

type Toggle = ConstructJSXElementComponent<typeof IonToggle, HTMLIonToggleElement>

export interface ToggleProps extends Toggle {
  title: string | undefined;
}

const Toggle: React.FC<ToggleProps> = ({ title, ...props }) => {
  return (
    <IonItem className={`${styles['toggle-item-wrapper']} ion-no-padding`} lines={'none'}>
      <IonToggle
        {...props}
        mode='ios'

        justify='space-between'>
        {title}
      </IonToggle>
    </IonItem>
  );
};

export default Toggle;
