
import type { SVG } from './types';

export const Prognosis = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;
  return (
    <svg width={width || '43'} height={height || '35'} viewBox="0 0 43 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03886 2.235C10.0785 1.18025 11.4671 0.589844 13.327 0.589844C14.9376 0.589844 16.2916 1.22227 17.2243 2.30279C18.137 3.3599 18.5688 4.75169 18.5688 6.18115V9.2222C19.307 9.08391 20.2416 8.9768 21.3644 8.9768C22.4873 8.9768 23.4219 9.08391 24.1601 9.2222V6.18115C24.1601 4.75169 24.5919 3.3599 25.5045 2.30279C26.4373 1.22227 27.7913 0.589844 29.4019 0.589844C31.2618 0.589844 32.6504 1.18025 33.69 2.235C34.6573 3.21636 35.2229 4.50961 35.6812 5.66622L35.7368 5.81229L41.6956 21.7051C41.6956 21.7051 41.9219 22.3435 41.9503 22.4383C42.2041 23.2862 42.3326 24.1667 42.3318 25.0518C42.3318 30.0698 38.264 34.1377 33.246 34.1377C28.2279 34.1377 24.1601 30.0698 24.1601 25.0518V19.7697L24.1566 19.7688C23.5563 19.6199 22.6209 19.4605 21.3644 19.4605C20.108 19.4605 19.1726 19.6199 18.5723 19.7688L18.5688 19.7697V25.0518C18.5688 30.0698 14.5009 34.1377 9.48293 34.1377C4.46491 34.1377 0.397064 30.0698 0.397064 25.0518C0.397064 24.1433 0.530382 23.266 0.778583 22.4382C0.791804 22.3776 0.809335 22.3174 0.831176 22.2575C0.894554 22.0719 0.961957 21.8877 1.03334 21.705L7.04766 5.66622C7.50597 4.50961 8.07165 3.21636 9.03886 2.235ZM26.9557 25.0435C26.9602 21.5733 29.7747 18.7616 33.246 18.7616C35.8994 18.7616 38.1691 20.4045 39.0932 22.7286L39.2119 23.0527C39.4274 23.6972 39.5369 24.3723 39.5362 25.0518C39.5362 28.5257 36.7199 31.342 33.246 31.342C29.7732 31.342 26.9577 28.5277 26.9557 25.0553V25.0518M9.48293 18.7616C6.8296 18.7616 4.55996 20.4045 3.63565 22.7284L3.51701 23.0529C3.30152 23.6973 3.19199 24.3723 3.19272 25.0518C3.19272 28.5257 6.00898 31.342 9.48293 31.342C12.9569 31.342 15.7731 28.5257 15.7731 25.0518C15.7731 21.5778 12.9569 18.7616 9.48293 18.7616Z" fill="url(#paint0_linear_1392_23310)" />
      <defs>
        <linearGradient id="paint0_linear_1392_23310" x1="21.3644" y1="0.589844" x2="21.3644" y2="34.1377" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFC0CB" />
          <stop offset="1" stop-color="#800080" />
        </linearGradient>
      </defs>
    </svg>

  );
};
