import { IonAvatar, IonCol, IonGrid, IonIcon, IonItem, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { pencilOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider, useFormContext } from 'react-hook-form';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './UserSettingsStyle.module.scss';
import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import ListTitle from '../../../../components/UI/Titles/ListTitle';
import { useAppSelector } from '../../../../hooks';
import useProfilePicture from '../../../../hooks/useProfilePicture';
import i18n from '../../../../i18n';
import { setProfilePicture, setUser } from '../../../../reducers/authentication';
import store from '../../../../store';
import RegistrationInputs from '../../../Registration/RegistrationInputs';

const UpdateUser: React.FC = () => {
  const [avatar, setAvatar] = useState<string | ArrayBuffer | null>('');
  const { t } = useTranslation();
  const methods = useFormContext();
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    multiple: false,
    maxSize: 1073741824
  });
  const user = useAppSelector(state => state.authentication);
  const updateInformationToasters = {
    pending: i18n.t('Saving changes'),
    success: i18n.t('Your information has been updated.'),
    error: i18n.t('Something went wrong, try again later.')
  };
  const profilePicture = useProfilePicture();

  const updateUserSettings: SubmitHandler<FieldValues> = (data: FieldValues) => {
    toasters.promise(networking.put(`/api/v1/users/${user.user?.id}`, data), updateInformationToasters)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
        store.dispatch(setUser(response.data.data));
        if (acceptedFiles.length) {
          uploadProfilePicture();
        }
      });
  };

  const updateProfilePicture = (user: E2U.V1.Models.User) => {
    if (user.profile_picture_url) {
      networking.get(`${user.profile_picture_url}?base64=true`, {
        headers: {
          Accept: '*/*'
        }
      })
        .then((res) => {
          store.dispatch(setProfilePicture(`data:${res.headers['content-type']};base64,${res.data}`));
        })
        .catch(() => {
          store.dispatch(setProfilePicture(''));
        });
    }
  };

  const uploadProfilePicture = () => {
    const formData = new FormData();
    formData.set('file', acceptedFiles[0]);
    networking.post(`/api/v1/me/profile_picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        networking.get(`/api/v1/me`)
          .then((response: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
            store.dispatch(setUser(response.data.data));
            updateProfilePicture(response.data.data);
          });
      });
  };

  const getBase64 = (file: File) => {
    if (!file) return;
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      return reader.result;
    });
  };

  useEffect(() => {
    setAvatar(avatar);
    methods.reset({
      first_name: user.user?.first_name,
      last_name: user.user?.last_name,
      email: user.user?.email,
      phone_number: user.user?.phone_number,
    });
  }, [user]);

  useEffect(() => {
    getBase64(acceptedFiles[0])?.then((file: string | ArrayBuffer | null) => {
      setAvatar(file);
    });
  }, [acceptedFiles]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(updateUserSettings)}>
        <IonGrid>
          <IonRow className='ion-align-items-center'>
            <IonCol className='ion-margin-bottom'>
              <ListTitle label={i18n.t('User settings')} width={'100%'} />
            </IonCol>
            <IonCol size={'auto'} className={styles['avatar-container']}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <IonAvatar className='ion-no-padding ion-no-margin'>
                  <img src={avatar?.toString() || profilePicture} />
                  <IonIcon icon={pencilOutline} size='small' color='tertiary' />
                </IonAvatar>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonItem className='ion-no-margin ion-no-padding ion-margin-bottom'>
              <h4>{i18n.t('Personal information')}</h4>
            </IonItem>
          </IonRow>
          <RegistrationInputs />
          <IonRow>
            <IonCol className='ion-text-end'>
              <BigUp.Buttons.Regular
                title={t('Save')}
                color='secondary'
                type='submit'
                disabled={methods.formState.isSubmitting || !methods.formState.isValid}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default UpdateUser;
