import { IonContent, IonList, IonSplitPane } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

import styles from './SideMenu.module.scss';
import SideMenuOptions from './SideMenuOptions';
import { useAppSelector } from '../../../hooks';
import useEconomyMenuOptions from '../../../hooks/useEconomyMenuOptions';
import useToolsMenuOptions from '../../../hooks/useToolsMenuOptions';
import i18n from '../../../i18n';
import AppLayout from '../../../layout/AppLayout';
import ReturnChevronText from '../Return/ReturnChevronText';
import type { ColourVariables } from '../variables';
import { ionicColours } from '../variables';

export type BaseUrlOptions = 'financials' | 'tools' | 'notice-board';

interface DynamicContentProps {
  baseURL: BaseUrlOptions;
  children: React.ReactNode;
  returnRoute?: string;
  canReturn?: boolean;
  title?: string;
  color?: ColourVariables | undefined;
}

const SideMenuLayout: React.FC<DynamicContentProps> = ({
  baseURL,
  canReturn = false,
  children,
  color = 'var(--ion-color-light)',
  returnRoute,
  title
}) => {
  const { economyMenuItems } = useEconomyMenuOptions();
  const { toolsMenuItems } = useToolsMenuOptions();
  const menuItems = baseURL === 'notice-board' ? [] : (baseURL === 'financials' ? economyMenuItems : toolsMenuItems);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id ?? '');
  if (!isDesktop) {
    return (
      <AppLayout headerTitle={title} contentColour={color}>
        {children}
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div style={{ minHeight: '100vh' }}>
        <IonSplitPane contentId="main" style={{ marginTop: '100px' }}>
          <SideMenuOptions
            toolColour={ionicColours.primary}
            baseUrl={baseURL}
            uuid={selectedProjectId}
            label={i18n.t('Project tools')}
            menuItems={menuItems}
          />
          <div id="main" className={styles['split-pane-wrapper']}>
            <IonContent style={{ '--background': color }}>
              <IonList className={`${styles.listWrapper} ion-margin-bottom ion-padding-bottom ion-no-padding`}>
                {canReturn && (<ReturnChevronText text={t('Back')} color={'none'} fill={'none'} />)}
                {children}
              </IonList>
            </IonContent>
          </div >
        </IonSplitPane>
      </div>
    </AppLayout>
  );
};

export default SideMenuLayout;
