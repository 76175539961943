import {
  IonCol,
  IonGrid,
  IonRow,
  useIonModal
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import AccessRequestDetails from './AccessRequestDetails';
import StatusColumn from './Columns/StatusColumn';
import SiteAccessRequestsSettings from './Settings/Settings';
import styles from './SiteAccessRequests.module.scss';
import BigUp from '../../../components/UI';
import ContactTile from '../../EmergencyContacts/ContactTile';

const SiteAccessRequests: React.FC = () => {
  const { t } = useTranslation();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>(Date.now());
  const [currentlyEditedRequest, setCurrentlyEditedRequest] = useState<E2U.V1.Models.ProjectAccessRequest | null>(null);
  const [showModal, hideModal] = useIonModal(SiteAccessRequestDetails, {
    model: currentlyEditedRequest,
    dismiss: () => {
      hideModal();
      setLastUpdatedAt(Date.now());
    },
  });
  const { uuid } = useParams<{ uuid: string }>();

  return (
    <React.Fragment>
      <IonGrid className="ion-padding">
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Site access requests')} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className={styles['site-access-request-table-wrapper']}>
        <div className={styles['site-access-request-table']}>
          <BigUp.Table columns={
            [
              {
                key: 'personal_information.company_name',
                label: t('Company'),
                alignment: 'left',
                sortable: false,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'personal_information.first_name',
                label: t('Created by'),
                body: <ContactTile attributes={{} as E2U.V1.Models.ProjectAccessRequest} />,
                alignment: 'left',
                sortable: false,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'status',
                label: t('Status'),
                body: <StatusColumn value={null} labels={{
                  pending: 'Pending',
                  approved: 'Approved',
                  rejected: 'Rejected',
                }} />,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'hot_work',
                label: t('Hot work'),
                body: <StatusColumn value={null} />,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'heavy_lifting',
                label: t('Heavy lifting'),
                body: <StatusColumn value={null} />,
                sizes: {
                  xl: '2',
                }
              },
              {
                key: 'work_from_lift',
                label: t('Work from lift'),
                body: <StatusColumn value={null} />,
                sizes: {
                  xl: '2',
                }
              }
            ]
          }
          filters={[
            {
              label: t('Approved'),
              callback: (rows: E2U.V1.Models.ProjectAccessRequest[], setRows) => {
                setRows(rows.filter((row) => row.status === 'approved'));
              }
            },
            {
              label: t('Denied'),
              callback: (rows: E2U.V1.Models.ProjectAccessRequest[], setRows) => {
                setRows(rows.filter((row) => row.status === 'rejected'));
              }
            },
            {
              label: t('Pending'),
              callback: (rows: E2U.V1.Models.ProjectAccessRequest[], setRows) => {
                setRows(rows.filter((row) => row.status === 'pending'));
              }
            }
          ]}
          callbacks={{
            onRowClick: (row: E2U.V1.Models.ProjectAccessRequest) => {
              setCurrentlyEditedRequest(row);
              showModal();
            }
          }}
          sourceUrl={{
            url: `/api/v1/projects/${uuid}/access_requests`,
            args: {
              'with[]': [
                'otherCertificates.files',
                'hotWorkCertificates.files',
                'heavyLiftingCertificates.files',
                'workFromLiftCertificates.files',
              ]
            }
          }}
          timestamp={lastUpdatedAt} />
        </div>
      </div>
      <IonRow>
        <SiteAccessRequestsSettings />
      </IonRow>
    </React.Fragment>
  );
};

const SiteAccessRequestDetails: React.FC<{
  model: E2U.V1.Models.ProjectAccessRequest;
  dismiss: () => void;
}> = (props) => {
  return (
    <AccessRequestDetails details={props.model} detailToggle={() => {
      props.dismiss();
    }} />
  );
};

export default SiteAccessRequests;
