import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../../api/networking';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import { handleSource } from '../../../../components/ListsComponents/rowList/handlers';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import useAcl from '../../../../hooks/useAcl';
import ActionColumn from '../Columns/ActionColumn';

const EconomyReport: React.FC = () => {
  const { t } = useTranslation();
  const { project } = useAcl();
  const router = useIonRouter();
  const [updatedAt, setUpdatedAt] = useState(Date.now());

  const deleteRow = (id: string) => {
    toasters
      .promise(
        networking.delete(
          `/api/v1/economy_monitorings/${id}`
        ),
        {
          success: t('Successfully deleted report.'),
          pending: t('Deleting report...'),
          error: t("Couldn't delete report")
        }
      );
  };

  return (
    <DesktopWrapper>
      <IonGrid>
        <IonRow className={'ion-align-items-center'}>
          <IonCol>
            <BigUp.Title label={t('Economy reports')}/>
          </IonCol>
        </IonRow>
      </IonGrid>

      {project && <BigUp.Table columns={[
        {
          key: 'document_name',
          label: t('Reference'),
          alignment: 'left',
          sizes: {
            xs: '6',
            xl: '10'
          }
        },
        {
          key: 'actions',
          label: t('Actions'),
          sortable: false,
          body: <ActionColumn type="monitoring"
            attributes={{} as E2U.V1.Models.EconomyReport}
            callbacks={{
              onDelete: (id: string) => {
                deleteRow(id);
                setUpdatedAt(Date.now());
              }
            }}/>,
          sizes: {
            xs: '6',
            xl: '2'
          },
        }
      ]}
      callbacks={{
        onRowClick: (row: E2U.V1.Models.EconomyReport) => {
          const target = handleSource('monitoring', project?.id);

          if (typeof target === 'object') {
            router.push(`${target.routes.base}/${row.id}`);
          }
        }
      }}
      sourceUrl={`/api/v1/projects/${project.id}/economy_monitoring`}
      timestamp={updatedAt}/>}
    </DesktopWrapper>
  );
};
export default EconomyReport;
