import type { SVG } from './types';

export const Changes = (props: SVG) => {
  const { multiplyBy = 1, ...rest } = props;

  const width = 41 * multiplyBy;
  const height = 43 * multiplyBy;

  return (
    <svg width={width || '38'} height={height || '37'} viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.0829 20.1496L37.0829 20.1497C36.158 29.3322 28.4121 36.4998 18.9987 36.4998C12.8552 36.4998 7.42169 33.4447 4.13485 28.787L3.83201 28.3578V28.8831V31.6664C3.83201 32.491 3.15663 33.1664 2.33201 33.1664C1.50739 33.1664 0.832011 32.491 0.832011 31.6664V24.9998C0.832011 24.1751 1.50739 23.4998 2.33201 23.4998H8.99868C9.8233 23.4998 10.4987 24.1751 10.4987 24.9998C10.4987 25.8244 9.8233 26.4998 8.99868 26.4998H6.53201H6.22023L6.39342 26.759C9.10639 30.82 13.7407 33.4998 18.9987 33.4998C26.8684 33.4998 33.3562 27.5008 34.0979 19.8161C34.1725 19.071 34.8148 18.4998 35.582 18.4998C36.4684 18.4998 37.1727 19.2662 37.0829 20.1496ZM18.9987 0.166424L18.9987 0.166424C21.9132 0.165409 24.7852 0.865636 27.3722 2.20799C29.9592 3.55034 32.1852 5.49542 33.8624 7.879L34.1653 8.30958V7.78309V4.99976C34.1653 4.17514 34.8407 3.49976 35.6653 3.49976C36.49 3.49976 37.1653 4.17514 37.1653 4.99976V11.6664C37.1653 12.491 36.49 13.1664 35.6653 13.1664H28.9987C28.1741 13.1664 27.4987 12.491 27.4987 11.6664C27.4987 10.8418 28.1741 10.1664 28.9987 10.1664H31.4653H31.7771L31.6039 9.90717C28.891 5.84616 24.2566 3.16642 18.9987 3.16642C11.129 3.16642 4.64113 9.16541 3.89948 16.8501C3.82482 17.5952 3.18257 18.1664 2.41534 18.1664C1.53218 18.1664 0.840143 17.4028 0.91464 16.5151C1.84012 7.33324 9.58572 0.166424 18.9987 0.166424Z" fill="url(#paint0_linear_1392_23247)" stroke="url(#paint1_linear_1392_23247)" strokeWidth="0.333333" />
      <defs>
        <linearGradient id="paint0_linear_1392_23247" x1="18.9987" y1="-0.000244141" x2="18.9987" y2="36.6664" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7FD767" />
          <stop offset="1" stop-color="#468634" />
        </linearGradient>
        <linearGradient id="paint1_linear_1392_23247" x1="18.9987" y1="-0.000244141" x2="18.9987" y2="36.6664" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7FD767" />
          <stop offset="1" stop-color="#468634" />
        </linearGradient>
      </defs>
    </svg>

  );
};
