import type { Certificate } from 'crypto';

import type { InputProps } from '../../../components/UI/Inputs/Input.interface';
import i18n from '../../../i18n';

export type CertificateTypes = 'hot_work' | 'work_from_lift' | 'heavy_lifting' | 'other';
export const certificateInputFields: InputProps<Certificate>[] = [
  {
    label: i18n.t('Namn'),
    placeholder: i18n.t('e.g Hot work certificate'),
    register: 'name',
    enterkeyhint: 'next',
    validation: {
      required: {
        value: true,
        message: i18n.t('A certificate name is required')
      },
    },
  },
  {
    label: i18n.t('Issuing organization'),
    placeholder: i18n.t('e.g BBV'),
    register: 'issuing_organization',
    enterkeyhint: 'next',
    validation: {
      required: {
        value: true,
        message: i18n.t('An issuing organization is required')
      },
    },
  },
];

export const typeSelectOptions: { value: string; id: CertificateTypes }[] = [
  { value: i18n.t('Hot work'), id: 'hot_work' },
  { value: i18n.t('Heavy lifting'), id: 'heavy_lifting' },
  { value: i18n.t('Work from lift'), id: 'work_from_lift' },
  { value: i18n.t('Other'), id: 'other' },
];
