import { IonIcon, IonItem, IonLabel } from '@ionic/react';

import type { PanelProps } from './interface';

const Panel: React.FC<PanelProps> = (props) => {
  return (
    <IonItem
      detail={
        !props.icon.end?.icon
      }
      button={!props.disabled}
      style={{
        cursor: props.disabled ? 'initial' : 'cursor'.toString(),
        color: props.disabled ? 'var(--ion-color-medium)' : 'inherit'
      }}
      className='ion-no-padding'
      onClick={props.onClick}
    >
      <IonIcon
        icon={props.icon.start.icon}
        size='default'
        slot="start"
        color={props.icon.start.color || 'medium'}
        {...props.icon.start.rest}
      />
      <IonLabel>{props.panelTitle}</IonLabel>
      {props.icon.end?.icon && (
        <IonIcon
          icon={props.icon.end?.icon}
          slot="end"
          color={props.icon.end?.color || 'medium'}
          {...props.icon.end?.rest}
        />
      )}

    </IonItem>
  );
};

export default Panel;
