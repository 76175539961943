import {
  clipboardOutline,
  cogOutline,
  constructOutline,
  documentsOutline,
  idCardOutline,
  keyOutline,
  layersOutline
  ,
  mapOutline
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import useAcl from './useAcl';
import { useAppSelector } from '../hooks';
import type { SubpanelItem } from '../pages/DesktopCards';

const useToolsMenuOptions = () => {
  const { t } = useTranslation();
  const { scopedAbilities } = useAcl();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);

  const toolsMenuItems: SubpanelItem[] = [
    {
      subpanelTitle: t('Control of execution', 'Control of execution'),
      subpanelIcon: keyOutline,
      subpanelActiveColour: 'var(--ion-color-primary)',
      subpanelDisabled: !scopedAbilities.includes('can_view_control_of_executions_in_projects'),
      route: `${selectedProjectId}/control-of-execution`,
      value: 'control-of-execution',
      only_routing: false,
    },
    {
      subpanelTitle: t('Working enviroment'),
      subpanelIcon: constructOutline,
      subpanelActiveColour: 'var(--ion-color-warning)',
      subpanelDisabled: !scopedAbilities.includes('can_view_working_environments_in_projects'),
      route: `${selectedProjectId}/working-environment`,
      value: 'working-environment',
      only_routing: false
    },
    {
      subpanelTitle: t('Site Access Requests'),
      subpanelIcon: idCardOutline,
      subpanelActiveColour: 'var(--ion-color-success)',
      subpanelDisabled: !scopedAbilities.includes('can_view_access_requests_in_projects'),
      route: `${selectedProjectId}/site-access-requests`,
      value: 'site-access-requests',
      only_routing: false
    },
    {
      subpanelTitle: t('Documents'),
      subpanelDisabled: !scopedAbilities.includes('can_view_documents_in_projects'),
      subpanelIcon: documentsOutline,
      subpanelActiveColour: '#181D3D',
      route: `${selectedProjectId}/documents`,
      value: 'documents',
      only_routing: false
    },
    {
      subpanelTitle: t('Worksites'),
      subpanelIcon: layersOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_work_sites_in_projects'),
      route: `${selectedProjectId}/worksites`,
      value: 'worksites',
      only_routing: false
    },
    {
      subpanelTitle: t('Map'),
      subpanelIcon: mapOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_work_sites_in_projects'),
      route: `${selectedProjectId}/map`,
      value: 'map/:worksite_layer_id?',
      only_routing: true
    },
    {
      subpanelTitle: t('Work preparations'),
      subpanelIcon: clipboardOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: !scopedAbilities.includes('can_view_protocols_in_projects'),
      route: `work-preparations/${selectedProjectId}`,
      value: 'work-preparations',
      only_admin: true,
      only_routing: false
    },
    {
      subpanelTitle: t('Settings'),
      subpanelIcon: cogOutline,
      subpanelActiveColour: '#181D3D',
      subpanelDisabled: false,
      route: `${selectedProjectId}/settings`,
      value: 'settings',
      only_admin: true,
      only_routing: false
    },
  ];

  return {
    toolsMenuItems
  };
};

export default useToolsMenuOptions;
