import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import type { TFunction } from 'i18next';
import type { ComponentProps } from 'react';

import styles from './Header.module.scss';
import type { ButtonProps } from '../../Buttons/interface';

export interface WrapperHeaderProps {
  title: TFunction;
  end?: {
    onClick: () => void;
    icon?: ComponentProps<typeof IonIcon>['icon'];
    button?: ButtonProps;
  }
  start?: {
    onClick: () => void;
    icon?: ComponentProps<typeof IonIcon>['icon'];
    button?: ButtonProps;
  }
}

export const ModalHeader: React.FC<WrapperHeaderProps> = (props) => {
  const { end, start, title } = props;
  return (
    <IonHeader className={`ion-no-border  ${styles['modal-header']}`}>
      <IonToolbar mode='ios' className='ion-padding-top' color={'light'}>
        {start && (
          <IonButtons slot="start" className={styles['header-button-start']} >
            <IonButton
              {...start.button}
              onClick={start.onClick}
            >
              {start.button?.title
                ? start.button.title
                : <IonIcon slot="icon-only" icon={start.icon} />
              }
            </IonButton>
          </IonButtons>
        )}
        <IonTitle>{title}</IonTitle>
        {end && (
          <IonButtons slot="end" className={styles['header-button-start']}>
            <IonButton
              {...end.button}
              onClick={end.onClick}
            >
              {end.button?.title
                ? end.button?.title
                : <IonIcon slot="icon-only" icon={end.icon} />
              }
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
