import * as Sentry from '@sentry/capacitor';
import { close, ribbon } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBankIdContext } from '../../../bankid/bankid.context';
import { useAppSelector } from '../../../hooks';
import useModal from '../../../hooks/useModal';
import i18n from '../../../i18n';
import { setUser } from '../../../reducers/authentication';
import storage from '../../../storage';
import store from '../../../store';
import BigUp from '../../UI';
import BankID from '../../UI/BankID/BankID';
import toasters from '../../UI/Toasts';

const BankIdAuthenticate: React.FC = () => {
  const { t } = useTranslation();
  const bankIdModal = useModal();
  const bankid = useBankIdContext();
  const [bankdIdError, setBankIdError] = useState<string>();
  const user = useAppSelector(state => state.authentication.user);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const initiateBankIdAuthentication = async () => {
    bankIdModal.openModal();
    bankid?.sign.initiateBankIdSign(t('Connect my account {email} with my personal number', 'Connect my account {email} with my personal number', { email: user?.email }));
  };

  const handleBankIdCompleted = (user_details: any, device_details: any) => {
    storage.get('access_token')
      .then((accessToken: string) => {
        bankid?.associate.associateBankId(user_details.personal_number, accessToken)
          .then(() => {
            bankid.abortBankIdAuthentication();
            bankIdModal.closeModal();
            toasters.createToast({
              message: i18n.t('BankID successfully connected'),
              background: 'var(--ion-color-light)',
              textColour: 'var(--ion-color-dark)',
              iconColour: 'success',
              icon: ribbon,
            }, 'success');
            store.dispatch(setUser({ ...user, ssn: user_details.personal_number }));
          })
          .catch((error) => {
            Sentry.captureException(error);
            setBankIdError(t('Could not connect BankID to account {email}', 'Could not connect BankID to account {email}', { email: user?.email }));
            bankid.abortBankIdAuthentication();
            toasters.createToast({
              message: i18n.t('Could not connect BankID to account {email}', 'Could not connect BankID to account {email}', { email: user?.email }),
              background: 'var(--ion-color-light)',
            }, 'error');
          })
          .finally(() => {
            bankid.abortBankIdAuthentication();
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const bankdIdModalClose = () => {
    bankid?.abortBankIdAuthentication();
    bankIdModal.closeModal();
    setBankIdError('');
  };

  return (
    <>
      <BankID.BankIdButton shape='round' expand='full' onClick={() => initiateBankIdAuthentication()} title={undefined}>
        {t('Authenticate')} <BankID.BankIdIcon color='white' width={40} />
      </BankID.BankIdButton>
      <BigUp.Modal.Wrapper
        isOpen={bankIdModal.isModalOpen}
        dismiss={() => bankdIdModalClose()}
        header={{
          title: t('Authenticate with BankID'),
          end: {
            icon: close,
            onClick: () => bankdIdModalClose(),
          },
        }}>
        <BankID.BankIdSignForm
          handleBankIdCompleted={(user, device) => handleBankIdCompleted(user, device)}
          type='sign'
          error={bankdIdError ?? ''}
        />
      </BigUp.Modal.Wrapper>
    </>
  );
};

export default BankIdAuthenticate;
