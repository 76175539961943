import { alert, alertCircle, informationCircle, warning } from 'ionicons/icons';

import type { NoticeFormValues } from './Form/form.interface';
import type { NoticeBoardObject } from './notice.models';
import type { ColourVariables } from '../../components/UI/variables';
import { ionicColours } from '../../components/UI/variables';
import i18n from '../../i18n';

export const desktopWidth = 1000;

export const noticeToasters = {
  pending: 'Loading...',
  error: 'Error',
};
export const statusVariables = {
  unseen: 'unseen',
  seen: 'seen',
  archived: 'archived',
  all: 'all',
};

export const statusMap = new Map([
  [statusVariables.unseen, 'New'],
  [statusVariables.seen, 'Seen'],
  [statusVariables.archived, 'Archived'],
]);

export const legendList = [
  { colour: ionicColours.primary, label: i18n.t('Information') },
  { colour: ionicColours.warning, label: i18n.t('Warning') },
  { colour: ionicColours.danger, label: i18n.t('Danger') },
  { colour: ionicColours.danger, label: i18n.t('Emergency') },
];

type NoticeLevelMap = Map<NoticeFormValues['level'], ColourVariables>;

export const levelColour: NoticeLevelMap = new Map([
  ['info', 'var(--ion-color-primary)'],
  ['warning', 'var(--ion-color-warning)'],
  ['emergency', 'var(--ion-color-danger)'],
  ['danger', 'var(--ion-color-danger)'],
]);

export const findNoticeLevel = (level: NoticeBoardObject['level']) => {
  const levelMap = {
    info: { color: 'primary', icon: informationCircle },
    danger: { color: 'danger', icon: alertCircle },
    emergency: { color: 'danger', icon: alert },
    warning: { color: 'warning', icon: warning },
  };

  return levelMap[level] || {};
};
