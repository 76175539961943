import format from 'number-format.js';

export default function formatNumber(num: number | string, strict?: boolean, decimalsPoints?: number) {
  if (typeof num === 'undefined' || num === null) return num;
  if (num === '0') return num; // No need to format a string '0'
  if (num === '0.00') return '0'; // No need to format a string '0.00'

  let value = num.toString();

  switch (num) {
    case 0:
      return '0';
    default:
      if (!/.*\.00$/.test(value) && value.indexOf('.') > -1) {
        value = format(`# ###.${'0'.repeat(decimalsPoints || 2)}`, num as number);

        if (strict) {
          value = value.replace(/0+$/, '');
          value = value.replace(/\.$/, '');
        }
      } else {
        value = format('# ###.', num as number);
      }

      return value;
  }
}

export const defaultFormatter = (value: number) => formatNumber(value) || '0';
